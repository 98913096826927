import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { styled, Menu, MenuItem, Button, ListItemIcon, MenuProps, ListItemText } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { color } from '../styles';
import { selfDataSelector } from '../redux/config/selfData';
import { residentListSettingsSlice } from '../redux/config/residentListSettings';
import { IGetUnitRes } from '../types/api/unit/getUnit';
import { SortType } from '../types/components';

interface Props {
  title: string;
  conditions: 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit';
  unitId: number | null;
  sortType?: SortType;
  rightElement?: React.ReactNode;
  onUpdate: () => void;
  onChangeListSettings: (
    unitId: number | null,
    condition?: 'deviceAssigned' | 'deviceAttached',
    sortType?: SortType
  ) => void; // unitもしくは施設全体
}

const Container = css`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = css`
  display: flex;
  align-items: center;
`;

const Title = css`
  font-size: 20px;
  font-weight: bold;
  margin-right: 16px;
`;

const StyledButton = styled(Button)({
  borderRadius: '100px',
  color: `${color.dark_gray}`,
  background: `${color.white}`,
  '&:hover': {
    backgroundColor: `${color.white}`,
  },
});

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(() => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      justifyContent: 'flex-end',
    },
  },
}));

const RightElementContainer = css`
  display: flex;
`;

export const ResidentListHeader = React.memo(
  ({ title, sortType, conditions, unitId, rightElement, onUpdate, onChangeListSettings }: Props) => {
    const { selfUser, selfUnits } = useSelector(selfDataSelector);
    const dispatch = useDispatch();
    const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(null);
    const [anchorElSort, setAnchorElSort] = React.useState<null | HTMLElement>(null);

    const isFilterMenuOpen = Boolean(anchorElFilter);
    const isSortMenuOpen = Boolean(anchorElSort);
    const [units, setUnits] = React.useState<IGetUnitRes[]>();
    const { t } = useTranslation();

    const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElFilter(event.currentTarget);
    };

    const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElSort(event.currentTarget);
    };

    const handleCloseFilter = () => {
      setAnchorElFilter(null);
    };

    const handleCloseSort = () => {
      setAnchorElSort(null);
    };

    const handleChangeSortType = React.useCallback(
      (sortType: SortType) => {
        const fetchCondition = ['deviceAssigned', 'deviceAttached'].includes(conditions)
          ? (conditions as 'deviceAssigned' | 'deviceAttached')
          : undefined;
        onChangeListSettings(unitId, fetchCondition, sortType);
        dispatch(residentListSettingsSlice.actions.setSortType(sortType));
        localStorage.setItem('sort', sortType);
        handleCloseSort();
      },
      [onChangeListSettings, selfUser, sortType]
    );

    const handleChangeListItem = React.useCallback(
      (condition: 'deviceAssigned' | 'deviceAttached') => {
        onChangeListSettings(selfUser.nursingHomeUnitId ?? null, condition, sortType);
        dispatch(
          residentListSettingsSlice.actions.setFilterCondition({
            conditions: condition,
            unitId: selfUser.nursingHomeUnitId ? selfUser.nursingHomeUnitId : unitId,
          })
        );
        localStorage.setItem('condition', condition);
        localStorage.setItem('unit', selfUser.nursingHomeUnitId ? String(selfUser.nursingHomeUnitId) : 'null');
        handleCloseFilter();
      },
      [onChangeListSettings, selfUser]
    );

    const handleChangeUnit = React.useCallback(
      (unitId: number | null) => () => {
        if (typeof onChangeListSettings === 'function') {
          onChangeListSettings(unitId, undefined, sortType);
          dispatch(
            residentListSettingsSlice.actions.setFilterCondition({
              conditions: !unitId || selfUser.nursingHomeUnitId ? 'all' : 'unit',
              unitId: selfUser.nursingHomeUnitId ? selfUser.nursingHomeUnitId : unitId,
            })
          );
          localStorage.setItem('condition', !unitId ? 'all' : 'unit');
          localStorage.setItem('unit', String(selfUser.nursingHomeUnitId ? selfUser.nursingHomeUnitId : unitId));
          handleCloseFilter();
        }
      },
      [onChangeListSettings, selfUser]
    );

    React.useEffect(() => {
      if (!selfUser || !selfUnits || selfUser.hasUnitPerm) return;
      setUnits(selfUnits);
    }, [selfUser, selfUnits]);

    return (
      <div css={Container}>
        <div css={TitleContainer}>
          <div css={Title}>{title}</div>
          <StyledButton onClick={onUpdate} startIcon={<RefreshIcon color='primary' />}>
            {t('update', '更新')}
          </StyledButton>
        </div>
        <div css={RightElementContainer}>
          {rightElement && rightElement}
          {sortType && (
            <>
              <Button onClick={handleSortClick}>
                <span style={{ color: color.black }}>{t('Sort', '並び替え')}</span>
                <SortIcon />
              </Button>
              <StyledMenu open={isSortMenuOpen} anchorEl={anchorElSort} onClose={handleCloseSort}>
                <MenuItem onClick={() => handleChangeSortType('unit')}>
                  <ListItemIcon>{sortType === 'unit' && <CheckIcon />}</ListItemIcon>
                  <ListItemText>{t('ByUnit', 'ユニット順')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleChangeSortType('notification')}>
                  <ListItemIcon>{sortType === 'notification' && <CheckIcon />}</ListItemIcon>
                  <ListItemText>{t('ByNotificationElapsedTime', '通知経過時間順')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleChangeSortType('careTiming')}>
                  <ListItemIcon>{sortType === 'careTiming' && <CheckIcon />}</ListItemIcon>
                  <ListItemText>{t('ByToiletingCaretimePriority', '排泄ケア時刻優先度順')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleChangeSortType('notificationAndCareTiming')}>
                  <ListItemIcon>{sortType === 'notificationAndCareTiming' && <CheckIcon />}</ListItemIcon>
                  <ListItemText>
                    {t('ByNotificationElapsedTimeAndToiletingCaretimePriority', '通知経過時間+排泄ケア時刻優先度順')}
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleChangeSortType('createdAt')}>
                  <ListItemIcon>{sortType === 'createdAt' && <CheckIcon />}</ListItemIcon>
                  <ListItemText>{t('ByCreationOrder', '作成順')}</ListItemText>
                </MenuItem>
              </StyledMenu>
            </>
          )}

          <Button onClick={handleFilterClick}>
            <span style={{ color: color.black }}>{t('filter', '絞り込み')}</span>
            <>{conditions === 'all' ? <ArrowDropDownIcon /> : <FilterAltIcon />}</>
          </Button>
          <StyledMenu open={isFilterMenuOpen} anchorEl={anchorElFilter} onClose={handleCloseFilter}>
            <MenuItem onClick={handleChangeUnit(selfUser.nursingHomeUnitId ?? null)}>
              <ListItemIcon>{conditions === 'all' && <CheckIcon />}</ListItemIcon>
              <ListItemText>{t('all', 'すべて')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleChangeListItem('deviceAssigned')}>
              <ListItemIcon>{conditions === 'deviceAssigned' && <CheckIcon />}</ListItemIcon>
              <ListItemText>{t('DFreeIsAssigned', 'DFree登録されている方')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleChangeListItem('deviceAttached')}>
              <ListItemIcon>{conditions === 'deviceAttached' && <CheckIcon />}</ListItemIcon>
              <ListItemText>{t('DFreeIsAttached', 'DFree装着中の方')}</ListItemText>
            </MenuItem>
            {!selfUser.hasUnitPerm && units && units.length > 0 && (
              <div>
                {units.map((unit) => (
                  <MenuItem key={unit.id} onClick={handleChangeUnit(unit.id)}>
                    <ListItemIcon>{conditions === 'unit' && unitId === unit.id && <CheckIcon />}</ListItemIcon>
                    <ListItemText>{unit.name}</ListItemText>
                  </MenuItem>
                ))}
              </div>
            )}
          </StyledMenu>
        </div>
      </div>
    );
  }
);
