import React from 'react';
import { css } from '@emotion/react';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';

interface Props {
  facilityType: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Container = css`
  display: flex;
`;

export const FacilityRadioGroup = React.memo(
  ({ facilityType, onChange }: Props) => {
    return (
      <div css={Container}>
        <RadioGroup value={facilityType} onChange={onChange} row>
          <FormControlLabel value='unit' control={<Radio />} label='ユニット' />
          <FormControlLabel
            value='nursingHome'
            control={<Radio />}
            label='施設全体'
          />
        </RadioGroup>
      </div>
    );
  }
);
