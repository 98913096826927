import React from 'react';
import { Cell, CellBase, HeaderCell, Row, Table, TextWrap } from '../../../styles/table';
import { ITraversalOrder } from '../../../types/api/traversal/order';
import { numInsertComma } from '../../../utils/dataFormatUtil';

interface Props {
  data: ITraversalOrder[];
}

// テーブルのセルの横幅を左から順に定義
const CellWidth = [
  { flex: '2 0 180px' },
  { flex: '1 1 80px' },
  { flex: '1 1 80px' },
  { flex: '1 1 90px' },
  { flex: '1 1 90px' },
  { flex: '1 1 90px' },
  { flex: '1 1 90px' },
  { flex: '1 1 80px' },
];

export const OrderedDiaperTable = React.memo(({ data }: Props) => {
  const tableHeader = ['シリーズ/製品名', 'サイズ', '枚数', 'ケース入数', 'ケース単価', '発注数', '合計枚数', '納品額'];

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0], TextWrap]}>
                {data.diaperSeries}
                <br />
                {data.diaperShorten}
              </div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.diaperDisplaySize ?? '-'}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{data.diaperSheets ?? 0}枚</div>
              <div css={[CellBase, Cell, CellWidth[3]]}>{data.diaperBags ?? 0}袋</div>
              <div css={[CellBase, Cell, CellWidth[4]]}>{numInsertComma(data.caseCost ?? 0)}円</div>
              <div css={[CellBase, Cell, CellWidth[5]]}>{data.amount ?? 0}ケース</div>
              <div css={[CellBase, Cell, CellWidth[6]]}>{data.amount * data.diaperBags * data.diaperSheets}枚</div>
              <div css={[CellBase, Cell, CellWidth[7]]}>{numInsertComma(data.deliveryPrice ?? 0)}円</div>
            </div>
          );
        })}
      </div>
    </>
  );
});
