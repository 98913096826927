import React from 'react';
import { StyledTextField } from '../mui/textfield';
import { TableLinkCell } from '../../styles/table';
import { css } from '@emotion/react';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  value: boolean; // 入力フォームに表示する初期値
  onChange: (value: boolean) => void;
}

export const CheckBoxCell = React.memo(({ value, onChange }: Props) => {
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked);
    onChange(checked);
  };

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  return <Checkbox checked={checked} onChange={handleCheckBox} />;
});
