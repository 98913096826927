import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// 言語jsonファイルのimport
import TranslationEn from './en.json';
import TranslationJa from './ja.json';

const resources = {
  ja: {
    translation: TranslationJa,
  },
  en: {
    translation: TranslationEn,
  },
};

// ブラウザの情報が最優先で言語の変更を検知
const detector = new LanguageDetector(null, {
  order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
  htmlTag: document.documentElement,
});

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
