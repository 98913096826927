import React from 'react';
import dayjs from 'dayjs';
import { css } from '@emotion/react';
import api from '../../../api';
import { color } from '../../../styles';
import { setApiDateFormat, setTableDateFormat } from '../../../utils/dataFormatUtil';
import { DateSelector } from '../../../components/mui/dateSelector';
import { IGetCareRecordSummary } from '../../../types/api/traversal/getCareRecordSummary';
import { Loading } from '../../../components/mui/Loading';
import { MenuItemProps, Select } from '../../../components/mui/select';
import { TERM } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  residentId: string;
}

const Container = css`
  padding: 16px 24px 24px;
`;

const Header = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const DateSelectContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DateSelect = css``;

const TableContainer = css`
  overflow-x: auto;
`;

const Table = css`
  width: 100%;
  min-width: 600px;
  text-align: center;
  border: 1px solid ${color.border_gray};
`;

const TableHeader = css`
  background-color: ${color.table_gray};
`;

const ThCell = css`
  padding: 8px 4px;
  border: 1px solid ${color.border_gray};
  border-right: 1px solid ${color.white};
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: ${color.dark_gray};
  &:last-child {
    border-right: 1px solid ${color.border_gray};
  }
`;

const TdCell = css`
  border-right: 1px solid ${color.border_gray};
  border-bottom: 1px solid ${color.border_gray};
  padding: 16px 4px;
  font-size: 14px;
  font-weight: bold;
  color: ${color.dark_gray};
  font-family: Oswald;
  font-style: normal;
  letter-spacing: 0.8px;
`;

const Term = css`
  text-align: left;
  padding: 8px 16px;
`;

const CellMinWidth = css`
  min-width: 80px;
`;

const SelectContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ResidentDetailCareRecordData: React.FC<Props> = ({ residentId }) => {
  const { t, i18n } = useTranslation();
  const [date, setDate] = React.useState<{ start: dayjs.Dayjs; end: dayjs.Dayjs }>({
    start: dayjs(new Date()).subtract(3, 'month'),
    end: dayjs(new Date()),
  });
  const [summaries, setSummaries] = React.useState<IGetCareRecordSummary['summaries']>();
  const [term, setTerm] = React.useState(10);

  const handleChangeStartDate = React.useCallback(
    (start: dayjs.Dayjs) => {
      setDate((prev) => {
        return { ...prev, start };
      });
    },
    [date]
  );

  const handleChangeEndDate = React.useCallback(
    (end: dayjs.Dayjs) => {
      setDate((prev) => {
        return { ...prev, end };
      });
    },
    [date]
  );

  const handleChangeTerm = React.useCallback((e: MenuItemProps) => {
    setTerm(+e.key);
  }, []);

  const fetchAnalyticsMonthly = React.useCallback(async () => {
    const [from, to] = setApiDateFormat(date.start, date.end);
    const res = await api.get('/traversal/residents/:residentId/care-records/summary', {
      params: { residentId, from, to, term, sort: 'desc' },
    });
    const summaries = res.summaries.reverse();
    setSummaries(summaries);
  }, [date, term]);

  React.useEffect(() => {
    fetchAnalyticsMonthly();
  }, [date, term]);

  return (
    <>
      {summaries ? (
        <div css={Container}>
          <div css={Header}>
            <div css={DateSelectContainer}>
              <div css={DateSelect}>
                <DateSelector newValue={date.start} maxDate={date.end} onChange={handleChangeStartDate} />
              </div>
              〜
              <div css={DateSelect}>
                <DateSelector
                  newValue={date.end}
                  minDate={date.start}
                  maxDate={dayjs(new Date())}
                  onChange={handleChangeEndDate}
                />
              </div>
            </div>
            <div css={SelectContainer}>
              <Select listData={TERM} value={term} onChange={handleChangeTerm} />
              {t('days', '日ごと')}
            </div>
          </div>
          <div css={TableContainer}>
            <table css={Table}>
              <thead css={TableHeader}>
                <tr>
                  <th css={[ThCell, CellMinWidth, Term]}>{t('period', '期間')}</th>
                  <th css={[ThCell, CellMinWidth]}>{t('urinationPerDay', '1日あたりの排尿回数')}</th>
                  <th css={[ThCell, CellMinWidth]}>{t('incontinenceRate', '失禁率')}</th>
                  <th css={[ThCell, CellMinWidth]}>{t('toiletGuidanceNoUrgeRate', '空振り率')}</th>
                  <th css={[ThCell, CellMinWidth]}>{t('toiletGuidanceAndUrination', 'トイレ誘導成功率')}</th>
                  {i18n.language === 'ja' && (
                    <>
                      <th css={ThCell}>排尿量</th>
                      <th css={ThCell}>飲水</th>
                      <th css={ThCell}>下剤量</th>
                      <th css={ThCell}>
                        1日あたりの
                        <br />
                        テープ/パンツ枚数
                        <br />
                        平均
                      </th>
                      <th css={ThCell}>
                        1日あたりの
                        <br />
                        パッド枚数
                        <br />
                        平均
                      </th>
                    </>
                  )}
                  <th css={[ThCell, CellMinWidth]}>{t('avgWokingTime', '1日あたりのケア時間（分）')}</th>
                </tr>
              </thead>
              <tbody>
                {summaries.map((summary, index) => (
                  <tr key={index}>
                    <td css={[TdCell, CellMinWidth, Term]}>
                      {setTableDateFormat(summary.from)}〜{setTableDateFormat(summary.to)}
                    </td>
                    <td css={[TdCell, CellMinWidth]}>
                      {summary.avgUrineCount ? Math.round(summary.avgUrineCount * 10) / 10 : '0'} {t('times', '回')}
                    </td>
                    <td css={[TdCell, CellMinWidth]}>
                      {summary.incontinenceRate ? Math.round(summary.incontinenceRate * 100 * 10) / 10 : '0'} %
                    </td>
                    <td css={[TdCell, CellMinWidth]}>
                      {summary.missRate ? Math.round(summary.missRate * 100 * 10) / 10 : '0'} %
                    </td>
                    <td css={[TdCell, CellMinWidth]}>
                      {summary.successRate ? Math.round(summary.successRate * 100 * 10) / 10 : '0'} %
                    </td>
                    {i18n.language === 'ja' && (
                      <>
                        <td css={[TdCell, CellMinWidth]}>
                          {summary.avgUrineVolume ? Math.round(summary.avgUrineVolume * 10) / 10 : '0'} ml
                        </td>
                        <td css={[TdCell, CellMinWidth]}>
                          {summary.avgDrinkVolume ? Math.round(summary.avgDrinkVolume * 10) / 10 : '0'} ml
                        </td>
                        <td css={[TdCell, CellMinWidth]}>
                          {summary.avgLaxVolume ? Math.round(summary.avgLaxVolume * 10) / 10 : '0'} ml
                        </td>
                        <td css={[TdCell, CellMinWidth]}>
                          {summary.avgCountOfDiaper ? Math.round(summary.avgCountOfDiaper * 10) / 10 : '0'} 枚
                        </td>
                        <td css={[TdCell, CellMinWidth]}>
                          <div>{summary.avgCountOfPad ? Math.round(summary.avgCountOfPad * 10) / 10 : '0'} 枚</div>
                          <div>{summary.avgVolumeOfPad ? Math.round(summary.avgVolumeOfPad * 10) / 10 : '0'} ml</div>
                        </td>
                      </>
                    )}
                    <td css={[TdCell, CellMinWidth]}>
                      {summary.avgWokingTime? Math.round(summary.avgWokingTime * 10) / 10 : '0'} {t('minutes', '分')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};
