import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory } from 'react-router-dom';
import { color } from '../../styles';

interface Props {
  tabsLink: {
    label: string;
    href: string;
  }[];
  selectedTabIndex: number;
  onChange: (tabIndex: number) => void;
}

interface LinkTabProps {
  label: string;
  href: string;
}

const LinkTab = (props: LinkTabProps) => {
  const { href } = props;
  const history = useHistory();
  return (
    <Tab
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        history.push(href);
      }}
      {...props}
    />
  );
};

export const NavTabs = React.memo(({ tabsLink, selectedTabIndex, onChange }: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: '100%', borderBottom: `1px solid ${color.border_gray}` }}>
      <Tabs value={selectedTabIndex} onChange={handleChange} aria-label='nav tabs example'>
        {tabsLink.map((tab) => (
          <LinkTab key={tab.href} label={tab.label} href={tab.href} />
        ))}
      </Tabs>
    </Box>
  );
});
