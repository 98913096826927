import React from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { PanelContainer } from '../../../components/panel';
import {
  DAY_OF_WEEK,
  ORDER_STATUS,
  DEFAULT_ORDER_LIMIT_DAY_OF_WEEK,
  DEFAULT_ORDER_SHIPPING_DAY_OF_WEEK,
  DEFAULT_ORDER_LIMIT_HOUR,
  DEFAULT_ORDER_SHIPPING_DAY,
} from '../../../constants';
import { sortArray } from '../../../utils/arrayUtil';
import { MenuItemProps } from '../../../components/mui/select';
import { useFetchOrdersSummary } from '../../../hooks/api/traversal/useFetchOrdersSummary';
import { useFetchMasterDiapers } from '../../../hooks/api/master/useFetchMasterDiapers';
import { useFetchNursingHomeDiapers } from '../../../hooks/api/careSettings/useFetchNursingHomeDiapers';
import { IGetOrderSummaryRes } from '../../../types/api/traversal/getOrderSummary';
import { getCurrentUtcTime } from '../../../utils/dateutil';
import { HistoryFilterSelector } from './historyFilterSelector';
import { OrderDetails } from './orderDetails';
import { useFetchOrderableDiapers } from '../../../hooks/multi/useFetchOrderableDiapers';
import { Loading } from '../../../components/mui/Loading';
import { useLoading } from '../../../contexts/LoadingContext';
import { useTranslation } from 'react-i18next';

export interface IDisplayOrderSummary extends IGetOrderSummaryRes {
  displayOrderDate: string; // 発送日
  displayOrderDeliveryDate: string; // 納品日
  displayUnitName: string; // ユニット名
  displayOrderStatus: string; // オーダーステータス
  totalDeliveryPrice: number; // 納品額
}

const Description = css`
  margin-bottom: 16px;
  p {
    font-size: 16px;
    margin: 2px 0;
  }
`;

const BoldText = css`
  font-size: 16px;
  font-weight: bold;
`;

const ContentIsEmpty = css`
  display: flex;
  justify-content: center;
  margin: 32px;
`;

export const OrderHistory = React.memo(() => {
  const { showLoading, hideLoading } = useLoading();
  const { i18n } = useTranslation();
  const { ordersSummary, fetchOrdersSummary } = useFetchOrdersSummary(false);
  const { masterDiapers, fetchMasterDiapers } = useFetchMasterDiapers();
  const { nursingHomeDiapers, fetchNursingHomeDiapers } = useFetchNursingHomeDiapers();
  const orderable = useFetchOrderableDiapers(true, 'asc');
  const [unitFilterId, setUnitFilterId] = React.useState<number>();
  const [dateFilterId, setDateFilterId] = React.useState<number>();
  const [formattedOrdersSummary, setFormattedOrdersSummary] = React.useState<IDisplayOrderSummary[]>();

  dayjs.locale(i18n.language);

  const handleChangeDateFilter = (selected: MenuItemProps) => {
    setDateFilterId(+selected.key);
  };

  // ユニットの絞り込みに対して id:0は施設全体 id:999999は全て その他はユニットIDを表す
  const handleChangeUnitFilter = (selected: MenuItemProps) => {
    setUnitFilterId(+selected.key);
  };

  const insertDisplayOrderDate = (orderSummary: IGetOrderSummaryRes) => {
    return orderSummary.orderDate ? dayjs(orderSummary.orderDate).format('YYYY年MM月DD日(dddd) HH:mm') : '-';
  };

  const fetchOrderSummary = React.useCallback(async () => {
    if (unitFilterId === undefined || dateFilterId === undefined) return;

    const to = getCurrentUtcTime();
    const from = dayjs(to).subtract(dateFilterId, 'M').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');

    // ユニットフィルターで全て（999999）を選択した場合はparamsのunitIdをnullとして送る
    showLoading();
    if (unitFilterId === 999999) {
      await fetchOrdersSummary(null, from, to);
    } else {
      await fetchOrdersSummary(unitFilterId, from, to);
    }
    await fetchMasterDiapers();
    hideLoading();
  }, [unitFilterId, dateFilterId]);

  const insertOrderDeliveryDate = (orderSummary: IGetOrderSummaryRes) => {
    return orderSummary.orderDeliveryDate ? dayjs(orderSummary.orderDeliveryDate).format('YYYY年MM月DD日(dddd)') : '-';
  };

  const insertDisplayUnitName = (orderSummary: IGetOrderSummaryRes) => {
    return orderSummary.nursingHomeUnitId ? orderSummary.nursingHomeUnitName : '施設全体';
  };

  const insertDisplayOrderStatus = (orderSummary: IGetOrderSummaryRes) => {
    return ORDER_STATUS[orderSummary.orderStatus] ?? '受付済';
  };

  const insertTotalDeliveryPrice = (orderSummary: IGetOrderSummaryRes) => {
    let totalDeliveryPrice = 0;
    orderSummary.orders.map((order) => {
      if (['inner', 'outer'].includes(order.diaperShape)) {
        totalDeliveryPrice += order.deliveryPrice;
      } else if (['expendable'].includes(order.diaperShape)) {
        totalDeliveryPrice += order.diaperExpendablePrice * order.amount;
      }
    });
    return totalDeliveryPrice;
  };

  const createTableData = (ordersSummary: IGetOrderSummaryRes[]) => {
    return ordersSummary.map((orderSummary) => {
      return {
        ...orderSummary,
        displayOrderDate: insertDisplayOrderDate(orderSummary),
        displayOrderDeliveryDate: insertOrderDeliveryDate(orderSummary),
        displayUnitName: insertDisplayUnitName(orderSummary),
        displayOrderStatus: insertDisplayOrderStatus(orderSummary),
        totalDeliveryPrice: insertTotalDeliveryPrice(orderSummary),
      };
    });
  };

  React.useEffect(() => {
    fetchOrderSummary();
    //dateFilterIdの処理
  }, [unitFilterId, dateFilterId]);

  React.useEffect(() => {
    if (!ordersSummary) return;

    const formattedOrdersSummary = createTableData(sortArray(ordersSummary, 'orderDate', 'desc'));
    setFormattedOrdersSummary(formattedOrdersSummary);
  }, [ordersSummary, masterDiapers]);

  return (
    <PanelContainer title={'消耗品の発注履歴'}>
      <div css={Description}>
        <p>発注キャンセルの場合は、電話でお問い合わせください。</p>
        <p>03-5459-1295</p>
        <p>※ 祝日の場合、お届け日が前後する場合がございます。</p>
      </div>
      <HistoryFilterSelector onChangeDateFilter={handleChangeDateFilter} onChangeUnitFilter={handleChangeUnitFilter} />
      {formattedOrdersSummary ? (
        <>
          {formattedOrdersSummary.length > 0 ? (
            <>
              {formattedOrdersSummary?.map((v, i) => {
                return (
                  <OrderDetails
                    orderData={v}
                    orderableList={orderable}
                    key={v.orderCode}
                    onReload={fetchOrderSummary}
                  ></OrderDetails>
                );
              })}
            </>
          ) : (
            <div css={[ContentIsEmpty, BoldText]}>発注履歴はありません</div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </PanelContainer>
  );
});
