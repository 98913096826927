import json2csv from 'json2csv';

export async function exportCSV(
  data: any[],
  column: string[] | { label: string; value: string }[],
  filename: string,
  extension: string = '.csv',
  csvHeaderRow: string | null = null
) {
  let parseData = csvParser(column, data);
  if (csvHeaderRow) {
    parseData = `${csvHeaderRow}\n${parseData}`;
  }

  const blob = new Blob(['\ufeff' + parseData], {
    type: 'text/csv;charset=utf-8;',
  });
  const downloadLink = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
  if (isSafariBrowser) {
    downloadLink.setAttribute('target', '_blank');
  }
  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', filename + extension);
  downloadLink.style.visibility = 'hidden';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
}

const csvParser = (column: string[] | { label: string; value: string }[], data: any[]) => {
  const parser = new json2csv.Parser({ fields: column });
  // console.log(parser);
  return parser.parse(data);
};
