import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { t } from 'i18next';
import HeatmapModule from 'highcharts/modules/heatmap';

HeatmapModule(Highcharts);

interface Props {
  heatmapData: { data: any; xCategories: string[]; yCategories: string[] };
  careRecordPlot: { x: number; y: number; color: string; name: string }[];
  notificationPlot: { x: number; y: number; color: string; name: string }[];
  showCareRecordPlot: boolean;
  showNotificationPlot: boolean;
  isPrinting?: boolean;
}

export const UrineTrendHeatmapGraph = React.memo(
  ({
    heatmapData,
    careRecordPlot,
    notificationPlot,
    showCareRecordPlot,
    showNotificationPlot,
    isPrinting = false,
  }: Props) => {
    // heatmapのセル右下に通知プロットをオフセット
    const notificationData = notificationPlot.map((r) => ({ ...r, x: r.x + 0.4, y: r.y - 0.35 }));

    // heatmapのセル右上にケア履歴プロットをオフセット
    const careRecordData = careRecordPlot.map((r) => ({ ...r, x: r.x + 0.4, y: r.y + 0.35 }));

    const xAxisMax = heatmapData.xCategories ? heatmapData.xCategories.length - 1 : 0;
    const yAxisMax = heatmapData.yCategories ? heatmapData.yCategories.length - 1 : 0;

    const option: Highcharts.Options = {
      title: {
        text: '',
      },
      chart: {
        zoomType: 'x',
        marginLeft: 120,
        marginRight: isPrinting ? 180 : 48,
        marginTop: 0,
        marginBottom: 42,
        shadow: false,
        animation: false,
        backgroundColor: '#FFFFFF',
        height: heatmapData.yCategories ? heatmapData.yCategories.length * 30 + 60 : 100,
      },
      xAxis: {
        categories: heatmapData.xCategories,
        min: 0,
        max: xAxisMax,
        tickInterval: 2,
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
      },
      yAxis: {
        reversed: false,
        categories: heatmapData.yCategories,
        min: 0,
        max: yAxisMax,
        gridLineWidth: 0,
        title: { text: '' },
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
      },
      colorAxis: {
        min: 0,
        floor: 0,
        max: 10,
        minColor: '#EEFFFF',
        maxColor: '#00AAFF',
      },
      series: [
        {
          type: 'heatmap',
          boostThreshold: 100,
          data: heatmapData.data,
          colsize: 1,
          rowsize: 1,
          index: 0,
          turboThreshold: Number.MAX_VALUE,
          dataLabels: {
            enabled: true,
            color: '#000000',
            style: {
              fontSize: '12px',
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormat: `<b>${t('urineGraphLabel', '尿量')} {point.value}</b><br/>`,
          },
        },
        {
          name: t('careHistory', 'ケア履歴'),
          data: careRecordData,
          type: 'scatter',
          yAxis: 0,
          index: 1,
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.name}<br />',
          },
          marker: {
            radius: 4,
            symbol: 'square',
          },
          stickyTracking: false,
          showInLegend: false,
          visible: showCareRecordPlot,
        },
        {
          name: t('notification', '通知'),
          data: notificationData,
          type: 'scatter',
          yAxis: 0,
          index: 1,
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.name}<br />',
          },
          marker: {
            radius: 4,
            symbol: `circle`,
          },
          stickyTracking: false,
          showInLegend: false,
          visible: showNotificationPlot,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      boost: {
        useGPUTranslations: true,
      },
    };

    React.useEffect(() => {
      Highcharts.charts.forEach((chart) => {
        if (chart) {
          chart.reflow();
        }
      });
    }, [isPrinting]);

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={option} />
      </div>
    );
  }
);
