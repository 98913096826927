import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { ResidentListHeader } from '../../../components/residentListHeader';
import api from '../../../api';
import { IGetNotificationRes } from '../../../types/api/notification/getNotification';
import { NotificationListItem } from './notificationListItem';
import { selfDataSelector } from '../../../redux/config/selfData';
import { filterConditionSelector } from '../../../redux/config/residentListSettings';
import { useLoading } from '../../../contexts/LoadingContext';

interface ResidentNotification extends IGetNotificationRes {
  residentName: string;
  residentLastName: string;
}

const NotificationListContainer = css`
  border-radius: 8px;
  overflow: hidden;
`;

const Container = css`
  padding: 0 16px 16px;
`;

const EmptyResidentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

export const Notification: React.VFC = () => {
  const createFilterType = (condition: 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit') => {
    if (['deviceAssigned', 'deviceAttached'].includes(condition)) {
      return condition as 'deviceAssigned' | 'deviceAttached';
    } else return null;
  };

  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const { conditions, unitId } = useSelector(filterConditionSelector);
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  const [residentNotifications, setResidentNotifications] = React.useState<ResidentNotification[]>([]);

  const handleUpdateNotifications = React.useCallback(() => {
    fetchNotifications(unitId, createFilterType(conditions));
  }, [unitId, conditions]);

  const handleChangeUnit = React.useCallback(
    (unitId: number | null, condition?: 'deviceAssigned' | 'deviceAttached') => {
      fetchNotifications(unitId, condition);
    },
    []
  );

  const fetchNotifications = React.useCallback(
    async (unitId?: number | null, condition?: 'deviceAssigned' | 'deviceAttached' | null) => {
      showLoading();
      const params = { nursingHomeId, limit: 50, sort: 'desc' };
      const notifications = await api.get('/nursing-homes/:nursingHomeId/notifications', {
        params: {
          ...params,
          ...(selfUser.hasUnitPerm ? { nursingHomeUnitId: selfUser.nursingHomeUnitId } : {}),
          ...(unitId ? { nursingHomeUnitId: unitId } : {}),
        },
      });
      const res = await api.get('/traversal/nursing-homes/:nursingHomeId', {
        params: unitId ? { nursingHomeId, nursingHomeUnitId: unitId } : { nursingHomeId },
      });
      if (!notifications || !res) return;

      const filteredResidents = (() => {
        if (condition === 'deviceAssigned') {
          return res.residents.filter((resident) => resident.dfreeDeviceId);
        } else if (condition === 'deviceAttached') {
          return res.residents.filter((resident) => resident.urineSize !== null);
        } else {
          return res.residents;
        }
      })();
      const residentIds = filteredResidents.map((resident) => resident.id);
      const filteredNotifications = notifications
        .filter((notification) => residentIds.includes(notification.residentId))
        .map((notification) => {
          const resident = filteredResidents.find((resident) => resident.id === notification.residentId);
          return {
            ...notification,
            residentName: resident ? resident.firstName : '',
            residentLastName: resident ? resident.lastName : '',
          };
        });
      setResidentNotifications(filteredNotifications);
      hideLoading();
    },
    [selfUser, residentNotifications]
  );

  React.useEffect(() => {
    fetchNotifications(unitId, createFilterType(conditions));
  }, []);

  return (
    <div css={Container}>
      <ResidentListHeader
        title={t('notification', '通知')}
        conditions={conditions}
        unitId={unitId}
        onUpdate={handleUpdateNotifications}
        onChangeListSettings={handleChangeUnit}
      />
      {!residentNotifications.length ? (
        <div css={EmptyResidentContainer}>{t('noNotification', '通知はありません')}</div>
      ) : (
        <div css={NotificationListContainer}>
          {residentNotifications.map((notification) => (
            <NotificationListItem key={notification.id} notification={notification} />
          ))}
        </div>
      )}
    </div>
  );
};
