import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selfDataSelector } from '../../../redux/config/selfData';
import api from '../../../api';
import { IGetCareRecordRes, IGetResidentCareRecordParams } from '../../../types/api/care-record/getCareRecord';

export const useFetchResidentCareRecord = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  residentId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  sort = 'desc',
  limit = 10000,
  offset = 0
) => {
  const [residentCareRecord, setResidentCareRecord] = React.useState<IGetCareRecordRes[]>();

  const fetchResidentCareRecord = async (
    residentId: number,
    from: string | null = null,
    to: string | null = null,
    sort = 'desc',
    limit = 10000,
    offset = 0
  ) => {
    const params: IGetResidentCareRecordParams = {
      residentId,
      from,
      to,
      sort,
      limit,
      offset,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;
    if (!offset) delete params.offset;

    let result = await api.get('/residents/:residentId/care-records', {
      params,
      timeout: 5 * 60 * 1000, // 5分に設定
    });

    setResidentCareRecord(result);
    return result; // TODO: 値を直接returnし、useStateを使っていないため修正する
  };

  React.useEffect(() => {
    if (!shouldInitFetch || !residentId) return;
    fetchResidentCareRecord(residentId, from, to, sort, limit, offset);
  }, []);

  return { residentCareRecord, fetchResidentCareRecord };
};
