import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { color } from '../../styles';
import { IGetDiaperRes } from '../../types/api/care-settings/getDiaper';
import { Cell, CellBase, HeaderCell, Row, Table, TableLinkText, TableLinkCell } from '../../styles/table';
import { Dialog } from '../mui/dialog/Dialog';
import api from '../../../src/api';
import { useSnackbar } from '../../contexts/SnackbarContext';

interface Props {
  data: IGetDiaperRes[];
  type: 'outer' | 'inner';
  url: string;
  dataFetch: () => void;
}

const ModalText = css`
  margin-bottom: 16px;
  font-size: 14px;
`;

const ModalSubText = css`
  color: ${color.red};
  font-size: 14px;
  font-weight: bold;
`;

// テーブルのセルの横幅を左から順に定義
const CellWidth = [
  { flex: '1 0 76px' },
  { flex: '1 1 120px' },
  { flex: '3 1 250px' },
  { flex: '1 1 100px' },
  { flex: '0 0 120px' },
];

export const DiaperTable = React.memo(({ data, type, url, dataFetch }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const header = ['定額対象', 'シリーズ', '製品名', '単価', '操作'];
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<IGetDiaperRes>();

  const handleClickEdit = (id: number) => () => {
    history.push(`${url}/diaper-${type}/${id}/edit`);
  };

  const handleClickDelete = (data: IGetDiaperRes) => async () => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    dataFetch();
  };

  const handleClickModalSubmit = async () => {
    if (selectedData) {
      await api.delete('/diapers/:nursingHomeDiaperId', {
        params: { nursingHomeDiaperId: selectedData.id },
      });

      setTimeout(() => {
        onModalClose();
        showSnackbar('削除しました');
      }, 500);
    }
  };

  const OperationCell = (data: IGetDiaperRes) => {
    return (
      <div css={TableLinkCell}>
        <div css={TableLinkText} onClick={handleClickEdit(data.id)}>
          編集
        </div>
        {!data.isFlatRate && (
          <div css={TableLinkText} onClick={handleClickDelete(data)}>
            削除
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {header.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0]]}>{data.isFlatRate ? '対象' : '対象外'}</div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.diaperSeries}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{data.diaperName}</div>
              <div css={[CellBase, Cell, CellWidth[3]]}>{data.cost}円</div>
              <div css={[CellBase, Cell, CellWidth[4]]}>{OperationCell(data)}</div>
            </div>
          );
        })}
      </div>
      {isModalOpen && (
        <Dialog isOpen={isModalOpen} title={'項目の削除'} onClose={onModalClose} onOkClick={handleClickModalSubmit}>
          <div css={ModalText}>下記のデータを削除しますか?</div>
          <div css={ModalSubText}>
            {selectedData?.diaperShape === 'inner' ? 'パッド' : 'テープ/パンツ'} / {selectedData?.diaperSeries}
          </div>
          <div css={ModalSubText}>{selectedData?.diaperName}</div>
        </Dialog>
      )}
    </>
  );
});
