import React from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from 'react-router-dom';
import { useLoading } from '../contexts/LoadingContext';
import { selfDataSlice } from '../redux/config/selfData';
import { currentNursingHomeSlice } from '../redux/config/currentNursingHome';
import api from '../api';
import { LeftNav } from '../components/leftNav';
import { GlobalHeader } from '../components/globalHeader';
import { IGetNursingHomeRes } from '../types/api/nursingHome/getNursingHome';
import { filterConditionSelector, residentListSettingsSlice } from '../redux/config/residentListSettings';
import { navSlice } from '../redux/config/leftnav';
import { AxiosError } from 'axios';

interface IErrorResponse {
  error: string;
}

const AppWrapper = css`
  display: flex;
  font-size: 14px;
  height: 100vh;
  width: 100%;
`;

const ContentWrapper = css`
  width: 100%;
  overflow: hidden;
`;

const ScrollContainer = css`
  width: 100%;
  height: calc(100vh - 60px);
  overflow: auto;
`;

export const DefaultLayout: React.FC = ({ children }) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { unitId } = useSelector(filterConditionSelector);
  const { currentUser } = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenLeftnav, setIsOpenLeftnav] = React.useState(true);
  const [nursingHomeList, setNursingHomeList] = React.useState<IGetNursingHomeRes[] | null>(null);

  const fetchBadge = React.useCallback(
    async (currentNursingHomeId = nursingHomeId, currentUnitId = unitId) => {
      const params = currentUnitId
        ? { nursingHomeId: currentNursingHomeId, nursingHomeUnitId: currentUnitId }
        : { nursingHomeId: currentNursingHomeId };
      const nursingHome = await api.get('/traversal/nursing-homes/:nursingHomeId', {
        params,
      });
      const careSchedule = await api.get('/nursing-homes/:nursingHomeId/care-schedule', { params });
      const orders = await api.get('/nursing-homes/:nursingHomeId/orders', {
        params: { nursingHomeId: currentNursingHomeId, nursingHomeUnitId: currentUnitId, sort: 'desc', limit: 2000 },
      });
      const badge = {
        resident: nursingHome.residents.filter(
          (resident) =>
            resident.bigBladderNotification ||
            resident.gotUpNotification ||
            resident.sensorErrorNotification ||
            resident.upsideDownNotification ||
            resident.urinatedNotification ||
            resident.zeroResetNotification ||
            resident.urineResetNotification
        ).length,
        careSchedule: careSchedule.length,
        temporarilySaved: orders.filter((order) => order.orderStatus === 'temporarilySaved').length,
      };
      dispatch(navSlice.actions.setBadge(badge));
    },
    [unitId]
  );

  const handleSetSelfUser = React.useCallback(async () => {
    if (currentUser.isPending || !currentUser) return;
    try {
      const selfUser = await api.get('/users/_self');
      dispatch(selfDataSlice.actions.setSelfUser(selfUser));
      if (selfUser.nursingHomeUnitId) {
        const selfUnit = await api.get('/nursing-home-units/:nursingHomeUnitId', {
          params: { nursingHomeUnitId: selfUser.nursingHomeUnitId },
        });
        dispatch(selfDataSlice.actions.setSelfUnit(selfUnit));
      }
      const units = await api.get('/nursing-homes/:nursingHomeId/nursing-home-units', {
        params: { nursingHomeId },
      });
      dispatch(selfDataSlice.actions.setSelfUnits(units));

      const selfNursingHome = await api.get('/nursing-homes/:nursingHomeId', {
        params: { nursingHomeId: selfUser.nursingHomeId },
      });

      const currentNursingHome = await api.get('/nursing-homes/:nursingHomeId', {
        params: { nursingHomeId },
      });

      // _selfのnursingHomeGroupIdとURLのIdから取得したnursingHomeGroupIdが異なる場合は404画面へ
      if (selfNursingHome.nursingHomeGroupId !== currentNursingHome.nursingHomeGroupId) {
        history.push('/404');
        return;
      } else {
        dispatch(selfDataSlice.actions.setSelfNursingHome(currentNursingHome));
        dispatch(
          currentNursingHomeSlice.actions.setCurrentNursingHome({
            ...currentNursingHome,
          })
        );
      }

      // メンバー権限の場合は取得しない
      if (selfUser.professionalRole !== 'member') {
        const nursingHomes = await api.get('/nursing-home-groups/:nursingHomeGroupId/nursing-homes', {
          params: { nursingHomeGroupId: currentNursingHome.nursingHomeGroupId },
        });
        setNursingHomeList(nursingHomes);
      }

      // localStorage周りの処理
      // unitIDを自身でもっている場合はLocalStorageに格納する
      if (selfUser.nursingHomeUnitId) {
        localStorage.setItem('unit', String(selfUser.nursingHomeUnitId));
      }
      const conditions =
        (localStorage.getItem('condition') as 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit') ?? 'all';
      const myId = Number(localStorage.getItem('self')) ?? 0;

      let unitId = null;
      // 前回と異なるIDの場合はunitIdを消去
      if (+myId !== selfUser.id) {
        localStorage.removeItem('unit');
        localStorage.removeItem('condition');
      }

      if (selfUser.nursingHomeUnitId) {
        localStorage.setItem('unit', String(selfUser.nursingHomeUnitId));
        unitId = selfUser.nursingHomeUnitId;
      } else if (!localStorage.getItem('unit') || localStorage.getItem('unit') === 'null') {
        unitId = null;
      } else {
        unitId = localStorage.getItem('unit');
      }

      localStorage.setItem('self', String(selfUser.id) ?? '0');
      dispatch(
        residentListSettingsSlice.actions.setFilterCondition({
          conditions: conditions ?? 'all',
          unitId: unitId ? +unitId : null,
        })
      );
      fetchBadge(undefined, selfUser.nursingHomeUnitId);
    } catch (err) {
      if (
        (err as AxiosError<IErrorResponse>).response &&
        (err as AxiosError<IErrorResponse>).response?.status === 403
      ) {
        hideLoading();
        history.push('/404');
        return;
      }
    }
  }, [currentUser]);

  const handleOpenLeftnav = React.useCallback(() => {
    setIsOpenLeftnav(true);
  }, []);

  const handleCloseLeftnav = React.useCallback(() => {
    setIsOpenLeftnav(false);
  }, []);

  React.useEffect(() => {
    handleSetSelfUser();
  }, [currentUser]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      fetchBadge();
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [unitId]);

  return (
    <div css={AppWrapper}>
      {isOpenLeftnav && <LeftNav isOpenLeftnav={isOpenLeftnav} onClose={handleCloseLeftnav} />}
      <div css={ContentWrapper}>
        <GlobalHeader
          nursingHomes={nursingHomeList}
          isOpenLeftnav={isOpenLeftnav}
          onOpen={handleOpenLeftnav}
          fetchBadge={fetchBadge}
        />
        {currentUser && <div css={ScrollContainer}>{children}</div>}
      </div>
    </div>
  );
};
