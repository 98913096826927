export const MEASUREMENT_DATA_CSV_COL_JA = [
  {
    label: '入居者ID',
    value: 'id',
  },
  {
    label: '名前',
    value: 'name',
  },
  {
    label: '時間',
    value: 'timestamp',
  },
  {
    label: 'センサー',
    value: 'u1xActiveRatio',
  },
  {
    label: '通知タイプ',
    value: 'notificationType',
  },
];

export const MEASUREMENT_DATA_CSV_COL_EN = [
  {
    label: 'Resident ID',
    value: 'id',
  },
  {
    label: 'Full name',
    value: 'name',
  },
  {
    label: 'Date time',
    value: 'timestamp',
  },
  {
    label: 'Sensor',
    value: 'u1xActiveRatio',
  },
  {
    label: 'Notification type',
    value: 'notificationType',
  },
];
