import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { selfDataSlice } from '../../../redux/config/selfData';
import api from '../../../api';
import { UnitTable } from './unitTable';
import { ICreateUnitParams } from '../../../types/api/unit/createUnit';
import { Dialog } from '../../../components/mui/dialog';
import { StyledTextField } from '../../../components/mui/textfield';
import { PanelContainer } from '../../../components/panel';
import { OutlineButton } from '../../../components/outlineButton';
import { useFetchUnits } from '../../../hooks/api/unit/useFetchUnits';
import { useSnackbar } from '../../../contexts/SnackbarContext';

const ItemContainer = css`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
`;

const ModalBodyContainer = css`
  margin: 8px;
`;

const EmptyContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
  font-size: 16px;
`;

const Space = css`
  margin-right: 6px;
`;

export const UnitSettings = () => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { units, fetchUnits } = useFetchUnits();
  const [unitName, setUnitName] = React.useState<string>('');
  const [isError, setIsError] = React.useState<boolean>();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const createUnits = async (params: ICreateUnitParams) => {
    return await api.post('/nursing-homes/:nursinghomeid/nursing-home-units', params);
  };

  const handleClickAddButton = () => {
    setIsModalOpen(true);
  };

  const handleClickModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClickModalSubmit = async () => {
    if (onSend || !unitName || isError) return;
    setOnSend(true);

    const params: ICreateUnitParams = {
      nursinghomeid: +nursingHomeId, // URI用 URIとparamsに同じnursingHomeIdを参照する必要があるため、別Keyで持たせる
      nursingHomeId: +nursingHomeId,
      name: unitName,
    };

    await createUnits(params);
    setTimeout(() => {
      fetchUnits();
      setUnitName('');
      setOnSend(false);
      showSnackbar(t('unitNameHasBeenAdded', 'ユニットを追加しました'), 'success');
      handleClickModalClose();
    }, 500);
  };

  const handleChangeUnitName = (event: React.ChangeEvent<HTMLInputElement>) => {
    !event.target.value ? setIsError(true) : setIsError(false);
    setUnitName(event.target.value);
  };

  React.useEffect(() => {
    if (!units) return;
    dispatch(selfDataSlice.actions.setSelfUnits(units ?? []));
  }, [units]);

  return (
    <>
      <PanelContainer title={t('unitList', 'ユニット一覧')}>
        <div css={ItemContainer}>
          <OutlineButton icon={'＋'} onClick={handleClickAddButton}>
            {t('add', '追加')}
          </OutlineButton>
        </div>
        <div>
          {units?.length ? (
            <UnitTable data={units} dataFetch={fetchUnits} />
          ) : (
            <div css={EmptyContent}>{t('unitIsNotRegistered', 'ユニットが登録されていません')}</div>
          )}
        </div>
        {i18n.language === 'ja' ? (
          <span css={ItemContainer}>
            ユニットを削除する場合は、
            <a href='https://dfree.biz/professional/contact.html' target='_blank' rel='noreferrer'>
              こちら
            </a>
            からお問い合わせください
          </span>
        ) : (
          <span css={[ItemContainer]}>
            <span css={Space}>{'If you want to remove a unit, please contact us'}</span>
            <a href='https://dfree.biz/professional/contact.html' target='_blank' rel='noreferrer'>
              {'here'}
            </a>
          </span>
        )}
      </PanelContainer>
      {isModalOpen && (
        <Dialog
          isOpen={isModalOpen}
          title={t('addUnit', 'ユニットの追加')}
          onClose={handleClickModalClose}
          onOkClick={handleClickModalSubmit}
        >
          <div css={ModalBodyContainer}>
            <StyledTextField
              id={'add-unit'}
              value={unitName}
              label={t('unitName', 'ユニット名')}
              isError={isError}
              errorText={t('unitNameIsRequired', 'ユニット名を入力してください')}
              onChange={handleChangeUnitName}
              fullWidth
            ></StyledTextField>
          </div>
        </Dialog>
      )}
    </>
  );
};
