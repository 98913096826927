import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ResidentDetailLayout } from '../layouts/residentDetailLayout';
import { DetailedRecordPlus } from '../pages/residents/detailedRecordPlus';
import { ResidentSetting } from '../pages/residents/residentSetting';
import { ResidentLifeSetting } from '../pages/residents/residentSetting/life';
import {
  ResidentDetailCareHistory,
  ResidentDetailGraph,
  ResidentDetailMonthly,
  ResidentDetailCareRecordData,
  ResidentDetailDiary,
  ResidentDetailUrineTrendAnalysis,
  ResidentDetailActivityStatusAnalysis,
  ResidentDetailLifeCarePlannings,
  ResidentDetailLifeCarePlanSettings,
} from '../pages/residents/detail';

export const ResidentDetailRouter: React.FC = () => {
  const { path, params, url } = useRouteMatch();
  const { nursingHomeId, residentId } = params as { nursingHomeId: string; residentId: string };

  return (
    <Switch>
      <Route exact path={`${path}/edit`}>
        <ResidentSetting isEdit residentId={+residentId} />
      </Route>
      <Route exact path={`${path}/life/edit`}>
        <ResidentLifeSetting residentId={+residentId} />
      </Route>
      <Route path={`${path}/care-record/new`}>
        <DetailedRecordPlus />
      </Route>
      <Route path={`${path}/care-record/:careRecordId/edit`}>
        <DetailedRecordPlus />
      </Route>
      <ResidentDetailLayout params={{ nursingHomeId, residentId }} url={url}>
        <Switch>
          <Route exact path={`${path}/graph`}>
            <ResidentDetailGraph residentId={residentId} />
          </Route>
          <Route exact path={`${path}/care-record`}>
            <ResidentDetailCareHistory residentId={residentId} />
          </Route>
          <Route exact path={`${path}/care-record-data`}>
            <ResidentDetailCareRecordData residentId={residentId} />
          </Route>
          <Route exact path={`${path}/monthly`}>
            <ResidentDetailMonthly residentId={residentId} />
          </Route>
          <Route exact path={`${path}/diary`}>
            <ResidentDetailDiary residentId={residentId} nursingHomeId={nursingHomeId} />
          </Route>
          <Route exact path={`${path}/urine-trend-analysis`}>
            <ResidentDetailUrineTrendAnalysis residentId={residentId} nursingHomeId={nursingHomeId} />
          </Route>
          <Route exact path={`${path}/activity-analysis`}>
            <ResidentDetailActivityStatusAnalysis residentId={residentId} nursingHomeId={nursingHomeId} />
          </Route>
          <Route exact path={`${path}/life-care-plannings`}>
            <ResidentDetailLifeCarePlannings residentId={residentId} nursingHomeId={nursingHomeId} />
          </Route>
          <Route exact path={`${path}/life-care-plannings/new`}>
            <ResidentDetailLifeCarePlanSettings residentId={residentId} nursingHomeId={nursingHomeId} />
          </Route>
          <Route exact path={`${path}/life-care-plannings/:lifeCarePlanningId/edit`}>
            <ResidentDetailLifeCarePlanSettings residentId={residentId} nursingHomeId={nursingHomeId} />
          </Route>
          <Redirect path='*' to='/404' />
        </Switch>
      </ResidentDetailLayout>
      <Redirect path='*' to='/404' />
    </Switch>
  );
};
