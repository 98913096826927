import * as React from 'react';
import { useSelector } from 'react-redux';
import { styled, css } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { color } from '../../../styles';
import { FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  data: { key: string | number | boolean; value: string; icon?: string }[];
  isDisabled?: boolean;
  isRow?: boolean;
  newValue?: string | number | boolean | null;
  mode?: 'button' | 'radio'; // 同じボタンを押した時に値をクリアするかしないか
  onChange: (value: string | number | boolean | null) => void;
}

interface RadioWrapperProps {
  isRow: boolean;
}

const StyledRadioGroup = styled(({ isRow, ...props }: RadioWrapperProps & RadioGroupProps) => (
  <RadioGroup {...props} />
))`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
  ${({ isRow }) =>
    isRow
      ? css`
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 6px;
            background: ${color.thin_gray};
            transform: translateY(-50%);
          }
        `
      : css`
          height: 100%;
        `}
`;

const StyledRadio = styled(({ isRow, ...props }: RadioWrapperProps & RadioProps) => <Radio {...props} />)`
  min-width: 60px;
  flex-grow: 1;
  background: ${color.white};
  margin-right: ${({ isRow }) => (isRow ? '4px' : 0)};
  margin-bottom: ${({ isRow }) => (!isRow ? '4px' : 0)};
  border-radius: 8px;
  border: 1px solid ${color.border_gray};
  &:hover {
    color: ${color.white};

    background: ${color.gray};
  }
  &.Mui-checked {
    color: ${color.white};
    background: ${color.dark_gray};
  }
  &.Mui-disabled {
    background: ${color.button_gray};
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const CareRecordButtonGroup = React.memo(
  ({ data: displayList, newValue = '', isRow = true, isDisabled = false, mode = 'button', onChange }: Props) => {
    const { i18n } = useTranslation();
    const [value, setValue] = React.useState<string | number | boolean | null>(null);
    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChange(e.target.value);
    }, []);

    const handleClickItem = (e: React.MouseEvent<HTMLElement>) => {
      const selected = (e.currentTarget as HTMLInputElement).querySelector('input')?.value;
      if (mode === 'button' && selected === String(value)) {
        setValue(null);
        onChange(null);
      }
    };

    React.useEffect(() => {
      setValue(newValue);
    }, [newValue]);

    return (
      <FormGroup sx={{ width: '100%' }}>
        <StyledRadioGroup isRow={isRow} value={value ?? ''} onChange={handleChange}>
          {displayList.map((radio, index) => (
            <StyledRadio
              key={index}
              disabled={isDisabled}
              value={radio.key}
              color='default'
              icon={
                i18n.language !== 'ja' && radio.icon ? <img src={radio.icon} alt={radio.value} /> : <>{radio.value}</>
              } // 日本語以外かつiconに画像パスが渡っているときは、画像を表示する
              checkedIcon={
                i18n.language !== 'ja' && radio.icon ? <img src={radio.icon} alt={radio.value} /> : <>{radio.value}</>
              }
              isRow={isRow}
              onClick={handleClickItem}
            />
          ))}
        </StyledRadioGroup>
      </FormGroup>
    );
  }
);
