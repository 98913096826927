import React from 'react';
import { css } from '@emotion/react';
import { Controller, Control } from 'react-hook-form';
import { TextField, Checkbox, FormControlLabel, FormControl, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICreateResidentParams } from '../../../types/api/resident/createResident';
import { IGetUnitRes } from '../../../types/api/unit/getUnit';
import { MenuItemProps, Select } from '../../../components/mui/select';
import {
  BLADDER_THRESHOLD_PERCENTAGE,
  CARE_LEVEL,
  HOURS,
  HOURS_WITH_NOTHING,
  INDEPENDENCE_LEVELS,
  PURPOSE_JA,
  PURPOSE_EN,
  SUPPORT_URL,
  SNOOZETERM_JA,
  SNOOZETERM_EN,
  SNOOZEPERIOD_JA,
  SNOOZEPERIOD_EN,
  LOCAL_HOURS_WITH_NOTHING,
} from '../../../constants';
import { media } from '../../../styles/media';
import { color } from '../../../styles';
import { IGetDfreeDevicesRes } from '../../../types/api/dfree-devices/getDfreeDevices';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { IGetNursingHomeRes } from '../../../types/api/nursingHome/getNursingHome';

interface Props {
  control: Control<ICreateResidentParams, object>;
  units: IGetUnitRes[];
  formSelectLists: {
    pants: { key: number; value: string }[];
    pads: { key: number; value: string }[];
    devices: IGetDfreeDevicesRes[];
    laxatives: { key: number; value: string }[];
    goodTreeList: { key: number; value: string }[];
  };
  deviceId: string;
  careSchedule: string[];
  isDisplayBigBladderOption?: boolean;
  isDisplayUrinationOption?: boolean;
  isDisplayGotUpOption?: boolean;
  lastNameError?: boolean;
  nameError?: boolean;
  unitError?: boolean;
  endDiaperMiddleHourError?: boolean;
  isAfternoonEndBeforeNightStart: (afternoonEnd: string | null) => boolean;
  onChangeCareSchedule: (editType: 'add' | 'change' | 'delete', schedule?: string, index?: number) => void;
  onChangeDeviceId: (id: string) => void;
  onChangePurpose: (purpose: string) => void;
  onChangeSnoozeTerm: (type: string, value: string | number) => void;
  onChangeSnoozePeriod: (type: string, value: string | number) => void;
  nursingHome: IGetNursingHomeRes;
}

const WidthMax = css`
  width: 100%;
`;

const Container = css`
  padding: 40px 16px 0;

  ${media.minDesktop} {
    padding: 40px 100px 0;
  }
`;

const FormItem = css`
  display: flex;
`;

const Label = css`
  flex: 0 0 18%;
  margin-right: 8px;
`;

const Required = css`
  color: #eb3223;
`;

const AlignCenter = css`
  display: flex;
  align-items: center;
`;

const FormContent = css`
  flex: 1 1 80%;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 8px;
`;

const NotificationImage = css`
  margin-right: 8px;
  width: 24px;
  min-width: 24px;
  height: 24px;
`;

const CheckboxSelectContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background: ${color.light_green};
  margin-bottom: 16px;
  border-radius: 8px;
`;

const CareTimingContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  background: ${color.white};
  margin-bottom: 8px;
`;

const GotUpSnoozeCheckboxSelectContainer = css`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding: 8px 16px;
  background: ${color.light_green};
  margin-bottom: 16px;
  border-radius: 8px;
`;

const GotUpSnoozeCheckboxSelectorInner = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CareScheduleLabel = css`
  margin-top: 22px;
`;

const CareScheduleContainer = css`
  ${AlignCenter};
  min-width: 180px;
  gap: 8px;
`;

const ScheduleSelector = css`
  min-width: 88px;
`;

const AddTimingButtonStyle = css`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

const BarTitle = css`
  padding: 8px;
  border-left: 4px solid;
  margin-bottom: 16px;
`;

const ContingencyModeSub = css`
  color: ${color.dark_gray};
  font-size: 14px;
  margin-top: 4px;
`;

const ContingencyModeText = css`
  padding: 8px 16px;
  background: ${color.light_green};
  border-radius: 8px;
  font-size: 16px;
`;

const HelperText = css`
  color: ${color.gray};
`;

const IconText = css`
  font-size: 14px;
`;

export const ResidentSettingForm = React.memo(
  ({
    control,
    units,
    formSelectLists,
    deviceId,
    careSchedule,
    isDisplayBigBladderOption = false,
    isDisplayUrinationOption = false,
    isDisplayGotUpOption = false,
    nameError = false,
    lastNameError = false,
    unitError = false,
    endDiaperMiddleHourError = false,
    isAfternoonEndBeforeNightStart,
    onChangeCareSchedule,
    onChangeDeviceId,
    onChangePurpose,
    onChangeSnoozeTerm,
    onChangeSnoozePeriod,
    nursingHome,
  }: Props) => {
    const { showSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation();

    const memoUnits = React.useMemo(() => {
      return units.map((unit) => {
        return { key: unit.id, value: unit.name };
      });
    }, []);

    const enableAddTimingButton = React.useMemo(() => {
      return !careSchedule.some((v) => !v);
    }, [careSchedule]);

    const selectableTimingList: MenuItemProps[] = React.useMemo(() => {
      return LOCAL_HOURS_WITH_NOTHING.map((v, i) => ({
        ...v,
        disabled: i === 0 || careSchedule.includes(v.key),
      }));
    }, [careSchedule]);

    const memoDevices = React.useMemo(() => {
      const currentDevice = formSelectLists.devices.find((device) => device.id === +deviceId);
      const devices = formSelectLists.devices.map((device) => {
        // TODO: 英語の場合は逆にする
        let attachedName = '';
        if (device.lastName || device.firstName) {
          const lastName = device.lastName ? device.lastName : '';
          const firstName = device.firstName ? device.firstName : '';
          if (i18n.language === 'ja') {
            attachedName = ' (' + lastName + ' ' + firstName + 'さん 利用中)';
          } else {
            attachedName = ' (' + firstName + ' ' + lastName + ' In use)';
          }
        }
        return { key: String(device.id), value: device.serialNumber + attachedName };
      });
      devices.unshift({ key: '', value: t('unregisterDFree', 'DFree登録を解除') });
      return devices;
    }, []);

    const memoGoodTreeList = React.useMemo(() => {
      // !! 「未選択」の選択肢を含ませる。String → Int への変換は、onChange のコールバックで行う。
      const goodTreeList = formSelectLists.goodTreeList.map((goodTree) => {
        return { key: String(goodTree.key), value: goodTree.value };
      });
      goodTreeList.unshift({ key: '', value: '未選択' });
      return goodTreeList;
    }, []);

    return (
      <div css={Container}>
        <div css={FormItem}>
          <label css={Label}>
            {t('lastName', '名')}
            <span css={Required}>*</span>
          </label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='lastName'
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  label={t('lastName', '名')}
                  error={lastNameError}
                  helperText={lastNameError && t('firstName', '姓') + t('isRequired', 'は必須入力です。')}
                />
              )}
            />
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>
            {t('firstName', '姓')}
            <span css={Required}>*</span>
          </label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='name'
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={nameError}
                  label={t('firstName', '姓')}
                  helperText={nameError && t('lastName', '名') + t('isRequired', 'は必須入力です。')}
                />
              )}
            />
          </div>
        </div>
        {nursingHome.goodTree && ( // ケア樹連携フラグが立っている場合のみ表示する
          <div css={FormItem}>
            <div css={Label} />
            <div css={FormContent}>
              <Controller
                control={control}
                name='goodTreeResidentId'
                render={({ field: { value, onChange } }) => (
                  <div css={CheckboxSelectContainer}>
                    <div>ケア樹連携先入居者</div>
                    <Select
                      style={{ width: '60%' }}
                      value={value}
                      listData={memoGoodTreeList}
                      onChange={(v) => {
                        if (v.key == '') {
                          onChange(null); // 「未選択」の場合は、null を格納
                        } else {
                          onChange(+v.key);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        )}
        <div css={FormItem}>
          <label css={Label}>
            {t('unit', 'ユニット')}
            <span css={Required}>*</span>
          </label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='nursingHomeUnitId'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  style={{ width: '100%' }}
                  value={value ? String(value) : null}
                  listData={memoUnits}
                  label={t('unit', 'ユニット')}
                  onChange={(v) => {
                    onChange(v.key);
                  }}
                  isError={unitError}
                  errorText={unitError ? t('unit', 'ユニット') + t('isRequired', 'は必須入力です。') : ''}
                />
              )}
            />
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>{t('roomName', '部屋名')}</label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='roomName'
              render={({ field: { onChange, value } }) => (
                <TextField sx={{ width: '100%' }} label={t('roomName', '部屋名')} onChange={onChange} value={value} />
              )}
            />
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>{t('bed', 'ベッド番号')}</label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='bed'
              render={({ field: { onChange, value } }) => (
                <TextField sx={{ width: '100%' }} label={t('bed', 'ベッド番号')} onChange={onChange} value={value} />
              )}
            />
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>{t('dfreeSn', 'DFree SN')}</label>
          <div css={FormContent}>
            <FormControl sx={{ width: '100%' }}>
              <Select
                label={t('dfreeSn', 'DFree SN')}
                listData={memoDevices}
                value={deviceId}
                onChange={(v) => onChangeDeviceId(v.key as string)}
              />
            </FormControl>
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>{t('mainPurpose', '主な利用目的')}</label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='purpose'
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    style={{ width: '100%' }}
                    label={t('mainPurpose', '主な利用目的')}
                    value={value}
                    listData={i18n.language === 'ja' ? PURPOSE_JA : PURPOSE_EN}
                    onChange={(v) => {
                      onChange(v.key);
                      onChangePurpose(String(v.key));
                    }}
                  />
                  {value === 'toilet' && (
                    <div css={HelperText}>{t('mainPurposeToiletDiaper', '※ 排泄記録をつけると値が0に戻ります')}</div>
                  )}
                  {value === 'diaper' && (
                    <div css={HelperText}>
                      {t('mainPurposeDiaper1', '※ 排尿を検出すると値が自動で下がります')}
                      <br />
                      {t('mainPurposeDiaper2', '(姿勢や装着具合によって正確に検出できない場合があります)')}
                    </div>
                  )}
                  {value === 'monitoring' && (
                    <div css={HelperText}>
                      {t('mainPurposeMonitoring1', '※ 排尿を検出すると値が自動で下がります')}
                      <br />
                      {t('mainPurposeMonitoring2', '（姿勢や装着具合によって正確に検出できない場合があります）')}
                    </div>
                  )}
                </>
              )}
            />
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>{t('notificationSettings', '通知設定')}</label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='bigBladderNotification'
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{ marginBottom: '8px' }}
                  label={t('receiveAlmostNotification', 'そろそろ通知を受け取る')}
                  control={
                    <>
                      <Checkbox
                        sx={{ padding: 0, marginRight: '8px' }}
                        checked={value}
                        onChange={(v) => onChange(v.target.checked)}
                      />
                      <img css={NotificationImage} src={`/images/notification/icon_bigbladder.svg`} />
                    </>
                  }
                />
              )}
            />
            <Controller
              control={control}
              name='bladderThresholdPercentage'
              render={({ field: { onChange, value } }) => (
                <div css={CheckboxSelectContainer}>
                  <div>{t('almostThreshold', 'そろそろライン')}</div>
                  <Select
                    listData={BLADDER_THRESHOLD_PERCENTAGE}
                    value={String(value)}
                    onChange={(v) => onChange(v.key)}
                  />
                </div>
              )}
            />
            {isDisplayBigBladderOption && (
              <>
                <Controller
                  control={control}
                  name='bigBladderSnoozeTerm'
                  render={({ field: { onChange, value } }) => (
                    <div css={CheckboxSelectContainer}>
                      <div>{t('SnoozeInterval', '繰り返し間隔')}</div>
                      <Select
                        listData={i18n.language === 'ja' ? SNOOZETERM_JA : SNOOZETERM_EN}
                        value={String(value)}
                        onChange={(v) => {
                          onChange(+v.key);
                          onChangeSnoozeTerm('bigBladder', v.key);
                        }}
                      />
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name='bigBladderSnoozePeriod'
                  render={({ field: { onChange, value } }) => (
                    <div css={CheckboxSelectContainer}>
                      <div>{t('SnoozeEnd', '繰り返し終了')}</div>
                      <Select
                        listData={i18n.language === 'ja' ? SNOOZEPERIOD_JA : SNOOZEPERIOD_EN}
                        value={String(value)}
                        onChange={(v) => {
                          onChange(+v.key);
                          onChangeSnoozePeriod('bigBladder', v.key);
                        }}
                      />
                    </div>
                  )}
                />
              </>
            )}
            <Controller
              control={control}
              name='urinationNotification'
              render={({ field: { onChange, value } }) => (
                <div>
                  <FormControlLabel
                    sx={{ marginBottom: '8px' }}
                    label={t('receiveCheckNotification', 'でたかも通知を受け取る')}
                    control={
                      <>
                        <Checkbox
                          sx={{ padding: 0, marginRight: '8px' }}
                          checked={value}
                          onChange={(v) => onChange(v.target.checked)}
                        />
                        <img css={NotificationImage} src={`/images/notification/icon_urinated.svg`} />
                      </>
                    }
                  />
                </div>
              )}
            />
            <div css={ContingencyModeText}>
              {i18n.language === 'ja' ? (
                <>
                  DFreeは、膀胱の尿のたまり具合を見ている機器です。ご利用者さまの動きや姿勢により、
                  膀胱が見えなくなった場合にも通知されることがあるため、ベッド上で過ごされる方への使用を推奨しております。
                  トイレへ行くことができる方には、「そろそろ通知」のご使用をおすすめいたします。
                </>
              ) : (
                <>
                  DFree is a device that monitors the amount of urine in your bladder. We recommend using this app for
                  people who spend their time in bed, as it may still notify you if your bladder is no longer visible to
                  the sensor due to movements or change in posture. For those who are able to go to the restroom, we
                  recommend using the &quot;Almost&quot; notification feature.
                </>
              )}
            </div>
            <br />
            {isDisplayUrinationOption && (
              <>
                <Controller
                  control={control}
                  name='urinationSnoozeTerm'
                  render={({ field: { onChange, value } }) => (
                    <div css={CheckboxSelectContainer}>
                      <div>{t('SnoozeInterval', '繰り返し間隔')}</div>
                      <Select
                        listData={i18n.language === 'ja' ? SNOOZETERM_JA : SNOOZETERM_EN}
                        value={String(value)}
                        onChange={(v) => {
                          onChange(+v.key);
                          onChangeSnoozeTerm('urination', v.key);
                        }}
                      />
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name='urinationSnoozePeriod'
                  render={({ field: { onChange, value } }) => (
                    <div css={CheckboxSelectContainer}>
                      <div>{t('SnoozeEnd', '繰り返し終了')}</div>
                      <Select
                        listData={i18n.language === 'ja' ? SNOOZEPERIOD_JA : SNOOZEPERIOD_EN}
                        value={String(value)}
                        onChange={(v) => {
                          onChange(+v.key);
                          onChangeSnoozePeriod('urination', v.key);
                        }}
                      />
                    </div>
                  )}
                />
              </>
            )}
            <Controller
              control={control}
              name='gotUpNotification'
              render={({ field: { onChange, value } }) => (
                <div>
                  <FormControlLabel
                    sx={{ marginBottom: '8px' }}
                    label={t('receiveGettingUpNotification', '起き上がり通知を受け取る')}
                    control={
                      <>
                        <Checkbox
                          sx={{ padding: 0, marginRight: '8px' }}
                          checked={value}
                          onChange={(v) => onChange(v.target.checked)}
                        />
                        <img css={NotificationImage} src={`/images/notification/icon_gotUp.svg`} />
                      </>
                    }
                  />
                </div>
              )}
            />
            {isDisplayGotUpOption && (
              <>
                <div css={GotUpSnoozeCheckboxSelectContainer}>
                  <div css={[WidthMax, GotUpSnoozeCheckboxSelectorInner]}>
                    <div>{t('notificationTimeZone', '通知時間帯')}</div>
                    <div css={AlignCenter}>
                      <Controller
                        control={control}
                        name='beginGotUpCheckTerm'
                        render={({ field: { onChange, value } }) => (
                          <Select listData={HOURS} value={value} onChange={(v) => onChange(v.key)} />
                        )}
                      />
                      -
                      <Controller
                        control={control}
                        name='endGotUpCheckTerm'
                        render={({ field: { onChange, value } }) => (
                          <Select listData={HOURS} value={value} onChange={(v) => onChange(v.key)} />
                        )}
                      />
                    </div>
                  </div>
                  <div css={[WidthMax, ContingencyModeSub]}>
                    {t(
                      'NotificationLoopSettingDescription',
                      '※起き上がり通知を24時間有効にしたい場合は、上欄の開始時刻と終了時刻を同じ時刻に設定してください。例）19:00 - 19:00'
                    )}
                  </div>
                </div>
                <Controller
                  control={control}
                  name='gotUpSnoozeTerm'
                  render={({ field: { onChange, value } }) => (
                    <div css={GotUpSnoozeCheckboxSelectContainer}>
                      <div css={[WidthMax, GotUpSnoozeCheckboxSelectorInner]}>
                        <div>{t('SnoozeInterval', '繰り返し間隔')}</div>
                        <Select
                          listData={i18n.language === 'ja' ? SNOOZETERM_JA : SNOOZETERM_EN}
                          value={String(value)}
                          onChange={(v) => {
                            onChange(+v.key);
                            onChangeSnoozeTerm('gotUp', v.key);
                          }}
                        />
                      </div>
                      <div css={[WidthMax, ContingencyModeSub]}>
                        ※{t('NotificationIntervalMayVaryBySeveralMinutes', '間隔が数分前後することがあります')}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name='gotUpSnoozePeriod'
                  render={({ field: { onChange, value } }) => (
                    <div css={CheckboxSelectContainer}>
                      <div>{t('SnoozeEnd', '繰り返し終了')}</div>
                      <Select
                        listData={i18n.language === 'ja' ? SNOOZEPERIOD_JA : SNOOZEPERIOD_EN}
                        value={String(value)}
                        onChange={(v) => {
                          onChange(+v.key);
                          onChangeSnoozePeriod('gotUp', v.key);
                        }}
                      />
                    </div>
                  )}
                />
              </>
            )}

            <Controller
              control={control}
              name='zeroResetNotification'
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{ marginBottom: '8px' }}
                  label={t('notificationIfSensorIsNotAttachedProperly', '装着開始時エラー通知を受け取る')}
                  control={
                    <>
                      <Checkbox
                        sx={{ padding: 0, marginRight: '8px' }}
                        checked={value}
                        onChange={(v) => onChange(v.target.checked)}
                      />
                      <img css={NotificationImage} src={`/images/notification/icon_zeroReset.svg`} />
                    </>
                  }
                />
              )}
            />
          </div>
        </div>
        <div css={FormItem}>
          <label css={[Label, CareScheduleLabel]}>{t('assistToTheToiletAtASetTime', '排泄ケア時刻設定')}</label>
          <div css={FormContent}>
            {careSchedule.map((timing, index) => (
              <div key={index} css={CareTimingContainer}>
                <div>{t('ProvideCare', '排泄ケア時刻')}</div>
                <div css={CareScheduleContainer}>
                  <div css={ScheduleSelector}>
                    <Select
                      listData={selectableTimingList}
                      value={String(timing)}
                      fullWidth
                      isDisplayEmpty
                      onChange={(v) => {
                        onChangeCareSchedule('change', String(v.key), index);
                      }}
                    />
                  </div>
                  {timing !== '' && (
                    <IconButton
                      sx={{ borderRadius: '4px', border: `1px solid ${color.border_gray}` }}
                      onClick={() => onChangeCareSchedule('delete', undefined, index)}
                    >
                      <DeleteIcon sx={{ fontSize: 18 }} />
                      <span css={IconText}>{t('delete', '削除')}</span>
                    </IconButton>
                  )}
                </div>
              </div>
            ))}
            <Button
              sx={{ borderColor: `${color.green}` }}
              variant='outlined'
              disabled={!enableAddTimingButton}
              onClick={() => onChangeCareSchedule('add')}
              css={AddTimingButtonStyle}
            >
              + {t('AddScheduledTime', '排泄ケア時刻を追加')}
            </Button>
          </div>
        </div>
        <div css={FormItem}>
          <label css={Label}>{t('modeSettings', 'モード設定')}</label>
          <div css={FormContent}>
            <Controller
              control={control}
              name='contingencyMode'
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{ marginBottom: '8px' }}
                  label={t('contingencyMode', '救済モード')}
                  control={
                    <Checkbox
                      sx={{ padding: 0, marginRight: '8px' }}
                      checked={value}
                      onChange={(v) => onChange(v.target.checked)}
                    />
                  }
                />
              )}
            />
            <div css={ContingencyModeText}>
              {i18n.language === 'ja' ? (
                <>
                  尿のたまり具合が4より上がらない場合は、救済モードをお試しください。 <br />
                  数値を上がりやすくします。 <br />
                  <div css={ContingencyModeSub}>
                    ※ ご利用前に
                    <a href={SUPPORT_URL.howToWear.ja} target='_blank' rel='noreferrer'>
                      装着手順
                    </a>
                    を再度ご確認ください。 <br />
                    ※ 救済モードを使用した場合、必ず数値があがるわけではございません。
                    <br />※ 尿のたまり具合が急激に上がる可能性もありますので、ご利用には注意ください。
                  </div>
                </>
              ) : (
                <>
                  If your urine level does not rise above 4, try the Contingency Mode. This mode will allow the number
                  to increase easier.
                  <br />
                  <div css={ContingencyModeSub}>
                    * Please also refer to{' '}
                    <a href={SUPPORT_URL.howToWear.en} target='_blank' rel='noreferrer'>
                      the placement instructions
                    </a>{' '}
                    before using.
                  </div>
                  <div css={ContingencyModeSub}>* When using this mode, the value may not necessarily increase.</div>
                  <div css={ContingencyModeSub}>* Please note that your urine level may rise sharply.</div>
                </>
              )}
            </div>
          </div>
        </div>

        {i18n.language === 'ja' && (
          <>
            <div css={BarTitle}>排泄基本情報</div>
            <div css={FormItem}>
              <label css={Label}>ケア方針:</label>
              <div css={FormContent}>
                <Controller
                  control={control}
                  name='carePlanMemo'
                  render={({ field: { onChange, value } }) => (
                    <TextField sx={{ width: '100%' }} label='ケア方針' onChange={onChange} value={value} />
                  )}
                />
              </div>
            </div>
            <div css={FormItem}>
              <label css={Label}>排泄自立度：</label>
              <div css={FormContent}>
                <Controller
                  control={control}
                  name='careSettings.independenceLevel'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%' }}
                      label='排泄自立度'
                      value={value}
                      listData={INDEPENDENCE_LEVELS}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div css={BarTitle}>排泄基本情報</div>
            <div css={FormItem}>
              <label css={Label}>昼用1:</label>
              <div css={FormContent}>
                <Controller
                  control={control}
                  name='careSettings.nursingHomeDiaperIdNoon'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='テープ/パンツ'
                      value={value}
                      listData={formSelectLists.pants}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='careSettings.nursingHomePadIdNoonFirst'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='パッド1枚目'
                      value={value}
                      listData={formSelectLists.pads}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='careSettings.nursingHomePadIdNoonSecond'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='パッド2枚目'
                      value={value}
                      listData={formSelectLists.pads}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div css={FormItem}>
              <label css={Label}>昼用2:</label>
              <div css={FormContent}>
                <div>
                  <div css={CheckboxSelectContainer}>
                    <div>使用時間帯</div>
                    <div css={AlignCenter}>
                      <Controller
                        control={control}
                        name='careSettings.beginDiaperMiddleHour'
                        render={({ field: { onChange, value } }) => (
                          <Select
                            listData={HOURS_WITH_NOTHING}
                            value={!value ? '-' : value}
                            onChange={(v) => onChange(v.key)}
                          />
                        )}
                      />
                      -
                      <Controller
                        control={control}
                        name='careSettings.endDiaperMiddleHour'
                        rules={{
                          validate: (value) => {
                            if (isAfternoonEndBeforeNightStart(value)) {
                              showSnackbar('夜用の開始時刻より前に設定してください');
                            }
                            return !isAfternoonEndBeforeNightStart(value);
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            listData={HOURS_WITH_NOTHING}
                            value={!value ? '-' : value}
                            onChange={(v) => onChange(v.key)}
                            isError={endDiaperMiddleHourError}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <Controller
                  control={control}
                  name='careSettings.nursingHomeDiaperIdMiddle'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='テープ/パンツ'
                      value={value}
                      listData={formSelectLists.pants}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='careSettings.nursingHomePadIdMiddleFirst'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='パッド1枚目'
                      value={value}
                      listData={formSelectLists.pads}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='careSettings.nursingHomePadIdMiddleSecond'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='パッド2枚目'
                      value={value}
                      listData={formSelectLists.pads}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div css={FormItem}>
              <label css={Label}>夜用:</label>
              <div css={FormContent}>
                <div css={CheckboxSelectContainer}>
                  <div>使用時間帯</div>
                  <div css={AlignCenter}>
                    <Controller
                      control={control}
                      name='careSettings.beginDiaperNightHour'
                      render={({ field: { onChange, value } }) => (
                        <Select
                          listData={HOURS_WITH_NOTHING}
                          value={!value ? '-' : value}
                          onChange={(v) => onChange(v.key)}
                        />
                      )}
                    />
                    -
                    <Controller
                      control={control}
                      name='careSettings.endDiaperNightHour'
                      render={({ field: { onChange, value } }) => (
                        <Select
                          listData={HOURS_WITH_NOTHING}
                          value={!value ? '-' : value}
                          onChange={(v) => onChange(v.key)}
                        />
                      )}
                    />
                  </div>
                </div>
                <Controller
                  control={control}
                  name='careSettings.nursingHomeDiaperIdNight'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='テープ/パンツ'
                      value={value}
                      listData={formSelectLists.pants}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='careSettings.nursingHomePadIdNightFirst'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='パッド1枚目'
                      value={value}
                      listData={formSelectLists.pads}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='careSettings.nursingHomePadIdNightSecond'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='パッド2枚目'
                      value={value}
                      listData={formSelectLists.pads}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div css={FormItem}>
              <label css={Label}>下剤:</label>
              <div css={FormContent}>
                <Controller
                  control={control}
                  name='careSettings.laxativeId'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='下剤'
                      value={value}
                      listData={formSelectLists.laxatives}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div css={BarTitle}>排泄支援の情報</div>
            <div css={FormItem}>
              <label css={Label}>要介護度:</label>
              <div css={FormContent}>
                <Controller
                  control={control}
                  name='careSettings.careLevel'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      style={{ width: '100%', marginBottom: '8px' }}
                      label='要介護度'
                      value={value}
                      listData={CARE_LEVEL}
                      onChange={(v) => {
                        onChange(v.key);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);
