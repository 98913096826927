import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { color } from '../../styles';
import { IGetLaxative } from '../../pages/expendables/expendablesList';
import { Cell, CellBase, HeaderCell, Row, Table, TableLinkText, TableLinkCell } from '../../styles/table';
import { Dialog } from '../mui/dialog/Dialog';
import api from '../../api';
import { useSnackbar } from '../../contexts/SnackbarContext';

interface Props {
  data: IGetLaxative[];
  url: string;
  dataFetch: () => void;
}

const ModalText = css`
  margin-bottom: 16px;
  font-size: 14px;
`;

const ModalSubText = css`
  color: ${color.red};
  font-size: 14px;
  font-weight: bold;
`;

// テーブルのセルの横幅を左から順に定義
const CellWidth = [{ flex: '1 0 130px' }, { flex: '1 1 320px' }, { flex: '0 0 120px' }];

export const LaxativeTable = React.memo(({ data, url, dataFetch }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { showSnackbar } = useSnackbar();
  const history = useHistory();
  const header = ['名前', '単位', '操作'];
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<IGetLaxative>();

  const handleClickEdit = (id: number) => () => {
    history.push(`${url}/laxative/${id}/edit`);
  };

  const handleClickDelete = (data: IGetLaxative) => async () => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    dataFetch();
  };

  const handleClickModalSubmit = async () => {
    if (selectedData) {
      await api.delete('/laxatives/:nursingHomeLaxativeId', {
        params: { nursingHomeLaxativeId: selectedData.id },
      });

      setTimeout(() => {
        onModalClose();
        showSnackbar('削除しました');
      }, 500);
    }
  };

  const LaxativeOperationCell = (data: IGetLaxative) => {
    return (
      <div css={TableLinkCell}>
        <div css={TableLinkText} onClick={handleClickEdit(data.id)}>
          編集
        </div>
        <div css={TableLinkText} onClick={handleClickDelete(data)}>
          削除
        </div>
      </div>
    );
  };

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {header.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0]]}>{data.name}</div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.displayUnit}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{LaxativeOperationCell(data)}</div>
            </div>
          );
        })}
      </div>
      {isModalOpen && (
        <Dialog isOpen={isModalOpen} title={'項目の削除'} onClose={onModalClose} onOkClick={handleClickModalSubmit}>
          <div css={ModalText}>下記のデータを削除しますか?</div>
          <div css={ModalSubText}>
            {selectedData?.name} / {selectedData?.displayUnit}
          </div>
        </Dialog>
      )}
    </>
  );
});
