interface INotification {
  [key: string]: string;
}

export const NOTIFICATION_TYPE: INotification = {
  bigBladder: 'そろそろ',
  urinated: '出たかも',
  batteryDead: '電池切れ',
  dfreeDeviceRemoved: '外れた',
  gotUp: '起き上がり',
  resetUrine: 'リセット',
  zeroReset: 'ゼロリセット',
  upsideDown: '上下逆',
};

export const NOTIFICATION_NAME_VALUES = [
  {
    value: 'bigBladder',
    label: 'そろそろ',
  },
  {
    value: 'urinated',
    label: '出たかも',
  },
  {
    value: 'batteryDead',
    label: '電池切れ',
  },
  {
    value: 'dfreeDeviceRemoved',
    label: '外れた',
  },
  {
    value: 'gotUp',
    label: '起き上がり',
  },
  {
    value: 'resetUrine',
    label: 'リセット',
  },
  {
    value: 'zeroReset',
    label: 'ゼロリセット',
  },
  {
    value: 'upsideDown',
    label: '上下逆',
  },
];
