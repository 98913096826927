export const LIFE_CARE_PLANNING_CSV_COL_JA = [
  { label: 'ID', value: 'id' },
  { label: '所属施設ID', value: 'nursingHomeId' },
  { label: '所属施設名称', value: 'nursingHomeName' },
  { label: '入居者ID', value: 'residentId' },
  { label: '入居者(姓)', value: 'residentLastName' },
  { label: '入居者(名)', value: 'residentFirstName' },
  { label: '事業所番号', value: 'careFacilityId' },
  { label: '保険者番号', value: 'insurerNo' },
  { label: '被保険者番号', value: 'insuredNo' },
  { label: 'サービス種類コード', value: 'serviceCode' },
  { label: '外部システム管理番号', value: 'externalSystemManagementNumber' },
  { label: '評価日', value: 'evaluateDate' },
  { label: '計画作成日', value: 'planCreateDate' },
  { label: '記入者職種', value: 'recordStaffJobCategory' },
  { label: '記入者名', value: 'filingStaff' },
  { label: '医師名', value: 'doctorName' },
  { label: '看護師名', value: 'nurseName' },
  { label: '排尿の状態(施設入所時)', value: 'urinationAdmissionStatus' },
  { label: '排尿の状態(評価時)', value: 'urinationEvaluationStatus' },
  { label: '排尿の状態(支援あり)', value: 'urinationThreeSupportStatus' },
  { label: '排尿の状態(支援なし)', value: 'urinationThreeNoSupportStatus' },
  { label: '排便の状態(施設入所時)', value: 'defecationAdmissionStatus' },
  { label: '排便の状態(評価時)', value: 'defecationEvaluationStatus' },
  { label: '排便の状態(支援あり)', value: 'defecationThreeSupportStatus' },
  { label: '排便の状態(支援なし)', value: 'defecationThreeNoSupportStatus' },
  { label: 'おむつ使用の有無(施設入所時)', value: 'diaperUserAdmissionStatus' },
  { label: 'おむつ使用の有無(評価時)', value: 'diaperUserEvaluationStatus' },
  { label: 'おむつ使用の有無(支援あり)', value: 'diaperUserThreeSupportStatus' },
  { label: 'おむつ使用の有無(支援なし)', value: 'diaperUserThreeNoSupportStatus' },
  { label: 'ポータブルトイレ使用の有無(施設入所時)', value: 'portableToiletAdmissionStatus' },
  { label: 'ポータブルトイレ使用の有無(評価時)', value: 'portableToiletEvaluationStatus' },
  { label: 'ポータブルトイレ使用の有無(支援あり)', value: 'portableToiletThreeSupportStatus' },
  { label: 'ポータブルトイレ使用の有無(支援なし)', value: 'portableToiletThreeNoSupportStatus' },
  { label: '排泄支援の必要性', value: 'excretionStatusNecessity' },
  { label: '排泄に介護を要する原因', value: 'excretionCauseSupport' },
  { label: '支援計画', value: 'supportProgram' },
  { label: 'バージョン番号', value: 'version' },
];

export const LIFE_CARE_PLANNING_CSV_COL_EN = [
  { label: 'care_facility_id', value: 'careFacilityId' },
  { label: 'service_code', value: 'serviceCode' },
  { label: 'insurer_no', value: 'insurerNo' },
  { label: 'insured_no', value: 'insuredNo' },
  { label: 'external_system_management_number', value: 'externalSystemManagementNumber' },
  { label: 'evaluate_date', value: 'evaluateDate' },
  { label: 'plan_create_date', value: 'planCreateDate' },
  { label: 'record_staff_job_category', value: 'recordStaffJobCategory' },
  { label: 'filing_staff', value: 'filingStaff' },
  { label: 'doctor_name', value: 'doctorName' },
  { label: 'nurse_name', value: 'nurseName' },
  { label: 'urination_admission_status', value: 'urinationAdmissionStatus' },
  { label: 'urination_evaluation_status', value: 'urinationEvaluationStatus' },
  { label: 'urination_three_support_status', value: 'urinationThreeSupportStatus' },
  { label: 'urination_three_no_support_status', value: 'urinationThreeNoSupportStatus' },
  { label: 'defecation_admission_status', value: 'defecationAdmissionStatus' },
  { label: 'defecation_evaluation_status', value: 'defecationEvaluationStatus' },
  { label: 'defecation_three_support_status', value: 'defecationThreeSupportStatus' },
  { label: 'defecation_three_no_support_status', value: 'defecationThreeNoSupportStatus' },
  { label: 'diaper_user_admission_status', value: 'diaperUserAdmissionStatus' },
  { label: 'diaper_user_evaluation_status', value: 'diaperUserEvaluationStatus' },
  { label: 'diaper_user_three_support_status', value: 'diaperUserThreeSupportStatus' },
  { label: 'diaper_user_three_no_support_status', value: 'diaperUserThreeNoSupportStatus' },
  { label: 'portable_toilet_admission_status', value: 'portableToiletAdmissionStatus' },
  { label: 'portable_toilet_evaluation_status', value: 'portableToiletEvaluationStatus' },
  { label: 'portable_toilet_three_support_status', value: 'portableToiletThreeSupportStatus' },
  { label: 'portable_toilet_three_no_support_status', value: 'portableToiletThreeNoSupportStatus' },
  { label: 'excretion_status_necessity', value: 'excretionStatusNecessity' },
  { label: 'excretion_cause_support', value: 'excretionCauseSupport' },
  { label: 'support_program', value: 'supportProgram' },
  { label: 'version', value: 'version' },
];
