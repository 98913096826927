import { IGetDiaperRes } from '../types/api/care-settings/getDiaper';
import { IGetMasterDiaperRes } from '../types/api/master/getDiaper';

// おむつ・パッドフィルタメソッド
export function filterInnersOnly(diapers: IGetDiaperRes[]): IGetDiaperRes[] {
  return diapers.filter((v) => v.diaperShape === 'inner');
}

export function filterOutersOnly(diapers: IGetDiaperRes[]): IGetDiaperRes[] {
  return diapers.filter((v) => v.diaperShape === 'outer');
}

export function filterExpendableOnly(diapers: IGetMasterDiaperRes[]): IGetMasterDiaperRes[] {
  return diapers.filter((v) => v.shape === 'expendable');
}