export const ACTIVITY_STATUS_POS_CODES_JA: Record<ACTIVITY_STATUS_POS_NUM, string> = {
  8: '動いている(立・座)',
  7: '動いている(寝)',
  6: '右側臥位',
  5: '左側臥位',
  4: 'うつ伏せ',
  3: '仰臥位',
  2: '上下逆',
  1: '座位',
  0: '立位',
} as const;

export const ACTIVITY_STATUS_POS_CODES_EN: Record<ACTIVITY_STATUS_POS_NUM, string> = {
  8: 'Moving (standing/sitting)',
  7: 'Moving (while lying in bed)',
  6: 'Right lateral position',
  5: 'Left lateral position',
  4: 'Face down position',
  3: 'Supine position',
  2: 'Upside down',
  1: 'Sitting',
  0: 'Standing',
} as const;

export type ACTIVITY_STATUS_POS_NUM = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
