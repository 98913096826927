import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PanelContainer } from '../../../components/panel';
import { MenuItemProps, Select } from '../../../components/mui/select';
import { OrderFilterSelector } from './orderFilterSelector';
import api from '../../../api';
import { color } from '../../../styles';
import { useParams } from 'react-router-dom';
import { IGetOrderAnalysis } from '../../../types/api/traversal/getOrderAnalysis';
import { IGetCareRecordOrderAnalysis } from '../../../types/api/traversal/getCareRecordOrderAnalysis';
import { Loading } from '../../../components/mui/Loading';
import { useLoading } from '../../../contexts/LoadingContext';
import { FACILITY_REFERENCE_VALUE } from '../../../constants';
import { selfDataSelector } from '../../../redux/config/selfData';
import { calcAnalysisAverage } from '../../../utils/calcAnalysisAverage';
import { numInsertComma } from '../../../utils/dataFormatUtil';

// TODO: 納品額一人当たり、納品額合計、請求額一人当たりの値がない場合に0を表示しない
// TODO: 各月をクリックした場合にその月の発注履歴を見せる
// TODO: 基準値を超えた値はハイライトをつける

export interface AnalysisTableData {
  absorption: number;
  numberOfDiaper: number;
  numberOfPad: number;
  nursingHomeId: number;
  nursingHomeName: string;
  activeUser: number;
  occupancyRate: number;
  invoice: number;
  invoicePerPerson?: number | null;
  capacity: number;
  deliveryPrice: number;
  deliveryPerPerson?: number | null;
  plan: number;
  yyyyMM: string;
}

const SelectorContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  span {
    padding: 16px;
  }
`;

const Selector = css`
  width: 140px;
`;

const TableContainer = css`
  overflow-x: auto;
`;

const Table = css`
  width: 100%;
  min-width: 840px;
  text-align: center;
  border: 1px solid ${color.border_gray};
`;

const TableHeader = css``;

const ThCell = css`
  min-width: 80px;
  max-width: 100px;
  border: 1px solid ${color.border_gray};
  font-weight: normal;
  padding: 8px 4px;
  font-size: 12px;
  line-height: 14px;
  color: ${color.gray};
`;

const TdCell = css`
  min-width: 80px;
  max-width: 100px;
  border-right: 1px solid ${color.border_gray};
  border-bottom: 1px solid ${color.border_gray};
  padding: 8px 4px;
  font-size: 14px;
  color: ${color.dark_gray};
`;

export const AnalysisOrder = React.memo(() => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfNursingHome } = useSelector(selfDataSelector);
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();
  const [date, setDate] = React.useState<{ start: string; end: string }>({
    start: dayjs(new Date())
      .set('date', 1)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .subtract(1, 'year')
      .utc()
      .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    end: dayjs(new Date())
      .set('date', 1)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .subtract(1, 'month')
      .utc()
      .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
  });
  const [tableData, setTableData] = React.useState<AnalysisTableData[]>();

  const fetchAnalysis = React.useCallback(async () => {
    const params = { nursingHomeId: +nursingHomeId, from: date.start, to: date.end };
    showLoading();
    Promise.all([
      api.get('/traversal/nursing-homes/:nursingHomeId/orders/analytics', {
        params,
      }),
      api.get('/traversal/nursing-homes/:nursingHomeId/orders/care-records/analytics', {
        params,
      }),
    ]).then(([orders, records]) => {
      if (records.length <= 0 || !selfNursingHome) {
        hideLoading();
        setTableData([]);
        return;
      }
      const tableData: AnalysisTableData[] = records.map((record) => {
        const yyyyMM = dayjs(record.yyyyMM).format('YYYY年MM月');
        const findRecord = orders.find((order) => order.yyyyMM === record.yyyyMM);
        if (findRecord) {
          return { ...findRecord, ...record, yyyyMM };
        } else {
          return { ...record, yyyyMM, activeUser: 0, capacity: 0, deliveryPrice: 0, plan: 0, invoice: 0 };
        }
      });
      const average: AnalysisTableData = calcAnalysisAverage(tableData);
      setTableData([average, ...tableData]);
      hideLoading();
    });
  }, [date]);

  const handleChangeStartDate = React.useCallback(
    (selected: MenuItemProps) => {
      setDate((prev) => {
        return { ...prev, start: String(selected.key) };
      });
    },
    [date]
  );

  const handleChangeEndDate = React.useCallback(
    (selected: MenuItemProps) => {
      setDate((prev) => {
        return { ...prev, end: String(selected.key) };
      });
    },
    [date]
  );

  const dateFilterList = React.useMemo(() => {
    const today = dayjs();
    let dateFilterList: MenuItemProps[] = [];
    for (let i = 1; i <= 12; i++) {
      dateFilterList.push({
        key: today
          .set('date', 1)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .subtract(i, 'month')
          .utc()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        value: today.subtract(i, 'month').format('YYYY/MM'),
      });
    }
    return dateFilterList;
  }, []);

  const facilityReference = React.useMemo(() => {
    return FACILITY_REFERENCE_VALUE.find((v) => v.type === selfNursingHome.type);
  }, [selfNursingHome]);

  React.useEffect(() => {
    fetchAnalysis();
  }, [date]);

  return (
    <PanelContainer title={'発注分析'}>
      {tableData !== undefined ? (
        <>
          <div css={SelectorContainer}>
            <div css={Selector}>
              <Select
                id='filter-date-selector'
                listData={dateFilterList}
                value={date.start}
                label='取得開始月'
                fullWidth={true}
                onChange={handleChangeStartDate}
              />
            </div>
            <span>〜</span>
            <div css={Selector}>
              <Select
                id='filter-date-selector'
                listData={dateFilterList}
                value={date.end}
                label='取得終了月'
                fullWidth={true}
                onChange={handleChangeEndDate}
              />
            </div>
          </div>
          {tableData.length ? (
            <div css={TableContainer}>
              <table css={Table}>
                <thead css={TableHeader}>
                  <tr>
                    <th css={ThCell}></th>
                    <th css={ThCell}>
                      定員数 <br />
                      稼働率 <br />
                      稼働床数 <br />
                    </th>
                    <th css={ThCell}>
                      納品額 <br />
                      一人あたり
                    </th>
                    <th css={ThCell}>
                      納品額 <br />
                      施設合計
                    </th>
                    <th css={ThCell}>
                      請求額 <br />
                      一人当たり
                    </th>
                    <th css={ThCell}>
                      請求額 <br />
                      施設合計
                    </th>
                    <th css={ThCell}>
                      おむつ発注枚数 <br />
                      発注枚数/1人1日
                    </th>
                    <th css={ThCell}>
                      パッド発注枚数 <br />
                      発注枚数/1人1日
                    </th>
                    <th css={ThCell}>
                      発注パッド吸収量 <br />
                      吸収量/1人1日
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {facilityReference && (
                    <tr>
                      <td css={TdCell}>基準値</td>
                      <td css={TdCell}>
                        {facilityReference.capacity}
                        <br /> {facilityReference.occupancyRate} <br />
                        {facilityReference.activeUser}
                      </td>
                      <td css={TdCell}>{numInsertComma(facilityReference.deliveryPerPerson)}円</td>
                      <td css={TdCell}>{facilityReference.deliveryPrice}</td>
                      <td css={TdCell}></td>
                      <td css={TdCell}></td>
                      <td css={TdCell}>{facilityReference.numberOfDiaper}枚</td>
                      <td css={TdCell}>{facilityReference.numberOfPad}枚</td>
                      <td css={TdCell}>{numInsertComma(facilityReference.absorption)}cc</td>
                    </tr>
                  )}
                  {tableData.map((analysis) => (
                    <tr key={analysis.yyyyMM}>
                      <td css={TdCell}>{analysis.yyyyMM}</td>
                      <td css={TdCell}>
                        {analysis.capacity > 0 && numInsertComma(analysis.capacity) + '人'}
                        <br />
                        {analysis.occupancyRate > 0 && numInsertComma(analysis.occupancyRate) + '%'}
                        <br />
                        {analysis.activeUser > 0 && numInsertComma(analysis.activeUser) + '床'}
                      </td>
                      <td css={TdCell}>
                        {analysis.activeUser && analysis.deliveryPrice
                          ? numInsertComma(analysis.deliveryPrice / analysis.activeUser) + '円'
                          : ''}
                      </td>
                      <td css={TdCell}>
                        {analysis.deliveryPrice && analysis.deliveryPrice > 0
                          ? numInsertComma(analysis.deliveryPrice) + '円'
                          : ''}
                      </td>
                      <td css={TdCell}>
                        {analysis.activeUser && analysis.invoice
                          ? numInsertComma(analysis.invoice / analysis.activeUser) + '円'
                          : ''}
                      </td>
                      <td css={TdCell}>{analysis.invoice > 0 && numInsertComma(analysis.invoice) + '円'}</td>
                      <td css={TdCell}>
                        {analysis.numberOfDiaper > 0 && numInsertComma(analysis.numberOfDiaper) + '枚'}
                      </td>
                      <td css={TdCell}>{analysis.numberOfPad > 0 && numInsertComma(analysis.numberOfPad) + '枚'}</td>
                      <td css={TdCell}>{analysis.absorption > 0 && numInsertComma(analysis.absorption) + 'cc'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>{t('dataForTheSpecifiedPeriodWasNotFound', '指定した期間のデータが見つかりませんでした')}</div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </PanelContainer>
  );
});
