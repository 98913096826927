import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { DefaultLayout } from '../layouts/defaultLayout';
import { BulkRecord } from '../pages/residents/bulkRecord';
import { ExcretionTableList } from '../pages/residents/ExcretionTableList';
import { Notification } from '../pages/residents/notification/notification';
import { AnalysisRouter } from './AnalysisRouter';
import { ExpendableRouter } from './ExpendableRouter';
import { ResidentRouter } from './ResidentRouter';
import { SettingsRouter } from './SettingsRouter';
import { Announcement } from '../pages/announcement/index';

export const NursingHomeRouter: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <DefaultLayout>
      <Switch>
        <Route path={`${path}/residents`}>
          <ResidentRouter />
        </Route>
        <Route path={`${path}/records`}>
          <ExcretionTableList />
        </Route>
        <Route path={`${path}/bulk-record`}>
          <BulkRecord />
        </Route>
        <Route path={`${path}/notifications`}>
          <Notification />
        </Route>
        <Route path={`${path}/analysis`}>
          <AnalysisRouter />
        </Route>
        <Route path={`${path}/expendable`}>
          <ExpendableRouter />
        </Route>
        <Route path={`${path}/settings`}>
          <SettingsRouter />
        </Route>
        <Route path={`${path}/announcements`}>
          <Announcement />
        </Route>
        <Redirect path='*' to='/404' />
      </Switch>
    </DefaultLayout>
  );
};
