import React from 'react';
import dayjs from 'dayjs';
import { css } from '@emotion/react';
import { IGetNotificationRes } from '@/types/api/notification/getNotification';
import { color } from '../../../styles';
import { useTranslation } from 'react-i18next';

interface ResidentNotification extends IGetNotificationRes {
  residentName: string;
  residentLastName: string;
}

interface Props {
  notification: ResidentNotification;
}

const Container = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  min-height: 60px;
  background: ${color.white};
`;

const NotificationInfo = css`
  display: flex;
  align-items: center;
`;

const NotificationImage = css`
  margin: 0 20px;
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const ResidentName = css`
  font-size: 14px;
`;

const NotificationDescription = css`
  font-size: 12px;
  color: ${color.gray};
`;

const NotificationDate = css`
  font-family: 'Oswald', sans-serif;
  font-size: 12px;
  line-height: 18px;
  padding: 21px 25px;
  color: ${color.gray};
`;

export const NotificationListItem = React.memo(({ notification }: Props) => {
  const { t } = useTranslation();
  const notificationInfo = React.useMemo(() => {
    switch (notification.notificationType) {
      case 'urinated':
        return { path: 'icon_urinated', description: t('declineInVolume', 'でたかもしれません') };
      case 'bigBladder':
        return { path: 'icon_bigbladder', description: t('exceededAlmostThreshold', 'そろそろラインを超えました') };
      case 'gotUp':
        return { path: 'icon_gotUp', description: t('detectedGettingUp', '起き上がりを検知しました') };
      case 'resetUrine':
        return {
          path: 'icon_alert',
          description: t('measurementResumed', '測定が再開されました'),
        };
      case 'dfreeDeviceRemoved':
        return {
          path: 'icon_alert',
          description: t('sensorNotResponding', 'DFreeが外れています'),
        };
      case 'zeroReset':
        return {
          path: 'icon_zeroReset',
          description: t('urineLevelHasBeen0For1HourOrMore', '装着から尿量が2時間以上0です'),
        };
      case 'upsideDown':
        return {
          path: 'icon_alert',
          description: t('dFreeIsPlacedUpsideDown', 'DFreeの向きが上下逆です'),
        };
      case 'batteryAlert':
        return {
          path: 'icon_alert',
          description: t('AnErrorWasDetectedWithTheDFreeDevice', 'DFree機器本体の異常を検知しました'),
        };
      case 'batteryDead':
        return {
          path: 'icon_batteryDead',
          description: t('DFreeBatteryIsLow', 'DFreeの電池残量が少なくなっています'),
        };
      default:
        return {
          path: 'icon_alert',
          description: t('errorNonStandardValue', '規定外の値です'),
        };
    }
  }, []);

  return (
    <div css={Container}>
      <div css={NotificationInfo}>
        <img css={NotificationImage} src={`/images/notification/${notificationInfo.path}.svg`} />
        <div>
          <div css={ResidentName}>
            {notification.residentLastName} {notification.residentName}
          </div>
          <div css={NotificationDescription}>{notificationInfo.description}</div>
        </div>
      </div>
      <div css={NotificationDate}>{dayjs(notification.timestamp).format('YYYY/MM/DD HH:mm')}</div>
    </div>
  );
});
