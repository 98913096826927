import React from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { Button } from '../components/mui/button';
import { color } from '../styles/color';
import { useTranslation } from 'react-i18next';

const Container = css`
  padding: 60px 24px 0 24px;
`;

const Card = css`
  margin: 0 auto;
  padding: 60px 32px;
  max-width: 800px;
  background-color: ${color.white};
  border-radius: 8px;
  text-align: center;
`;

const Title = css`
  font-family: 'Oswald';
  font-size: 48px;
  font-weight: bold;
`;

const SubTitle = css`
  font-family: 'Oswald';
  font-size: 18px;
  font-weight: bold;
`;

const CaptionContainer = css`
  margin: 32px 0;
  font-size: 14px;
`;

export const NotFound: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleGoTop = React.useCallback(() => {
    history.push('/');
  }, []);

  return (
    <div css={Container}>
      <div css={Card}>
        <div css={Title}>404</div>
        <div css={SubTitle}>Not Found</div>
        <div css={CaptionContainer}>
          {t('cantFindThePage', 'お探しのページは見つかりません')}
          <br />
          {t(
            'temporarilyInaccessible',
            '一時的にアクセス出来ない状況か、移動もしくは制限されてしまった可能性があります'
          )}
        </div>
        <Button variant='contained' onClick={handleGoTop}>
          {t('backToTopPage', 'トップページへ戻る')}
        </Button>
      </div>
    </div>
  );
};
