import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../redux/core/store';

interface Badge {
  resident: number;
  careSchedule: number;
  temporarilySaved: number;
}

interface NavState {
  isNavOpen: boolean;
  badge: Badge;
}

// initialize
const initialState: NavState = {
  isNavOpen: true,
  badge: {
    resident: 0,
    careSchedule: 0,
    temporarilySaved: 0,
  },
};

// slice
export const navSlice = createSlice({
  name: 'navState',
  initialState,
  reducers: {
    setIsNavOpen: (state, action: PayloadAction<boolean>) => {
      state.isNavOpen = action.payload;
    },
    setBadge: (state, action: PayloadAction<Badge>) => {
      state.badge = action.payload;
    },
    setResidentBadge: (state, action: PayloadAction<number>) => {
      state.badge.resident = state.badge.resident + action.payload;
    },
    setTemporarilySavedBadge: (state, action: PayloadAction<number>) => {
      state.badge.temporarilySaved = state.badge.temporarilySaved + action.payload;
    },
  },
});

// action
export const { setIsNavOpen, setBadge, setTemporarilySavedBadge } = navSlice.actions;

// selector
export const navSelector = (state: RootState) => state.navState;
