import React from 'react';
import { css } from '@emotion/react';

const Container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 100vh;
  width: 100%;
  background-color: #e6eaf0;
`;

const Card = css`
  max-width: 630px;
  min-height: 460px;
  width: 96%;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  padding: 40px 80px;
  border-radius: 8px;
  box-sizing: border-box;
`;

const Img = css`
  width: 108px;
  margin-bottom: 40px;
`;

export const LoginLayout: React.FC = ({ children }) => {
  return (
    <div css={Container}>
      <div css={Card}>
        <img css={Img} src='/images/login/logo.svg' alt='DFree' />
        {children}
      </div>
    </div>
  );
};
