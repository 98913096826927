export const LIFE_SETTINGS = [
  { key: true, value: 'あり' },
  { key: false, value: 'なし' },
];

export const LIFE_CARE_LEVEL = [
  { key: '01', value: '非該当' },
  { key: '06', value: '事業対象者' },
  { key: '11', value: '要支援（経過的要介護）' },
  { key: '12', value: '要支援１' },
  { key: '13', value: '要支援２' },
  { key: '21', value: '要介護' },
  { key: '22', value: '要介護２' },
  { key: '23', value: '要介護３' },
  { key: '24', value: '要介護４' },
  { key: '25', value: '要介護５' },
];

export const LIFE_IMPAIRED = [
  { key: 'independence', value: '自立' },
  { key: 'indJ1', value: 'J1' },
  { key: 'indJ2', value: 'J2' },
  { key: 'indA1', value: 'A1' },
  { key: 'indA2', value: 'A2' },
  { key: 'indB1', value: 'B1' },
  { key: 'indB2', value: 'B2' },
  { key: 'indC1', value: 'C1' },
  { key: 'indC2', value: 'C2' },
];

export const LIFE_DEMENTIA = [
  { key: 'independence', value: '自立' },
  { key: 'ind1', value: 'Ⅰ' },
  { key: 'ind2a', value: 'Ⅱa' },
  { key: 'ind2b', value: 'Ⅱb' },
  { key: 'ind3a', value: 'Ⅲa' },
  { key: 'ind3b', value: 'Ⅲb' },
  { key: 'ind4', value: 'Ⅳ' },
  { key: 'indm', value: 'M' },
];

export const LIFE_CARE_PLANNING_JOB_CATEGORY = [
  { key: '010', value: '医師' },
  { key: '020', value: '歯科医師' },
  { key: '030', value: '薬剤師' },
  { key: '040', value: '看護師' },
  { key: '050', value: '准看護師' },
  { key: '060', value: '保健師' },
  { key: '070', value: '助産師' },
  { key: '080', value: '理学療法士' },
  { key: '090', value: '作業療法士' },
  { key: '100', value: '言語聴覚士' },
  { key: '110', value: '柔道整復師' },
  { key: '120', value: 'あん摩マッサージ指圧師' },
  { key: '125', value: 'はり師・きゅう師' },
  { key: '130', value: '歯科衛生士' },
  { key: '140', value: '精神保健福祉士' },
  { key: '150', value: '介護支援専門員' },
  { key: '160', value: '社会福祉士' },
  { key: '170', value: '介護福祉士' },
  { key: '180', value: '福祉用具専門相談員' },
  { key: '190', value: '管理栄養士' },
  { key: '200', value: '栄養士' },
  { key: '210', value: 'その他' },
  { key: '999', value: '不明' },
];

export const LIFE_CARE_PLANNING_URINATION = [
  { key: 'all', value: '全介助' },
  { key: 'part', value: '一部介助' },
  { key: 'monitoring', value: '見守り等' },
  { key: 'none', value: '介助されていない' },
];

export const LIFE_CARE_PLANNING_USE_DIAPER = [
  { key: 'none', value: 'なし' },
  { key: 'daytime', value: 'あり（日中のみ）' },
  { key: 'night', value: 'あり（夜間のみ）' },
  { key: 'all', value: 'あり（終日）' },
];

export const LIFE_CARE_PLANNING_EXCRETION_STATUS = [
  { key: 'none', value: '無し' },
  { key: 'necessity', value: '有り' },
];

export const LIFE_GENDER = [
  { key: 'female', value: '女性' },
  { key: 'male', value: '男性' },
  { key: null, value: '未設定' },
];

// csv出力用
export const LIFE_IMPAIRED_CSV_VALUE = [
  { key: 'independence', value: 1 },
  { key: 'indJ1', value: 2 },
  { key: 'indJ2', value: 3 },
  { key: 'indA1', value: 4 },
  { key: 'indA2', value: 5 },
  { key: 'indB1', value: 6 },
  { key: 'indB2', value: 7 },
  { key: 'indC1', value: 8 },
  { key: 'indC2', value: 9 },
];

export const LIFE_DEMENTIA_CSV_VALUE = [
  { key: 'independence', value: 1 },
  { key: 'ind1', value: 2 },
  { key: 'ind2a', value: 3 },
  { key: 'ind2b', value: 4 },
  { key: 'ind3a', value: 5 },
  { key: 'ind3b', value: 6 },
  { key: 'ind4', value: 7 },
  { key: 'indm', value: 8 },
];

export const LIFE_CARE_PLANNING_URINATION_CSV_VALUE = [
  { key: 'all', value: 0 },
  { key: 'part', value: 1 },
  { key: 'monitoring', value: 2 },
  { key: 'none', value: 3 },
];

export const LIFE_CARE_PLANNING_USE_DIAPER_CSV_VALUE = [
  { key: 'none', value: 0 },
  { key: 'daytime', value: 1 },
  { key: 'night', value: 2 },
  { key: 'all', value: 3 },
];

export const LIFE_CARE_PLANNING_EXCRETION_STATUS_CSV_VALUE = [
  { key: 'none', value: 0 },
  { key: 'necessity', value: 1 },
];

export const LIFE_GENDER_CSV_VALUE = [
  { key: 'female', value: 2 },
  { key: 'male', value: 1 },
];
