export const SUPPORT_URL = {
  manual: {
    ja: 'https://dfree.biz/img/pdf/professional_manual.pdf',
  },
  howToWear: {
    ja: 'https://dfree.biz/professional/product/wearing.html',
    en: 'https://www.youtube.com/watch?v=F8cswb_SCQU',
  },
  troubleshooting: {
    ja: 'https://dfree.biz/img/pdf/professional_solution.pdf',
  },
  contact: {
    ja: 'https://dfree.biz/professional/contact.html',
    en: 'https://www.dfreeus.biz/support',
  },
};
