import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetUnitRes } from '../../../types/api/unit/getUnit';

export const useFetchUnits = () => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [units, setUnits] = React.useState<IGetUnitRes[]>();

  const fetchUnits = async () => {
    const params = {
      nursingHomeId: +nursingHomeId,
    };
    const result = await api.get('/nursing-homes/:nursingHomeId/nursing-home-units', {
      params,
    });
    setUnits(result);
  };

  React.useEffect(() => {
    fetchUnits();
  }, []);

  return { units, fetchUnits };
};
