import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { GetDiaperRequiredResponse } from '../../../types/api/diaper-required/getDiaperRequired';
import { selfDataSelector } from '../../../redux/config/selfData';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  sort: string;
  limit: number;
}

// nursingHomeUnitIdがParamsに指定できないが、今後の拡張も踏まえnursingHomeUnitIdを含めて実装

// 絞り込み
// nursingHomeUnitId: null = 全体検索, 0 = 施設全体のみ , unitId = 指定unitのみ

export const useFetchDiapersRequired = (nursingHomeUnitId: number | null = null, sort = 'desc', limit = 10000) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const [diapersRequired, setDiaperRequired] = React.useState<GetDiaperRequiredResponse>();

  const fetchDiapersRequired = async (nursingHomeUnitId: number | null = null, sort = 'desc', limit = 10000) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      sort,
      limit,
    };

    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/nursing-homes/:nursingHomeId/diaper-required', {
      params,
    });

    // unitIdが0の場合は施設全体を取り出す
    // paramsにnursingHomeUnitIdが指定できるようになればelseの処理は不要
    if (nursingHomeUnitId === 0) {
      result = result.filter((v) => v.nursingHomeUnitId === null);
    } else {
      result = result.filter((v) => v.nursingHomeUnitId === nursingHomeUnitId);
    }

    setDiaperRequired(result);
  };

  React.useEffect(() => {
    if (!selfUser.nursingHomeId) return;
    if (nursingHomeUnitId) fetchDiapersRequired(nursingHomeUnitId, sort, limit);
    else if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId)
      fetchDiapersRequired(selfUser.nursingHomeUnitId, sort, limit);
    else fetchDiapersRequired(null, sort, limit);
  }, [selfUser]);

  return { diapersRequired, fetchDiapersRequired };
};
