import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { css } from '@emotion/react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { currentNursingHomeSelector } from '../../../redux/config/currentNursingHome';
import {
  LIFE_CARE_PLANNING_URINATION_CSV_VALUE as LIFE_URINATION_CSV_VALUE,
  LIFE_CARE_PLANNING_USE_DIAPER_CSV_VALUE as LIFE_USE_DIAPER_CSV_VALUE,
  LIFE_RESIDENT_CSV_COL_EN,
  LIFE_CARE_PLANNING_CSV_COL_EN,
  LIFE_CARE_PLANNING_EXCRETION_STATUS_CSV_VALUE,
  LIFE_GENDER_CSV_VALUE,
  LIFE_IMPAIRED_CSV_VALUE,
  LIFE_DEMENTIA_CSV_VALUE,
} from '../../../constants';
import { OutlineButton } from '../../../components/outlineButton';
import { PanelContainer } from '../../../components/panel';
import { IGetResidentRes } from '../../../types/api/resident/getResident';
import { IGetLifeCarePlanningsRes } from '../../../types/api/life/getLifeCarePlanning';
import { IGetLifeResidentsRes } from '../../../types/api/life/getLifeResident';
import { useFetchResidents } from '../../../hooks/api/resident/useFetchResidents';
import { useFetchLifeResidents } from '../../../hooks/api/life/useFetchLifeResidents';
import { useFetchLifeCarePlannings } from '../../../hooks/api/life/useFetchLifeCarePlannings';
import { exportCSV } from '../../../utils/exportCSV';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { ResidentsTable } from '../residentsTable';

interface IDisplayResident extends IGetResidentRes {
  isChecked: boolean; // テーブルのチェック状態の管理フラグ
}

interface DownloadButtonProps {
  title: string;
  disable: boolean;
  onClick: () => void;
}

const TopContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;

const DateRangePicker = css`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ButtonContainer = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 4px;
`;

export const LifeCSVExport = React.memo(() => {
  const rangeMinDate = dayjs().startOf('day').subtract(3, 'year');
  const rangeMaxDate = dayjs().endOf('day');
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { residents } = useFetchResidents(true);
  const { lifeCarePlannings, fetchLifeCarePlannings } = useFetchLifeCarePlannings(false);
  const { lifeResidents, fetchLifeResidents } = useFetchLifeResidents(false);
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const [tableData, setTableData] = React.useState<IDisplayResident[]>();
  const [checkArray, setCheckArray] = React.useState<boolean[]>();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [fetchTimestamp, setFetchTimestamp] = React.useState<{ start: Dayjs; end: Dayjs }>({
    start: dayjs().startOf('day').subtract(1, 'day'),
    end: dayjs().endOf('day'),
  });
  const { showSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const selectedResidents: IGetResidentRes[] = React.useMemo(() => {
    if (!residents || !checkArray) return [];
    return residents?.filter((v, i) => {
      return checkArray[i] === true;
    });
  }, [checkArray, residents]);

  const handleChangeStartTime = (start: Dayjs | null) => {
    if (!start) return;
    setFetchTimestamp((prev) => {
      return { ...prev, start: start.startOf('day') };
    });
  };

  const handleChangeEndTime = (end: Dayjs | null) => {
    if (!end) return;
    setFetchTimestamp((prev) => {
      return { ...prev, end: end.endOf('day') };
    });
  };

  const handleCSVownload = (csvType: 'resident' | 'carePlanning') => async () => {
    if (!selectedResidents.length) {
      showSnackbar(t('noResidentSelected', '入居者が選択されていません'), 'error');
      return;
    }
    if (!fetchTimestamp?.start || !fetchTimestamp?.end) {
      return;
    }
    const residentIds = selectedResidents.map((resident) => resident.id);
    setOnSend(true);

    if (csvType === 'resident') {
      const res = await fetchLifeResidents(
        +nursingHomeId,
        residentIds,
        fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')
      );
      await createLifeResidentsCSVData(res);
    } else if (csvType === 'carePlanning') {
      const res = await fetchLifeCarePlannings(
        +nursingHomeId,
        residentIds,
        fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')
      );
      await createLifeCarePlanningCSVData(res);
    }
    setOnSend(false);
  };

  const createLifeResidentsCSVData = async (data: IGetLifeResidentsRes) => {
    let exportData: any[] = [];
    if (!data.length) {
      showSnackbar(t('dataForTheSpecifiedPeriodWasNotFound', '指定した期間のデータが見つかりませんでした'));
      return;
    }

    data.forEach((v) => {
      let csvData = {
        careFacilityId: v.careFacilityId,
        serviceCode: v.serviceCode,
        externalSystemManagementNumber: v.externalSystemManagementNumber,
        insurerNo: v.insurerNo,
        insuredNo: v.insuredNo,
        lastName: v.lastName,
        firstName: v.firstName,
        lastNameKana: v.lastNameKana,
        firstNameKana: v.firstNameKana,
        gender: formatCsvValue(v.gender, LIFE_GENDER_CSV_VALUE),
        birthday: formatCsvDate(v.birthday),
        certifiedDate: formatCsvDate(v.certifiedDate),
        carePeriodStart: formatCsvDate(v.carePeriodStart),
        carePeriodEnd: formatCsvDate(v.carePeriodEnd),
        careLevel: v.careLevel,
        impairedElderlyIndependenceDegree: formatCsvValue(v.impairedElderlyIndependenceDegree, LIFE_IMPAIRED_CSV_VALUE),
        dementiaElderlyIndependenceDegree: formatCsvValue(v.dementiaElderlyIndependenceDegree, LIFE_DEMENTIA_CSV_VALUE),
        startDate: formatCsvDate(v.startDate),
        endDate: formatCsvDate(v.endDate),
        deathDate: formatCsvDate(v.deathDate),
        remarks: v.remarks?.replace(/\r?\n|\r|\n/g, ' '),
        version: v.version,
      };
      exportData.push(csvData);
    });
    const filename =
      currentNursingHome.name +
      '_' +
      'LifeResident' +
      '_' +
      dayjs(fetchTimestamp!.start).format('YYYY-MM-DD') +
      '_' +
      dayjs(fetchTimestamp!.end).format('YYYY-MM-DD');

    const column = LIFE_RESIDENT_CSV_COL_EN;
    await exportCSV(exportData, column, filename, '.csv', 'SERVICE_USER_INFO');
  };

  const createLifeCarePlanningCSVData = async (data: IGetLifeCarePlanningsRes) => {
    let exportData: any[] = [];
    if (!data.length) {
      showSnackbar(t('dataForTheSpecifiedPeriodWasNotFound', '指定した期間のデータが見つかりませんでした'));
      return;
    }

    data.forEach((v) => {
      let csvData = {};
      csvData = {
        careFacilityId: v.careFacilityId,
        insurerNo: v.insurerNo,
        insuredNo: v.insuredNo,
        serviceCode: v.serviceCode,
        externalSystemManagementNumber: v.externalSystemManagementNumber,
        evaluateDate: formatCsvDate(v.evaluateDate),
        planCreateDate: formatCsvDate(v.planCreateDate),
        recordStaffJobCategory: v.recordStaffJobCategory,
        filingStaff: v.filingStaff,
        doctorName: v.doctorName,
        nurseName: v.nurseName,
        urinationAdmissionStatus: formatCsvValue(v.urinationAdmissionStatus, LIFE_URINATION_CSV_VALUE),
        urinationEvaluationStatus: formatCsvValue(v.urinationEvaluationStatus, LIFE_URINATION_CSV_VALUE),
        urinationThreeSupportStatus: formatCsvValue(v.urinationThreeSupportStatus, LIFE_URINATION_CSV_VALUE),
        urinationThreeNoSupportStatus: formatCsvValue(v.urinationThreeNoSupportStatus, LIFE_URINATION_CSV_VALUE),
        defecationAdmissionStatus: formatCsvValue(v.defecationAdmissionStatus, LIFE_URINATION_CSV_VALUE),
        defecationEvaluationStatus: formatCsvValue(v.defecationEvaluationStatus, LIFE_URINATION_CSV_VALUE),
        defecationThreeSupportStatus: formatCsvValue(v.defecationThreeSupportStatus, LIFE_URINATION_CSV_VALUE),
        defecationThreeNoSupportStatus: formatCsvValue(v.defecationThreeNoSupportStatus, LIFE_URINATION_CSV_VALUE),
        diaperUserAdmissionStatus: formatCsvValue(v.diaperUserAdmissionStatus, LIFE_USE_DIAPER_CSV_VALUE),
        diaperUserEvaluationStatus: formatCsvValue(v.diaperUserEvaluationStatus, LIFE_USE_DIAPER_CSV_VALUE),
        diaperUserThreeSupportStatus: formatCsvValue(v.diaperUserThreeSupportStatus, LIFE_USE_DIAPER_CSV_VALUE),
        diaperUserThreeNoSupportStatus: formatCsvValue(v.diaperUserThreeNoSupportStatus, LIFE_USE_DIAPER_CSV_VALUE),
        portableToiletAdmissionStatus: formatCsvValue(v.portableToiletAdmissionStatus, LIFE_USE_DIAPER_CSV_VALUE),
        portableToiletEvaluationStatus: formatCsvValue(v.portableToiletEvaluationStatus, LIFE_USE_DIAPER_CSV_VALUE),
        portableToiletThreeSupportStatus: formatCsvValue(v.portableToiletThreeSupportStatus, LIFE_USE_DIAPER_CSV_VALUE),
        portableToiletThreeNoSupportStatus: formatCsvValue(
          v.portableToiletThreeNoSupportStatus,
          LIFE_USE_DIAPER_CSV_VALUE
        ),
        excretionStatusNecessity: formatCsvValue(
          v.excretionStatusNecessity,
          LIFE_CARE_PLANNING_EXCRETION_STATUS_CSV_VALUE
        ),
        excretionCauseSupport: v.excretionCauseSupport?.replace(/\r?\n|\r|\n/g, ' '),
        supportProgram: v.supportProgram?.replace(/\r?\n|\r|\n/g, ' '),
        version: v.version,
      };
      exportData.push(csvData);
    });

    const filename =
      currentNursingHome.name +
      '_' +
      'LifeCarePlanning' +
      '_' +
      dayjs(fetchTimestamp!.start).format('YYYY-MM-DD') +
      '_' +
      dayjs(fetchTimestamp!.end).format('YYYY-MM-DD');

    const column = LIFE_CARE_PLANNING_CSV_COL_EN;
    await exportCSV(exportData, column, filename, '.csv', 'FORM_0600_2021');
  };

  const formatCsvValue = (data: string | null, list: { key: string; value: string | number }[]) => {
    return list.find((c) => c.key === data)?.value ?? data;
  };

  const formatCsvDate = (dateStr: string | null) => {
    return dateStr ? dayjs.utc(dateStr).format('YYYYMMDD') : '';
  };

  const handleChangeCheckBox = (checkArray: boolean[]) => {
    // TODO: selectedResidentsの更新検知のためにスプレッド演算子で更新を行なっているが
    // チェックボックスの状態を変更すると描画がTable全体に入るため要見直し
    setCheckArray([...checkArray]);
  };

  const createTableData = (data: IGetResidentRes[] | undefined) => {
    return data?.map((v) => {
      return {
        ...v,
        isChecked: false,
      };
    });
  };

  const DownloadButton = ({ title, disable, onClick }: DownloadButtonProps) => {
    return (
      <Tooltip
        placement='top-end'
        title={t(
          'downloadingMayTakeSomeTimeDependingOnTheAmountOfData',
          'データ量に応じてダウンロードに時間がかかる場合がございます。'
        )}
      >
        <div>
          <OutlineButton
            icon={<img src='/images/analysis/icon_dl_csv.svg' alt='CSV' />}
            onClick={onClick}
            disable={disable}
          >
            {title}
          </OutlineButton>
        </div>
      </Tooltip>
    );
  };

  React.useEffect(() => {
    if (!residents) return;
    const tableData = createTableData(residents);
    setTableData(tableData);
  }, [residents]);

  return (
    <PanelContainer title={t('csvdownload', 'CSVダウンロード') + ' - ' + 'LIFE'}>
      {tableData && tableData.length ? (
        <>
          <div css={TopContainer}>
            <div css={DateRangePicker}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={t('startDate', '開始日')}
                value={fetchTimestamp?.start}
                minDate={rangeMinDate}
                maxDate={fetchTimestamp?.end ?? undefined}
                onChange={handleChangeStartTime}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
              〜
              <DatePicker
                views={['year', 'month', 'day']}
                label={t('endDate', '終了日')}
                value={fetchTimestamp?.end}
                minDate={fetchTimestamp?.start ?? undefined}
                maxDate={rangeMaxDate}
                onChange={handleChangeEndTime}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
            </div>
            <div css={ButtonContainer}>
              <DownloadButton
                title={'入居者情報ダウンロード'}
                disable={onSend}
                onClick={handleCSVownload('resident')}
              />
              <DownloadButton
                title={'排泄支援情報ダウンロード'}
                disable={onSend}
                onClick={handleCSVownload('carePlanning')}
              />
            </div>
          </div>

          <ResidentsTable data={tableData} onChange={handleChangeCheckBox} />
        </>
      ) : (
        <>{t('thereAreNoResidents', '入居者がいません')}</>
      )}
    </PanelContainer>
  );
});
