export const LIFE_RESIDENT_CSV_COL_JA = [
  { label: '事業所番号', value: 'careFacilityId' },
  { label: 'サービス種類コード', value: 'serviceCode' },
  { label: '外部システム管理番号', value: 'externalSystemManagementNumber' },
  { label: '保険者番号', value: 'insurerNo' },
  { label: '被保険者番号', value: 'insuredNo' },
  { label: '利用者(姓)', value: 'lastName' },
  { label: '利用者(名)', value: 'firstName' },
  { label: '利用者(姓)カナ', value: 'lastNameKana' },
  { label: '利用者(名)カナ', value: 'firstNameKana' },
  { label: '性別', value: 'gender' },
  { label: '利用者生年月日', value: 'birthday' },
  { label: '認定日', value: 'certifiedDate' },
  { label: '利用者介護認定年月日(開始)', value: 'carePeriodStart' },
  { label: '利用者介護認定年月日(終了)', value: 'carePeriodEnd' },
  { label: '要介護度', value: 'careLevel' },
  { label: '障害高齢者の日常生活自立度', value: 'impairedElderlyIndependenceDegree' },
  { label: '認知症高齢者の日常生活自立度', value: 'dementiaElderlyIndependenceDegree' },
  { label: '利用開始日（入所日）', value: 'startDate' },
  { label: '利用終了日（退所日）', value: 'endDate' },
  { label: '死亡日', value: 'deathDate' },
  { label: '備考', value: 'remarks' },
  { label: 'バージョン番号', value: 'version' },
];

export const LIFE_RESIDENT_CSV_COL_EN = [
  { label: 'care_facility_id', value: 'careFacilityId' },
  { label: 'service_code', value: 'serviceCode' },
  { label: 'external_system_management_number', value: 'externalSystemManagementNumber' },
  { label: 'insurer_no', value: 'insurerNo' },
  { label: 'insured_no', value: 'insuredNo' },
  { label: 'last_name', value: 'lastName' },
  { label: 'first_name', value: 'firstName' },
  { label: 'last_name_kana', value: 'lastNameKana' },
  { label: 'first_name_kana', value: 'firstNameKana' },
  { label: 'gender', value: 'gender' },
  { label: 'birthday', value: 'birthday' },
  { label: 'certified_date', value: 'certifiedDate' },
  { label: 'care_period_start', value: 'carePeriodStart' },
  { label: 'care_period_end', value: 'carePeriodEnd' },
  { label: 'care_level', value: 'careLevel' },
  { label: 'impaired_elderly_independence_degree', value: 'impairedElderlyIndependenceDegree' },
  { label: 'dementia_elderly_independence_degree', value: 'dementiaElderlyIndependenceDegree' },
  { label: 'start_date', value: 'startDate' },
  { label: 'end_date', value: 'endDate' },
  { label: 'death_date', value: 'deathDate' },
  { label: 'remarks', value: 'remarks' },
  { label: 'version', value: 'version' },
];
