import React from 'react';
import { css } from '@emotion/react';
import { color } from '../styles';

interface Props {
  disabled?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

const ButtonBase = css`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 66px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.95;
  overflow: hidden;
  user-select: none;
  letter-spacing: 0.25em;
  text-decoration: none;
  outline: none;
`;

const OnEnableStyle = css`
  background: ${color.green};
  color: ${color.white};

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(circle, #fff 10%, transparent 10%) no-repeat 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    transition: 0s;
    opacity: 0.3;
  }
`;

const OnDisableStyle = css`
  background: ${color.input_gray};
  color: ${color.gray};
`;

export const BottomStickyButton = React.memo(({ disabled = false, children, onClick }: Props) => {
  // disabledの場合はonClickイベントを返さない
  const handleClick = () => {
    if (!disabled) onClick();
  };

  // disabledの状態によってCSSスタイルを変更する
  const ButtonStyle = React.useMemo(() => {
    return disabled ? OnDisableStyle : OnEnableStyle;
  }, [disabled]);

  return (
    <div css={[ButtonBase, ButtonStyle]} onClick={handleClick}>
      {children}
    </div>
  );
});
