import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../api';
import { selfDataSelector, selfDataSlice } from '../redux/config/selfData';

export const RedirectLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { selfUser } = useSelector(selfDataSelector);
  const [isUserLoaded, setIsUserLoaded] = React.useState(false);

  const setSelfData = React.useCallback(async () => {
    const selfUser = await api.get('/users/_self');
    dispatch(selfDataSlice.actions.setSelfUser(selfUser));
  }, []);

  React.useEffect(() => {
    setSelfData();
  }, []);

  React.useEffect(() => {
    if (selfUser.nursingHomeId !== 0) {
      setIsUserLoaded(true);
    }
  }, [selfUser]);

  return <>{isUserLoaded ? children : <>loading...</>}</>;
};
