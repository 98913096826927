import React from 'react';
import { useSelector } from 'react-redux';
import { selfDataSelector } from '../../../redux/config/selfData';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { Order } from '../../../types/api/order/getOrders';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  from?: string | null;
  to?: string | null;
  sort?: string;
  limit?: number;
  offset?: number | null;
}

export const useFetchOrders = (
  nursingHomeUnitId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  sort = 'desc',
  limit = 10000,
  offset = 0
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const [orders, setOrders] = React.useState<Order[]>();

  const fetchOrders = async (
    nursingHomeUnitId: number | null = null,
    from: string | null = null,
    to: string | null = null,
    sort = 'desc',
    limit = 10000,
    offset = 0
  ) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      from,
      to,
      sort,
      limit,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;
    if (!offset) delete params.offset;
    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    const result = await api.get('/nursing-homes/:nursingHomeId/orders', {
      params,
    });
    setOrders(result);
  };

  React.useEffect(() => {
    // 初回fetchはUnitIdの指定がない場合は、自身のUnitIdを優先して取得
    // それ以外はUnitIdなし（全ユニット取得）
    if (!selfUser.nursingHomeId) return;
    if (nursingHomeUnitId) fetchOrders(selfUser.nursingHomeUnitId, from, to, sort, limit, offset);
    else if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId)
      fetchOrders(selfUser.nursingHomeUnitId, from, to, sort, limit, offset);
    else fetchOrders(null, from, to, sort, limit, offset);
  }, [selfUser]);

  return { orders, fetchOrders };
};
