import { css } from '@emotion/react';
import React from 'react';
import { Cell, CellBase, HeaderCell, Row, Table, TextWrap } from '../../../../styles/table';
import { ITraversalOrder } from '../../../../types/api/traversal/order';
import { color } from '../../../../styles';
import { IGetMasterDiaperRes } from '../../../../types/api/master/getDiaper';
import { MenuItemProps } from '../../../../components/mui/select';
import { OrderableExpendableSelectorCell } from './orderableExpendableSelectorCell';
import { OrderAmountCell } from './orderAmountCell';

interface Props {
  data: ITraversalOrder[];
  orderableList: IGetMasterDiaperRes[] | undefined;
  onAmountChange: (id: number, value: number) => void;
  onDiaperNameChange: (id: number, value: MenuItemProps) => void;
  onDelete: (id: number) => void;
}

const CellHeight = css`
  height: 88px;
`;

const Separate = css`
  padding-left: 16px;
`;

const LinkText = css`
  display: flex;
  align-items: center;
  color: ${color.red};
  padding: 0 10px;
  height: 70%;

  &:hover {
    background-color: ${color.bg_gray};
  }
`;

// テーブルのセルの横幅を左から順に定義
const CellWidth = [{ flex: '3 1 380px' }, { flex: '0 0 180px' }, { flex: '0 0 90px' }];

export const ExpendableOrderChangeTable = ({
  data,
  orderableList,
  onAmountChange,
  onDiaperNameChange,
  onDelete,
}: Props) => {
  const [isError, setIsError] = React.useState<boolean>(false);
  const [heplerText, setHelperText] = React.useState<string>('');
  const tableHeader = ['名前', '発注数', '操作'];

  const handleClickDelete = React.useCallback(
    (id: number) => () => {
      onDelete(id);
    },
    [onDelete]
  );

  const handleChangeDiaperName = React.useCallback(
    (id: number) => (value: MenuItemProps) => {
      onDiaperNameChange(id, value);
    },
    [onDiaperNameChange]
  );

  const handleChangeAmount = React.useCallback(
    (id: number) => (value: number) => {
      setIsError(value <= 0 ? true : false);
      setHelperText(value <= 0 ? '発注数を0にするには 項目を削除してください' : '');
      onAmountChange(id, value);
    },
    [onAmountChange]
  );

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i], Separate]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={data.id}>
              <div css={[CellBase, Cell, CellWidth[0], TextWrap, CellHeight, Separate]}>
                {orderableList ? (
                  <OrderableExpendableSelectorCell
                    value={data}
                    orderableList={orderableList}
                    onChange={handleChangeDiaperName(i)}
                  />
                ) : (
                  data.diaperShorten
                )}
              </div>
              <div css={[CellBase, Cell, CellWidth[1], CellHeight, Separate]}>
                <OrderAmountCell value={data.amount} onChange={handleChangeAmount(i)} />
              </div>
              <div css={[CellBase, Cell, CellWidth[2], CellHeight, Separate]}>
                <div css={LinkText} onClick={handleClickDelete(i)}>
                  削除
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
