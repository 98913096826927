import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from '../../../../components/mui/dialog';
import { IGetResidentRes } from '../../../../types/api/resident/getResident';
import { BLADDER_THRESHOLD_PERCENTAGE, DEFAULT_RESIDENT_UPDATE_FORM_VALUE } from '../../../../constants';
import { Select } from '../../../../components/mui/select';
import { IUpdateResidentParams } from '../../../../types/api/resident/updateResident';
import { useTranslation } from 'react-i18next';

interface Props {
  resident: IGetResidentRes;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IUpdateResidentParams) => void;
}

export const ChangeLineModal = React.memo(({ resident, isOpen, onClose, onSubmit }: Props) => {
  const { handleSubmit, reset, control } = useForm({ defaultValues: DEFAULT_RESIDENT_UPDATE_FORM_VALUE });
  const { t } = useTranslation();

  React.useEffect(() => {
    reset(resident);
  }, []);

  return (
    <Dialog
      title={t('changeAlmostThreshold', 'そろそろライン変更')}
      type='submit'
      isOpen={isOpen}
      onClose={onClose}
      onOkClick={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name='bladderThresholdPercentage'
        render={({ field: { onChange, value } }) => (
          <Select listData={BLADDER_THRESHOLD_PERCENTAGE} value={value} onChange={(v) => onChange(v.key)} />
        )}
      />
    </Dialog>
  );
});
