import React from 'react';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import api from '../../../../../api';
import { color } from '../../../../../styles';
import { Select, MenuItemProps } from '../../../../../components/mui/select';
import { useSnackbar } from '../../../../../contexts/SnackbarContext';
import { ICreateLifeCarePlanningParams } from '../../../../../types/api/life/createDiaper';
import { IUpdateLifeCarePlanningsParams } from '../../../../../types/api/life/updateDiaper';
import { IGetLifeCarePlanningParams, IGetLifeCarePlanningRes } from '../../../../../types/api/life/getLifeCarePlanning';
import {
  LIFE_CARE_PLANNING_EXCRETION_STATUS,
  LIFE_CARE_PLANNING_JOB_CATEGORY,
  LIFE_CARE_PLANNING_URINATION,
  LIFE_CARE_PLANNING_USE_DIAPER,
} from '../../../../../constants';

interface Props {
  nursingHomeId: string;
  residentId: string;
}

interface FormState {
  [key: string]: {
    value: string;
    error: boolean;
    errorText: string;
  };
}

interface ValidationState {
  [key: string]: {
    required: boolean;
    maxLength: number | null;
  };
}

const StyledButton = css`
  margin: 16px 0 16px 32px;
  min-width: 80px;
`;

const Wrap = css`
  flex-wrap: wrap;
`;

const Wmax = css`
  width: 100%;
`;

const W200 = css`
  flex-shrink: 0;
  width: 200px;
  margin: 4px 0 12px 0;
`;

const W300 = css`
  flex-shrink: 0;
  width: 300px;
  margin: 4px 0 12px 0;
`;

const W400 = css`
  flex-shrink: 0;
  width: 400px;
  margin: 4px 0 12px 0;
`;

const AlignItems = css`
  display: flex;
  align-items: center;
`;

const PageWrapper = css`
  padding: 24px;
`;

const ContentWrapper = css`
  display: flex;
`;

const ContentLeft = css`
  width: 148px;
  padding: 16px 16px;
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
  color: ${color.white};
  background-color: ${color.green};
  border-bottom: 1px solid ${color.white};
`;

const ContentRight = css`
  width: 100%;
  padding: 24px 32px 16px 24px;
  background-color: ${color.light_green};
  border: 1px solid ${color.green};
  border-bottom: none;
  gap: 16px;
`;

// TODO: ContentRightを打ち消す冗長定義となっている
const Borderbottom = css`
  border-bottom: 1px solid ${color.green};
`;

const ButtonWrapper = css`
  display: flex;
  justify-content: center;
  padding: 24px 0;
`;

export const ResidentDetailLifeCarePlanSettings: React.FC<Props> = React.memo(({ nursingHomeId, residentId }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { lifeCarePlanningId } = useParams<{ lifeCarePlanningId: string }>();
  const { showSnackbar } = useSnackbar();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [isPassedValidation, setIsPassedValidation] = React.useState<boolean>(true);
  const [formData, setFormData] = React.useState<FormState>({
    evaluateDate: { value: dayjs().format('YYYY-MM-DD'), error: false, errorText: '' },
    planCreateDate: { value: dayjs().format('YYYY-MM-DD'), error: false, errorText: '' },
    recordStaffJobCategory: { value: '', error: false, errorText: '' },
    filingStaff: { value: '', error: false, errorText: '' },
    doctorName: { value: '', error: false, errorText: '' },
    nurseName: { value: '', error: false, errorText: '' },
    urinationAdmissionStatus: { value: '', error: false, errorText: '' },
    urinationEvaluationStatus: { value: '', error: false, errorText: '' },
    urinationThreeSupportStatus: { value: '', error: false, errorText: '' },
    urinationThreeNoSupportStatus: { value: '', error: false, errorText: '' },
    defecationAdmissionStatus: { value: '', error: false, errorText: '' },
    defecationEvaluationStatus: { value: '', error: false, errorText: '' },
    defecationThreeSupportStatus: { value: '', error: false, errorText: '' },
    defecationThreeNoSupportStatus: { value: '', error: false, errorText: '' },
    diaperUserAdmissionStatus: { value: '', error: false, errorText: '' },
    diaperUserEvaluationStatus: { value: '', error: false, errorText: '' },
    diaperUserThreeSupportStatus: { value: '', error: false, errorText: '' },
    diaperUserThreeNoSupportStatus: { value: '', error: false, errorText: '' },
    portableToiletAdmissionStatus: { value: '', error: false, errorText: '' },
    portableToiletEvaluationStatus: { value: '', error: false, errorText: '' },
    portableToiletThreeSupportStatus: { value: '', error: false, errorText: '' },
    portableToiletThreeNoSupportStatus: { value: '', error: false, errorText: '' },
    excretionStatusNecessity: { value: '', error: false, errorText: '' },
    excretionCauseSupport: { value: '', error: false, errorText: '' },
    supportProgram: { value: '', error: false, errorText: '' },
  });

  const formValidationData: ValidationState = {
    evaluateDate: { required: true, maxLength: null },
    planCreateDate: { required: true, maxLength: null },
    recordStaffJobCategory: { required: true, maxLength: null },
    filingStaff: { required: false, maxLength: 18 },
    doctorName: { required: false, maxLength: 18 },
    nurseName: { required: false, maxLength: 18 },
    urinationAdmissionStatus: { required: true, maxLength: null },
    urinationEvaluationStatus: { required: true, maxLength: null },
    urinationThreeSupportStatus: { required: true, maxLength: null },
    urinationThreeNoSupportStatus: { required: true, maxLength: null },
    defecationAdmissionStatus: { required: true, maxLength: null },
    defecationEvaluationStatus: { required: true, maxLength: null },
    defecationThreeSupportStatus: { required: true, maxLength: null },
    defecationThreeNoSupportStatus: { required: true, maxLength: null },
    diaperUserAdmissionStatus: { required: true, maxLength: null },
    diaperUserEvaluationStatus: { required: true, maxLength: null },
    diaperUserThreeSupportStatus: { required: true, maxLength: null },
    diaperUserThreeNoSupportStatus: { required: true, maxLength: null },
    portableToiletAdmissionStatus: { required: true, maxLength: null },
    portableToiletEvaluationStatus: { required: true, maxLength: null },
    portableToiletThreeSupportStatus: { required: true, maxLength: null },
    portableToiletThreeNoSupportStatus: { required: true, maxLength: null },
    excretionStatusNecessity: { required: true, maxLength: null },
    excretionCauseSupport: { required: false, maxLength: 200 },
    supportProgram: { required: false, maxLength: 200 },
  };

  const lifecarePlanningJobCategoryKeys = ['recordStaffJobCategory'];

  const lifecarePlanningUrinationKeys = [
    'urinationAdmissionStatus',
    'urinationEvaluationStatus',
    'urinationThreeSupportStatus',
    'urinationThreeNoSupportStatus',
    'defecationAdmissionStatus',
    'defecationEvaluationStatus',
    'defecationThreeSupportStatus',
    'defecationThreeNoSupportStatus',
  ];

  const lifecarePlanningUseDiaperKeys = [
    'diaperUserAdmissionStatus',
    'diaperUserEvaluationStatus',
    'diaperUserThreeSupportStatus',
    'diaperUserThreeNoSupportStatus',
    'portableToiletAdmissionStatus',
    'portableToiletEvaluationStatus',
    'portableToiletThreeSupportStatus',
    'portableToiletThreeNoSupportStatus',
  ];

  const lifecarePlanningExcretionStatusKeys = ['excretionStatusNecessity'];

  const lifecarePlanningJobCategoryList: Record<string, MenuItemProps[]> = lifecarePlanningJobCategoryKeys.reduce(
    (acc: Record<string, MenuItemProps[]>, key) => {
      acc[key] = LIFE_CARE_PLANNING_JOB_CATEGORY.map((v) => ({ ...v, option: key }));
      return acc;
    },
    {}
  );

  const lifecarePlanningUrinationList: Record<string, MenuItemProps[]> = lifecarePlanningUrinationKeys.reduce(
    (acc: Record<string, MenuItemProps[]>, key) => {
      acc[key] = LIFE_CARE_PLANNING_URINATION.map((v) => ({ ...v, option: key }));
      return acc;
    },
    {}
  );

  const lifecarePlanningUseDiaperList: Record<string, MenuItemProps[]> = lifecarePlanningUseDiaperKeys.reduce(
    (acc: Record<string, MenuItemProps[]>, key) => {
      acc[key] = LIFE_CARE_PLANNING_USE_DIAPER.map((v) => ({ ...v, option: key }));
      return acc;
    },
    {}
  );

  const lifecarePlanningExcretionStatusList: Record<string, MenuItemProps[]> =
    lifecarePlanningExcretionStatusKeys.reduce((acc: Record<string, MenuItemProps[]>, key) => {
      acc[key] = LIFE_CARE_PLANNING_EXCRETION_STATUS.map((v) => ({ ...v, option: key }));
      return acc;
    }, {});

  const handleDatePickerData = (key: string) => (date: Dayjs | null) => {
    const dateString = date?.format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD');
    if (date) {
      const { error, errorText } = checkValidations(key, dateString);
      setFormData({
        ...formData,
        [key]: { value: dateString, error, errorText },
      });
    }
  };

  const handleSelectFormData = (data: MenuItemProps) => {
    const key = data.option;
    const value = data.key?.toString() ?? '';
    const { error, errorText } = checkValidations(key, value);
    setFormData({
      ...formData,
      [key]: { value, error, errorText },
    });
  };

  const handleTextFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const value = e.target.value;
    const { error, errorText } = checkValidations(key, value);
    setFormData({
      ...formData,
      [key]: { value, error, errorText },
    });
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`../../life-care-plannings`);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setOnSend(true);
    const { updatedFormData, passedValidation } = validateAndUpdateFormData();

    if (!passedValidation) {
      setIsPassedValidation(false);
      setFormData(updatedFormData);
      setOnSend(false);
      return;
    }

    // formDataをkey: valueの形に変形する。この時、値が空文字の場合はparamsに含めない
    const formattedData = Object.entries(formData).reduce((acc: { [key: string]: string }, [key, { value }]) => {
      if (value !== '') acc[key] = value;
      return acc;
    }, {});

    const params: any = {
      ...formattedData,
      ...(lifeCarePlanningId ? { lifeCarePlanningId: +lifeCarePlanningId } : {}),
      nursingHomeId: +nursingHomeId,
      residentId: +residentId,
    };

    try {
      // paramsにidがある場合はput、そうでない場合はpostとして処理
      if (lifeCarePlanningId) {
        await updateLifeCarePlan(params);
        showSnackbar('データを更新しました', 'success');
      } else {
        await createLifeCarePlan(params);
        showSnackbar('データを作成しました', 'success');
      }
      setOnSend(false);
      history.push(`../../life-care-plannings`);
    } catch (error) {
      showSnackbar('データ送信に失敗しました。しばらくしてもう一度お試しください', 'error');
      setOnSend(false);
    }
  };

  // TODO: hookにする
  const getLifeCarePlan = async (params: IGetLifeCarePlanningParams) => {
    return await api.get(
      '/external/life/nursing-homes/:nursingHomeId/residents/:residentId/care-plannings/:lifeCarePlanningId',
      { params }
    );
  };

  // TODO: hookにする
  const createLifeCarePlan = async (params: ICreateLifeCarePlanningParams) => {
    return await api.post('/external/life/nursing-homes/:nursingHomeId/residents/:residentId/care-plannings', params);
  };

  // TODO: hookにする
  const updateLifeCarePlan = async (params: IUpdateLifeCarePlanningsParams) => {
    return await api.put(
      '/external/life/nursing-homes/:nursingHomeId/residents/:residentId/care-plannings/:lifeCarePlanningId',
      params
    );
  };

  const checkValidations = React.useCallback(
    (key: string, value: string) => {
      const validations = formValidationData[key];
      let errorState = { error: false, errorText: '' };
      if (validations.maxLength && value.length > validations.maxLength) {
        errorState = { error: true, errorText: `${validations.maxLength}文字以内で入力してください` };
      } else if (validations.required && !value.length) {
        errorState = { error: true, errorText: `必須事項です` };
      } else {
        errorState = { error: false, errorText: '' };
      }
      return errorState;
    },
    [formValidationData, formData]
  );

  const validateAndUpdateFormData = () => {
    const updatedFormData = { ...formData };
    let passedValidation = true;

    Object.entries(formData).forEach(([key, { value }]) => {
      const { error, errorText } = checkValidations(key, value);
      if (error) passedValidation = false;
      updatedFormData[key] = {
        ...updatedFormData[key],
        error,
        errorText,
      };
    });

    return { updatedFormData, passedValidation };
  };

  React.useEffect(() => {
    // lifeCarePlanningIdがある場合には初期値をとってくる
    if (nursingHomeId && residentId && lifeCarePlanningId) {
      const setInitFormData = async () => {
        const params = {
          nursingHomeId: +nursingHomeId,
          residentId: +residentId,
          lifeCarePlanningId: +lifeCarePlanningId,
        };
        const initData = await getLifeCarePlan(params);
        const newFormData = { ...formData };

        Object.keys(initData).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(newFormData, key)) {
            const value = initData[key as keyof IGetLifeCarePlanningRes];
            newFormData[key] = { ...newFormData[key], value: value ? value.toString() : '' };
          }
        });
        setFormData(newFormData);
      };

      setInitFormData();
    }
  }, [nursingHomeId, residentId, lifeCarePlanningId]);

  React.useEffect(() => {
    const { updatedFormData, passedValidation } = validateAndUpdateFormData();
    if (!isPassedValidation) setIsPassedValidation(passedValidation);
  }, [formData]);

  return (
    <div css={PageWrapper}>
      <div css={ContentWrapper}>
        <div css={ContentLeft}>基本情報</div>
        <div css={Wmax}>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'評価日'}
                value={dayjs(formData.evaluateDate.value)}
                onChange={handleDatePickerData('evaluateDate')}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
            </div>
            <div css={W300}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={'計画作成日'}
                value={dayjs(formData.planCreateDate.value)}
                onChange={handleDatePickerData('planCreateDate')}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
            </div>
          </div>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <Select
                id='job-category-selector'
                listData={lifecarePlanningJobCategoryList['recordStaffJobCategory']}
                value={formData.recordStaffJobCategory.value}
                label={'*記入者職種'}
                fullWidth={true}
                isError={formData.recordStaffJobCategory.error}
                errorText={formData.recordStaffJobCategory.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <TextField
                id={'filing-staff-field'}
                name={'filingStaff'}
                label={'記入者名: 18文字以内'}
                type={'text'}
                value={formData.filingStaff.value}
                error={formData.filingStaff.error}
                onChange={handleTextFormData}
                helperText={formData.filingStaff.errorText}
                fullWidth={true}
              />
            </div>
          </div>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <TextField
                id={'doctor-name-field'}
                name={'doctorName'}
                label={'医師名: 18文字以内'}
                type={'text'}
                value={formData.doctorName.value}
                error={formData.doctorName.error}
                onChange={handleTextFormData}
                helperText={formData.doctorName.errorText}
                fullWidth={true}
              />
            </div>
            <div css={W300}>
              <TextField
                id={'nurse-name-field'}
                name={'nurseName'}
                label={'看護師名: 18文字以内'}
                type={'text'}
                value={formData.nurseName.value}
                error={formData.nurseName.error}
                onChange={handleTextFormData}
                helperText={formData.nurseName.errorText}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={ContentWrapper}>
        <div css={ContentLeft}>排尿の状態</div>
        <div css={Wmax}>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <Select
                id='urination-admissionstatus-selector'
                listData={lifecarePlanningUrinationList['urinationAdmissionStatus']}
                value={formData.urinationAdmissionStatus.value}
                label={'*施設入所時'}
                fullWidth={true}
                isError={formData.urinationAdmissionStatus.error}
                errorText={formData.urinationAdmissionStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='urination-evaluationStatus-selector'
                listData={lifecarePlanningUrinationList['urinationEvaluationStatus']}
                value={formData.urinationEvaluationStatus.value}
                label={'*評価時'}
                fullWidth={true}
                isError={formData.urinationEvaluationStatus.error}
                errorText={formData.urinationEvaluationStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='urination-three-support-status-selector'
                listData={lifecarePlanningUrinationList['urinationThreeSupportStatus']}
                value={formData.urinationThreeSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行った場合）'}
                fullWidth={true}
                isError={formData.urinationThreeSupportStatus.error}
                errorText={formData.urinationThreeSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='urination-three-nosupport-status-selector'
                listData={lifecarePlanningUrinationList['urinationThreeNoSupportStatus']}
                value={formData.urinationThreeNoSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行わない場合）'}
                fullWidth={true}
                isError={formData.urinationThreeNoSupportStatus.error}
                errorText={formData.urinationThreeNoSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={ContentWrapper}>
        <div css={ContentLeft}>排便の状態</div>
        <div css={Wmax}>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <Select
                id='defecation-admissionstatus-selector'
                listData={lifecarePlanningUrinationList['defecationAdmissionStatus']}
                value={formData.defecationAdmissionStatus.value}
                label={'*施設入所時'}
                fullWidth={true}
                isError={formData.defecationAdmissionStatus.error}
                errorText={formData.defecationAdmissionStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='defecation-evaluationStatus-selector'
                listData={lifecarePlanningUrinationList['defecationEvaluationStatus']}
                value={formData.defecationEvaluationStatus.value}
                label={'*評価時'}
                fullWidth={true}
                isError={formData.defecationEvaluationStatus.error}
                errorText={formData.defecationEvaluationStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='defecation-three-support-status-selector'
                listData={lifecarePlanningUrinationList['defecationThreeSupportStatus']}
                value={formData.defecationThreeSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行った場合）'}
                fullWidth={true}
                isError={formData.defecationThreeSupportStatus.error}
                errorText={formData.defecationThreeSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='defecation-three-nosupport-status-selector'
                listData={lifecarePlanningUrinationList['defecationThreeNoSupportStatus']}
                value={formData.defecationThreeNoSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行わない場合）'}
                fullWidth={true}
                isError={formData.defecationThreeNoSupportStatus.error}
                errorText={formData.defecationThreeNoSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={ContentWrapper}>
        <div css={ContentLeft}>おむつ使用の有無</div>
        <div css={Wmax}>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <Select
                id='diaper-user-admission-status-selector'
                listData={lifecarePlanningUseDiaperList['diaperUserAdmissionStatus']}
                value={formData.diaperUserAdmissionStatus.value}
                label={'*施設入所時'}
                fullWidth={true}
                isError={formData.diaperUserAdmissionStatus.error}
                errorText={formData.diaperUserAdmissionStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='diaper-user-evaluation-status-selector'
                listData={lifecarePlanningUseDiaperList['diaperUserEvaluationStatus']}
                value={formData.diaperUserEvaluationStatus.value}
                label={'*評価時'}
                fullWidth={true}
                isError={formData.diaperUserEvaluationStatus.error}
                errorText={formData.diaperUserEvaluationStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='diaper-user-three-support-status-selector'
                listData={lifecarePlanningUseDiaperList['diaperUserThreeSupportStatus']}
                value={formData.diaperUserThreeSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行った場合）'}
                fullWidth={true}
                isError={formData.diaperUserThreeSupportStatus.error}
                errorText={formData.diaperUserThreeSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='diaper-user-three-nosupport-status-selector'
                listData={lifecarePlanningUseDiaperList['diaperUserThreeNoSupportStatus']}
                value={formData.diaperUserThreeNoSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行わない場合）'}
                fullWidth={true}
                isError={formData.diaperUserThreeNoSupportStatus.error}
                errorText={formData.diaperUserThreeNoSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={ContentWrapper}>
        <div css={ContentLeft}>ポータブルトイレ使用の有無</div>
        <div css={Wmax}>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W300}>
              <Select
                id='portable-toilet-admission-status-selector'
                listData={lifecarePlanningUseDiaperList['portableToiletAdmissionStatus']}
                value={formData.portableToiletAdmissionStatus.value}
                label={'*施設入所時'}
                fullWidth={true}
                isError={formData.portableToiletAdmissionStatus.error}
                errorText={formData.portableToiletAdmissionStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='portable-toilet-evaluation-status-selector'
                listData={lifecarePlanningUseDiaperList['portableToiletEvaluationStatus']}
                value={formData.portableToiletEvaluationStatus.value}
                label={'*評価時'}
                fullWidth={true}
                isError={formData.portableToiletEvaluationStatus.error}
                errorText={formData.portableToiletEvaluationStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='portable-toilet-three-support-status-selector'
                listData={lifecarePlanningUseDiaperList['portableToiletThreeSupportStatus']}
                value={formData.portableToiletThreeSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行った場合）'}
                fullWidth={true}
                isError={formData.portableToiletThreeSupportStatus.error}
                errorText={formData.portableToiletThreeSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={W300}>
              <Select
                id='portable-toilet-three-nosupport-status-selector'
                listData={lifecarePlanningUseDiaperList['portableToiletThreeNoSupportStatus']}
                value={formData.portableToiletThreeNoSupportStatus.value}
                label={'*3ヶ月後の見込み（支援を行わない場合）'}
                fullWidth={true}
                isError={formData.portableToiletThreeNoSupportStatus.error}
                errorText={formData.portableToiletThreeNoSupportStatus.errorText}
                onChange={handleSelectFormData}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={ContentWrapper}>
        <div css={ContentLeft}>排泄支援</div>
        <div css={Wmax}>
          <div css={[ContentRight, AlignItems, Wrap, Borderbottom]}>
            <div css={W300}>
              <Select
                id='excretion-status-necessity-selector'
                listData={lifecarePlanningExcretionStatusList['excretionStatusNecessity']}
                value={formData.excretionStatusNecessity.value}
                label={'*排泄の状態に関する支援の必要性'}
                fullWidth={true}
                isError={formData.excretionStatusNecessity.error}
                errorText={formData.excretionStatusNecessity.errorText}
                onChange={handleSelectFormData}
              />
            </div>
            <div css={Wmax}>
              <TextField
                id={'excretion-cause-support-field'}
                name={'excretionCauseSupport'}
                label={'排せつに介護を要する原因: 200文字以内'}
                type={'text'}
                value={formData.excretionCauseSupport.value}
                error={formData.excretionCauseSupport.error}
                onChange={handleTextFormData}
                helperText={formData.excretionCauseSupport.errorText}
                rows={4}
                multiline={true}
                fullWidth={true}
              />
            </div>
            <div css={Wmax}>
              <TextField
                id={'support-program-field'}
                name={'supportProgram'}
                label={'支援計画: 200文字以内'}
                type={'text'}
                value={formData.supportProgram.value}
                error={formData.supportProgram.error}
                onChange={handleTextFormData}
                helperText={formData.supportProgram.errorText}
                rows={4}
                multiline={true}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={ButtonWrapper}>
        <Button css={StyledButton} color={'secondary'} onClick={handleCancel} variant='contained'>
          キャンセル
        </Button>
        <Button css={StyledButton} onClick={handleSubmit} variant='contained' disabled={!isPassedValidation || onSend}>
          OK
        </Button>
      </div>
    </div>
  );
});
