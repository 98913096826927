import {
  SIZE,
  NOTIFICATION_TYPE,
  ASSISTANT_TYPE,
  DEFECATION_TYPE_JA,
  DEFECATION_TYPE_EN,
  DRINKING_TYPE,
  HARDNESS_TYPE_JA,
  HARDNESS_TYPE_EN,
  INCONTINENCE_TYPE_JA,
  INCONTINENCE_TYPE_EN,
  PAD_TYPE_JA,
  PAD_TYPE_EN,
  TOILET_TYPE_JA,
  TOILET_TYPE_EN,
  URINE_TYPE_JA,
  URINE_TYPE_EN,
} from '../constants';

export const formatSize = (data: string) => {
  return SIZE[data] ? SIZE[data] : data;
};

export const formatNotification = (data: string) => {
  return NOTIFICATION_TYPE[data] ? NOTIFICATION_TYPE[data] : data;
};

export const formatToiletJa = (data: string) => {
  return TOILET_TYPE_JA[data] ? TOILET_TYPE_JA[data] : data;
};

export const formatToiletEn = (data: string) => {
  return TOILET_TYPE_EN[data] ? TOILET_TYPE_EN[data] : data;
};

export const formatPadJa = (data: string) => {
  return PAD_TYPE_JA[data] ? PAD_TYPE_JA[data] : data;
};

export const formatPadEn = (data: string) => {
  return PAD_TYPE_EN[data] ? PAD_TYPE_EN[data] : data;
};

export const formatUrineJa = (data: string) => {
  return URINE_TYPE_JA[data] ? URINE_TYPE_EN[data] : data;
};

export const formatUrineEn = (data: string) => {
  return URINE_TYPE_EN[data] ? URINE_TYPE_EN[data] : data;
};

export const formatDefecationJa = (data: string) => {
  return DEFECATION_TYPE_JA[data] ? DEFECATION_TYPE_JA[data] : data;
};
export const formatDefecationEn = (data: string) => {
  return DEFECATION_TYPE_EN[data] ? DEFECATION_TYPE_EN[data] : data;
};

export const formatHardnessJa = (data: string) => {
  return HARDNESS_TYPE_JA[data] ? HARDNESS_TYPE_JA[data] : data;
};
export const formatHardnessEn = (data: string) => {
  return HARDNESS_TYPE_EN[data] ? HARDNESS_TYPE_EN[data] : data;
};

export const formatIncontinenceJa = (data: string) => {
  return INCONTINENCE_TYPE_JA[data] ? INCONTINENCE_TYPE_JA[data] : data;
};

export const formatIncontinenceEn = (data: string) => {
  return INCONTINENCE_TYPE_EN[data] ? INCONTINENCE_TYPE_EN[data] : data;
};

export const formatAssistant = (data: string) => {
  return ASSISTANT_TYPE[data] ? ASSISTANT_TYPE[data] : data;
};

export const formatDrinkingType = (data: string) => {
  return DRINKING_TYPE[data] ? DRINKING_TYPE[data] : data;
};
