import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../core/store';
import { IGetNursingHomeRes } from '../../types/api/nursingHome/getNursingHome';

// initialize
const initialState: IGetNursingHomeRes = {
  id: 0,
  nursingHomeGroupId: 0,
  name: '',
  address: '',
  email: '',
  phoneNumber: '',
  type: 0,
  capacity: 0,
  plan: 0,
  planStatus: '',
  planDate: '',
  orderLimitDayOfWeek: '',
  orderLimitHour: 0,
  orderShippingDay: 0,
  orderShippingDayOfWeek: '',
  orderMemo: '',
  clientCode: '',
  goodTree: false,
  membershipStatus: '',
  honobonoNumber: '',
  inUseLife: false,
  lifeCareFacilityId: '',
};

// slice
export const currentNursingHomeSlice = createSlice({
  name: 'currentNursingHome',
  initialState,
  reducers: {
    setCurrentNursingHome: (state, action: PayloadAction<IGetNursingHomeRes>) => {
      Object.assign(state, action.payload);
    },
  },
});

// action
export const { setCurrentNursingHome } = currentNursingHomeSlice.actions;

// selector
export const currentNursingHomeSelector = (state: RootState) => state.currentNursingHome;
export const showLifeContentSelector = (state: RootState): boolean => {
  const { inUseLife, lifeCareFacilityId } = state.currentNursingHome;
  return inUseLife && !!lifeCareFacilityId;
}