export const CARE_HISTORY_CSV_COL_JA = [
  {
    label: '入居者ID',
    value: 'residentId',
  },
  {
    label: '氏名',
    value: 'name',
  },
  {
    label: '施設名',
    value: 'facilityName',
  },
  {
    label: 'ユニット名',
    value: 'unitName',
  },
  {
    label: '部屋名',
    value: 'roomName',
  },
  {
    label: '日時',
    value: 'timestamp',
  },
  {
    label: '場所',
    value: 'place',
  },
  {
    label: '排尿量',
    value: 'urine',
  },
  {
    label: '失禁・空振り',
    value: 'incontinence',
  },
  {
    label: '排便量',
    value: 'defecation',
  },
  {
    label: '排便形態',
    value: 'hardnessType',
  },
  {
    label: '飲水名',
    value: 'drinkName',
  },
  {
    label: '飲水量',
    value: 'drinkingVolume',
  },
  {
    label: '下剤名',
    value: 'laxative',
  },
  {
    label: '下剤量',
    value: 'laxativeAmount',
  },
  {
    label: 'メモ',
    value: 'memo',
  },
  {
    label: '担当職員',
    value: 'staffName',
  },
  {
    label: 'ケア時間（分）',
    value: 'workingTime',
  },
];

export const CARE_HISTORY_CSV_COL_EN = [
  {
    label: 'Resident ID',
    value: 'residentId',
  },
  {
    label: 'Full name',
    value: 'name',
  },
  {
    label: 'Facility Name',
    value: 'facilityName',
  },
  {
    label: 'Unit name',
    value: 'unitName',
  },
  {
    label: 'Room name',
    value: 'roomName',
  },
  {
    label: 'Date time',
    value: 'timestamp',
  },
  {
    label: 'Place',
    value: 'place',
  },
  {
    label: 'Urine Amount',
    value: 'urine',
  },
  {
    label: 'Incontinence',
    value: 'incontinence',
  },
  {
    label: 'Defecation',
    value: 'defecation',
  },
  {
    label: 'Defecation Hardness Type',
    value: 'hardnessType',
  },
  {
    label: 'Drink name',
    value: 'drinkName',
  },
  {
    label: 'Drinking volume',
    value: 'drinkingVolume',
  },
  {
    label: 'Laxative Name',
    value: 'laxative',
  },
  {
    label: 'Laxative Amount',
    value: 'laxativeAmount',
  },
  {
    label: 'Memo',
    value: 'memo',
  },
  {
    label: 'Personnel in charge',
    value: 'staffName',
  },
  {
    label: 'Care time',
    value: 'workingTime',
  },
];
