import React from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import { color } from '../../styles';
import { useAuth } from '../../contexts/AuthContext';
import { SUPPORT_URL } from '../../constants';
import { LeftNavListItem } from './leftNavListItem';
import { navSelector } from '../../redux/config/leftnav';
import { residentListSettingsSlice } from '../../redux/config/residentListSettings';
import { selfDataSlice } from '../../redux/config/selfData';
import { showLifeContentSelector } from '../../redux/config/currentNursingHome';

interface Props {
  isOpenLeftnav: boolean;
  onClose: () => void;
}

const DrawerContainer = css`
  z-index: 1;
  position: sticky;
  overflow-y: auto;
  top: 0;

  &&.MuiDrawer-root {
    height: 100%;
    width: 240px;
    z-index: 0;
  }

  .MuiPaper-root {
    position: relative;
  }
`;

const DrawerHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 16px;
`;

const SubHeader = css`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px;
  color: ${color.gray};
  span {
    font-weight: bold;
  }
  div {
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: auto;
    color: ${color.dark_gray};
    font-size: 14px;
    &:hover {
      background-color: ${color.bg_gray};
      color: ${color.green};
    }
  }
`;

const SmallHeader = css`
  display: flex;
  align-items: center;
  height: 32px;
  margin-left: 24px;
  color: ${color.dark_gray};
`;

const Logo = css`
  width: 80px;
  padding: 16px 0;
`;

const OpenIcon = css`
  padding: 8px 0 8px 8px;
`;

const ListIcon = css`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const LinkButton = styled(NavLink)`
  text-decoration: none;
  color: ${color.gray};
  &:hover {
    color: ${color.green};
  }
`;

export const LeftNav = React.memo(({ isOpenLeftnav, onClose }: Props) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { signOut } = useAuth();
  const history = useHistory();
  const { badge } = useSelector(navSelector);
  const showLifeContent = useSelector(showLifeContentSelector);
  const { t, i18n } = useTranslation();

  const openExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleLogout = React.useCallback(async () => {
    dispatch(residentListSettingsSlice.actions.resetFilterCondition());
    dispatch(selfDataSlice.actions.reset());
    localStorage.setItem('unit', 'null');
    localStorage.setItem('condition', 'all');
    await signOut().then(() => {
      history.push('/login');
    });
  }, []);

  return (
    <Drawer
      css={DrawerContainer}
      anchor={'left'}
      variant='persistent'
      open={isOpenLeftnav}
      sx={{ position: 'relative' }}
    >
      <div css={DrawerHeader}>
        <LinkButton to={`${url}/residents`}>
          <img css={Logo} src='/images/login/logo.svg' alt='DFreeロゴ' />
        </LinkButton>
        <IconButton css={OpenIcon} onClick={onClose}>
          <MenuOpenIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <div css={SubHeader}>
          <img css={ListIcon} src='/images/leftnav/icon_user.svg' alt='入居者' />
          <span>{t('resident', '入居者')}</span>
          <div>
            <LinkButton to={`${url}/residents/new`}>{t('add', '追加')}</LinkButton>
          </div>
        </div>
        <LeftNavListItem
          path={`${url}/residents`}
          listItemName={t('dfreeGraph', 'DFreeグラフ')}
          badgeCount={badge.resident}
        />
        <LeftNavListItem
          path={`${url}/records`}
          listItemName={t('excretionTable', '排泄表')}
          badgeCount={badge.careSchedule}
        />
        <LeftNavListItem path={`${url}/bulk-record`} listItemName={t('recordAll', '一括記録')} />
        <LeftNavListItem path={`${url}/notifications`} listItemName={t('notification', '通知')} />
        {i18n.language === 'ja' && (
          <>
            <div css={SubHeader}>
              <img css={ListIcon} src='/images/leftnav/icon_diaper.svg' alt='消耗品' />
              <span>消耗品</span>
              <div>
                <LinkButton to={`${url}/expendable`}>一覧・登録</LinkButton>
              </div>
            </div>
            <LeftNavListItem path={`${url}/expendable/order`} listItemName='発注' />
            <LeftNavListItem
              path={`${url}/expendable/saved-order`}
              listItemName='一時保存'
              badgeCount={badge.temporarilySaved}
            />
            <LeftNavListItem path={`${url}/expendable/stocks`} listItemName='在庫管理' />
            <LeftNavListItem path={`${url}/expendable/order-history`} listItemName='発注履歴' />
          </>
        )}

        <div css={SubHeader}>
          <img css={ListIcon} src='/images/leftnav/icon_table.svg' alt='分析' />
          <span>{t('facilitiesUnitAnalysis', '施設・ユニット 全体分析')}</span>
        </div>
        <div css={SmallHeader}>
          <span>{t('csvdownload', 'CSVダウンロード')}</span>
        </div>
        <LeftNavListItem path={`${url}/analysis/ultrasonic-data`} listItemName={t('measurementData', '測定データ')} />
        <LeftNavListItem path={`${url}/analysis/care-records`} listItemName={t('careHistory', 'ケア履歴')} />
        {showLifeContent && <LeftNavListItem path={`${url}/analysis/life`} listItemName={'LIFE'} />}
        {i18n.language === 'ja' && (
          <>
            <div css={SmallHeader}>
              <span>消耗品利用状況</span>
            </div>
            <LeftNavListItem path={`${url}/analysis/equipments`} listItemName='製品種類・サイズ別' />
            <div css={SmallHeader}>
              <span>発注分析</span>
            </div>
            <LeftNavListItem path={`${url}/analysis/order`} listItemName='発注' />
          </>
        )}
        <div css={SubHeader}>
          <img css={ListIcon} src='/images/leftnav/icon_cog.svg' alt='設定' />
          <span>{t('setting', '設定')}</span>
        </div>
        <LeftNavListItem path={`${url}/settings/unit`} listItemName={t('unitListRegister', 'ユニット一覧・登録')} />
        <div css={SubHeader}>
          <img css={ListIcon} src='/images/leftnav/icon_help.svg' alt='サポート' />
          <span>{t('support', 'サポート')}</span>
        </div>
        {i18n.language === 'ja' && (
          <ListItem
            button
            key={'操作手順書'}
            sx={{ padding: '8px 16px 8px 40px' }}
            onClick={() => openExternalLink(SUPPORT_URL.manual.ja)}
          >
            <span>操作手順書</span>
          </ListItem>
        )}
        <ListItem
          button
          key={'装着方法'}
          sx={{ padding: '8px 16px 8px 40px' }}
          onClick={() => openExternalLink(i18n.language === 'ja' ? SUPPORT_URL.howToWear.ja : SUPPORT_URL.howToWear.en)}
        >
          <span>{t('howToAttachSensor', '装着方法')}</span>
        </ListItem>
        {i18n.language === 'ja' && (
          <ListItem
            button
            key={'困ったときの対処法'}
            sx={{ padding: '8px 16px 8px 40px' }}
            onClick={() => openExternalLink(SUPPORT_URL.troubleshooting.ja)}
          >
            <span>困ったときの対処法</span>
          </ListItem>
        )}
        <ListItem
          button
          key={'お問い合わせ'}
          sx={{ padding: '8px 16px 8px 40px' }}
          onClick={() => openExternalLink(i18n.language === 'ja' ? SUPPORT_URL.contact.ja : SUPPORT_URL.contact.en)}
        >
          <span>{t('contactUs', 'お問い合わせ')}</span>
        </ListItem>
        <Divider />
        <div css={SubHeader} onClick={handleLogout}>
          <img css={ListIcon} src='/images/icons/logout.svg' alt='ログアウト' />
          <span>{t('logout', 'ログアウト')}</span>
        </div>
      </List>
    </Drawer>
  );
});
