import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { OrderHistory } from '../pages/expendables/order-history';
import { SavedOrder } from '../pages/expendables/saved-order';
import { Stocks } from '../pages/expendables/stocks';
import { Order } from '../pages/expendables/order';
import { DiaperSetting } from '../pages/expendables/diaper';
import { UpdateDrink } from '../pages/expendables/drink';
import { ExpendableList } from '../pages/expendables/expendablesList';
import { UpdateLaxative } from '../pages/expendables/laxative';

export const ExpendableRouter: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ExpendableList />
      </Route>
      <Route path={`${path}/order`}>
        <Order url={url} />
      </Route>
      <Route path={`${path}/saved-order`}>
        <SavedOrder url={url} />
      </Route>
      <Route path={`${path}/stocks`}>
        <Stocks />
      </Route>
      <Route path={`${path}/order-history`}>
        <OrderHistory />
      </Route>
      <Route path={`${path}/diaper-outer/new`}>
        <DiaperSetting url={url} type='outer' />
      </Route>
      <Route path={`${path}/diaper-inner/new`}>
        <DiaperSetting url={url} type='inner' />
      </Route>
      <Route path={`${path}/diaper-outer/:diaperId/edit`}>
        <DiaperSetting url={url} isEdit type='outer' />
      </Route>
      <Route path={`${path}/diaper-inner/:diaperId/edit`}>
        <DiaperSetting url={url} isEdit type='inner' />
      </Route>
      <Route path={`${path}/laxative/new`}>
        <UpdateLaxative url={url} />
      </Route>
      <Route path={`${path}/laxative/:laxativeId/edit`}>
        <UpdateLaxative url={url} />
      </Route>
      <Route path={`${path}/drink/new`}>
        <UpdateDrink url={url} />
      </Route>
      <Route path={`${path}/drink/:drinkId/edit`}>
        <UpdateDrink url={url} />
      </Route>
      <Redirect path='*' to='/404' />
    </Switch>
  );
};
