import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../core/store';
import { IGetResidentRes } from '../../types/api/resident/getResident';

type CurrentResident = IGetResidentRes;

// initialize
const initialState: CurrentResident = {
  id: 0,
  nursingHomeId: 0,
  nursingHomeUnitId: null,
  nursingHomeUnitName: '',
  name: '',
  lastName: '',
  roomName: '',
  carePlanMemo: '',
  dfreeDeviceId: 0,
  bladderThresholdPercentage: 60,
  bigBladderNotification: true,
  urinationNotification: false,
  gotUpNotification: false,
  zeroResetNotification: false,
  beginGotUpCheckTerm: '22:00:00Z',
  endGotUpCheckTerm: '04:00:00Z',
  contingencyMode: false,
  gasPercentage: null,
  gasCareStatus: null,
  gasLevelStatus: null,
  gasLevelThreshold: null,
  gasPoopedNotification: false,
  purpose: 'toilet',
  bigBladderSnoozeTerm: 5,
  bigBladderSnoozePeriod: 60,
  urinationSnoozeTerm: 5,
  urinationSnoozePeriod: 60,
  gotUpSnoozeTerm: 5,
  gotUpSnoozePeriod: 60,
  memo: null,
  careSettings: {
    careLevel: null,
    independenceLevel: null,
    beginDiaperMiddleHour: '06:00:00Z',
    endDiaperMiddleHour: '12:00:00Z',
    beginDiaperNightHour: '12:00:00Z',
    endDiaperNightHour: '23:00:00Z',
    nursingHomeDiaperIdNoon: null,
    nursingHomePadIdNoonFirst: null,
    nursingHomePadIdNoonSecond: null,
    nursingHomeDiaperIdMiddle: null,
    nursingHomePadIdMiddleFirst: null,
    nursingHomePadIdMiddleSecond: null,
    nursingHomeDiaperIdNight: null,
    nursingHomePadIdNightFirst: null,
    nursingHomePadIdNightSecond: null,
    laxativeId: null,
  },
  goodTreeResidentId: null,
};

// slice
export const currentResidentSlice = createSlice({
  name: 'currentResident',
  initialState,
  reducers: {
    fetch: (state, action: PayloadAction<IGetResidentRes>) => {
      state.id = action.payload.id;
      state.nursingHomeId = action.payload.nursingHomeId;
      state.nursingHomeUnitId = action.payload.nursingHomeUnitId;
      state.nursingHomeUnitName = action.payload.nursingHomeUnitName;
      state.name = action.payload.name;
      state.lastName = action.payload.lastName;
      state.roomName = action.payload.roomName;
      state.carePlanMemo = action.payload.carePlanMemo;
      state.dfreeDeviceId = action.payload.dfreeDeviceId;
      state.bigBladderNotification = action.payload.bigBladderNotification;
      state.urinationNotification = action.payload.urinationNotification;
      state.gotUpNotification = action.payload.gotUpNotification;
      state.zeroResetNotification = action.payload.zeroResetNotification;
      state.beginGotUpCheckTerm = action.payload.beginGotUpCheckTerm;
      state.endGotUpCheckTerm = action.payload.endGotUpCheckTerm;
      state.contingencyMode = action.payload.contingencyMode;
      state.gasPercentage = action.payload.gasPercentage;
      state.gasCareStatus = action.payload.gasCareStatus;
      state.gasLevelStatus = action.payload.gasLevelStatus;
      state.gasLevelThreshold = action.payload.gasLevelThreshold;
      state.gasPoopedNotification = action.payload.gasPoopedNotification;
      state.purpose = action.payload.purpose;
      state.memo = action.payload.memo;
      state.careSettings = action.payload.careSettings;
      state.bladderThresholdPercentage = action.payload.bladderThresholdPercentage ?? 0;
      state.goodTreeResidentId = action.payload.goodTreeResidentId;
    },
  },
});

// action
export const { fetch } = currentResidentSlice.actions;

// selector
export const currentResidentSelector = (state: RootState) => state.currentResident;
