import React from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { IResidentCareRecord } from '../../../types/api/traversal/getNursingHomeCareRecord';
import { OutlineButton } from '../../../components/outlineButton';
import { color } from '../../../styles';
import { ResidentHeaderCareStatus } from '../../../components/residentItemHeader/careStatus';
import { CareRecordButtonGroup } from '../../../components/mui/radio/Radio';
import { CheckButton } from '../../../components/mui/checkButton';
import { ResidentItemHeader } from '../../../components/residentItemHeader';
import { useTranslation } from 'react-i18next';

interface Props {
  resident: IResidentCareRecord;
  onChange: (data: IBulkRecord) => void;
}

export interface IBulkRecord {
  residentId: number;
  urinatedToilet: boolean | null;
  toiletType: string | null;
  padUrineVolumeType: string | null;
  padType: string | null;
  defecationVolumeType?: string | null;
  hardnessType?: string | null;
  diaper: {
    useDefaultDiaper?: true | null;
    useDefaultPad?: true | null;
  };
}

const Container = css`
  border-radius: 8px;
  background-color: ${color.white};
  margin-bottom: 16px;
`;

const HeaderContents = css`
  display: flex;
  gap: 8px;
`;

const HeaderButtonContainer = css`
  display: flex;
  justify-content: end;
  gap: 6px;
  margin-bottom: 4px;
  align-items: center;
  font-size: 12px;
`;

const BodyContainer = css`
  border-top: 1px solid ${color.border_gray};
  display: flex;
  padding: 16px 16px 16px 8px;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: stretch;
`;

const RecordSection = css`
  display: flex;
  justify-content: stretch;
  flex-grow: 1;
  flex-shrink: 0;
`;

const SectionTitle = css`
  display: flex;
  align-items: center;
  width: 40px;
  writing-mode: vertical-rl;
  margin-right: 8px;
  height: 92px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.3em;
  color: ${color.dark_gray};
  border-right: 3px solid ${color.dark_gray};
  flex-shrink: 0;
`;

const SectionButtonContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
`;

const SectionUseContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  max-width: 240px;
`;

const SectionButtonRow = css`
  flex-shrink: 0;
  height: 46px;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 360px;
  flex-grow: 1;
`;

const UrineLabelContainer = css`
  width: 88px;
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
  align-items: center;
`;

const UrineLabelIcon = css`
  width: 28px;
  height: 28px;
`;

const UrineLabelText = css`
  margin-left: 4px;
  font-size: 14px;
  color: ${color.gray};
  line-height: 1.2em;
  flex-shrink: 0;
  margin-right: 8px;
`;

export const BulkRecordItem = React.memo(({ resident, onChange }: Props) => {
  const initData: IBulkRecord = {
    residentId: resident.residentId,
    urinatedToilet: null,
    toiletType: null,
    padUrineVolumeType: null,
    padType: null,
    defecationVolumeType: null,
    hardnessType: null,
    diaper: {
      useDefaultDiaper: null,
      useDefaultPad: null,
    },
  };

  const [isCheckPant, setCheckPant] = React.useState<boolean>(false);
  const [isCheckPad, setCheckPad] = React.useState<boolean>(false);
  const [sendData, setSendData] = React.useState<IBulkRecord>(initData);
  const history = useHistory();
  const { t } = useTranslation();

  // formControl
  const isDisabledHardnessTypeForm = React.useMemo(() => {
    return !sendData.defecationVolumeType;
  }, [sendData]);

  const handleChangeIsUrinatedToilet = (data: string | number | boolean | null) => {
    setSendData((prev) => {
      return {
        ...prev,
        urinatedToilet: data === 'true' ? true : data === 'false' ? false : null,
      };
    });
  };

  const handleChangePadUrineVolumeType = (data: string | number | boolean | null) => {
    setSendData((prev) => {
      return {
        ...prev,
        padUrineVolumeType: data ? String(data) : null,
      };
    });
  };

  const handleChangeDefecationVolumeType = (data: string | number | boolean | null) => {
    setSendData((prev) => {
      return {
        ...prev,
        hardnessType: data ? prev.hardnessType : null,
        defecationVolumeType: data ? String(data) : null,
      };
    });
  };

  const handleChangeHardnessType = (data: string | number | boolean | null) => {
    setSendData((prev) => {
      return {
        ...prev,
        hardnessType: data ? String(data) : null,
      };
    });
  };

  const handleClickPantButton = () => {
    setCheckPant(!isCheckPant);
    setSendData((prev) => {
      return {
        ...prev,
        diaper: {
          useDefaultDiaper: !isCheckPant ? true : null,
          useDefaultPad: prev.diaper.useDefaultPad,
        },
      };
    });
  };

  const handleClickPadButton = () => {
    setCheckPad(!isCheckPad);
    setSendData((prev) => {
      return {
        ...prev,
        diaper: {
          useDefaultDiaper: prev.diaper.useDefaultDiaper,
          useDefaultPad: !isCheckPad ? true : null,
        },
      };
    });
  };

  React.useEffect(() => {
    if (!Object.is(initData, sendData)) {
      onChange(sendData);
    }
  }, [sendData]);

  return (
    <>
      <div css={Container}>
        <ResidentItemHeader
          name={resident.residentName}
          unitName={resident.nursingHomeUnitName}
          roomName={resident.roomName}
          memo={resident.carePlanMemo}
          rightElement={
            <div css={HeaderContents}>
              <ResidentHeaderCareStatus resident={resident} />
              <div css={HeaderButtonContainer}>
                <OutlineButton
                  color={color.green}
                  onClick={() => {
                    history.push(`residents/${resident.residentId}/care-record/new`);
                  }}
                >
                  {t('detailedRecordPlus', '詳細記録＋')}
                </OutlineButton>
                <OutlineButton
                  onClick={() => {
                    history.push(`residents/${resident.residentId}/graph`);
                  }}
                >
                  {t('detailOrEdit', '詳細 / 編集')}
                </OutlineButton>
              </div>
            </div>
          }
        />
        <div css={[BodyContainer]}>
          <div css={RecordSection}>
            <div css={SectionTitle}>{t('urine', '排尿')}</div>
            <div css={SectionButtonContainer}>
              <div css={SectionButtonRow}>
                <div css={UrineLabelContainer}>
                  <img css={UrineLabelIcon} src='/images/bulkRecord/toilet.svg' alt={t('toilet', 'トイレ')} />
                  <span css={UrineLabelText}>{t('toilet', 'トイレ')}</span>
                </div>
                <CareRecordButtonGroup
                  data={[
                    { key: true, value: t('yes', '有') },
                    { key: false, value: t('no', '無') },
                  ]}
                  onChange={handleChangeIsUrinatedToilet}
                />
              </div>
              <div css={SectionButtonRow}>
                <div css={UrineLabelContainer}>
                  <img
                    css={UrineLabelIcon}
                    src='/images/bulkRecord/pad.svg'
                    alt={t('diaperOrPad', 'おむつ / パッド')}
                  />
                  <div css={UrineLabelText}>
                    <span>
                      {t('diaper', 'おむつ')}
                      <br />
                      {t('pad', 'パッド')}
                    </span>
                  </div>
                </div>
                <CareRecordButtonGroup
                  data={[
                    { key: 'large', value: t('large', '多'), icon: '/images/recordButtonIcon/urine-large.svg' },
                    { key: 'medium', value: t('medium', '中'), icon: '/images/recordButtonIcon/urine-medium.svg' },
                    { key: 'small', value: t('small', '少'), icon: '/images/recordButtonIcon/urine-small.svg' },
                    { key: 'none', value: t('none', '無'), icon: '/images/recordButtonIcon/urine-none.svg' },
                  ]}
                  onChange={handleChangePadUrineVolumeType}
                />
              </div>
            </div>
          </div>
          <div css={RecordSection}>
            <div css={SectionTitle}>{t('stool', '排便')}</div>
            <div css={SectionButtonContainer}>
              <div css={SectionButtonRow}>
                <CareRecordButtonGroup
                  data={[
                    { key: 'large', value: t('large', '多'), icon: '/images/recordButtonIcon/stool-large.svg' },
                    { key: 'medium', value: t('medium', '中'), icon: '/images/recordButtonIcon/stool-medium.svg' },
                    { key: 'small', value: t('small', '少'), icon: '/images/recordButtonIcon/stool-small.svg' },
                    { key: 'bit', value: t('bit', '付'), icon: '/images/recordButtonIcon/stool-bit.svg' },
                  ]}
                  onChange={handleChangeDefecationVolumeType}
                />
              </div>
              <div css={SectionButtonRow}>
                <CareRecordButtonGroup
                  data={[
                    { key: 'hard', value: t('hard', '硬'), icon: '/images/recordButtonIcon/stool-hard.svg' },
                    { key: 'middle', value: t('normal', '普'), icon: '/images/recordButtonIcon/stool-normal.svg' },
                    { key: 'soft', value: t('soft', '軟'), icon: '/images/recordButtonIcon/stool-soft.svg' },
                    { key: 'littleSoft', value: t('mushy', '泥'), icon: '/images/recordButtonIcon/stool-mushy.svg' },
                    {
                      key: 'water',
                      value: t('waterly', '水'),
                      icon: '/images/recordButtonIcon/stool-waterly.svg',
                    },
                  ]}
                  newValue={sendData.hardnessType}
                  isDisabled={isDisabledHardnessTypeForm}
                  onChange={handleChangeHardnessType}
                />
              </div>
            </div>
          </div>
          <div css={RecordSection}>
            <div css={SectionTitle}>{t('use', '交換')}</div>
            <div css={SectionUseContainer}>
              <CheckButton onClick={handleClickPantButton}>{t('diaperPants', 'テープ/パンツ')}</CheckButton>
              <CheckButton onClick={handleClickPadButton}>{t('pad', 'パッド')}</CheckButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
