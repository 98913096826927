import React from 'react';
import api from '../../api';
import { GetDiaperRequiredResponse } from '../../types/api/diaper-required/getDiaperRequired';
import { filterExpendableOnly, filterInnersOnly, filterOutersOnly } from '../../utils/filterDiaperShape';
import { margeDiaperTableData } from '../../utils/setDiaperTableData';
import { IGetDiaperRes } from '../../types/api/care-settings/getDiaper';
import { Order } from '../../types/api/order/getOrders';
import { IGetMasterDiaperRes } from '../../types/api/master/getDiaper';
import { sortArray } from '../../utils/arrayUtil';

interface Diapers {
  inner: IGetDiaperRes[] | null;
  outer: IGetDiaperRes[] | null;
  expendable: IGetDiaperRes[] | null;
  required: GetDiaperRequiredResponse;
  unitId: number | null;
  unitName: string | null;
  memo: string;
}

// tableに必要なデータをすべて付与したdiapers情報を返す
export function useFetchTableData(
  nursingHomeId: number,
  facilityType: string,
  nursingHomeUnit: { id: number | undefined; name: string | undefined } | null,
  savedOrderId?: string | null
): {
  inner: IGetDiaperRes[] | null;
  outer: IGetDiaperRes[] | null;
  expendable: IGetDiaperRes[] | null;
  memo: string;
  unitId: number | null;
  unitName: string | null;
} {
  // 1回呼ぶだけでよいapiのデータ
  const [apiData, setApiData] = React.useState<{
    diapers: IGetDiaperRes[];
    order: Order | null;
    masters: IGetMasterDiaperRes[];
    required: GetDiaperRequiredResponse;
  }>();
  const [diapers, setDiapers] = React.useState<Diapers>({
    inner: null,
    outer: null,
    expendable: null,
    required: [],
    unitId: null,
    unitName: null,
    memo: '',
  });
  const fetchDiapers = React.useCallback(async () => {
    const diapers = await api.get('/nursing-homes/:nursingHomeId/diapers', {
      params: { nursingHomeId },
    });
    const order = savedOrderId ? await api.get('/orders/:orderId', { params: { orderId: savedOrderId } }) : null;
    const masters = await api.get('/master/diapers', { params: { limit: 5000 } });
    const required = await api.get('/nursing-homes/:nursingHomeId/diaper-required', { params: { nursingHomeId } });
    setApiData({
      diapers,
      order,
      masters,
      required,
    });
  }, []);

  const createTableData = React.useCallback(async () => {
    if (!apiData) return;
    const { diapers, order, masters, required } = apiData;
    const enableOrderDiapers = diapers
      .filter((v) => v.orderEnable)
      .map((v) => {
        if (!order) {
          return { ...v };
        }
        const savedDiaper = order?.orders.find((order) => order.nursingHomeDiaperId === v.id);
        if (savedDiaper) {
          return { ...v, amount: savedDiaper.amount };
        } else {
          return { ...v };
        }
      });
    const inner = filterInnersOnly(enableOrderDiapers);
    const outer = filterOutersOnly(enableOrderDiapers);
    const masterExpendable = filterExpendableOnly(masters);
    // masterの型をテーブル用に置き換える
    const expendable: IGetDiaperRes[] = (masterExpendable || []).map((v) => {
      const savedDiaper = order?.orders.find((order) => order.diaperId === v.id);
      return {
        id: 0,
        diaperId: v.id,
        nursingHomeId,
        diaperName: v.name,
        diaperFullName: v.name,
        diaperShape: v.shape,
        diaperSeries: v.series,
        diaperForm: v.form,
        diaperSheets: v.sheets,
        diaperSize: v.size,
        diaperBags: v.bags,
        diaperExpendablePrice: null,
        diaperIncludeTax: v.includeTax,
        isFlatRate: v.isFlatRate,
        orderEnable: v.enableOrder,
        amount: savedDiaper ? savedDiaper.amount : 0,
      };
    });
    const params = !nursingHomeUnit?.id
      ? order
        ? { nursingHomeId, nursingHomeUnitId: order.nursingHomeUnitId }
        : { nursingHomeId }
      : order
      ? order.nursingHomeUnitId === nursingHomeUnit?.id
        ? { nursingHomeId, nursingHomeUnitId: order.nursingHomeUnitId }
        : { nursingHomeId, nursingHomeUnitId: nursingHomeUnit?.id }
      : { nursingHomeId, nursingHomeUnitId: nursingHomeUnit?.id };
    const stocks = await api.get('/nursing-homes/:nursingHomeId/diaper-stocks', { params });
    const averages = await api.get('/nursing-homes/:nursingHomeId/orders/average', { params });
    const tableDataInner = inner
      ? sortArray(
          margeDiaperTableData(inner, required, stocks, facilityType, nursingHomeUnit?.id, averages),
          'diaperName',
          'desc'
        )
      : null;
    const tableDataOuter = outer
      ? sortArray(
          margeDiaperTableData(outer, required, stocks, facilityType, nursingHomeUnit?.id, averages),
          'diaperFullName',
          'desc'
        )
      : null;
    // TODO: expendableだけ名前で降順ソートされていないので必要であればする
    setDiapers({
      inner: tableDataInner,
      outer: tableDataOuter,
      expendable,
      required,
      unitId: order ? order.nursingHomeUnitId : null,
      unitName: order ? order.nursingHomeUnitName : '',
      memo: order ? order.memo : '',
    });
  }, [apiData, nursingHomeUnit?.id, facilityType]);

  React.useEffect(() => {
    fetchDiapers();
  }, []);

  React.useEffect(() => {
    if (!nursingHomeUnit) return;
    createTableData();
  }, [apiData, nursingHomeUnit, facilityType]);

  return diapers;
}
