import React from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { color } from '../styles';
import { emailValidation } from '../utils/validation';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';

const title = css`
  height: 24px;
  font-size: 20px;
  font-weight: bold;
`;

const caption = css`
  margin: 24px 0 32px 0;
`;

const StyledTextField = css`
  width: 100%;
  height: 80px;
`;

const StyledButton = css`
  color: white;
  margin: 8px 0;
`;

const StyledLink = css`
  display: block;
  color: ${color.green};
  height: 24px;
  margin-top: 16px;
`;

const warnMsg = css`
  margin-top: 8px;
  color: ${color.red};
`;

export const PasswordReset = () => {
  const history = useHistory();
  const [isBlankEmail, setIsBlankEmail] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [emailHelperText, setEmailHelperText] = React.useState<string>('');
  const [errorText, setErrorText] = React.useState<string>('');
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (emailValidation(event.target.value)) {
      setEmail(event.target.value);
      setIsBlankEmail(false);
      setEmailHelperText('');
    } else {
      setEmail('');
      setIsBlankEmail(true);
      setEmailHelperText(t('pleaseEnterUsingCorrectEmailAddressFormat', 'メールアドレスの形式で入力してください'));
    }
    setEmail(event.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        showSnackbar(t('confirmationEmailHasBeenSent', '確認メールを送信しました'), 'success');
        history.push(`./login`);
      })
      .catch((err) => {
        if ('auth/user-disabled'.includes(err.code))
          setErrorText(t('userNotEnabled', 'ユーザーが有効化されていません'));
        else if ('auth/user-not-found'.includes(err.code))
          setErrorText(t('thisIsAnUnregisteredEmailAddress', '登録されていないメールアドレスです'));
        else if ('auth/invalid-email'.includes(err.code))
          setErrorText(t('emailAddressIsDifferent', 'メールアドレスが違います'));
        else setErrorText(t('emailAddressIsDifferent', 'メールアドレスが違います'));
      });
  };

  return (
    <>
      <div css={title}>{t('resetPassword', 'パスワード再設定')}</div>
      <div css={caption}>
        <span>
          {t(
            'weWillSendYouAnEmailWithTheURLOfThePasswordChangePage',
            'パスワード再設定のためのURLを登録メールアドレスに送信します。'
          )}
        </span>
        <br />
        <span>
          {t(
            'pleaseEnterYourRegisteredEmailAddressBelowAndPressTheSendButton',
            '登録メールアドレスを入力し「送信」ボタンを押してください。'
          )}
        </span>
      </div>
      <div>
        <TextField
          error={isBlankEmail}
          css={StyledTextField}
          id='email'
          label={t('emailAddress', 'メールアドレス')}
          variant='outlined'
          onChange={handleChangeEmail}
          type='email'
          autoComplete='email'
          helperText={emailHelperText}
        />
      </div>

      <Button css={StyledButton} onClick={handleSubmit} variant='contained' disabled={isBlankEmail}>
        {t('send', '送信')}
      </Button>

      <a href='/login' css={StyledLink}>
        {t('backToLogin', 'ログインへ戻る')}
      </a>
      {errorText && <div css={warnMsg}>{errorText}</div>}
    </>
  );
};
