import React from 'react';
import { IResident } from '../types/api/traversal/getNursingHomeResidents';
import { sortArray } from '../utils/arrayUtil';
import { SortType } from '@/types/components';
import { IResidentCareRecord } from '@/types/api/traversal/getNursingHomeCareRecord';

export const useSortResidents = <T extends IResident | IResidentCareRecord>() => {
  const [sortedResidents, setSortedResidents] = React.useState<T[]>([]);

  const sortResidents = (residents: T[], sortType: SortType) => {
    let sortedResidents: T[];
    switch (sortType) {
      case 'unit':
        sortedResidents = sortByUnit(residents);
        break;
      case 'notification':
        sortedResidents = sortByNotification(residents);
        break;
      case 'careTiming':
        sortedResidents = sortByCareTiming(residents);
        break;
      case 'notificationAndCareTiming':
        sortedResidents = sortByNotificationAndCareTiming(residents);
        break;
      case 'createdAt':
        sortedResidents = sortByCreatedAt(residents);
        break;
      default:
        sortedResidents = residents;
    }
    setSortedResidents(sortedResidents);
    return sortedResidents;
  };

  const sortByUnit = (residents: T[]) => {
    let sorted;
    sorted = sortArray(residents, 'residentName');
    sorted = sortArray(sorted, 'roomName');
    sorted = sortArray(sorted, 'nursingHomeUnitName');
    return sorted;
  };

  const sortByNotification = (residents: T[]) => {
    const sortedResidents: IResident[] = sortByUnit(residents);
    const withCover = sortedResidents.filter((r: IResident) => r.coverTimestamp);
    const withoutCover = sortedResidents.filter((r: IResident) => !r.coverTimestamp);
    const sortedWithCover = sortArray(withCover, 'coverTimestamp');

    return [...sortedWithCover, ...withoutCover];
  };

  const sortByCareTiming = (residents: T[]) => {
    const highPriority: T[] = [];
    const middlePriority: T[] = [];
    const lowPriority: T[] = [];
    const careTimingNotDefined: T[] = [];

    const sortedResidents = sortByUnit(residents);

    sortedResidents.forEach((resident) => {
      if (resident.nextCareTiming != null && resident.isCareCompleted != null && resident.inCareTiming != null) {
        // 定時誘導実施中 (優先度高)
        if (resident.isCareCompleted === 0 && resident.inCareTiming === 1) highPriority.push(resident);
        // 定時誘導実施予定 (優先度中)
        else if (resident.isCareCompleted === 0 && resident.inCareTiming === 0) middlePriority.push(resident);
        // 定時誘導実施済 (優先度低)
        else if (resident.isCareCompleted === 1) lowPriority.push(resident);
        // 優先度分類不可
        else console.warn('!! Unknown Priority');
        // 定時誘導時間未設定
      } else careTimingNotDefined.push(resident);
    });

    return [
      ...sortArray(highPriority, 'nextCareTiming'),
      ...sortArray(middlePriority, 'nextCareTiming'),
      ...sortArray(lowPriority, 'nextCareTiming'),
      ...careTimingNotDefined,
    ];
  };

  const sortByNotificationAndCareTiming = (residents: T[]) => {
    const sortedResidents = sortByCareTiming(residents);
    const withCover = sortedResidents.filter((r) => r.coverTimestamp);
    const withoutCover = sortedResidents.filter((r) => !r.coverTimestamp);
    const sortedWithCover = sortArray(withCover, 'coverTimestamp');

    return [...sortedWithCover, ...withoutCover];
  };

  // IResident型とIResidentCareRecord型で入居者IDを指し示すkey名が異なるため判別
  const sortByCreatedAt = (residents: T[]) => {
    if (residents[0] && 'id' in residents[0]) return sortArray(residents, 'id');
    else return sortArray(residents, 'residentId');
  };

  return { sortedResidents, sortResidents };
};
