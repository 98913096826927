import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { IGetResidentRes } from '../../types/api/resident/getResident';
import api from '../../api';
import { ResidentItemHeader } from '../../components/residentItemHeader';
import { OutlineButton } from '../../components/outlineButton';
import { NavTabs } from './navTabs';
import { currentResidentSlice } from '../../redux/config/currentResident';
import { currentNursingHomeSelector, showLifeContentSelector } from '../../redux/config/currentNursingHome';
import { Loading } from '../../components/mui/Loading';
import { useTranslation } from 'react-i18next';

interface Props {
  url: string;
  params: { nursingHomeId: string; residentId: string };
  children: React.ReactNode;
}

interface Tab {
  label: string;
  href: string;
}

const Container = css`
  max-width: 1280px;
  background: #fff;
  border-radius: 8px;
  margin: 40px auto;
`;

export const ResidentDetailLayout: React.FC<Props> = ({ children, params, url }) => {
  const { nursingHomeId, residentId } = params;
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const showLifeContent = useSelector(showLifeContentSelector);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabNumber, setTabNumber] = React.useState(0);
  const [resident, setResident] = React.useState<IGetResidentRes>();
  const { t, i18n } = useTranslation();

  const fetchResident = React.useCallback(async () => {
    const res = await api.get('/residents/:residentId', { params: { residentId } });
    setResident(res);
    dispatch(currentResidentSlice.actions.fetch(res));
  }, []);

  const handleChangeTab = React.useCallback((tabNumber: number) => {
    setTabNumber(tabNumber);
  }, []);

  const handleGoEdit = React.useCallback(() => {
    history.push(`./edit`);
  }, []);

  const tabLinks: Tab[] = React.useMemo(() => {
    let tabs = [
      { label: t('graph', 'グラフ'), href: `${url}/graph` },
      { label: t('careHistory', 'ケア履歴'), href: `${url}/care-record` },
      { label: t('careHistory3months', 'ケア履歴（集計）'), href: `${url}/care-record-data` },
      { label: t('monthlyExcretionTable', '月間排泄表'), href: `${url}/monthly` },
      { label: t('urinationTrendAnalysis', '排尿傾向分析'), href: `${url}/urine-trend-analysis` },
      { label: t('activityAnalysis', '活動傾向分析'), href: `${url}/activity-analysis` },
      { label: t('urinationDiary', '排尿日誌'), href: `${url}/diary` },
      { label: '排泄支援情報', href: `${url}/life-care-plannings` },
    ];

    // 開発環境のみの表示
    if (process.env.REACT_APP_ENV_NAME !== 'development') {
      tabs = tabs.filter((tab) => tab.label !== t('urinationDiary', '排尿日誌'));
    }

    if (!showLifeContent) {
      tabs = tabs.filter((tab) => tab.label !== '排泄支援情報');
    }

    return tabs;
  }, [i18n, nursingHomeId, residentId, showLifeContent]);

  React.useEffect(() => {
    fetchResident();
  }, []);

  React.useEffect(() => {
    if (tabLinks.length <= 0) return;
    const index = tabLinks.findIndex((v) => v.href === location.pathname);
    if (index === -1) return;
    setTabNumber(index);
  }, [location, tabLinks]);

  return (
    <div css={Container}>
      {resident ? (
        <>
          <ResidentItemHeader
            name={(resident.lastName ?? '') + (resident.name ?? '')}
            roomName={resident.roomName}
            unitName={resident.nursingHomeUnitName}
            rightElement={
              <OutlineButton
                onClick={handleGoEdit}
                icon={<img src='/images/icons/resident-setting.svg' alt='入居者設定' />}
              >
                {t('setting', '設定')}
              </OutlineButton>
            }
          />
          {tabLinks.length > 0 && (
            <NavTabs tabsLink={tabLinks} selectedTabIndex={tabNumber} onChange={handleChangeTab} />
          )}
          <div>{children}</div>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </div>
  );
};
