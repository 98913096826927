import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetLaxativeParams, IGetLaxativeRes } from '../../../types/api/care-settings/getLaxative';

export const useFetchLaxatives = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  sort: string = 'desc',
  limit: number = 1000,
  offset: number | null = null
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [laxatives, setLaxatives] = React.useState<IGetLaxativeRes[]>();

  const fetchLaxatives = async (sort = 'desc', limit = 1000, offset: number | null = null) => {
    const params: IGetLaxativeParams = {
      nursingHomeId: +nursingHomeId,
      sort,
      limit,
      offset,
    };

    if (!sort) delete params.sort;
    if (!limit) delete params.limit;
    if (!offset) delete params.offset;

    const result = await api.get('/nursing-homes/:nursingHomeId/laxatives', {
      params,
    });

    setLaxatives(result);
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchLaxatives(sort, limit, offset);
  }, []);

  return { laxatives, fetchLaxatives };
};
