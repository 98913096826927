import { css } from '@emotion/react';
import { color } from '../styles';

export const Table = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border: 1px solid ${color.border_gray};
`;

export const Row = css`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${color.border_gray};
  &:last-of-type {
    border-bottom: none;
  }
  &:nth-of-type(2n) {
    background: ${color.light_gray};
  }
`;

export const CellBase = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  height: 40px;

  &:first-of-type {
    padding-left: 16px;
  }

  &:last-of-type {
    padding-right: 12px;
  }

  &:not(:first-of-type) {
    border-left: none;
  }

  &:not(:last-of-type) {
    padding-right: 8px;
    border-right: none;
  }
`;

export const HeaderCell = css`
  background-color: ${color.table_gray};
  color: ${color.dark_gray};
  font-size: 12px;
`;

export const Cell = css`
  height: 48px;
  font-size: 14px;
  color: ${color.dark_gray};
`;

export const TableLinkCell = css`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TableLinkText = css`
  display: flex;
  align-items: center;
  color: ${color.red};
  padding: 0 10px;
  height: 70%;

  &:hover {
    background-color: ${color.bg_gray};
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${color.border_gray};
  }
`;

export const TextWrap = css`
  white-space: nowrap;
  overflow: hidden;
`;

export const OffsetLastHeaderCell = css`
  padding-left: 12px;
`;
