import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { UnitSettings } from '../pages/settings/unit';

export const SettingsRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/unit`}>
        <UnitSettings />
      </Route>
      <Redirect path='*' to='/404' />
    </Switch>
  );
};
