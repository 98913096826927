import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetDiaperRes } from '../../../types/api/care-settings/getDiaper';
import { IGetMasterDiaperRes } from '../../../types/api/master/getDiaper';

export const useFetchMasterDiapers = () => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [masterDiapers, setMasterDiapers] = React.useState<IGetMasterDiaperRes[]>();
  const [masterPants, setMasterPants] = React.useState<IGetMasterDiaperRes[]>();
  const [masterPads, setMasterPads] = React.useState<IGetMasterDiaperRes[]>();
  const [masterExpendable, setMasterExpendable] = React.useState<IGetMasterDiaperRes[]>();

  const fetchMasterDiapers = async (sort = 'desc', limit = 10000) => {
    const result: IGetMasterDiaperRes[] = await api.get('/master/diapers', {
      params: { sort, limit },
    });

    // データをパンツとパッドに分ける
    const pants = result.filter((v) => v.shape === 'outer');
    const pads = result.filter((v) => v.shape === 'inner');
    const expendable = result.filter((v) => v.shape === 'expendable');

    setMasterDiapers(result); // パンツ・パッドに分割していないもの
    setMasterPants(pants); // パンツレコードのみ
    setMasterPads(pads); // パッドレコードのみ
    setMasterExpendable(expendable); // 消耗品レコードのみ
  };

  React.useEffect(() => {
    fetchMasterDiapers();
  }, []);

  return { masterDiapers, masterPants, masterPads, masterExpendable, fetchMasterDiapers };
};
