import React from "react";
import { css, keyframes, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { color } from '../styles';

type SnackbarType = "success" | "error";

interface ContainerProps {
  visible: boolean;
  type: SnackbarType;
}

interface Context {
  showSnackbar: (text: string, type?: SnackbarType) => void;
}

const fadeIn = keyframes`
    0% {
        transform: translateX(-50%);
        opacity: 0;

    }
    100% {
        transform: translateY(-20px) translateX(-50%);
        opacity: 1;
    }
  `;

const fadeOut = keyframes`
    100% {
        transform: translateY(-20px) translateX(-50%);
        opacity: 1;
    }
    0% {
        transform: translateX(-50%);
        opacity: 0;

    }
  `;

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 50%;
  right: 0;
  background: ${({ type }) => (type === 'success' ? color.green : color.red)};
  height: 40px;
  border-radius: 4px;
  transform: translateY(-20px) translateX(-50%);
  width: fit-content;
  max-width: 480px;
  font-size: 14px;
  animation: ${({ visible }) => (visible ? fadeIn : fadeOut)} 0.3s;
  z-index: 1;
`;

const Text = css`
  color: ${color.white};
`;

const OKText = css`
  color: ${color.white};
  cursor: pointer;
  padding: 4px 0 4px 8px;
  border-radius: 6px;
`;

const SnackbarContext = React.createContext<Context>({
  showSnackbar: () => {},
});

export function useSnackbar() {
  return React.useContext(SnackbarContext);
}

export function SnackbarProvider({ children }: any) {
  const [visible, setVisible] = React.useState(true);
  const [type, setType] = React.useState<SnackbarType>("success");
  const [text, setText] = React.useState<string>();

  const handleClose = React.useCallback(() => {
    setVisible(false);
    setText(undefined);
  }, []);

  function showSnackbar(text: string, type?: SnackbarType) {
    if (type) {
      setType(type);
    }
    setText(text);
  }

  React.useEffect(() => {
    if (!text) return;
    setTimeout(() => {
      handleClose();
    }, 5000);
    return () => {
      clearTimeout();
    };
  }, [text]);

  const value = {
    showSnackbar,
  };

  return (
    <>
      <SnackbarContext.Provider value={value}>
        {children}
      </SnackbarContext.Provider>
      {text && (
        <Container visible={visible} type={type}>
          <div css={Text}>{text}</div>
          <div css={OKText} onClick={handleClose}>
            OK
          </div>
        </Container>
      )}
    </>
  );
}
