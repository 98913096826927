import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import api from '../../../api';
import { color } from '../../../styles';
import { PanelContainer } from '../../../components/panel';
import { numBetweenMinMax } from '../../../utils/dataFormatUtil';
import {
  DEFECATION_TYPE_JA,
  DEFECATION_TYPE_EN,
  HARDNESS_TYPE_JA,
  HARDNESS_TYPE_EN,
  PAD_TYPE_JA,
  PAD_TYPE_EN,
  TOILET_TYPE_JA,
  TOILET_TYPE_EN,
  URINE_TYPE_JA,
  URINE_TYPE_EN,
} from '../../../constants';
import { navSelector, navSlice } from '../../../redux/config/leftnav';
import { DateSelector } from '../../../components/mui/dateSelector';
import { MenuItemProps, Select } from '../../../components/mui/select';
import { CareRecordButtonGroup } from '../../../components/mui/radio/Radio';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useFetchResident } from '../../../hooks/api/resident/useFetchResident';
import { useFetchCareRecord } from '../../../hooks/api/careRecord/useFetchCareRecord';
import { useFetchNursingHomeDiapers } from '../../../hooks/api/careSettings/useFetchNursingHomeDiapers';
import { useFetchLaxatives } from '../../../hooks/api/careSettings/useFetchLaxatives';
import { useFetchDrinks } from '../../../hooks/api/careSettings/useFetchDrinks';
import { ICreateCareRecordParams } from '../../../types/api/care-record/createCareRecord';
import { ICareRecordDiaper } from '../../../types/api/care-record/careRecordDiaper';
import { IUpdateCareRecordParams } from '../../../types/api/care-record/updateCareRecord';
import { IUpdateUrineVolumeReset } from '../../../types/api/resident/updateUrineVolumeReset';
import { Button } from '../../../components/mui/button';
import { useLoading } from '../../../contexts/LoadingContext';

const Title = css`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
`;

const AlignItems = css`
  display: flex;
  align-items: center;
`;

const Wrap = css`
  flex-wrap: wrap;
`;

const Wmax = css`
  width: 100%;
`;

const W100 = css`
  flex-shrink: 0;
  width: 100px;
  margin: 4px 0 12px 0;
`;

const W150 = css`
  flex-shrink: 0;
  width: 150px;
  margin: 4px 0 12px 0;
`;

const W200 = css`
  flex-shrink: 0;
  width: 200px;
  margin: 4px 0 12px 0;
`;

const W300 = css`
  flex-shrink: 0;
  width: 300px;
  margin: 4px 0 12px 0;
`;

const W400 = css`
  flex-shrink: 0;
  width: 400px;
  margin: 4px 0 12px 0;
`;

const ContentMargin = css`
  margin: 0 0 12px 0;
`;

const BottomLine = css`
  border-bottom: 1px solid ${color.green};
`;

const ContentLeft = css`
  width: 136px;
  padding: 16px 16px;
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
  color: ${color.white};
  background-color: ${color.green};
  border-bottom: 1px solid ${color.white};
`;

const ContentWrapper = css`
  display: flex;
`;

const ContentRight = css`
  width: 100%;
  padding: 24px 32px 16px 24px;
  background-color: ${color.light_green};
  border: 1px solid ${color.green};
  border-bottom: none;
  gap: 16px;
`;

const ButtonWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

const TableLabel = css`
  margin: 8px;
  font-size: 14px;
`;

const Image = css`
  margin: 0;
  width: 24px;
  min-width: 24px;
  height: 24px;
`;

const ErrorText = css`
  color: ${color.red};
  font-size: 14px;
`;

const Unit = css`
  font-size: 14px;
  margin: 16px 8px 0 8px;
`;

const AfterText = (str: string) => [
  css`
    &::after {
      content: ${str};
    }
  `,
];

export const DetailedRecordPlus = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { residentId } = useParams<{ residentId: string }>();
  const { careRecordId } = useParams<{ careRecordId: string }>();
  const { resident } = useFetchResident(true, +residentId);
  const { badge } = useSelector(navSelector);
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();
  const { careRecord, fetchCareRecord } = useFetchCareRecord(true, +careRecordId);
  const { nursingHomeDiapers, nursingHomePants, nursingHomePads } = useFetchNursingHomeDiapers(true, 'asc');
  const { laxatives } = useFetchLaxatives(true, 'asc');
  const { drinks } = useFetchDrinks(true, 'asc');
  const initialDate = dayjs();
  const { t, i18n } = useTranslation();
  const [onSend, setOnSend] = React.useState<boolean>(false);

  // formData
  const [date, setDate] = React.useState<Dayjs>(initialDate);
  const [hour, setHour] = React.useState<number>();
  const [minute, setMinute] = React.useState<number>();
  const [staffName, setStaffName] = React.useState<string | null>(null);
  const [workingTime, setWorkingTime] = React.useState<number | null>(5);
  const [toiletType, setToiletType] = React.useState<MenuItemProps | null>(null);
  const [isUrinatedToilet, setIsUrinatedToilet] = React.useState<boolean | null>(null);
  const [toiletVolume, setToiletVolume] = React.useState<number | ''>('');
  const [padType, setPadType] = React.useState<MenuItemProps | null>(null);
  const [padUrineVolumeType, setPadUrineVolumeType] = React.useState<string | null>(null);
  const [padUrineVolume, setPadUrineVolume] = React.useState<number | ''>('');
  const [isUrgency, setIsUrgency] = React.useState<boolean | null>(null);
  const [defecationVolumeType, setDefecationVolumeType] = React.useState<string | null>(null);
  const [hardnessType, setHardnessType] = React.useState<string | null>(null);
  const [pantId, setPantId] = React.useState<MenuItemProps | null>(null);
  const [pad1Id, setPad1Id] = React.useState<MenuItemProps | null>(null);
  const [pad2Id, setPad2Id] = React.useState<MenuItemProps | null>(null);
  const [laxativeId, setLaxativeId] = React.useState<MenuItemProps | null>(null);
  const [laxativeAmount, setLaxativeAmount] = React.useState<number | ''>('');
  const [drinkId, setDrinkId] = React.useState<MenuItemProps | null>(null);
  const [otherDrink, setOtherDrink] = React.useState<string | ''>('');
  const [drinkVolumeType, setDrinkVolumeType] = React.useState<number | ''>('');
  const [drinkVolume, setDrinkVolume] = React.useState<number | ''>('');
  const [memo, setMemo] = React.useState<string | ''>('');

  // formErrorControl
  const [isDateError, setDateError] = React.useState<boolean>(false);
  const [isOtherDrinkError, setOtherDrinkError] = React.useState<boolean>(false);
  const [otherDrinkErrorText, setOtherDrinkErrorText] = React.useState<string>('');
  const [isLaxativeAmountError, setIsLaxativeAmountError] = React.useState<boolean>(false);
  const [laxativeAmountErrorText, setLaxativeAmountErrorText] = React.useState<string>('');

  // api
  const createCareRecord = async (data: ICreateCareRecordParams) => {
    return await api.post('/residents/:residentId/care-records', data);
  };

  const updateCareRecord = async (data: IUpdateCareRecordParams | any) => {
    return await api.put('/care-records/:careRecordId', data);
  };

  const updateUrineVolumeReset = async (residentId: number) => {
    const data: IUpdateUrineVolumeReset = {
      residentId,
      urineVolumeResetFlg: true,
    };
    return await api.put('/residents/:residentId/urine-volume-reset', data);
  };

  // TODO: Editの場合フォームの初期値がない場合にバグ
  // TODO: コンポーネント生成時に1回だけ実行させる(以下の他のリストも同様）
  // ItemList
  const hourList = React.useMemo(() => {
    const hourPerDay = 24; //24時間
    let hourList: MenuItemProps[] = [];
    for (let i = 0; i < hourPerDay; i++) {
      hourList.push({
        key: i,
        value: String(i),
      });
    }
    return hourList;
  }, []);

  const minuteList = React.useMemo(() => {
    const minPerHour = 60; //60分
    let minuteList: MenuItemProps[] = [];
    for (let i = 0; i < minPerHour / 1; i++) {
      minuteList.push({
        key: 1 * i,
        value: String(1 * i),
      });
    }
    return minuteList;
  }, [careRecord]);

  const toiletTypeList = React.useMemo(() => {
    const array = Object.entries(i18n.language === 'ja' ? TOILET_TYPE_JA : TOILET_TYPE_EN);
    return array.map((v) => {
      return { key: v[0], value: v[1] };
    });
  }, []);

  const booleanButtonList = [
    { key: true, value: t('yes', '有') },
    { key: false, value: t('no', '無') },
  ];

  const padTypeList = React.useMemo(() => {
    const array = Object.entries(i18n.language === 'ja' ? PAD_TYPE_JA : PAD_TYPE_EN);
    return array.map((v) => {
      return { key: v[0], value: v[1] };
    });
  }, []);

  const padUrineVolumeTypeList = React.useMemo(() => {
    const array = Object.entries(i18n.language === 'ja' ? URINE_TYPE_JA : URINE_TYPE_EN);
    return array.map((v) => {
      return { key: v[0], value: v[1] };
    });
  }, []);

  const defecationVolumeTypeList = React.useMemo(() => {
    const array = Object.entries(i18n.language === 'ja' ? DEFECATION_TYPE_JA : DEFECATION_TYPE_EN);
    return array.map((v) => {
      return { key: v[0], value: v[1] };
    });
  }, []);

  const hardnessTypeList = React.useMemo(() => {
    const array = Object.entries(i18n.language === 'ja' ? HARDNESS_TYPE_JA : HARDNESS_TYPE_EN);
    return array.map((v) => {
      return { key: v[0], value: v[1] };
    });
  }, []);

  const pantsList = React.useMemo(() => {
    if (!nursingHomePants) return [];
    return nursingHomePants.map((v) => {
      return { key: v.id, value: v.diaperName };
    });
  }, [nursingHomePants]);

  const padsList = React.useMemo(() => {
    if (!nursingHomePads) return [];
    return nursingHomePads.map((v) => {
      return { key: v.id, value: v.diaperName };
    });
  }, [nursingHomePads]);

  const laxativeList = React.useMemo(() => {
    if (!laxatives) return [];
    return laxatives.map((v) => {
      return { key: v.id, value: v.name };
    });
  }, [laxatives]);

  const drinkList = React.useMemo(() => {
    if (!drinks) return [];
    const list = drinks.map((v) => {
      return { key: v.id, value: v.name };
    });
    list.push({ key: 0, value: t('other', 'その他') });
    return list;
  }, [drinks]);

  const drinkVolumeList = [
    { key: 150, value: '150ml' },
    { key: 200, value: '200ml' },
    { key: 0, value: t('other', 'その他') },
  ];

  // initialValue
  const initialHour = React.useMemo(() => {
    if (!hourList) return undefined;
    if (!careRecord || !careRecord.timestamp) {
      setHour(+hourList[initialDate.hour()].key);
      return hourList[initialDate.hour()];
    } else {
      setHour(dayjs(careRecord.timestamp).hour());
      return hourList[dayjs(careRecord.timestamp).hour()];
    }
  }, [hourList, careRecord]);

  const initialMinute = React.useMemo(() => {
    const interval = 1; //1分
    if (!minuteList) return undefined;
    if (!careRecord || !careRecord.timestamp) {
      const index = Math.floor(initialDate.minute() / interval);
      setMinute(+minuteList[index].key);
      return minuteList[index];
    } else {
      const index = minuteList.findIndex((v) => {
        return v.key === dayjs(careRecord.timestamp).minute();
      });
      if (index) {
        setMinute(dayjs(careRecord.timestamp).minute());
        return minuteList[index];
      } else {
        setMinute(dayjs(careRecord.timestamp).minute());
        return minuteList[0];
      }
    }
  }, [minuteList, careRecord]);

  // formControl
  const isDisabledIsUrinatedToiletForm = React.useMemo(() => {
    return !toiletType;
  }, [careRecord, toiletType]);

  const isDisableToiletVolumeForm = React.useMemo(() => {
    return !toiletType || !isUrinatedToilet;
  }, [careRecord, toiletType, isUrinatedToilet]);

  const isDisabledPadUrineVolumeTypeForm = React.useMemo(() => {
    return !padType;
  }, [careRecord, padType]);

  const isDisabledPadUrineVolumeForm = React.useMemo(() => {
    return !padType || !padUrineVolumeType || padUrineVolumeType === 'none';
  }, [careRecord, padType, padUrineVolumeType]);

  const isDisabledHardnessTypeForm = React.useMemo(() => {
    return !defecationVolumeType;
  }, [careRecord, defecationVolumeType]);

  const isDisabledPad2IdForm = React.useMemo(() => {
    return !pad1Id;
  }, [careRecord, pad1Id]);

  const isDisabledLaxativeAmountForm = React.useMemo(() => {
    return !laxativeId;
  }, [careRecord, laxativeId]);

  const isDisabledOtherDrinkForm = React.useMemo(() => {
    return drinkId?.key !== 0 && !careRecord?.drinkingOther;
  }, [careRecord, drinkId]);

  const isDisabledDrinkVolumeForm = React.useMemo(() => {
    return drinkVolumeType !== 0 && !careRecord?.drinkingVolume;
  }, [careRecord, drinkVolumeType]);

  // form-change-event and validationCheck
  const handleChangeDate = (date: Dayjs) => {
    setDate(date);
    setDateError(false);
  };

  const handleChangeHour = (hour: MenuItemProps) => {
    setHour(+hour.key);
    setDateError(false);
  };

  const handleChangeMinute = (min: MenuItemProps) => {
    setMinute(+min.key);
    setDateError(false);
  };

  const handleChangeStaffName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStaffName(e.target.value);
  };

  const handleChangeWorkingTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkingTime(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangeToiletType = (data: MenuItemProps) => {
    setToiletType(data);
    if (isUrinatedToilet === null) setIsUrinatedToilet(true);
  };

  const handleChangeIsUrinatedToilet = (data: string | number | boolean | null) => {
    setIsUrinatedToilet(data === 'true' ? true : data === 'false' ? false : null);
    if (!data || String(data) === 'false') setToiletVolume('');
  };

  const handleChangeToiletVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToiletVolume(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangePadType = (data: MenuItemProps) => {
    setPadType(data);
    if (padUrineVolumeType === null) setPadUrineVolumeType('medium');
  };

  const handleChangePadUrineVolumeType = (data: string | number | boolean | null) => {
    setPadUrineVolumeType(data ? String(data) : null);
    if (!data || data === 'none') setPadUrineVolume('');
  };

  const handleChangePadUrineVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPadUrineVolume(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangeIsUrgency = (data: string | number | boolean | null) => {
    setIsUrgency(data === 'true' ? true : data === 'false' ? false : null);
  };

  const handleChangeDefecationVolumeType = (data: string | number | boolean | null) => {
    setDefecationVolumeType(data ? String(data) : null);
    if (!data) setHardnessType(null);
  };

  const handleChangeHardnessType = (data: string | number | boolean | null) => {
    setHardnessType(data ? String(data) : null);
  };

  const handleChangePantId = (data: MenuItemProps) => {
    setPantId(data);
  };

  const handleChangePad1Id = (data: MenuItemProps) => {
    setPad1Id(data);
  };

  const handleChangePad2Id = (data: MenuItemProps) => {
    setPad2Id(data);
  };

  const handleChangeLaxativeId = (data: MenuItemProps) => {
    setLaxativeId(data);
  };

  const handleChangeLaxativeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLaxativeAmountError(false);
    setLaxativeAmountErrorText('');
    setLaxativeAmount(numBetweenMinMax(+e.target.value, 1, 9999));
  };

  const handleChangeDrinkId = (data: MenuItemProps) => {
    setDrinkId(data);
    if (data.key !== 0) {
      setOtherDrink('');
      setOtherDrinkErrorText('');
      setOtherDrinkError(false);
    }
  };

  const handleChangeOtherDrink = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherDrinkError(false);
    setOtherDrinkErrorText('');
    setOtherDrink(e.target.value);
  };

  const handleChangeDrinkVolumeType = (data: string | number | boolean | null) => {
    setDrinkVolumeType(data ? +data : '');
    if (data === '0') setDrinkVolume(100);
    else if (data !== '0') setDrinkVolume('');
  };

  const handleChangeDrinkVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDrinkVolume(numBetweenMinMax(+e.target.value, 0, 9999));
  };

  const handleChangeMemo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemo(e.target.value);
  };

  const handleClickSubmit = async () => {
    // バリデーションにエラーがあるかどうか（trueでエラー有り）
    let hasValidationErrors = false;

    // 各formからデータを収集
    let sendData: ICreateCareRecordParams = {
      residentId: +residentId,
      timestamp: date
        .local()
        .set('hour', hour!)
        .set('minute', minute!)
        .set('second', 0)
        .utc()
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      staffName,
      workingTime,
      toiletType: toiletType?.key ? String(toiletType?.key) : null,
      urinatedToilet: isUrinatedToilet,
      toiletVolume: toiletVolume ? +toiletVolume : null,
      padType: padType?.key ? String(padType?.key) : null,
      padUrineVolumeType,
      padUrineVolume: padUrineVolume ? +padUrineVolume : null,
      isUrgency,
      defecationVolumeType,
      hardnessType,
      diaper: {
        nursingHomeDiaperId: pantId?.key ? +pantId.key : null,
        nursingHomePadIdFirst: pad1Id?.key ? +pad1Id?.key : null,
        nursingHomePadIdSecond: pad2Id?.key ? +pad2Id.key : null,
      },
      laxativeId: laxativeId?.key ? +laxativeId.key : null,
      laxativeAmount: laxativeAmount ? +laxativeAmount : null,
      drinkingId: drinkId?.key ? +drinkId.key : null,
      drinkingOther: otherDrink ? otherDrink : null,
      drinkingVolume: drinkVolumeType ? drinkVolumeType : drinkVolume ? +drinkVolume : null,
      memo,
    };

    // トイレかおむつのどちらかの排尿の記録がされた場合は片方が未選択でも無しとして補完する
    // また排尿の記録がある場合にはtoiletTypeとpadTypeも補完する
    sendData.urinatedToilet =
      sendData.urinatedToilet !== null ? sendData.urinatedToilet : sendData.padUrineVolumeType === null ? null : false;
    sendData.padUrineVolumeType =
      sendData.padUrineVolumeType !== null
        ? sendData.padUrineVolumeType
        : sendData.urinatedToilet === null
        ? null
        : 'none';
    sendData.toiletType = sendData.urinatedToilet === null && sendData.padUrineVolumeType === null ? null : 'toilet';
    sendData.padType = sendData.urinatedToilet === null && sendData.padUrineVolumeType === null ? null : 'pad';

    const nullCheckKeys: Array<keyof ICreateCareRecordParams> = [
      'toiletType',
      'padType',
      'isUrgency',
      'defecationVolumeType',
      'laxativeId',
      'drinkingId',
      'drinkingVolume',
    ];

    const diaperNullCheckKeys: Array<keyof ICareRecordDiaper> = [
      'nursingHomeDiaperId',
      'nursingHomePadIdFirst',
      'nursingHomePadIdSecond',
    ];

    // 送信データが日付、作業記録、空のパンツ情報しかなければデータを送信しない
    const isSendDataEmpty = nullCheckKeys.every((keys) => sendData[keys] === null);
    const isDiaperDataEmpty = diaperNullCheckKeys.every((key) => sendData.diaper![key] === null);
    if (isSendDataEmpty && isDiaperDataEmpty && !sendData?.memo) {
      showSnackbar(t('thereIsNoNewRecordData', '登録する記録がありません'), 'error');
      return;
    }

    // バリデーションチェック
    if (dayjs().diff(dayjs(sendData.timestamp)) < 0) {
      setDateError(true);
      hasValidationErrors = true;
    }

    if (drinkId?.key === 0 && !sendData.drinkingOther) {
      setOtherDrinkError(true);
      setOtherDrinkErrorText(t('drinkNameIsRequired', '飲料名は必須入力です'));
      hasValidationErrors = true;
    }

    if (laxativeId?.key && !sendData.laxativeAmount) {
      setIsLaxativeAmountError(true);
      setLaxativeAmountErrorText(t('enterTheAmountOfLaxative', '下剤量を入力してください'));
      hasValidationErrors = true;
    }

    if (hasValidationErrors) {
      showSnackbar(t('invalidFields', '入力内容に不備があります'), 'error');
      return;
    }

    setOnSend(true);
    if (careRecordId && careRecord?.residentId === +residentId) {
      const data = { ...sendData, careRecordId: careRecord.diaper.careRecordId };
      await updateCareRecord(data).then((res) => {
        setOnSend(false);
        showSnackbar(t('detailedRecordPlus', '詳細記録＋') + t('isEdited', 'の編集が完了しました'), 'success');
        history.push(`/nursinghome/${nursingHomeId}/residents/${residentId}/care-record`);
      });
    } else {
      await createCareRecord(sendData).then(async (res) => {
        // トイレかおむつ・パッドの記録が有る場合は排泄されたとみなしグラフを落として、通知カバーも除去する
        if (sendData.urinatedToilet || (sendData.padUrineVolumeType && sendData.padUrineVolumeType !== 'none')) {
          await updateUrineVolumeReset(sendData.residentId).catch((err) => {
            console.log(err);
          });
          setTimeout(() => {
            handleResetNotification(sendData.residentId);
          }, 500);
        }
        setOnSend(false);
        showSnackbar(t('detailedRecordPlus', '詳細記録＋') + t('isAdded', 'の登録が完了しました'), 'success');
        history.push(`/nursinghome/${nursingHomeId}/residents/${residentId}/care-record`);
      });
    }
  };

  // 通知アラートの消去とバッヂカウントの更新処理
  const handleResetNotification = React.useCallback(
    async (residentId: number) => {
      const resident = await api.get('/residents/:residentId', { params: { residentId } });
      const params = {
        ...resident,
        uncaredBigBladder: false,
        uncaredGotUp: false,
        uncaredUrination: false,
        uncaredSensorError: false,
        uncaredUrineReset: false,
        uncaredZeroReset: false,
        uncaredUpsideDown: false,
      };
      await api.put('/residents/:residentId', { residentId, ...params });
      if (badge.resident > 0) {
        dispatch(navSlice.actions.setResidentBadge(-1));
      }
    },
    [badge, nursingHomeId]
  );

  React.useEffect(() => {
    showLoading();
    if (resident) {
      hideLoading();
    }
  }, [resident]);

  // initialize-selector-form-value

  // toiletType
  React.useEffect(() => {
    if (!toiletTypeList || !careRecord) return;
    const result = toiletTypeList.find((v) => {
      return v.key === careRecord.toiletType;
    });
    setToiletType(result ?? null);
  }, [careRecord, toiletTypeList]);

  // padType
  React.useEffect(() => {
    if (!padTypeList || !careRecord) return;
    const result = padTypeList.find((v) => {
      return v.key === careRecord.padType;
    });
    setPadType(result ?? null);
  }, [careRecord, padTypeList]);

  // diaperId
  React.useEffect(() => {
    if (!pantsList || !careRecord) return;
    const result = pantsList.find((v) => {
      return v.key === careRecord.diaper.nursingHomeDiaperId;
    });
    setPantId(result ?? null);
  }, [careRecord, pantsList]);

  // PadIdFirst PadIdSecond
  React.useEffect(() => {
    if (!padsList || !careRecord) return;
    const pad1 = padsList.find((v) => {
      return v.key === careRecord.diaper.nursingHomePadIdFirst;
    });
    const pad2 = padsList.find((v) => {
      return v.key === careRecord.diaper.nursingHomePadIdSecond;
    });
    setPad1Id(pad1 ?? null);
    setPad2Id(pad2 ?? null);
  }, [careRecord, padsList]);

  // LaxativeId
  React.useEffect(() => {
    if (!laxativeList || !careRecord) return;
    const result = laxativeList.find((v) => {
      return v.key === careRecord.laxativeId;
    });
    setLaxativeId(result ?? null);
  }, [careRecord, laxativeList]);

  // drinkingId
  React.useEffect(() => {
    if (!drinkList || !careRecord) return;
    if (careRecord.drinkingOther) setDrinkId(drinkList[drinkList.length - 1]);
    else {
      const result = drinkList.find((v) => {
        return v.key === careRecord.drinkingId;
      });
      setDrinkId(result ?? null);
    }
  }, [careRecord, drinkList]);

  // Editの場合のsetter初期値設定
  React.useEffect(() => {
    showLoading();
    if (!careRecord || careRecord.residentId != +residentId) return;
    setDate(dayjs(careRecord.timestamp));
    // setHour(dayjs(careRecord.timestamp).hour()); //initialValueで定義
    // setMinute(dayjs(careRecord.timestamp).minute()); //initialValueで定義
    setStaffName(careRecord.staffName);
    setWorkingTime(careRecord.workingTime);
    setIsUrinatedToilet(careRecord.urinatedToilet);
    setToiletVolume(careRecord.toiletVolume);
    setPadUrineVolumeType(careRecord.padUrineVolumeType);
    setPadUrineVolume(careRecord.padUrineVolume);
    setIsUrgency(careRecord.isUrgency);
    setDefecationVolumeType(careRecord.defecationVolumeType);
    setHardnessType(careRecord.hardnessType);
    setLaxativeAmount(careRecord.laxativeAmount);
    setOtherDrink(careRecord.drinkingOther);
    setDrinkVolumeType(careRecord.drinkingVolume ? 0 : ''); // TODO: 150と200は除外
    setDrinkVolume(careRecord.drinkingVolume);
    setMemo(careRecord.memo);
    hideLoading();
  }, [careRecord]);

  return (
    <PanelContainer title={t('detailedRecordPlus', '詳細記録＋')}>
      <>
        {resident && <div css={Title}>{resident?.lastName + ' ' + resident?.name}</div>}
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('datetime', '日時')}</div>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W200}>
              <DateSelector
                newValue={date}
                minDate={initialDate.subtract(3, 'years')}
                maxDate={initialDate}
                variant={'outlined'}
                onChange={handleChangeDate}
              />
            </div>
            <div css={W200}>
              <Select
                id='hour-selector'
                listData={hourList}
                value={initialHour}
                label={t('hour', '時')}
                fullWidth={true}
                onChange={handleChangeHour}
              />
            </div>
            <div css={W200}>
              <Select
                id='minute-selector'
                listData={minuteList}
                value={initialMinute}
                label={t('min', '分')}
                fullWidth={true}
                onChange={handleChangeMinute}
              />
            </div>
            {isDateError && (
              <div css={ErrorText}>
                {t('"Future date and time cannot be specified."', '未来の日時は指定できません。')}
              </div>
            )}
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('workRecord', '作業記録')}</div>
          <div css={[ContentRight, AlignItems, Wrap, BottomLine]}>
            <div css={W200}>
              <TextField
                sx={{ background: '#fff' }}
                id={'staffname-field'}
                label={t('staffName', '担当職員')}
                value={staffName ?? ''}
                onChange={handleChangeStaffName}
                fullWidth={true}
              />
            </div>
            <div css={[W200, AlignItems]}>
              <TextField
                sx={{ background: '#fff' }}
                id={'workingtime-field'}
                label={t('workingTime', 'ケア時間')}
                value={workingTime ?? ''}
                onChange={handleChangeWorkingTime}
                fullWidth={true}
              />
              <span css={Unit}>{t('minutes', '分')}</span>
            </div>
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('urine', '排尿')}</div>
          <div css={Wmax}>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={W200}>
                <Select
                  id='toilet-type-selector'
                  listData={toiletTypeList}
                  value={toiletType}
                  label={t('toiletPlace', 'トイレの場所')}
                  fullWidth={true}
                  onChange={handleChangeToiletType}
                />
              </div>
              <div css={[W150, ContentMargin]}>
                <div>{t('isUrinated', '排尿有無')}</div>
                <CareRecordButtonGroup
                  data={booleanButtonList}
                  newValue={isUrinatedToilet}
                  isDisabled={isDisabledIsUrinatedToiletForm}
                  mode={'radio'}
                  onChange={handleChangeIsUrinatedToilet}
                ></CareRecordButtonGroup>
              </div>
              <div css={[W200, AlignItems]}>
                <TextField
                  id={'toilet-volume-field'}
                  label={t('toilet', 'トイレ') + ' ' + t('urineAmount', '排尿量')}
                  type={'number'}
                  value={toiletVolume ?? ''}
                  disabled={isDisableToiletVolumeForm}
                  onChange={handleChangeToiletVolume}
                  fullWidth={true}
                />
                <span css={Unit}>ml</span>
              </div>
            </div>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={W200}>
                <Select
                  id='pad-type-selector'
                  listData={padTypeList}
                  value={padType}
                  label={t('padType', 'パッド種別')}
                  fullWidth={true}
                  onChange={handleChangePadType}
                />
              </div>
              <div css={[W300, ContentMargin]}>
                <div>{t('urineAmount', '排尿量')}</div>
                <CareRecordButtonGroup
                  data={padUrineVolumeTypeList}
                  isDisabled={isDisabledPadUrineVolumeTypeForm}
                  newValue={padUrineVolumeType}
                  mode={'radio'}
                  onChange={handleChangePadUrineVolumeType}
                ></CareRecordButtonGroup>
              </div>
              <div css={[W200, AlignItems]}>
                <TextField
                  id={'pad-volume-field'}
                  label={t('pad', 'パッド') + ' ' + t('urineAmount', '排尿量')}
                  type={'number'}
                  value={padUrineVolume ?? ''}
                  disabled={isDisabledPadUrineVolumeForm}
                  onChange={handleChangePadUrineVolume}
                  fullWidth={true}
                />
                <span css={Unit}>ml</span>
              </div>
            </div>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={[W300, ContentMargin]}>
                <div>{t('isUrgency', '切迫感')}</div>
                <CareRecordButtonGroup
                  data={booleanButtonList}
                  newValue={isUrgency}
                  onChange={handleChangeIsUrgency}
                ></CareRecordButtonGroup>
              </div>
            </div>
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('stool', '排便')}</div>
          <div css={Wmax}>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={W400}>
                <div>{t('defecation', '排便量')}</div>
                <CareRecordButtonGroup
                  data={defecationVolumeTypeList}
                  newValue={defecationVolumeType}
                  onChange={handleChangeDefecationVolumeType}
                ></CareRecordButtonGroup>
              </div>
            </div>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={[Wmax, ContentMargin]}>
                <div>{t('defecationHardnessType', '排便形態')}</div>
                <CareRecordButtonGroup
                  data={hardnessTypeList}
                  newValue={hardnessType}
                  isDisabled={isDisabledHardnessTypeForm}
                  onChange={handleChangeHardnessType}
                ></CareRecordButtonGroup>
              </div>
            </div>
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('use', '交換')}</div>
          <div css={Wmax}>
            <div css={[ContentRight, AlignItems]}>
              <div css={[W100, AlignItems]}>
                <img css={Image} src='/images/recordPlus/pant.svg' alt='' />
                <span css={TableLabel}>{t('diaperPants', 'テープ/パンツ')}</span>
              </div>
              <div css={Wmax}>
                <Select
                  id='pant-selector'
                  listData={pantsList}
                  value={pantId}
                  label={t('diaperPants', 'テープ/パンツ')}
                  fullWidth={true}
                  onChange={handleChangePantId}
                />
              </div>
            </div>
            <div css={[ContentRight, ContentWrapper, AlignItems]}>
              <div css={[W100, AlignItems]}>
                <img css={Image} src='/images/recordPlus/pad.svg' alt='' />
                <span css={TableLabel}>{t('pad', 'パッド')}</span>
              </div>
              <div css={Wmax}>
                <div css={Wmax}>
                  <Select
                    id='pad-1-selector'
                    listData={padsList}
                    value={pad1Id}
                    label={t('firstPad', 'パッド1枚目')}
                    fullWidth={true}
                    onChange={handleChangePad1Id}
                  />
                </div>
                <div css={Wmax}>
                  <Select
                    id='pad-2-selector'
                    listData={padsList}
                    value={pad2Id}
                    label={t('secondPad', 'パッド2枚目')}
                    fullWidth={true}
                    disabled={isDisabledPad2IdForm}
                    onChange={handleChangePad2Id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('laxative', '下剤')}</div>
          <div css={[ContentRight, AlignItems, Wrap]}>
            <div css={W200}>
              <Select
                id='laxative-selector'
                listData={laxativeList}
                value={laxativeId}
                label={t('laxativeName', '下剤名')}
                fullWidth={true}
                onChange={handleChangeLaxativeId}
              />
            </div>
            <div css={W200}>
              <TextField
                id={'laxative-amount-field'}
                label={t('laxativeAmount', '下剤量')}
                type={'number'}
                value={laxativeAmount ?? ''}
                disabled={isDisabledLaxativeAmountForm}
                error={isLaxativeAmountError}
                helperText={laxativeAmountErrorText}
                onChange={handleChangeLaxativeAmount}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={ContentLeft}>{t('hydration', '飲水')}</div>
          <div css={Wmax}>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={W200}>
                <Select
                  id='drink-type-selector'
                  listData={drinkList}
                  value={drinkId}
                  label={t('drinkName', '飲水名')}
                  fullWidth={true}
                  onChange={handleChangeDrinkId}
                />
              </div>
              <div css={W200}>
                <TextField
                  id={'other-drink-field'}
                  label={t('other', 'その他')}
                  value={otherDrink ?? ''}
                  disabled={isDisabledOtherDrinkForm}
                  error={isOtherDrinkError}
                  helperText={otherDrinkErrorText}
                  onChange={handleChangeOtherDrink}
                  fullWidth={true}
                />
              </div>
            </div>
            <div css={[ContentRight, AlignItems, Wrap]}>
              <div css={[W300, ContentMargin]}>
                <div>{t('drinkingVolume', '飲水量')}</div>
                <CareRecordButtonGroup
                  data={drinkVolumeList}
                  onChange={handleChangeDrinkVolumeType}
                  newValue={drinkVolumeType}
                ></CareRecordButtonGroup>
              </div>
              <div css={[W200, AlignItems]}>
                <TextField
                  id={'drink-volume-field'}
                  label={t('drinkingVolume', '飲水量')}
                  type={'number'}
                  value={drinkVolume ?? ''}
                  disabled={isDisabledDrinkVolumeForm}
                  onChange={handleChangeDrinkVolume}
                  fullWidth={true}
                />
                <span css={Unit}>ml</span>
              </div>
            </div>
          </div>
        </div>
        <div css={ContentWrapper}>
          <div css={[ContentLeft, BottomLine]}>{t('memoDetailRecord', '経過記録・メモ')}</div>
          <div css={[ContentRight, AlignItems, Wrap, BottomLine]}>
            <div css={[Wmax, ContentMargin]}>
              <TextField
                sx={{ background: '#fff' }}
                id={'memo-field'}
                label={t('memoDetailRecord', '経過記録・メモ')}
                value={memo ?? ''}
                onChange={handleChangeMemo}
                fullWidth={true}
                multiline
                rows={4}
              />
            </div>
          </div>
        </div>
        <div css={[ButtonWrapper]}>
          <Button variant={'contained'} onClick={handleClickSubmit} disabled={onSend}>
            {t('record', '記録する')}
          </Button>
        </div>
      </>
    </PanelContainer>
  );
});
