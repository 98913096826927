import React from 'react';
import { css } from '@emotion/react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useLoading } from '../../../../contexts/LoadingContext';
import { useFetchAnalyticsMonthly } from '../../../../hooks/api/traversal/useFetchAnalyticsMonthly';
import { AnalyticsMonthlyTable } from './AnalyticsMonthlyTable';
import { useFetchCareSchedule } from '../../../../hooks/api/resident/useFetchCareSchedule';

dayjs.extend(isBetween);

interface Props {
  residentId: string;
}

export interface AnalyticsData {
  label: string;
  timestamp: Dayjs;
  dataList:
    | {
        day: string;
        halfhourPerDay?: number;
        data: {
          notification: string;
          excretion: string;
        };
      }[]
    | null;
}

const BodyContainer = css`
  padding: 16px 24px 24px;
`;

const ScrollContainer = css`
  overflow-x: auto;
`;

const DatepickerContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 60px;
  margin-bottom: 16px;
`;

export const ResidentDetailMonthly: React.FC<Props> = ({ residentId }) => {
  const { showLoading, hideLoading } = useLoading();
  const [range, setRange] = React.useState<{ start: dayjs.Dayjs; end: dayjs.Dayjs }>({
    start: dayjs().subtract(1, 'month').local().set('hour', 0).set('minute', 0).set('second', 0).utc(),
    end: dayjs().local().set('hour', 23).set('minute', 59).set('second', 59).utc(),
  });
  const { analyticsMonthly, fetchAnalyticsMonthly } = useFetchAnalyticsMonthly(
    true,
    +residentId,
    range.start.format(),
    range.end.format()
  );
  const careTimings = useFetchCareSchedule(+residentId).map((v) =>
    dayjs()
      .utc()
      .set('hour', +v.slice(0, 2))
      .local()
      .hour()
  );
  const [analyticsData, setAnalyticsData] = React.useState<AnalyticsData[]>([]);

  React.useEffect(() => {
    if (!range) return;
    (async () => {
      showLoading();
      await fetchAnalyticsMonthly(
        +residentId,
        range.start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        range.end.format('YYYY-MM-DDTHH:mm:ss[Z]')
      );
      hideLoading();
    })();
  }, [range]);

  React.useEffect(() => {
    if (!analyticsMonthly) return;
    const dateArray = [];
    const days = range.end.diff(dayjs(range.start), 'day');
    let prevDate: dayjs.Dayjs = range.start.local();
    for (let i = 1; i <= days + 1; i++) {
      const currentDate = range.start.add(i, 'day').local();
      const analytics = analyticsMonthly.monthly.filter((v) => {
        return dayjs(v.day).isBetween(prevDate, currentDate);
      });
      const dataList = analytics.map((v) => {
        return { ...v, halfhourPerDay: dayjs(v.day).hour() * 2 + dayjs(v.day).minute() / 30 };
      });
      dateArray.push({
        timestamp: currentDate,
        label: prevDate.format('MM/DD'),
        dataList,
      });
      prevDate = currentDate;
    }
    setAnalyticsData(dateArray);
  }, [analyticsMonthly]);

  const handleChangeStart = React.useCallback((start) => {
    setRange((prev) => {
      return {
        ...prev,
        start: dayjs(start.format('YYYY-MM-DD')).local().set('hour', 0).set('minute', 0).set('second', 0).utc(),
      };
    });
  }, []);

  const handleChangeEnd = React.useCallback((end) => {
    setRange((prev) => {
      return {
        ...prev,
        end: dayjs(end.format('YYYY-MM-DD')).local().set('hour', 23).set('minute', 59).set('second', 59).utc(),
      };
    });
  }, []);

  return (
    <div css={BodyContainer}>
      <div css={DatepickerContainer}>
        <DatePicker
          views={['year', 'month', 'day']}
          value={range.start.add(1, 'day')}
          maxDate={range.end}
          minDate={dayjs().subtract(3, 'month')}
          onChange={handleChangeStart}
          renderInput={(params) => <TextField variant={'standard'} {...params} helperText={null} />}
        />
        〜
        <DatePicker
          views={['year', 'month', 'day']}
          value={range.end}
          minDate={range.start.add(1, 'day')}
          maxDate={dayjs()}
          onChange={handleChangeEnd}
          renderInput={(params) => <TextField variant={'standard'} {...params} helperText={null} />}
        />
      </div>
      <div css={ScrollContainer}>
        <AnalyticsMonthlyTable
          date={{
            start: range.start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: range.end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
          }}
          data={analyticsData}
          careTimings={careTimings}
        />
      </div>
    </div>
  );
};
