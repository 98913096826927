import React from 'react';
import { css } from '@emotion/react';
import { OrderTableContents } from './OrderTableContents';
import { OrderTitle } from './OrderTitle';
import { Diaper } from '@/types/api/order/getOrders';

interface Props {
  title: string;
  diapers: Diaper[];
  isConfirm: boolean;
  isExpendable?: boolean;
  onAmountChange: (diaper: Diaper, amount: number, type: 'inner' | 'outer' | 'expendable') => void;
}

const TableTitle = css`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #ededed;
  min-height: 40px;
  div:first-of-type {
    display: flex;
    align-items: center;
    /* flex-basis: 320px; */
  }
  div:not(:first-of-type) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div:not(:last-of-type) {
    border-right: 1px solid #cccccc;
  }
`;

const TableContainer = css`
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const TableTitleItem = css`
  font-size: 14px;
  height: 40px;
`;

const TableContents = css`
  display: flex;
  height: 86px;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 40px;
  div:first-of-type {
    display: flex;
    align-items: center;
  }
  div:not(:first-of-type) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:not(:last-of-type) {
    border-right: 1px solid #cccccc;
  }
`;

const LeftItem = css`
  margin-left: 24px;
`;

const Last = css`
  border-bottom: none;
`;

// テーブルのセルの横幅を左から順に定義
export const OrderCellWidth = [
  { flex: '1 1 260px' },
  { flex: '1 1 70px' },
  { flex: '1 1 180px' },
  { flex: '1 1 160px' },
  { flex: '1 1 80px' },
];

// 消耗品テーブルのセルの横幅を左から順に定義
export const ExpendableOrderCellWidth = [{ flex: '3 3 480px' }, { flex: '2 2 220px' }];

export const OrderTable = React.memo(({ title, diapers, isConfirm, isExpendable = false, onAmountChange }: Props) => {
  const amounts = React.useMemo(() => {
    let list: { key: number; value: string }[] = [];
    for (let i = 0; i <= 20; i++) {
      list = [...list, { key: i, value: `${i}ケース` }];
    }
    return list;
  }, []);

  const expendableAmounts = React.useMemo(() => {
    let list: { key: number; value: string }[] = [];
    for (let i = 0; i <= 20; i++) {
      list = [...list, { key: i, value: `${i}個` }];
    }
    return list;
  }, []);

  const filteredDiapers = React.useMemo(() => {
    if (!isConfirm) return diapers;
    return diapers.filter((diaper) => diaper.amount);
  }, [isConfirm, diapers]);

  return (
    <div>
      <OrderTitle title={title} />
      {filteredDiapers.length ? (
        <div css={TableContainer}>
          {isExpendable ? (
            <div css={TableTitle}>
              <div css={[TableTitleItem, LeftItem, ExpendableOrderCellWidth[0]]}>製品名/製品情報</div>
              <div css={[TableTitleItem, ExpendableOrderCellWidth[1]]}>発注数</div>
            </div>
          ) : (
            <div css={TableTitle}>
              <div css={[TableTitleItem, LeftItem, OrderCellWidth[0]]}>製品名/製品情報</div>
              <div css={[TableTitleItem, OrderCellWidth[1]]}>サイズ</div>
              <div css={[TableTitleItem, OrderCellWidth[2]]}>現在庫数</div>
              <div css={[TableTitleItem, OrderCellWidth[3]]}>発注ケース数</div>
              <div css={[TableTitleItem, OrderCellWidth[4]]}>発注袋数</div>
            </div>
          )}

          {filteredDiapers.map((diaper, i) => (
            <div css={i === filteredDiapers.length - 1 ? [TableContents, Last] : TableContents} key={i}>
              <OrderTableContents
                isConfirm={isConfirm}
                isExpendable={isExpendable}
                diaper={diaper}
                amounts={isExpendable ? expendableAmounts : amounts}
                onAmountChange={onAmountChange}
              />
            </div>
          ))}
        </div>
      ) : (
        <div>{title}の発注はありません</div>
      )}
    </div>
  );
});
