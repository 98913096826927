import React from 'react';
import { color } from '../../../styles';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';

interface Props {
  value?: boolean; //外部から値を制御したい場合に
  children?: React.ReactNode;
  onClick: (isChecked: boolean) => void;
}

interface CheckButtonProps {
  checked: boolean;
}

const StyledButton = styled(Button)<CheckButtonProps>`
  min-width: 48px;
  width: 100%;
  flex-grow: 1;
  background: ${color.white};
  border-radius: 8px;
  border: 1px solid ${color.border_gray};
  color: ${(props) => (props.checked ? color.white : color.dark_gray)};
  background: ${(props) => (props.checked ? color.dark_gray : color.white)};
  box-shadow: none;
  &:hover {
    color: ${color.white};
    background: ${color.gray};
  }
`;

export const CheckButton = React.memo(({ value = false, children = '', onClick }: Props) => {
  const [isChecked, setChecked] = React.useState<boolean>(value);

  const handleClick = () => {
    setChecked(!isChecked);
    onClick(!isChecked);
  };

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <StyledButton checked={isChecked} onClick={handleClick} variant={'contained'}>
      {children}
    </StyledButton>
  );
});
