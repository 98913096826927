import React from 'react';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { PanelContainer } from '../../../components/panel';
import { useFetchEquipments } from '../../../hooks/api/traversal/useFetchEquipments';
import { formatUtcFetchTimestamp } from '../../../utils/dateutil';
import { numInsertComma } from '../../../utils/dataFormatUtil';
import { EquipmentsTable } from './equipmentsTable';

const EmptyContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 64px 32px;
  font-size: 16px;
`;

const TopContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 16px 0;
`;

const DateRangePicker = css`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const BoldText = css`
  font-size: 16px;
  font-weight: bold;
`;

const SubTitleText = css`
  font-size: 16px;
`;

export const AnalysisEquipments = React.memo(() => {
  const rangeMinDate = dayjs().local().startOf('day').subtract(3, 'year').utc();
  const rangeMaxDate = dayjs().local().endOf('day').utc();
  const { equipments, equipmentDiapers, equipmentPads, equipmentLaxatives, fetchEquipments } = useFetchEquipments();
  const [fetchTimestamp, setFetchTimestamp] = React.useState<{ start: Dayjs; end: Dayjs }>({
    start: dayjs().local().startOf('day').subtract(1, 'month').utc(),
    end: dayjs().local().endOf('day').utc(),
  });

  const diaperCost = React.useMemo(() => {
    let total = 0;
    equipmentDiapers?.forEach((v) => (total += v.totalCost));
    return total;
  }, [equipmentDiapers]);

  const padCost = React.useMemo(() => {
    let total = 0;
    equipmentPads?.forEach((v) => (total += v.totalCost));
    return total;
  }, [equipmentPads]);

  const handleChangeStartTime = (start: Dayjs | null) => {
    if (!start) return;
    setFetchTimestamp((prev) => {
      return { ...prev, start: start?.utc() };
    });
  };

  const handleChangeEndTime = (end: Dayjs | null) => {
    if (!end) return;
    setFetchTimestamp((prev) => {
      return { ...prev, end: end?.utc() };
    });
  };

  React.useEffect(() => {
    if (!fetchTimestamp?.start || !fetchTimestamp?.end) return;
    fetchEquipments(fetchTimestamp.start.utc().format(), fetchTimestamp.end.utc().format());
  }, [fetchTimestamp]);

  return (
    <>
      <PanelContainer title={'消耗品利用状況 - 製品種類・サイズ別'}>
        <div css={TopContainer}>
          <div css={DateRangePicker}>
            <DatePicker
              views={['year', 'month', 'day']}
              label={'開始日'}
              value={fetchTimestamp?.start}
              minDate={rangeMinDate}
              maxDate={fetchTimestamp?.end.subtract(1, 'day') ?? undefined}
              onChange={handleChangeStartTime}
              renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
            />
            〜
            <DatePicker
              views={['year', 'month', 'day']}
              label={'終了日'}
              value={fetchTimestamp?.end}
              minDate={fetchTimestamp?.start.add(1, 'day') ?? undefined}
              maxDate={rangeMaxDate}
              onChange={handleChangeEndTime}
              renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
            />
          </div>
        </div>
        <div>
          {equipmentDiapers?.length ? (
            <>
              <div css={TopContainer}>
                <div css={BoldText}>テープ/パンツ</div>
                <div css={SubTitleText}>総金額: {numInsertComma(diaperCost ?? 0)}円</div>
              </div>
              <EquipmentsTable data={equipmentDiapers} type={'pants'} />
            </>
          ) : (
            <div css={EmptyContent}>選択された期間ではテープ/パンツが使用されていません</div>
          )}
        </div>
        <div>
          {equipmentPads?.length ? (
            <>
              <div css={TopContainer}>
                <div css={BoldText}>パッド</div>
                <div css={SubTitleText}>総金額: {numInsertComma(padCost ?? 0)}円</div>
              </div>
              <EquipmentsTable data={equipmentPads} type={'pads'} />
            </>
          ) : (
            <div css={EmptyContent}>選択された期間ではパッドが使用されていません</div>
          )}
        </div>
      </PanelContainer>
    </>
  );
});
