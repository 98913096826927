import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selfDataSelector } from '../../../redux/config/selfData';
import api from '../../../api';
import {
  IGetNursingHomeCareRecordParams,
  IGetNursingHomeCareRecordRes,
  IResidentCareRecord,
} from '../../../types/api/traversal/getNursingHomeCareRecord';
import { useSortResidents } from '../../../hooks/useSortResidents';
import { SortType } from '@/types/components';

export const useFetchNursingHomeCareRecord = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  unitId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  filterType: 'deviceAssigned' | 'deviceAttached' | null = null, // デバイスの装着状態で結果をフィルタしたい時に指定
  sortType: SortType = 'unit'
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const { sortedResidents, sortResidents } = useSortResidents<IResidentCareRecord>();
  const [nursingHomeCareRecord, setNursingHomeCareRecord] = React.useState<IGetNursingHomeCareRecordRes>();

  const fetchNursingHomeCareRecord = async (
    unitId: number | null = null,
    from: string | null = null,
    to: string | null = null,
    filterType: 'deviceAssigned' | 'deviceAttached' | null = null,
    sortType: SortType = 'unit'
  ) => {
    const params: IGetNursingHomeCareRecordParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId: unitId,
      from,
      to,
    };

    // UnitIdの指定がない場合は、自身のUnitIdを優先して取得
    if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId && !unitId)
      params.nursingHomeUnitId = selfUser.nursingHomeUnitId;

    if (!from) delete params.from;
    if (!to) delete params.to;
    if ([0, null].includes(unitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/traversal/nursing-homes/:nursingHomeId/care-records', {
      params,
      timeout: 5 * 60 * 1000, // 5分に設定
    });

    if (filterType === 'deviceAssigned') {
      result = {
        ...result,
        residents: result.residents.filter((resident) => resident.dfreeDeviceId),
      };
    }

    if (filterType === 'deviceAttached') {
      result = {
        ...result,
        residents: result.residents.filter((resident) => resident.urineSize !== null),
      };
    }

    result.residents = sortResidents(result.residents, sortType);
    setNursingHomeCareRecord(result);
    return result;
  };

  React.useEffect(() => {
    // 初回fetchはUnitIdの指定がない場合は、自身のUnitIdを優先して取得
    // それ以外はUnitIdなし（全ユニット取得）
    if (!shouldInitFetch || !selfUser.nursingHomeId) return;
    if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId)
      fetchNursingHomeCareRecord(selfUser.nursingHomeUnitId, from, to, filterType, sortType);
    else if (unitId) fetchNursingHomeCareRecord(filterType ? null : unitId, from, to, filterType, sortType);
    else fetchNursingHomeCareRecord(null, from, to, filterType, sortType);
  }, [selfUser]);

  return { nursingHomeCareRecord, fetchNursingHomeCareRecord };
};
