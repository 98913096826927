import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { selfDataSelector } from '../../../redux/config/selfData';
import { currentNursingHomeSelector } from '../../../redux/config/currentNursingHome';
import { OutlineButton } from '../../../components/outlineButton';
import { PanelContainer } from '../../../components/panel';
import { IGetResidentRes } from '../../../types/api/resident/getResident';
import { useFetchResidents } from '../../../hooks/api/resident/useFetchResidents';
import { useFetchNotifications } from '../../../hooks/api/notification/useFetchNotifications';
import { useFetchSensorReactions } from '../../../hooks/api/traversal/useFetchSensorReactions';
import { ResidentsTable } from '../residentsTable';
import { exportCSV } from '../../../utils/exportCSV';
import { IGetNursingHomeSensorReactionRes } from '../../../types/api/traversal/getNursingHomeSensorReaction';
import { IGetNotificationRes } from '../../../types/api/notification/getNotification';
import { formatNotification } from '../../../utils/dataLabelUtil';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { MEASUREMENT_DATA_CSV_COL_JA, MEASUREMENT_DATA_CSV_COL_EN } from '../../../constants';

interface IDisplayResident extends IGetResidentRes {
  isChecked: boolean; // テーブルのチェック状態の管理フラグ
}

const TopContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;

const DateRangePicker = css`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const UltrasonicData = React.memo(() => {
  const rangeMinDate = dayjs().startOf('day').subtract(3, 'year');
  const rangeMaxDate = dayjs().endOf('day');
  const { residents } = useFetchResidents(true);
  const { notifications, fetchNotifications } = useFetchNotifications(false);
  const { sensorReactions, fetchSensorReactions } = useFetchSensorReactions(false);
  const { selfUser } = useSelector(selfDataSelector);
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const { showSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = React.useState<IDisplayResident[]>();
  const [checkArray, setCheckArray] = React.useState<boolean[]>();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [fetchTimestamp, setFetchTimestamp] = React.useState<{ start: Dayjs; end: Dayjs }>({
    start: dayjs().startOf('day').subtract(1, 'month'),
    end: dayjs().endOf('day'),
  });

  const selfUnit: number | null = React.useMemo(() => {
    if (!selfUser.nursingHomeId) return null;
    return selfUser.hasUnitPerm ? selfUser.nursingHomeUnitId : null;
  }, [selfUser]);

  const selectedResidentsId: number[] = React.useMemo(() => {
    if (!residents || !checkArray) return [];
    const filtered = residents?.filter((v, i) => {
      return checkArray[i] === true;
    });
    return filtered.map((v) => {
      return v.id;
    });
  }, [checkArray, residents]);

  const handleChangeStartTime = (start: Dayjs | null) => {
    if (!start) return;
    setFetchTimestamp((prev) => {
      return { ...prev, start: start.startOf('day') };
    });
  };

  const handleChangeEndTime = (end: Dayjs | null) => {
    if (!end) return;
    setFetchTimestamp((prev) => {
      return { ...prev, end: end.endOf('day') };
    });
  };
  const handleClickCSVDownload = () => {
    if (!checkArray?.filter((v) => v).length) {
      showSnackbar(t('noResidentSelected', '入居者が選択されていません'), 'error');
      return;
    }
    if (!fetchTimestamp?.start || !fetchTimestamp?.end) {
      return;
    }
    setOnSend(true);
    if (selfUnit) {
      // TODO: 施設の全入居者をFetchしてからフィルタをしているので、fetch時に個別に取得をする
      // TODO: customHookの一部をPromise.allで呼んでいるため、好ましい形に今後修正する
      Promise.all([
        fetchNotifications(
          selfUnit,
          fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          'desc',
          20000
        ),
        fetchSensorReactions(
          selfUnit,
          fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          'desc'
        ),
      ]).then(async ([notifications, sensorReactions]) => {
        await createCSVData(notifications, sensorReactions);
        setOnSend(false);
      });
    } else {
      Promise.all([
        fetchNotifications(
          undefined,
          fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          'desc',
          20000
        ),
        fetchSensorReactions(
          undefined,
          fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          'desc'
        ),
      ]).then(async ([notifications, sensorReactions]) => {
        await createCSVData(notifications, sensorReactions);
        setOnSend(false);
      });
    }
  };

  const createCSVData = async (
    notifications: IGetNotificationRes[],
    sensorReactions: IGetNursingHomeSensorReactionRes
  ) => {
    if (!notifications || !sensorReactions) return;
    let exportData: {
      id: number;
      name: string;
      timestamp: string;
      u1xActiveRatio: number;
      notificationType: string;
    }[] = [];

    // 選択されたユーザーかつ件数が0件でないsensorReactionsレコードを抽出
    const selectedSensorReactions = sensorReactions?.residents.filter(
      (resident) => selectedResidentsId.includes(resident.residentId) && resident.sensorReactions.length
    );
    if (!selectedSensorReactions.length) {
      showSnackbar(t('dataForTheSpecifiedPeriodWasNotFound', '指定した期間のデータが見つかりませんでした'));
      return;
    }
    selectedSensorReactions?.forEach((v) => {
      v.sensorReactions.forEach((sensorReaction) => {
        // sensorReactionの記録時間と非常に近いnotificationがある場合は挿入
        const selectedNotification = notifications?.find((notification) => {
          return (
            notification.residentId === v.residentId &&
            dayjs(notification.timestamp).unix() - dayjs.utc(sensorReaction.timestamp).unix() <= 300 &&
            dayjs(notification.timestamp).unix() - dayjs.utc(sensorReaction.timestamp).unix() > 0
          );
        });

        // 通知はロケールが日本以外の場合はフォーマットせずに出力する
        const i18nNotificationType = selectedNotification?.notificationType
          ? i18n.language === 'ja'
            ? formatNotification(selectedNotification?.notificationType)
            : selectedNotification?.notificationType
          : '';

        const data = {
          id: v.residentId,
          name: v.lastName + v.name,
          timestamp: dayjs(sensorReaction.timestamp).utc().local().format('YYYY/MM/DD HH:mm'),
          u1xActiveRatio: Math.round(sensorReaction.u1xActiveRatio * 10),
          notificationType: i18nNotificationType,
        };
        exportData.push(data);
      });
    });

    const filename =
      currentNursingHome.name +
      '_' +
      t('measurementData', '測定データ') +
      '_' +
      dayjs(fetchTimestamp!.start).format('YYYY-MM-DD') +
      '_' +
      dayjs(fetchTimestamp!.end).format('YYYY-MM-DD');

    const column = i18n.language === 'ja' ? MEASUREMENT_DATA_CSV_COL_JA : MEASUREMENT_DATA_CSV_COL_EN;
    // dataとcolumnの先頭に通し番号を追加
    exportData = exportData.map((v, i) => {
      return { 'S.No': i + 1, ...v };
    });
    const columnWithSNo = [{ label: 'S.No', value: 'S.No' }, ...column];
    await exportCSV(exportData, columnWithSNo, filename);
  };

  const handleChangeCheckBox = (checkArray: boolean[]) => {
    // TODO: selectedResidentsの更新検知のためにスプレッド演算子で更新を行なっているが
    // チェックボックスの状態を変更すると描画がTable全体に入るため要見直し
    setCheckArray([...checkArray]);
  };

  const createTableData = (data: IGetResidentRes[] | undefined) => {
    return data?.map((v) => {
      return {
        ...v,
        isChecked: false,
      };
    });
  };

  React.useEffect(() => {
    if (!residents) return;
    const tableData = createTableData(residents);
    setTableData(tableData);
  }, [residents]);

  return (
    <PanelContainer title={t('csvdownload', 'CSVダウンロード') + ' - ' + t('measurementData', '測定データ')}>
      {tableData && tableData.length ? (
        <>
          <div css={TopContainer}>
            <div css={DateRangePicker}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={t('startDate', '開始日')}
                value={fetchTimestamp?.start}
                minDate={rangeMinDate}
                maxDate={fetchTimestamp?.end ?? undefined}
                onChange={handleChangeStartTime}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
              〜
              <DatePicker
                views={['year', 'month', 'day']}
                label={t('endDate', '終了日')}
                value={fetchTimestamp?.end}
                minDate={fetchTimestamp?.start ?? undefined}
                maxDate={rangeMaxDate}
                onChange={handleChangeEndTime}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
            </div>
            <Tooltip
              placement='top-end'
              title={t(
                'downloadingMayTakeSomeTimeDependingOnTheAmountOfData',
                'データ量に応じてダウンロードに時間がかかる場合がございます。'
              )}
            >
              <div>
                <OutlineButton
                  icon={<img src='/images/analysis/icon_dl_csv.svg' alt='CSV' />}
                  onClick={handleClickCSVDownload}
                  disable={onSend}
                >
                  {t('download', 'ダウンロード')}
                </OutlineButton>
              </div>
            </Tooltip>
          </div>
          <ResidentsTable data={tableData} onChange={handleChangeCheckBox} />
        </>
      ) : (
        <>{t('thereAreNoResidents', '入居者がいません')}</>
      )}
    </PanelContainer>
  );
});
