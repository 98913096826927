import React from 'react';
import { css } from '@emotion/react';
import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ArrowRightAlt, HelpOutline, MoreHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { color } from '../../../../styles/color';

const FlexWrap = css`
  display: flex;
  flex-wrap: wrap;
`;

const CaptionContainer = css`
  display: grid;
  align-items: center;
  height: 24px;
  grid-template-columns: 128px 40px repeat(7, 28px) 36px 28px;
  font-size: 12px;
  color: ${color.dark_gray};
`;

const LabelContainer = css`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 100%;
  margin: 4px 24px 4px 0;
  color: ${color.dark_gray};
`;

const CaptionRow = css`
  display: flex;
  align-items: center;
`;

const BgColorGrid = (colorCode: string, opacity: number = 1) => css`
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: center;
  align-items: center;
  background-color: ${`rgba(${parseInt(colorCode.slice(1, 3), 16)}, ${parseInt(colorCode.slice(3, 5), 16)}, ${parseInt(
    colorCode.slice(5, 7),
    16
  )}, ${opacity})`}; // 色コードをrgbaに変換
`;

const ColorShape = (shape: 'square' | 'circle', bgColor: string) => css`
  background: ${bgColor};
  border: 1px solid ${color.border_gray};
  border-radius: ${shape === 'circle' ? '6px' : 'none'};
  width: 12px;
  height: 12px;
`;

const LabelMargin = css`
  margin-left: 8px;
`;

const YellowLine = css`
  background: ${color.notification_yellow};
  width: 30px;
  height: 3px;
`;

const StyledPopper = css`
  font-size: 14px;
`;

const PostureLabel = (LabelColor: string) => css`
  position: relative;
  padding-left: 16px;
  line-height: 24px;
  border-radius: 8px;
  margin-right: 16px;

  ::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 8px;
    height: 24px;
    background-color: ${LabelColor};
  }
`;

export const UrineTrendGraphCaption = React.memo(() => {
  const { t } = useTranslation();

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 360,
    },
  });

  const HelpTooltip = ({ title, description }: { title: string; description: string }) => (
    <CustomWidthTooltip
      sx={{ padding: 0 }}
      title={
        <div css={StyledPopper}>
          <strong>{title}</strong>
          <div>{description}</div>
        </div>
      }
      placement='top'
    >
      <IconButton>
        <HelpOutline />
      </IconButton>
    </CustomWidthTooltip>
  );
  return (
    <div>
      <div css={CaptionContainer}>
        <span>{t('尿のたまり具合傾向:', '尿のたまり具合傾向:')}</span>
        <span>{t('弱い', '弱い')}</span>
        <span>
          <ArrowRightAlt />
        </span>
        <span css={BgColorGrid('#CC4444', 0.1)}>0</span>
        <span css={BgColorGrid('#CC4444', 0.25)}>
          <MoreHoriz />
        </span>
        <span css={BgColorGrid('#CC4444', 0.5)}>50</span>
        <span css={BgColorGrid('#CC4444', 0.75)}>
          <MoreHoriz />
        </span>
        <span css={BgColorGrid('#CC4444', 1)}>100</span>
        <span>
          <ArrowRightAlt />
        </span>
        <span>{t('強い', '強い')}</span>
        <HelpTooltip
          title='集計期間全体における尿のたまり具合の傾向値'
          description='※設定した閾値を超えた割合を表示しており、赤色が濃いほど尿のたまり具合の傾向が強い'
        />
      </div>
      <div css={CaptionContainer}>
        <span>{t('尿のたまり具合平均:', '尿のたまり具合平均:')}</span>
        <span>{t('少ない', '少ない')}</span>
        <span>
          <ArrowRightAlt />
        </span>
        <span css={BgColorGrid('#00AA66', 0.1)}>0</span>
        <span css={BgColorGrid('#00AA66', 0.25)}>
          <MoreHoriz />
        </span>
        <span css={BgColorGrid('#00AA66', 0.5)}>5</span>
        <span css={BgColorGrid('#00AA66', 0.75)}>
          <MoreHoriz />
        </span>
        <span css={BgColorGrid('#00AA66', 1)}>10</span>
        <span>
          <ArrowRightAlt />
        </span>
        <span>{t('多い', '多い')}</span>
        <HelpTooltip
          title='集計期間全体における尿のたまり具合の平均値（DFree数値の0～10段階表記）'
          description='※緑色が濃いほど尿のたまり具合の平均値が高い'
        />
      </div>
      <div css={CaptionContainer}>
        <span>{t('尿のたまり具合:', '尿のたまり具合:')}</span>
        <span>{t('少ない', '少ない')}</span>
        <span>
          <ArrowRightAlt />
        </span>
        <span css={BgColorGrid('#00AAFF', 0.1)}>0</span>
        <span css={BgColorGrid('#00AAFF', 0.25)}>
          <MoreHoriz />
        </span>
        <span css={BgColorGrid('#00AAFF', 0.5)}>5</span>
        <span css={BgColorGrid('#00AAFF', 0.75)}>
          <MoreHoriz />
        </span>
        <span css={BgColorGrid('#00AAFF', 1)}>10</span>
        <span>
          <ArrowRightAlt />
        </span>
        <span>{t('多い', '多い')}</span>
        <HelpTooltip
          title=' 集計期間における尿のたまり具合の平均値（DFree数値の0～10段階表記を30分ごとに平均した値）'
          description='※青色が濃いほど尿のたまり具合の平均値が高い'
        />
      </div>
    </div>
  );
});

export const ActivityStatusGraphCaption = React.memo(() => {
  const { t } = useTranslation();
  const postureLabelArray = [
    { label: t('StandingAndSitting', '立位＆座位'), color: '#FFF4CC' },
    { label: t('SupinePosition', '仰臥位'), color: '#141F80' },
    { label: t('RightLateralPosition', '右側臥位'), color: '#4EC1D0' },
    { label: t('LeftLateralPosition', '左側臥位'), color: '#5855DB' },
    { label: t('Moving(WhileLyingInBed)', '動いている（寝）'), color: '#B092E2' },
    { label: t('Moving(Standing/Sitting)', '動いている（立・座）'), color: '#FF7152' },
    { label: t('FacedownPosition', 'うつ伏せ'), color: '#D7F5FF' },
    { label: t('UpsideDown', '上下逆'), color: '#000000' },
  ];

  return (
    <div css={[CaptionRow]}>
      <div css={LabelContainer}>{t('posture', '姿勢')}:</div>
      <div css={[LabelContainer, FlexWrap]}>
        {postureLabelArray.map((v, i) => {
          return (
            <span css={PostureLabel(v.color)} key={i}>
              {v.label}
            </span>
          );
        })}
      </div>
    </div>
  );
});

export const PlaceCaption = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div css={[CaptionRow, FlexWrap]}>
      <div css={LabelContainer}>
        <div css={ColorShape('square', '#72FBFD')}></div>
        <span css={LabelMargin}>
          {t('toilet', 'トイレ')}：{t('yes', '有')} {t('diaperOrPad', 'おむつ / パッド')}：{t('no', '無')}
        </span>
      </div>
      <div css={LabelContainer}>
        <div css={ColorShape('square', '#75F94C')}></div>
        <span css={LabelMargin}>
          {t('toilet', 'トイレ')}：{t('yes', '有')} {t('diaperOrPad', 'おむつ / パッド')}：{t('yes', '有')}
        </span>
      </div>
      <div css={LabelContainer}>
        <div css={ColorShape('square', '#CCCCCC')}></div>
        <span css={LabelMargin}>
          {t('toilet', 'トイレ')}：{t('no', '無')} {t('diaperOrPad', 'おむつ / パッド')}：{t('yes', '有')}
        </span>
      </div>
      <div css={LabelContainer}>
        <div css={ColorShape('square', '#6D6D6D')}></div>
        <span css={LabelMargin}>
          {t('toilet', 'トイレ')}：{t('no', '無')} {t('diaperOrPad', 'おむつ / パッド')}：{t('no', '無')}
        </span>
      </div>
    </div>
  );
});

export const NotificationCaption = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div css={[CaptionRow, FlexWrap]}>
      <div css={LabelContainer}>
        <div css={YellowLine}></div>
        <span css={LabelMargin}>{t('almostThreshold', 'そろそろライン')}</span>
      </div>
      <div css={LabelContainer}>
        <div css={ColorShape('circle', color.notification_yellow)}></div>
        <span css={LabelMargin}>{t('almostNotificiation', 'そろそろ通知')}</span>
      </div>
      <div css={LabelContainer}>
        <div css={ColorShape('circle', color.notification_pink)}></div>
        <span css={LabelMargin}>{t('checkNotificiation', 'でたかも通知')}</span>
      </div>
      <div css={LabelContainer}>
        <div css={ColorShape('circle', color.notification_red)}></div>
        <span css={LabelMargin}>{t('gotUpNotificiation', '起き上がり通知')}</span>
      </div>
    </div>
  );
});
