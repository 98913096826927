import React from 'react';
import api from '../../../api';

export const useFetchCareSchedule = (residentId: number): string[] => {
  const [careSchedule, setCareSchedule] = React.useState<string[]>([]);

  const fetchCareSchedule = async (residentId: number) => {
    const result = await api.get('/residents/:residentId/care-schedule', { params: { residentId } });
    setCareSchedule(result.careTimings);
  };

  React.useEffect(() => {
    fetchCareSchedule(residentId);
  }, []);

  return careSchedule;
};
