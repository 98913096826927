import firebase from 'firebase/compat/app';
import React from 'react';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
};

firebase.initializeApp(firebaseConfig);

interface Context {
  currentUser: { user: firebase.User | null; isPending: boolean };
  getUser: () => firebase.User | null;
  login: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  signUp: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  signOut: () => Promise<void>;
}

const AuthContext = React.createContext<Context>({
  currentUser: { user: null, isPending: true },
  getUser: () => null,
  login: () => auth.signInWithEmailAndPassword('', ''),
  signUp: () => auth.createUserWithEmailAndPassword('', ''),
  signOut: () => auth.signOut(),
});

export const auth = firebase.auth();

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = React.useState<{
    user: firebase.User | null;
    isPending: boolean;
  }>({ user: null, isPending: true });

  function login(email: string, password: string) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function signOut() {
    return auth.signOut();
  }

  function signUp(email: string, password: string) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function getUser() {
    return auth.currentUser;
  }

  // ページがリロードされた場合にも認証情報を継続して取得する
  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser({ user, isPending: false });
      } else {
        setCurrentUser({ user: null, isPending: false });
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    getUser,
    login,
    signUp,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
