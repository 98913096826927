import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetNursingHomeSensorReactionRes } from '../../../types/api/traversal/getNursingHomeSensorReaction';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  from?: string | null;
  to?: string | null;
  sort?: string;
}

export const useFetchSensorReactions = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeUnitId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  sort: string = 'desc'
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [sensorReactions, setSensorReactions] = React.useState<IGetNursingHomeSensorReactionRes>();

  const fetchSensorReactions = async (
    nursingHomeUnitId: number | null = null,
    from: string | null = null,
    to: string | null = null,
    sort = 'desc'
  ) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      from,
      to,
      sort,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;
    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/traversal/nursing-homes/:nursingHomeId/sensor-reactions', {
      params,
      timeout: 5 * 60 * 1000, // 5分に設定
    });

    setSensorReactions(result);
    return result; // TODO: 値を直接returnし、useStateを使っていないため修正する
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchSensorReactions(nursingHomeUnitId, from, to, sort);
  }, []);

  return { sensorReactions, fetchSensorReactions };
};
