import React from 'react';
import { css } from '@emotion/react';
import { MenuItemProps, Select } from '../../../../components/mui/select';
import { TableLinkCell } from '../../../../styles/table';
import { ITraversalOrder } from '../../../../types/api/traversal/order';
import { IGetDiaperRes } from '../../../../types/api/care-settings/getDiaper';

interface Props {
  value: ITraversalOrder; // 入力フォームに表示する初期値
  orderableList: IGetDiaperRes[];
  onChange: (value: MenuItemProps) => void;
}

const W100 = css`
  width: 100%;
`;

export const OrderableSelectorCell = React.memo(({ value, orderableList, onChange }: Props) => {
  const [inputValue, setInputValue] = React.useState<MenuItemProps>();
  const [isError, setIsError] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');
  const [listData, setListData] = React.useState<MenuItemProps[]>([]);

  const handleChange = (value: MenuItemProps) => {
    if (value.key) setIsError(false);
    setInputValue(value);
    onChange(value);
  };

  React.useEffect(() => {
    if (!orderableList) return;
    const list: MenuItemProps[] = orderableList.map((v) => {
      return {
        key: v.id,
        value: v.diaperName,
        option: {
          diaperId: v.diaperId,
        },
      };
    });
    setListData(list);
  }, [orderableList]);

  React.useEffect(() => {
    if (!listData.length) return;
    const inputValue = listData.find((v) => v.key === value.nursingHomeDiaperId);
    setIsError(inputValue?.key ? false : true);
    setErrorText(inputValue?.key ? '' : '名前を指定してください');
    setInputValue(inputValue);
  }, [listData, value]);

  return (
    <div css={[W100, TableLinkCell]}>
      <Select
        id={'orderable-selector'}
        listData={listData}
        value={inputValue}
        isError={isError}
        errorText={errorText}
        fullWidth={true}
        onChange={handleChange}
      />
    </div>
  );
});
