import React from 'react';
import { css } from '@emotion/react';
import { Cell, CellBase, HeaderCell, Row, Table } from '../../../styles/table';
import { IStockTableData } from '../../../pages/expendables/stocks';
import { InputFormCell } from '../inputFormCell';

interface Props {
  data: IStockTableData[];
  onChange: (id: number, stock: number, bagStock: number) => void;
}

// テーブルのセルの横幅を左から順に定義
const CellWidth = [{ flex: '3 1 220px' }, { flex: '1 1 60px' }, { flex: '0 0 240px' }, { flex: '1 2 110px' }];

const TextWrap = css`
  white-space: nowrap;
  overflow: hidden;
`;

const Unit = css`
  margin: 4px;
`;

export const StockTable = React.memo(({ data, onChange }: Props) => {
  const tableHeader = ['名前', 'サイズ', '在庫', '更新日時'];
  const handleStockChange = React.useCallback(
    (id: number) => (value: number) => {
      onChange(id, value, data[id].bagStock);
    },
    [onChange]
  );

  const handleBagStockChange = React.useCallback(
    (id: number) => (value: number) => {
      onChange(id, data[id].stock, value);
    },
    [onChange]
  );

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0], TextWrap]}>
                {data.diaperSeries}
                <br />
                {data.diaperName}
              </div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.displaySize}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>
                <InputFormCell value={data.stock} id={String(data.id)} onChange={handleStockChange(i)} />
                <span css={Unit}>ケース</span>
                <InputFormCell value={data.bagStock} id={String(data.id)} onChange={handleBagStockChange(i)} />
                <span css={Unit}>袋</span>
              </div>
              <div css={[CellBase, Cell, CellWidth[3]]}>{data.stockUpdatedAt}</div>
            </div>
          );
        })}
      </div>
    </>
  );
});
