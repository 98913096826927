import React from 'react';
import { css } from '@emotion/react';
import { color } from '../../../../styles';
import styled from '@emotion/styled';
import { AnalyticsData } from '.';
import { useTranslation } from 'react-i18next';

interface Props {
  date: { start: string; end: string };
  data: AnalyticsData[];
  careTimings: number[]; // ケアスケジュール
}

interface CellProps {
  index: number;
  hour?: number;
  notification?: string;
  careTimings?: number[];
  row?: number;
}

const TableContainer = css`
  min-width: 820px;
  margin-right: 16px;
  margin-left: 1px;
`;

const TableHeader = css`
  height: 24px;
  margin-bottom: 8px;
  margin-left: 32px;
  margin-right: -16px;
  display: flex;
  justify-content: space-between;
`;

const TableHeaderHour = css`
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  line-height: 24px;
  width: 40px;
  text-align: center;
  color: ${color.gray};
`;

const Row = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${color.border_gray};

  &:last-of-type {
    border-bottom: 1px solid ${color.border_gray};
  }
`;

const ThCell = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 48px;
  height: 24px;
  width: 48px;
  border-left: 1px solid ${color.border_gray};
  background-color: ${color.white};
  color: ${color.gray};
  font-weight: bold;
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  letter-spacing: 0.05em;
`;

const Cell = styled.div<CellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  flex: 1 0 16px;
  border-right: ${({ index }) => ((index + 1) % 6 ? `1px solid ${color.white}` : `1px solid ${color.dark_gray}`)};
  &:last-of-type {
    border-right: 1px solid ${color.border_gray};
  }
  // 背景を条件に応じて塗る
  ${({ index, careTimings, notification }) => {
    if (notification === 'bigBladder') {
      return `background-color:${color.notification_yellow}`;
    }
    if (notification === 'urinated') {
      return `background-color:${color.notification_pink}`;
    }
    // ケアスケジュールに該当する時間（条件式では30分刻みのマスなので *2 して判定）
    if (careTimings && careTimings.some((v) => v * 2 === index - 1 || v * 2 === index)) {
      return `background-color: ${color.light_green}`;
    }
    return `background-color: ${color.table_gray}`;
  }};
  // ケアスケジュールに該当する時間で一番上の行だけ濃い緑色のラインをつける
  ${({ row, careTimings, index }) =>
    !row &&
    careTimings &&
    careTimings.some((v) => v * 2 === index - 1 || v * 2 === index) &&
    `
      position: relative;
      &:before {
        position: absolute;
        content: '';
        height: 4px;
        top: -4px;
        left: 0;
        right: 0;
        background-color: ${color.green};
      }
    `};
`;

const TableInfo = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 16px 0;
  gap: 16px;
  font-size: 16px;
`;

const BigBladderText = css`
  &::before {
    content: '■';
    color: ${color.notification_yellow};
    margin-right: 4px;
  }
`;

const UrinatedText = css`
  &::before {
    content: '■';
    color: ${color.notification_pink};
    margin-right: 4px;
  }
`;

const SuccessText = css`
  &::before {
    content: '●';
    margin-right: 4px;
  }
`;

const IncontinenceText = css`
  &::before {
    content: '▲';
    margin-right: 4px;
  }
`;

const MissText = css`
  &::before {
    content: '×';
    margin-right: 4px;
  }
`;

const CareTimingText = css`
  display: flex;
  align-items: center;
  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    background-color: ${color.light_green};
    margin-right: 4px;
    border-top: 2px solid ${color.green};
  }
`;

const AnalyticsCell = (data: any, index: number, careTimings: number[], i: number) => {
  return (
    <Cell index={index} notification={data?.data.notification} key={index} careTimings={careTimings} row={i}>
      {data?.data.excretion === 'success'
        ? '●'
        : data?.data.excretion === 'incontinence'
        ? '▲'
        : data?.data.excretion === 'miss'
        ? '×'
        : ''}
    </Cell>
  );
};

export const AnalyticsMonthlyTable = React.memo(({ date, data, careTimings }: Props) => {
  const { t } = useTranslation();
  const halfhourPerDay = 48;
  const headerHour = ['0:00', '3:00', '6:00', '9:00', '12:00', '15:00', '18:00', '21:00', '24:00'];
  return (
    <>
      <div css={TableContainer}>
        <div css={TableHeader}>
          {headerHour.map((v, i) => {
            return (
              <div css={TableHeaderHour} key={i}>
                {v}
              </div>
            );
          })}
        </div>
        {data.map((v, i) => {
          return (
            <div key={i} css={Row}>
              <div css={ThCell}>{v.label}</div>
              <>
                {/* TODO: タイムゾーンによって月間排尿表の印とハイライトが1日分ずれる場合がある */}
                {[...Array(halfhourPerDay)].map((_, index) => {
                  return AnalyticsCell(
                    v.dataList?.find((v) => v.halfhourPerDay === index),
                    index,
                    careTimings,
                    i
                  );
                })}
              </>
            </div>
          );
        })}
      </div>
      <div css={TableInfo}>
        <span css={BigBladderText}>{t('almostNotificiation', 'そろそろ通知')}</span>
        <span css={UrinatedText}>{t('checkNotificiation', 'でたかも通知')}</span>
        <span css={SuccessText}>{t('success', '成功')}</span>
        <span css={IncontinenceText}>{t('incontinenceUrination', '失禁')}</span>
        <span css={MissText}>{t('toiletGuidanceNoUrge', '空振り')}</span>
        <span css={CareTimingText}>{t('careTiming', '誘導時間')}</span>
      </div>
    </>
  );
});
