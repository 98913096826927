interface IOrderStatus {
  [key: string]: string;
}

export const ORDER_STATUS: IOrderStatus = {
  recept: '受付中',
  accepted: '受付済',
  order: '受付済',
  shipping: '受付済',
  mailSent: '受付済',
  complete: '配達完了',
  cancel: '取り消し',
  csvImport: 'CSVインポート',
  temporarilySaved: '一時保存',
};

// プルダウンリスト用
export const ORDER_STATUS_LIST = [
  {
    label: 'recept',
    value: '受付中',
  },
  {
    label: 'accepted',
    value: '受付済',
  },
  {
    label: 'order',
    value: '受付済',
  },
  {
    label: 'shipping',
    value: '受付済',
  },
  {
    label: 'mailSent',
    value: '受付済',
  },
  {
    label: 'complete',
    value: '配達完了',
  },
  {
    label: 'cancel',
    value: '取り消し',
  },
  {
    label: 'csvImport',
    value: 'CSVインポート',
  },
  {
    label: 'temporarilySaved',
    value: '一時保存',
  },
];
