import React from 'react';
import { css } from '@emotion/react';

interface Props {
  title: string;
}

const Container = css`
  margin: 12px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.6px;
`;

export const OrderTitle: React.FC<Props> = ({ title }) => {
  return <div css={Container}>{title}</div>;
};
