import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { Order } from '../../../types/api/order/getOrders';
import { ExcretionTable } from './excreteTable';
import { useFetchNursingHomeCareRecord } from '../../../hooks/api/traversal/useFetchNursingHomeCareRecord';
import { ResidentListHeader } from '../../../components/residentListHeader';
import { DateSelector } from '../../../components/mui/dateSelector';
import { residentListSettingsSelector } from '../../../redux/config/residentListSettings';
import { SortType } from '@/types/components';

export interface IGetOrder extends Order {
  displayUnitName: string; // 施設名がないものを施設全体として変換したもの
  displayTimestamp: string; // 日付をutcからタイムゾーン変換したもの
}

const Container = css`
  padding: 0 16px;
`;

const RightElementContainer = css`
  margin-right: 16px;
`;

const EmptyResidentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

export const ExcretionTableList = React.memo(() => {
  const createFilterType = (condition: 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit') => {
    if (['deviceAssigned', 'deviceAttached'].includes(condition)) {
      return condition as 'deviceAssigned' | 'deviceAttached';
    } else return null;
  };

  const initialDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0).utc();
  const initialDateEnd = initialDate.add(1, 'day');
  const {
    conditions: storeCondition,
    unitId: storeUnitId,
    sortType: storeSortType,
  } = useSelector(residentListSettingsSelector);
  const [selectedDate, setSelectDate] = React.useState<Dayjs>(initialDate);
  const { t } = useTranslation();
  const { nursingHomeCareRecord, fetchNursingHomeCareRecord } = useFetchNursingHomeCareRecord(
    true,
    storeUnitId,
    initialDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    initialDateEnd.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    createFilterType(storeCondition),
    storeSortType
  );

  const handleUpdate = () => {
    fetchNursingHomeCareRecord(
      storeUnitId,
      selectedDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      selectedDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      createFilterType(storeCondition),
      storeSortType
    );
  };

  const handleChangeListSettings = (
    selectedUnitId: number | null,
    selectedCondition?: 'deviceAssigned' | 'deviceAttached',
    selectedSortType?: SortType
  ) => {
    fetchNursingHomeCareRecord(
      selectedUnitId ? selectedUnitId : null,
      selectedDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      selectedDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      selectedCondition ? selectedCondition : null,
      selectedSortType ? selectedSortType : storeSortType
    );
  };

  const handleChangeDate = (newDate: Dayjs) => {
    setSelectDate(newDate);
    fetchNursingHomeCareRecord(
      storeUnitId ? storeUnitId : null,
      newDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      newDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      createFilterType(storeCondition),
      storeSortType
    );
  };

  // 5分ごとに入居者データを更新する
  React.useEffect(() => {
    const timer = setInterval(() => {
      fetchNursingHomeCareRecord(
        storeUnitId,
        selectedDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        selectedDate.add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        createFilterType(storeCondition),
        storeSortType
      );
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [selectedDate]);

  return (
    <div css={Container}>
      <ResidentListHeader
        title={t('excretionTable', '排泄表')}
        conditions={storeCondition}
        unitId={storeUnitId}
        sortType={storeSortType}
        rightElement={
          <div css={RightElementContainer}>
            <DateSelector
              newValue={selectedDate}
              minDate={initialDate.subtract(3, 'years')}
              maxDate={initialDate}
              onChange={handleChangeDate}
            />
          </div>
        }
        onUpdate={handleUpdate}
        onChangeListSettings={handleChangeListSettings}
      />

      {nursingHomeCareRecord && nursingHomeCareRecord.residents.length ? (
        nursingHomeCareRecord.residents.map((v, i) => {
          return <ExcretionTable resident={v} key={i} />;
        })
      ) : (
        <div css={EmptyResidentContainer}>{t('thereAreNoResidents', '入居者が居ません')}</div>
      )}
    </div>
  );
});
