import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetDrinkRes, IGetDrinksParams } from '../../../types/api/care-settings/getDrink';

export const useFetchDrinks = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  sort: string = 'desc',
  limit: number = 1000,
  offset: number | null = null
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [drinks, setDrinks] = React.useState<IGetDrinkRes[]>();

  const fetchDrinks = async (sort = 'desc', limit = 1000, offset: number | null = null) => {
    const params: IGetDrinksParams = {
      nursingHomeId: +nursingHomeId,
      sort,
      limit,
      offset,
    };

    if (!sort) delete params.sort;
    if (!limit) delete params.limit;
    if (!offset) delete params.offset;

    const result = await api.get('/nursing-homes/:nursingHomeId/drinks', {
      params,
    });

    setDrinks(result);
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchDrinks(sort, limit, offset);
  }, []);

  return { drinks, fetchDrinks };
};
