import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

interface Props {
  newValue?: Dayjs;
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onChange: (value: Dayjs) => void;
}

export const TimeSelector = React.memo(({ newValue, label = '', variant = 'standard', onChange }: Props) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());

  const handleChangeValue = (value: dayjs.Dayjs | null, keyboardInputValue?: string | undefined) => {
    if (keyboardInputValue) return; // キーボード入力を無効化
    setValue(value!.local()); // 表示はローカル時間で表示
    onChange(value!.utc()); // 親にはUTC時間で渡す
  };

  useEffect(() => {
    if (!newValue) return;
    setValue(newValue.local());
  }, [newValue]);

  return (
    <TimePicker
      ampm={false}
      label={label}
      value={value}
      inputFormat={'HH:mm'}
      onChange={handleChangeValue}
      renderInput={(params) => <TextField variant={variant} {...params} helperText={null} />}
    />
  );
});
