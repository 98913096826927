import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../core/store';
import { IGetNursingHomeRes } from '../../types/api/nursingHome/getNursingHome';
import { IGetUnitRes } from '../../types/api/unit/getUnit';
import { IGetSelfRes } from '../../types/api/user/getSelfUser';

interface ISelfUser extends IGetSelfRes {
  hasUnitPerm: boolean; //自身のunitIDをもっていれば有効
}

interface selfProps {
  selfUser: ISelfUser;
  selfUnit: IGetUnitRes;
  selfUnits: IGetUnitRes[];
  selfNursingHome: IGetNursingHomeRes;
}

// initialize
const initialState: selfProps = {
  selfUser: {
    id: 0,
    nursingHomeId: 0,
    nursingHomeUnitId: 0,
    professionalRole: '',
    professionalPermission: false,
    personalPermission: false,
    hasUnitPerm: false,
  },
  selfUnit: {
    nursingHomeId: 0,
    name: '',
    email: [''],
    tel: [''],
    orderMemo: '',
    clientCode: '',
    nurseCallCode: '',
    id: 0,
  },
  selfUnits: [],
  selfNursingHome: {
    id: 0,
    nursingHomeGroupId: 0,
    name: '',
    address: '',
    email: '',
    phoneNumber: '',
    type: 0,
    capacity: 0,
    plan: 0,
    planStatus: '',
    planDate: '',
    orderLimitDayOfWeek: '',
    orderLimitHour: 0,
    orderShippingDay: 0,
    orderShippingDayOfWeek: '',
    orderMemo: '',
    clientCode: '',
    goodTree: false,
    membershipStatus: '',
    honobonoNumber: '',
    inUseLife: false,
    lifeCareFacilityId: '',
  },
};

// slice
export const selfDataSlice = createSlice({
  name: 'selfData',
  initialState,
  reducers: {
    setSelfUser: (state: { selfUser: ISelfUser }, action: PayloadAction<IGetSelfRes>) => {
      state.selfUser = { ...state.selfUser, ...action.payload };
      state.selfUser.hasUnitPerm = !!state.selfUser.nursingHomeUnitId;
    },
    setSelfUnit: (state: { selfUnit: IGetUnitRes }, action: PayloadAction<IGetUnitRes>) => {
      state.selfUnit = { ...state.selfUnit, ...action.payload };
    },
    setSelfUnits: (state: { selfUnits: IGetUnitRes[] }, action: PayloadAction<IGetUnitRes[]>) => {
      state.selfUnits = action.payload;
    },
    setSelfNursingHome: (state: { selfNursingHome: IGetNursingHomeRes }, action: PayloadAction<IGetNursingHomeRes>) => {
      state.selfNursingHome = { ...state.selfNursingHome, ...action.payload };
    },
    reset: (state) => {
      state.selfUser = { ...initialState.selfUser };
      state.selfUnit = { ...initialState.selfUnit };
      state.selfNursingHome = { ...initialState.selfNursingHome };
    },
  },
});

// action
export const { setSelfUser } = selfDataSlice.actions;

// selector
export const selfDataSelector = (state: RootState) => state.selfData;
