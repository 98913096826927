import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, CellBase, HeaderCell, Row, Table, TableLinkCell, TableLinkText } from '../../../../styles/table';
import { IGetLifeCarePlanningsRes } from '../../../../types/api/life/getLifeCarePlanning';
import { LIFE_CARE_PLANNING_JOB_CATEGORY } from '../../../../constants';

interface Props {
  data: IGetLifeCarePlanningsRes;
  onClickEditCell: (id: number) => void;
}

// テーブルのセルの横幅を左から順に定義
const CellWidth = [
  { flex: '1 1 60px' },
  { flex: '1 1 160px' },
  { flex: '1 1 120px' },
  { flex: '1 1 120px' },
  { flex: '0 0 112px' },
];

export const LifeCarePlanningTable = React.memo(({ data, onClickEditCell }: Props) => {
  const { t } = useTranslation();
  const tableHeader = ['ID', '評価日', '計画作成日', '記入者職種', '操作'];

  const getJobCategory = (jobCode: string): string | undefined => {
    const findItem = LIFE_CARE_PLANNING_JOB_CATEGORY.find((v) => v.key === jobCode);
    return findItem?.value;
  };

  const OperationCell = (id: number) => {
    return (
      <div css={TableLinkCell}>
        <div css={TableLinkText} onClick={() => onClickEditCell(id)}>
          {t('detailOrEdit', '詳細 / 編集')}
        </div>
      </div>
    );
  };

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0]]}>{data.id ?? ''}</div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.evaluateDate ?? ''}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{data.planCreateDate}</div>
              <div css={[CellBase, Cell, CellWidth[3]]}>{getJobCategory(data.recordStaffJobCategory)}</div>
              <div css={[CellBase, Cell, CellWidth[4]]}>{OperationCell(data.id)}</div>
            </div>
          );
        })}
      </div>
    </>
  );
});
