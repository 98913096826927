import React from 'react';
import Button from '@mui/material/Button';
import { css, Dialog as MUIDialog, styled } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  isOpen: boolean;
  title?: string;
  children: React.ReactNode;
  cancelLabel?: string;
  submitLabel?: string;
  graphResetLabel?: string;
  dialogWidth?: string;
  onClose: () => void;
}

interface DialogProps {
  width: string;
}

const ActionContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UpperButtonContainer = css`
  display: flex;
  gap: 16px;
`;

const BootstrapDialog = styled(MUIDialog)<DialogProps>(({ width }) => ({
  textAlign: 'center',
  '& .MuiDialog-paper': {
    maxWidth: '80vw',
    pointerEvents: 'auto',
    width: `${width}`,
  },
  '& .MuiDialogContent-root': {
    justifyContent: 'center',
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    paddingBottom: '32px',
  },
}));

export const RecordDialog: React.FC<Props> = React.memo(
  ({ isOpen, title, children, dialogWidth = '640px', onClose }) => {
    return (
      <BootstrapDialog open={isOpen} width={dialogWidth} onClose={onClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
      </BootstrapDialog>
    );
  }
);
