import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { t } from 'i18next';
import { Dayjs } from 'dayjs';

interface Props {
  start: Dayjs;
  end: Dayjs;
  title: string;
  sensorData: [number, number | null][];
  resampleData: [number, number | null][];
  notificationData: ScatterPlot[];
  careRecordData: ScatterPlot[];
  bladderThreshold: number | undefined;
  showResampleData: boolean;
  showNotificationData: boolean;
  showCareRecordData: boolean;
  isPrinting?: boolean;
}

interface ScatterPlot {
  x: number;
  y: number;
  color: string;
  name: string;
}

export const UrineGraph = React.memo(
  ({
    start,
    end,
    title,
    sensorData,
    resampleData,
    notificationData,
    careRecordData,
    bladderThreshold,
    showResampleData,
    showNotificationData,
    showCareRecordData,
    isPrinting = false,
  }: Props) => {
    const bladderThresholdLines = React.useMemo(() => {
      let grids: Highcharts.YAxisPlotLinesOptions[] = [];
      if (bladderThreshold) {
        grids.push({
          color: '#FFBB00',
          dashStyle: 'Solid',
          value: bladderThreshold / 10 - 0.03,
          width: 2,
          zIndex: 0,
        });
      }
      return grids;
    }, [bladderThreshold]);

    const options: Highcharts.Options = {
      chart: {
        zoomType: 'x',
        marginLeft: 108,
        marginRight: isPrinting ? 180 : 48,
        height: 180,
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'datetime',
        min: start.valueOf(),
        max: end.valueOf(),
        showEmpty: true,
        opposite: false,
        gridLineWidth: 1,
        lineWidth: 0,
        tickWidth: 0,
        minPadding: 0,
        maxPadding: 0,
        plotLines: [
          {
            color: '#CCCCCC',
            dashStyle: 'Solid',
            value: start.valueOf(),
            width: 0.5,
          },
          {
            color: '#CCCCCC',
            dashStyle: 'Solid',
            value: end.valueOf(),
            width: 0.5,
          },
        ],
        dateTimeLabelFormats: {
          minute: '%H:%M',
          hour: '%H:00',
          day: t('graphDayFormat', '%m月%d日'),
          week: t('graphDayFormat', '%m月%d日'),
          month: t('graphYearFormat', '%m月%d日'),
        },
        crosshair: {
          width: 2,
        },
      },
      yAxis: {
        title: {
          text: title,
          x: -8,
          style: {
            fontSize: '18px',
          },
        },
        labels: {
          formatter: function () {
            return this.value as string;
          },
        },
        plotLines: bladderThresholdLines,
        max: 10,
        min: 0,
        tickInterval: 2,
        crosshair: {
          width: 2,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        scatter: {
          marker: {
            enabled: true,
          },
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: [
        {
          name: t('urineGraphLabel', '尿量'),
          data: sensorData,
          yAxis: 0,
          color: '#7BD6E7',
          zIndex: 1,
          type: 'area',
          lineWidth: 3,
          fillOpacity: 0.5,
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: t('graphDateTimeFormat', '{point.y}<br />{point.x:%m月%d日 %H時%M分}'),
          },
        },
        {
          name: t('rawGraph', '補正前グラフ'),
          data: resampleData,
          yAxis: 0,
          zIndex: 0,
          type: 'area',
          fillColor: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, 'rgba(105, 147, 209, 1)'],
              [1, 'rgba(187, 212, 251, 0.2)'],
            ],
          },
          lineWidth: 1,
          lineColor: '#C0C0C0',
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: t('graphDateTimeFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
          },
          visible: showResampleData,
        },
        {
          name: t('notification', '通知'),
          data: notificationData,
          marker: {
            enabled: true,
            symbol: 'circle',
          },
          zIndex: 3,
          type: 'line', // scatterだとエリア外のマウスオーバーでtooltipがでないためタイプをlineに
          lineWidth: 0,
          states: {
            hover: {
              lineWidth: 0,
              lineWidthPlus: 0,
            },
          },
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: t('graphDateTimePlotFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
          },
          visible: showNotificationData,
        },
        {
          name: t('careHistory', 'ケア履歴'),
          data: careRecordData,
          marker: {
            enabled: true,
            symbol: 'square',
          },
          zIndex: 3,
          type: 'line', // scatterだとエリア外のマウスオーバーでtooltipがでないためタイプをlineに
          lineWidth: 0,
          states: {
            hover: {
              lineWidth: 0,
              lineWidthPlus: 0,
            },
          },
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: t('graphDateTimePlotFormat', '{point.name}<br />{point.x:%m月%d日 %H時%M分}'),
          },
          visible: showCareRecordData,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    React.useEffect(() => {
      Highcharts.charts.forEach((chart) => {
        if (chart) {
          chart.reflow();
        }
      });
    }, [isPrinting]);

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
);
