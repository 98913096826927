// paramsがValidationの要件を満たしている場合はTrue、満たしていない場合はFalseを返す

// emailの形式のみ許容
export const emailValidation = (params: string) => {
  const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]+$/;
  return regex.test(params);
};

// 0以上の整数のみ許容
export const numberValidation = (params: string | number) => {
  const regex = /^([1-9]\d*|0)$/;
  return regex.test(params as string);
};

// 0から始まり、残りの数字9桁または10桁の半角数字
export const telNumberValidation = (params: string | number) => {
  const regex = /^0\d{9,10}$/;
  return regex.test(params as string);
}
