import React from "react";
import { css } from "@emotion/react";
import { useLoading } from '../../contexts/LoadingContext';
import { useTranslation } from 'react-i18next';
import { useFetchAllAnnouncements } from '../../hooks/api/announcement/useFetchAllAnnouncements';
import { AnnouncementCard } from "./announcementCard";

const TitleArea = css`
  font-size: 20px;
  font-weight: bold;
  margin: 24px;
`;

const EmptyAnnouncement = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

export const Announcement: React.FC = () => {
  const { showLoading, hideLoading } = useLoading();
  const { t, i18n } = useTranslation();
  const lang =(i18n.language == 'ja') ? 'ja' : 'en';
  const { announcements, fetchAllAnnouncements } = useFetchAllAnnouncements(false, lang);

  const fetchAnnouncements = async () => {
    showLoading();
    await fetchAllAnnouncements();
    hideLoading();
  }

  React.useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <>
      <div css={TitleArea}>{t('information', 'お知らせ')}</div>
      {announcements?.length ? (
        <div>
          {announcements?.map((announcement) => {
            return <AnnouncementCard key={announcement.id} announcement={announcement}/>
          })}
        </div>
      ) : (
        <div css={EmptyAnnouncement}>
          {t('noInformation', 'お知らせはありません')}
        </div>
      )}
    </>
  );
};
