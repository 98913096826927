import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from '@mui/material';
import { color } from '../../styles';
import Badge from '@mui/material/Badge';

interface Props {
  path: string;
  listItemName: string;
  badgeCount?: number;
}

const Link = {
  padding: '8px 16px 8px 40px',
  width: '100%',
  color: '#000',
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'space-between',
};

const ActiveMenu = {
  fontWeight: 'bold',
  color: `${color.green}`,
  backgroundColor: `${color.light_green}`,
};

export const LeftNavListItem = React.memo(({ path, listItemName, badgeCount }: Props) => {
  return (
    <ListItem button disablePadding>
      <NavLink exact to={path} style={Link} activeStyle={ActiveMenu}>
        <div>{listItemName}</div>
        <div>{!!badgeCount && <Badge color='error' badgeContent={badgeCount} />}</div>
      </NavLink>
    </ListItem>
  );
});
