import React from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { PanelContainer } from '../../components/panel';
import { Order } from '../../types/api/order/getOrders';
import { SavedOrderTable } from '../../components/parts/savedOrderTable';
import { convertUtcToTzString } from '../../utils/dateutil';
import { useFetchOrders } from '../../hooks/api/order/useFetchOrders';
import { Loading } from '../../components/mui/Loading';
import { useLoading } from '../../contexts/LoadingContext';

export interface IGetOrder extends Order {
  displayUnitName: string; // 施設名がないものを施設全体として変換したもの
  displayTimestamp: string; // 日付をutcからタイムゾーン変換したもの
}

const Container = css`
  min-width: 600px;
`;

interface Props {
  url: string;
}

export const SavedOrder = ({ url }: Props) => {
  const { orders, fetchOrders } = useFetchOrders();
  const { showLoading, hideLoading } = useLoading();
  const [savedOrders, setSavedOrders] = React.useState<IGetOrder[]>();

  const handleFetchOrders = React.useCallback(async () => {
    showLoading();
    await fetchOrders();
    hideLoading();
  }, []);

  const formatUnitName = (unitName: string) => {
    return unitName ? unitName : '施設全体';
  };
  React.useEffect(() => {
    if (!orders) return;

    const savedOrders = orders.filter((order) => order.orderStatus === 'temporarilySaved');
    const formattedSavedOrders: IGetOrder[] = savedOrders.map((order: Order) => {
      return {
        ...order,
        displayTimestamp: convertUtcToTzString(order.updatedAt),
        displayUnitName: formatUnitName(order.nursingHomeUnitName),
      };
    });
    setSavedOrders(formattedSavedOrders);
  }, [orders]);

  return (
    <div css={Container}>
      <PanelContainer title={'消耗品の一時保存'}>
        {savedOrders ? (
          <>
            {savedOrders.length > 0 ? (
              <SavedOrderTable data={savedOrders} url={url} dataFetch={handleFetchOrders} />
            ) : (
              <>保存されている消耗品の発注データはありません</>
            )}
          </>
        ) : (
          <Loading />
        )}
      </PanelContainer>
    </div>
  );
};
