import React from 'react';
import { css } from '@emotion/react';
import { IGetUltraSonicData } from '../../../../../types/api/ultra-sonic-data/getUltraSonicDataSets';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/mui/button';
import { styled, SvgIconTypeMap } from '@mui/material';
import { color } from '../../../../../styles';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { useTranslation } from 'react-i18next';

interface Props {
  dataset: IGetUltraSonicData;
  addGraph: (start: string, end: string) => void;
  updateGraph: (start: string, end: string) => void;
  toggleFavorite: (start: string, end: string, favoriteId?: number) => void;
}

interface StarProps {
  isFavorite: boolean;
}

const Container = css`
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;

const Inner = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = css`
  display: flex;
`;

const StyledStar = styled(({ isFavorite, ...props }: StarProps & DefaultComponentProps<SvgIconTypeMap<{}, 'svg'>>) =>
  isFavorite ? <StarIcon {...props} /> : <StarOutlineIcon {...props} />
)`
  cursor: pointer;
  color: ${({ isFavorite }) => (isFavorite ? color.notification_yellow : color.gray)};
`;

export const DataSetListItem = React.memo(({ dataset, toggleFavorite, addGraph, updateGraph }: Props) => {
  const { t } = useTranslation();
  const handleClickAddGraph = React.useCallback(() => {
    addGraph(dataset.begin, dataset.end);
  }, []);

  const handleClickupdateGraph = React.useCallback(() => {
    updateGraph(dataset.begin, dataset.end);
  }, []);

  const handleClickFavorite = React.useCallback(() => {
    toggleFavorite(dataset.begin, dataset.end, dataset.favoriteId);
  }, []);

  return (
    <div css={Container}>
      <div>{dayjs(dataset.begin).format('YYYY/MM/DD HH:mm:ss')}~</div>
      <div css={Inner}>
        <StyledStar isFavorite={dataset.isFavorite} onClick={handleClickFavorite} />
        <div css={ButtonContainer}>
          <Button onClick={handleClickAddGraph}>{t('addGraph', 'グラフ追加')}</Button>
          <Button variant='contained' onClick={handleClickupdateGraph}>
            {t('view', '表示')}
          </Button>
        </div>
      </div>
    </div>
  );
});
