import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HeatmapModule from 'highcharts/modules/heatmap';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { color as COLOR } from '../../../../styles/color';

HeatmapModule(Highcharts);

interface Props {
  graphData: any;
  xCategories: string[];
  isPrinting?: boolean;
}

const GraphContainer = css`
  width: 100%;

  .highcharts-legend-item {
    border-radius: 1rem;

    & > span {
      display: flex;
      align-items: center;
      border-radius: 1rem;
      border: 2px solid ${COLOR.border_gray_dark};
      padding: 4px 8px;
      background-color: #ffffff;
    }
    &:hover {
      background-color: #f5f5f5;
    }

    .highcharts-point {
      display: none;
    }
  }

  .legend-marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

export const CareRecordStackbarGraph = React.memo(({ graphData, xCategories, isPrinting = false }: Props) => {
  const { t } = useTranslation();

  const customLegend = (color: string, name: string, visible: boolean) => {
    let bgColor = COLOR.button_gray;
    if (visible) bgColor = color;
    return `<span class="legend-marker" style="background:${bgColor}"></span>${name}`;
  };

  const option: Highcharts.Options = {
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      zoomType: 'x',
      margin: isPrinting ? [80, 120, 80, 80] : [80, 20, 80, 80],
      shadow: false,
      animation: false,
      backgroundColor: COLOR.light_gray,
    },
    xAxis: {
      categories: xCategories,
      tickmarkPlacement: 'on',
      showLastLabel: true,
      tickInterval: 1,
      gridLineWidth: 0,
      lineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      reversed: false,
      allowDecimals: false,
      title: { text: '' },
      gridLineWidth: 1,
      labels: { format: `{value} ${t('times', '回')}` },
    },
    plotOptions: { column: { stacking: 'normal' } },
    series: graphData,
    colors: ['#72fbfd', '#75f94c', '#CCCCCC', '#6D6D6D'],
    legend: {
      enabled: isPrinting ? false : true,
      verticalAlign: 'top',
      useHTML: true,
      itemDistance: -16,
      labelFormatter: function () {
        // @ts-ignore
        return customLegend(this.color, this.name, this.visible);
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
    },
  };

  React.useEffect(() => {
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.reflow();
      }
    });
  }, [isPrinting]);

  return (
    <div css={GraphContainer}>
      <HighchartsReact highcharts={Highcharts} options={option} />
    </div>
  );
});
