import React from 'react';
import { Button as MUIButton } from '@mui/material';

interface Props {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  startIcon?: React.ReactNode;
  onClick?: () => void;
}

export const Button: React.FC<Props> = React.memo(
  ({ children, type = 'button', variant, disabled = false, startIcon, onClick, ...props }) => {
    return (
      <MUIButton
        type={type}
        variant={variant}
        disabled={disabled}
        startIcon={startIcon}
        disableElevation
        onClick={onClick}
        {...props}
      >
        {children}
      </MUIButton>
    );
  }
);
