import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { DfreeGraphList } from '../pages/residents/dfreeGraphList';
import { ResidentSetting } from '../pages/residents/residentSetting';
import { ResidentDetailRouter } from './ResidentDetailRouter';

export const ResidentRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <DfreeGraphList />
      </Route>
      <Route path={`${path}/new`}>
        <ResidentSetting />
      </Route>
      <Route path={`${path}/:residentId`}>
        <ResidentDetailRouter />
      </Route>
      <Redirect path='*' to='/404' />
    </Switch>
  );
};
