import React from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import { PanelContainer } from '../../../../components/panel';
import { color } from '../../../../styles';
import { DEFAULT_LIFE_RESIDENT_FORM_VALUE } from '../../../../constants';
import { useSnackbar } from '../../../../contexts/SnackbarContext';
import { useFetchResident } from '../../../../hooks/api/resident/useFetchResident';
import { IGetResidentRes } from '../../../../types/api/resident/getResident';
import { IUpdateResidentParams } from '../../../../types/api/resident/updateResident';
import { Button } from '../../../../components/mui/button';
import { OutlineButton } from '../../../../components/outlineButton';
import { useLoading } from '../../../../contexts/LoadingContext';
import { LifeSettingForm } from './lifeSettingForm';

dayjs.extend(isSameOrAfter);

interface Props {
  residentId?: number;
}

const PanelButtonContainer = css`
  display: flex;
  gap: 0 16px;
`;

const LowerButtonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResidentLifeSetting: React.FC<Props> = ({ residentId }) => {
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_LIFE_RESIDENT_FORM_VALUE,
    mode: 'onBlur',
  });
  const { resident, fetchResident } = useFetchResident();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const setFormValue = (resident: IGetResidentRes) => {
    const initData = {
      firstFurigana: resident.firstFurigana,
      lastFurigana: resident.lastFurigana,
      birthday: resident.birthday,
      serviceCode: resident.lifeSettings?.serviceCode,
      insurerNo: resident.lifeSettings?.insurerNo,
      insuredNo: resident.lifeSettings?.insuredNo,
      certifiedDate: resident.lifeSettings?.certifiedDate,
      carePeriodStart: resident.lifeSettings?.carePeriodStart,
      carePeriodEnd: resident.lifeSettings?.carePeriodEnd,
      lifeCareLevel: resident.lifeSettings?.lifeCareLevel,
      impairedElderlyIndependenceDegree: resident.lifeSettings?.impairedElderlyIndependenceDegree,
      dementiaElderlyIndependenceDegree: resident.lifeSettings?.dementiaElderlyIndependenceDegree,
      startDate: resident.lifeSettings?.startDate,
      endDate: resident.lifeSettings?.endDate,
      deathDate: resident.lifeSettings?.deathDate,
      remarks: resident.lifeSettings?.remarks,
      version: resident.lifeSettings?.version,
      id: resident.lifeSettings?.id,
    };
    reset({ ...initData });
  };

  const handleNavigateLifeSettingsPage = React.useCallback(() => {
    history.push('../edit');
  }, []);

  const onSubmit = async (data: any) => {
    if (!resident) return;
    setOnSend(true);
    showLoading();
    const { firstFurigana, lastFurigana, birthday, ...lifeSettingsData } = data;
    if (!lifeSettingsData.id) {
      showSnackbar(t('somethingWentWrong', 'エラーが発生しました'), 'error');
      hideLoading();
      return;
    }
    const params: IUpdateResidentParams = {
      ...resident,
      firstFurigana,
      lastFurigana,
      birthday,
      lifeSettings: { ...lifeSettingsData },
    };

    try {
      let currentResidentId = null;
      await api.put('/residents/:residentId', { residentId, ...params });
      currentResidentId = residentId;
      setOnSend(false);
      hideLoading();
      showSnackbar(t('residentHasBeenUpdated', '入居者情報を更新しました'), 'success');
      history.push('/');
    } catch (e) {
      setOnSend(false);
      hideLoading();
      showSnackbar(t('somethingWentWrong', 'エラーが発生しました'), 'error');
    }
  };

  React.useEffect(() => {
    if (!residentId) return;
    const initFetch = async () => {
      try {
        showLoading();
        const resident = await fetchResident(residentId);
        if (!resident) {
          showSnackbar('入居者情報の取得に失敗しました', 'error');
          return;
        }
        setFormValue(resident);
        hideLoading();
      } catch (error) {
        showSnackbar(t('somethingWentWrong', 'エラーが発生しました'), 'error');
      }
    };
    initFetch();
  }, [residentId]);

  return (
    <>
      <PanelContainer
        title={'LIFE利用者設定'}
        rightElement={
          <div css={PanelButtonContainer}>
            <OutlineButton onClick={handleNavigateLifeSettingsPage}>{'入居者設定'}</OutlineButton>
          </div>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <LifeSettingForm resident={resident} control={control} errors={errors} />
          <div css={LowerButtonContainer}>
            <Button variant='contained' type='submit' disabled={onSend}>
              {t('save', '保存')}
            </Button>
          </div>
        </form>
      </PanelContainer>
    </>
  );
};
