import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HeatmapModule from 'highcharts/modules/heatmap';
import { t } from 'i18next';

HeatmapModule(Highcharts);

interface Props {
  heatmapData: HeatmapProps;
  isPrinting?: boolean;
}

interface HeatmapProps {
  data: number[][];
  xCategories: string[];
  yCategories: string[];
}

// 平均Heatmap生成
export const AverageHeatmapGraph = React.memo(({ heatmapData, isPrinting = false }: Props) => {
  const xAxisCategories = heatmapData.xCategories ? heatmapData.xCategories : [];
  const xAxisMax = heatmapData.xCategories ? heatmapData.xCategories.length - 1 : 0;
  const averageHeatmap = React.useMemo(() => {
    if (!heatmapData.data || !Array.isArray(heatmapData.data)) {
      return [];
    }

    const counts = new Map<number, number>();
    const sums = new Map<number, number>();
    const averageHeatmapData: number[][] = [];

    heatmapData.data.forEach(([x, , value]) => {
      counts.set(x, (counts.get(x) || 0) + 1);
      sums.set(x, (sums.get(x) || 0) + value);
    });

    for (let x = 0; x < heatmapData.xCategories.length; x++) {
      const count = counts.get(x) || 0;
      const sum = sums.get(x) || 0;
      const average = count !== 0 ? Math.round((sum / count) * 10) / 10 : 0;
      averageHeatmapData.push([x, 0, average]);
    }
    return averageHeatmapData;
  }, [heatmapData, heatmapData]);

  const option: Highcharts.Options = {
    title: {
      text: '',
    },
    chart: {
      zoomType: 'x',
      height: 30,
      marginLeft: 120,
      marginRight: isPrinting ? 180 : 48,
      marginTop: 0,
      marginBottom: 0,
      shadow: false,
      animation: false,
      backgroundColor: '#FFFFFF',
    },
    xAxis: {
      categories: xAxisCategories,
      min: 0,
      max: xAxisMax,
      tickInterval: 2,
      visible: false,
    },
    yAxis: {
      categories: [t('average', '平均')],
      reversed: false,
      gridLineWidth: 0,
      title: { text: '' },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    colorAxis: {
      min: 0,
      floor: 0,
      max: 10,
      minColor: '#EEFFEE',
      maxColor: '#00AA66',
    },
    series: [
      {
        type: 'heatmap',
        boostThreshold: 100,
        data: averageHeatmap,
        colsize: 1,
        rowsize: 1,
        index: 0,
        turboThreshold: Number.MAX_VALUE,
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            fontSize: '12px',
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{point.value}</b><br/>',
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
    },
  };

  React.useEffect(() => {
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.reflow();
      }
    });
  }, [isPrinting]);

  return <HighchartsReact highcharts={Highcharts} options={option} />;
});
