import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Cell, CellBase, HeaderCell, Row, Table, TextWrap } from '../../styles/table';
import { IGetResidentRes } from '../../types/api/resident/getResident';
import { CheckBoxCell } from '../../components/parts/checkboxCell';
import { useTranslation } from 'react-i18next';

interface Props {
  data: IDisplayResident[];
  onChange: (checkArray: boolean[]) => void;
}

interface IDisplayResident extends IGetResidentRes {
  isChecked: boolean; // テーブルのチェック状態の管理フラグ
}

// テーブルのセルの横幅を左から順に定義
const CheckBoxCellWidth = { flex: '0 0 88px' };
const CellWidth = [{ flex: '1 1 140px' }, { flex: '1 1 80px' }, { flex: '1 1 80px' }];

export const ResidentsTable = React.memo(({ data, onChange }: Props) => {
  const { t } = useTranslation();
  const tableHeader = [t('fullName', '氏名'), t('unitName', 'ユニット名'), t('roomName', '部屋名')];
  const [headerChecked, setHeaderChecked] = React.useState<boolean>(false);
  const [indeterminateFlag, setIndeterminateFlag] = React.useState<boolean>(false);

  const checkArray: boolean[] = React.useMemo(() => {
    return new Array(data.length).fill(false);
  }, [data]);

  const handleChangeCheckBox = (id: number) => (value: boolean) => {
    checkArray[id] = value;
    const flag = checkArray.find((v) => v) || checkArray.find((v) => !v) ? true : false;
    onChange(checkArray);
    setIndeterminateFlag(flag);
  };

  const handleChangeHeaderCheckBox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setHeaderChecked(checked);
    checked ? checkArray.fill(true) : checkArray.fill(false);
    onChange(checkArray);
    setIndeterminateFlag(false);
  };

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          <div css={[CellBase, HeaderCell, CheckBoxCellWidth]}>
            <Checkbox checked={headerChecked} onChange={handleChangeHeaderCheckBox} indeterminate={indeterminateFlag} />
          </div>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CheckBoxCellWidth]}>
                <CheckBoxCell value={checkArray[i]} onChange={handleChangeCheckBox(i)} />
              </div>
              <div css={[CellBase, Cell, CellWidth[0]]}>
                {data.lastName ?? ''} {data.name ?? ''}
              </div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.nursingHomeUnitName}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{data.roomName}</div>
            </div>
          );
        })}
      </div>
    </>
  );
});
