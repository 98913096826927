import React from 'react';
import { css } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { navSelector, navSlice } from '../../../redux/config/leftnav';
import api from '../../../api';
import { color } from '../../../styles';
import { useFetchNursingHomeCareRecord } from '../../../hooks/api/traversal/useFetchNursingHomeCareRecord';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { Dialog } from '../../../components/mui/dialog';
import { MenuItemProps, Select } from '../../../components/mui/select';
import { DateSelector } from '../../../components/mui/dateSelector';
import { ResidentListHeader } from '../../../components/residentListHeader';
import { BottomStickyButton } from '../../../components/bottomStickyButton';
import { BulkRecordItem, IBulkRecord } from './bulkRecordTable';
import { ICreateCareRecordMultipleParams } from '../../../types/api/care-record/createCareRecord';
import { IUpdateUrineVolumeReset } from '../../../types/api/resident/updateUrineVolumeReset';
import { residentListSettingsSelector } from '../../../redux/config/residentListSettings';
import { useLoading } from '../../../contexts/LoadingContext';
import { Loading } from '../../../components/mui/Loading';
import { SortType } from '@/types/components';

// TODO: ボタンの状態が変わった場合に、全ての入居者のボタンの再レンダリングが発生しているため

interface ITimeStamp {
  date: Dayjs;
  hour: number;
  minute: number;
}

const Center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const W100 = css`
  flex-shrink: 0;
  width: 100px;
  margin: 4px 0 12px 0;
`;

const W200 = css`
  flex-shrink: 0;
  width: 200px;
  margin: 4px 0 12px 0;
`;

const BodyContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const RecordContainer = css`
  overflow-y: auto;
  padding: 0 16px;
`;

const ButtonContainer = css`
  display: flex;
  gap: 0 2px;
`;

const LargeText = css`
  font-size: 18px;
  font-weight: bold;
`;

const DateModalContainer = css`
  margin-top: 16px;
  height: 88px;
  width: 100%;
  justify-content: center;
  gap: 0 8px;
`;

const EmptyResidentContainer = css`
  height: 100px;
  font-size: 16px;
`;

const ErrorText = css`
  text-align: center;
  color: ${color.red};
  font-size: 14px;
`;

export const BulkRecord = React.memo(() => {
  const createFilterType = (condition: 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit') => {
    if (['deviceAssigned', 'deviceAttached'].includes(condition)) {
      return condition as 'deviceAssigned' | 'deviceAttached';
    } else return null;
  };

  const now = dayjs();
  const fetchTime = dayjs().startOf('day').utc();
  const initialTimestamp = {
    date: now,
    hour: 0,
    minute: 0,
  };
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const {
    conditions: storeCondition,
    unitId: storeUnitId,
    sortType: storeSortType,
  } = useSelector(residentListSettingsSelector);
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();
  const history = useHistory();
  const dispatch = useDispatch();
  const { badge } = useSelector(navSelector);
  const [timestamp, setTimestamp] = React.useState<ITimeStamp>(initialTimestamp);
  const [isDateError, setDateError] = React.useState<boolean>(false);
  const [bulkData, setBulkData] = React.useState<IBulkRecord[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState<boolean>(false);
  const [isDateModalOpen, setIsDateModalOpen] = React.useState<boolean>(false);
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const { nursingHomeCareRecord, fetchNursingHomeCareRecord } = useFetchNursingHomeCareRecord(
    true,
    storeUnitId,
    fetchTime.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    fetchTime.add(1, 'second').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    createFilterType(storeCondition),
    storeSortType
  ); // 最終記録時間のみ必要なため、期間は1秒で取得

  // api
  const createCareRecord = async (careRecords: ICreateCareRecordMultipleParams) => {
    return await api.post('/nursing-homes/:nursingHomeId/care-records/multiple', {
      nursingHomeId: +nursingHomeId,
      careRecords,
    });
  };

  const updateUrineVolumeReset = async (residentId: number) => {
    const data: IUpdateUrineVolumeReset = {
      residentId,
      urineVolumeResetFlg: true,
    };
    return await api.put('/residents/:residentId/urine-volume-reset', data);
  };

  const hourList = React.useMemo(() => {
    const hourPerDay = 24; //24時間
    let hourList: MenuItemProps[] = [];
    for (let i = 0; i < hourPerDay; i++) {
      hourList.push({
        key: i,
        value: String(i),
      });
    }
    return hourList;
  }, []);

  const minuteList = React.useMemo(() => {
    const minPerHour = 60; //60分
    const interval = 15; //15分
    let minuteList: MenuItemProps[] = [];
    for (let i = 0; i < minPerHour / interval; i++) {
      minuteList.push({
        key: interval * i,
        value: String(interval * i),
      });
    }
    return minuteList;
  }, []);

  // initialValue
  const initialHour = React.useMemo(() => {
    if (!hourList) return;
    setTimestamp((prev) => {
      return { ...prev, hour: +hourList[now.hour()].key };
    });
    return hourList[now.hour()];
  }, [hourList]);

  const initialMinute = React.useMemo(() => {
    const interval = 15; //15分
    if (!minuteList) return;
    const index = Math.floor(now.minute() / interval);
    setTimestamp((prev) => {
      return { ...prev, minute: +minuteList[index].key };
    });
    return minuteList[index];
  }, [minuteList]);

  const handleUpdate = async () => {
    showLoading();
    await fetchNursingHomeCareRecord(
      storeUnitId,
      fetchTime.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      fetchTime.add(1, 'second').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      createFilterType(storeCondition),
      storeSortType
    );
    hideLoading();
  };

  const handleChangeListSettings = async (
    selectedUnitId: number | null,
    selectedCondition?: 'deviceAssigned' | 'deviceAttached',
    selectedSortType?: SortType
  ) => {
    showLoading();
    await fetchNursingHomeCareRecord(
      selectedUnitId ? selectedUnitId : null,
      fetchTime.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      fetchTime.add(1, 'second').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      selectedCondition ? selectedCondition : null,
      selectedSortType ? selectedSortType : storeSortType
    );
    hideLoading();
  };

  const handleChangeData = React.useCallback(
    (data: IBulkRecord) => {
      setBulkData((prev) => {
        const index = prev.findIndex((v) => v.residentId === data.residentId);
        if (index === -1) {
          // 配列に変更検知したユーザーがいなければ新規追加
          return [...prev, data];
        } else if (
          data.urinatedToilet === null &&
          data.padUrineVolumeType === null &&
          data.defecationVolumeType === null &&
          data.hardnessType === null &&
          data.diaper.useDefaultDiaper === null &&
          data.diaper.useDefaultPad === null
        ) {
          // ボタンの押下状態がなくなったユーザーは配列から除外(falseもあるためnullで判定)
          prev.splice(index, 1);
          return [...prev];
        } else {
          // 既に変更検知されたユーザーであればデータを上書きする
          prev[index] = data;
          return [...prev];
        }
      });
    },
    [bulkData]
  );

  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const onConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const onModalSubmit = (type: string) => () => {
    if (onSend) return;
    setOnSend(true);
    let sendTime = dayjs().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    if (type === 'changeDate') {
      sendTime = timestamp.date
        .local()
        .set('hour', timestamp.hour!)
        .set('minute', timestamp.minute!)
        .set('second', 0)
        .utc()
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    }
    // トイレかおむつのどちらかの排尿の記録がされた場合は片方が未選択でも無しとして補完する
    // また排尿の記録がある場合にはtoiletTypeとpadTypeも補完する
    const sendData = bulkData.map((v) => {
      return {
        ...v,
        urinatedToilet: v.urinatedToilet !== null ? v.urinatedToilet : v.padUrineVolumeType === null ? null : false,
        padUrineVolumeType:
          v.padUrineVolumeType !== null ? v.padUrineVolumeType : v.urinatedToilet === null ? null : 'none',
        toiletType: v.urinatedToilet === null && v.padUrineVolumeType === null ? null : 'toilet',
        padType: v.urinatedToilet === null && v.padUrineVolumeType === null ? null : 'pad',
        timestamp: sendTime,
        workingTime: 5,
      };
    });
    createCareRecord(sendData)
      .then((res) => {
        // トイレかおむつ・パッドの記録が有る場合は排泄されたとみなしグラフを落として、通知カバーも除去する
        sendData.forEach((v) => {
          if (v.urinatedToilet || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none')) {
            updateUrineVolumeReset(v.residentId).catch((err) => {
              console.log(err);
            });
            setTimeout(() => {
              handleResetNotification(v.residentId);
            }, 500);
          }
        });
        showSnackbar(t('recordSaved', '記録しました'));
        setOnSend(false);
        setIsConfirmModalOpen(false);
        history.push(`/nursinghome/${nursingHomeId}/records`);
      })
      .catch((err) => {
        setOnSend(false);
        console.log(err);
      });
  };

  // 通知アラートの消去とバッヂカウントの更新処理
  const handleResetNotification = React.useCallback(
    async (residentId: number) => {
      const resident = await api.get('/residents/:residentId', { params: { residentId } });
      const params = {
        ...resident,
        uncaredBigBladder: false,
        uncaredGotUp: false,
        uncaredUrination: false,
        uncaredSensorError: false,
        uncaredUrineReset: false,
        uncaredZeroReset: false,
        uncaredUpsideDown: false,
      };
      await api.put('/residents/:residentId', { residentId, ...params });
      if (badge.resident > 0) {
        dispatch(navSlice.actions.setResidentBadge(-1));
      }
    },
    [badge, nursingHomeId]
  );

  const handleOpenDateModal = () => {
    setIsDateModalOpen(true);
  };

  const onDateModalClose = () => {
    setIsDateModalOpen(false);
  };

  const handleChangeDate = (date: Dayjs) => {
    setTimestamp((prev) => {
      return { ...prev, date };
    });
    setDateError(false);
  };

  const handleChangeHour = (hour: MenuItemProps) => {
    setTimestamp((prev) => {
      return { ...prev, hour: +hour.key };
    });
    setDateError(false);
  };

  const handleChangeMinute = (min: MenuItemProps) => {
    setTimestamp((prev) => {
      return { ...prev, minute: +min.key };
    });
    setDateError(false);
  };

  // 5分ごとに入居者データを更新する
  React.useEffect(() => {
    const timer = setInterval(() => {
      handleUpdate();
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [storeUnitId, storeCondition, storeSortType]);

  return (
    <>
      <div css={BodyContainer}>
        <div css={RecordContainer}>
          <ResidentListHeader
            title={t('recordAll', '一括記録')}
            conditions={storeCondition}
            unitId={storeUnitId}
            sortType={storeSortType}
            onUpdate={handleUpdate}
            onChangeListSettings={handleChangeListSettings}
          />
          {nursingHomeCareRecord ? (
            nursingHomeCareRecord.residents.map((v) => {
              return <BulkRecordItem resident={v} key={v.residentId} onChange={handleChangeData} />;
            })
          ) : (
            <Loading />
          )}
          {!nursingHomeCareRecord?.residents.length && (
            <div css={[EmptyResidentContainer, Center]}>{t('thereAreNoResidents', '記録できる入居者が居ません')}</div>
          )}
        </div>
        <div css={ButtonContainer}>
          <BottomStickyButton disabled={!bulkData.length} onClick={handleOpenDateModal}>
            {t('recordAllByDateAndTime', '日時を指定して一括登録')}
          </BottomStickyButton>
          <BottomStickyButton disabled={!bulkData.length} onClick={handleOpenConfirmModal}>
            {t('recordAll', '一括登録')}
          </BottomStickyButton>
        </div>
      </div>
      {isConfirmModalOpen && (
        <Dialog isOpen={isConfirmModalOpen} title={''} onClose={onConfirmModalClose} onOkClick={onModalSubmit('now')}>
          <div css={Center}>
            <div css={LargeText}>{t('doYouWantRecordAll', '一括登録をしますか？')}</div>
          </div>
        </Dialog>
      )}
      {isDateModalOpen && (
        <Dialog isOpen={isDateModalOpen} title={''} onClose={onDateModalClose} onOkClick={onModalSubmit('changeDate')}>
          <div css={Center}>
            <div css={LargeText}>{t('doYouWantRecordAll', '一括登録をしますか？')}</div>
          </div>
          <div css={[DateModalContainer, Center]}>
            <div css={W200}>
              <DateSelector
                newValue={timestamp.date}
                label={t('pickDate', '日付を選択')}
                minDate={now.subtract(3, 'years')}
                maxDate={now}
                variant={'outlined'}
                onChange={handleChangeDate}
              />
            </div>
            <div css={W100}>
              <Select
                id='hour-selector'
                listData={hourList}
                value={initialHour}
                label={t('hour', '時')}
                fullWidth={true}
                onChange={handleChangeHour}
              />
            </div>
            <div css={W100}>
              <Select
                id='minute-selector'
                listData={minuteList}
                value={initialMinute}
                label={t('min', '分')}
                fullWidth={true}
                onChange={handleChangeMinute}
              />
            </div>
          </div>
          {isDateError && (
            <div css={ErrorText}>{t('Future date and time cannot be specified.', '未来の日時は指定できません。')}</div>
          )}
        </Dialog>
      )}
    </>
  );
});
