import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HeatmapModule from 'highcharts/modules/heatmap';
import { t } from 'i18next';

HeatmapModule(Highcharts);

interface Props {
  heatmapData: HeatmapProps;
  threshold: number;
  isPrinting?: boolean;
}

interface HeatmapProps {
  data: number[][];
  xCategories: string[];
  yCategories: string[];
}

// 閾値超過率Heatmap生成
export const OverThresholdHeatmapGraph = React.memo(({ heatmapData, threshold, isPrinting = false }: Props) => {
  const xAxisCategories = heatmapData.xCategories ? heatmapData.xCategories : [];
  const xAxisMax = heatmapData.xCategories ? heatmapData.xCategories.length - 1 : 0;
  const overThresholdHeatmap = React.useMemo(() => {
    if (!heatmapData.data || !Array.isArray(heatmapData.data)) {
      return [];
    }

    const counts = new Map<number, number>();
    const countOverThreshold = new Map<number, number>();

    heatmapData.data.forEach(([x, y, value]) => {
      counts.set(x, (counts.get(x) || 0) + 1);
      if (value >= threshold) {
        countOverThreshold.set(x, (countOverThreshold.get(x) || 0) + 1);
      }
    });
    const percentageOverThresholdData: number[][] = [];

    for (let x = 0; x < heatmapData.xCategories.length; x++) {
      const count = counts.get(x) || 0;
      const overThresholdCount = countOverThreshold.get(x) || 0;
      const percentage = count !== 0 ? Math.round((overThresholdCount * 100) / count) : 0;
      percentageOverThresholdData.push([x, 0, percentage]);
    }
    return percentageOverThresholdData;
  }, [threshold, heatmapData]);

  const option: Highcharts.Options = {
    title: {
      text: '',
    },
    chart: {
      zoomType: 'x',
      height: 30,
      marginLeft: 120,
      marginRight: isPrinting ? 180 : 48,
      marginTop: 0,
      marginBottom: 0,
      shadow: false,
      animation: false,
      backgroundColor: '#FFFFFF',
    },
    xAxis: {
      categories: xAxisCategories,
      min: 0,
      max: xAxisMax,
      tickInterval: 2,
      visible: false,
    },
    yAxis: {
      categories: [`${t('傾向値', '傾向値')}<br>(${t('thresholdValue', '閾値')} ${threshold})`],
      reversed: false,
      gridLineWidth: 0,
      title: { text: '' },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    colorAxis: {
      min: 0,
      floor: 0,
      max: 100,
      minColor: '#FFEEEE',
      maxColor: '#CC4444',
      labels: {
        format: '{value}%',
      },
    },
    series: [
      {
        type: 'heatmap',
        boostThreshold: 100,
        data: overThresholdHeatmap,
        colsize: 1,
        rowsize: 1,
        index: 0,
        turboThreshold: Number.MAX_VALUE,
        dataLabels: {
          enabled: true,
          color: '#000000',
          style: {
            fontSize: '12px',
          },
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{point.value}</b><br/>',
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
    },
  };

  React.useEffect(() => {
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.reflow();
      }
    });
  }, [isPrinting]);

  return <HighchartsReact highcharts={Highcharts} options={option} />;
});
