import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/react';
import { PanelContainer } from '../../components/panel';
import api from '../../api';
import { color } from '../../styles';
import { LAXATIVE_UNIT } from '../../constants';
import { IGetLaxativeRes } from '../../types/api/care-settings/getLaxative';
import { IGetDrinkRes } from '../../types/api/care-settings/getDrink';
import { IGetDiaperRes } from '../../types/api/care-settings/getDiaper';
import { OutlineButton } from '../../components/outlineButton';
import { LaxativeTable } from '../../components/parts/laxativeTable';
import { DiaperTable } from '../../components/parts/diaperTable';
import { DrinkTable } from '../../components/parts/drinkTable';

export interface IGetLaxative extends IGetLaxativeRes {
  displayUnit: string; // 日本語化された下剤の単位
}

const TableTitle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-bottom: 8px;

  p {
    font-size: 16px;
    font-weight: bold;
    color: ${color.dark_gray};
  }
`;

const TableContainer = css`
  margin-bottom: 24px;
`;

const AddButton = css`
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid ${color.dark_gray};
  border-radius: 18px;
  padding: 0 16px;

  &:hover {
    background-color: ${color.bg_gray};
  }
`;

export const ExpendableList = () => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { url } = useRouteMatch();
  const [laxatives, setLaxatives] = React.useState<IGetLaxative[] | null>(null);
  const [drinks, setDrinks] = React.useState<IGetDrinkRes[] | null>(null);
  const [pants, setPants] = React.useState<IGetDiaperRes[] | null>(null);
  const [pads, setPads] = React.useState<IGetDiaperRes[] | null>(null);
  const history = useHistory();

  const fetchLaxatives = async () => {
    const laxatives = await api.get('/nursing-homes/:nursingHomeId/laxatives', {
      params: { nursingHomeId },
    });
    const formattedLaxatives = laxatives.map((laxative: IGetLaxativeRes) => {
      return { ...laxative, displayUnit: LAXATIVE_UNIT[laxative.unit] };
    });
    setLaxatives(formattedLaxatives);
  };

  const fetchDrinks = async () => {
    const drinks = await api.get('/nursing-homes/:nursingHomeId/drinks', {
      params: { nursingHomeId },
    });
    setDrinks(drinks);
  };

  const fetchDiaper = async () => {
    const diapers = await api.get('/nursing-homes/:nursingHomeId/diapers', {
      params: { nursingHomeId, sort: 'desc', limit: 10000 },
    });

    // データをパンツとパッドに分ける
    const pants = diapers.filter((v) => v.diaperShape === 'outer');
    const pads = diapers.filter((v) => v.diaperShape === 'inner');

    setPants(pants);
    setPads(pads);
  };

  // type = diaper | padがくる  diaperShape = outer | innerがくる
  const handleOnClickAddButton = (type: string, diaperShape?: string) => () => {
    if (diaperShape) {
      history.push(`${url}/${type}-${diaperShape}/new`);
    } else {
      history.push(`${url}/${type}/new`);
    }
  };

  React.useEffect(() => {
    fetchLaxatives();
    fetchDrinks();
    fetchDiaper();
  }, []);

  return (
    <PanelContainer title={'下剤・消耗品の設定'}>
      <div css={TableTitle}>
        <p>下剤</p>
        <OutlineButton icon={'╋'} onClick={handleOnClickAddButton('laxative')}>
          追加
        </OutlineButton>
      </div>
      <div css={TableContainer}>
        {laxatives && laxatives.length ? (
          <LaxativeTable data={laxatives} url={url} dataFetch={fetchLaxatives} />
        ) : (
          <>{'下剤が登録されていません'}</>
        )}
      </div>
      <div css={TableTitle}>
        <p>ドリンク</p>
        <OutlineButton icon={'╋'} onClick={handleOnClickAddButton('drink')}>
          追加
        </OutlineButton>
      </div>
      <div css={TableContainer}>
        {drinks && drinks.length ? (
          <DrinkTable data={drinks} url={url} dataFetch={fetchDrinks} />
        ) : (
          <>{'ドリンクが登録されていません'}</>
        )}
      </div>
      <div css={TableTitle}>
        <p>テープ/パンツ</p>
        <OutlineButton icon={'╋'} onClick={handleOnClickAddButton('diaper', 'outer')}>
          追加
        </OutlineButton>
      </div>
      <div css={TableContainer}>
        {pants && pants.length ? (
          <DiaperTable data={pants} type='outer' url={url} dataFetch={fetchDiaper} />
        ) : (
          <>{'テープ/パンツが登録されていません'}</>
        )}
      </div>
      <div css={TableTitle}>
        <p>パッド</p>
        <OutlineButton icon={'╋'} onClick={handleOnClickAddButton('diaper', 'inner')}>
          追加
        </OutlineButton>
      </div>
      <div css={TableContainer}>
        {pads && pads.length ? (
          <DiaperTable data={pads} type='inner' url={url} dataFetch={fetchDiaper} />
        ) : (
          <>{'パッドが登録されていません'}</>
        )}
      </div>
    </PanelContainer>
  );
};
