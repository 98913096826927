import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AuthError } from 'firebase/auth';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { color } from '../styles';
import { useAuth, auth } from '../contexts/AuthContext';
import { emailValidation } from '../utils/validation';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const container = css`
  margin-top: 20px;
`;

const CheckboxContainer = css`
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 16px;
`;

const StyledTextField = css`
  width: 100%;
  height: 80px;
`;

const StyledButton = css`
  color: white;
`;

const StyledCheckbox = css`
  padding: 6px;
`;

const StyledLink = css`
  display: block;
  color: ${color.green};
  height: 24px;
  margin-top: 16px;
`;

const warnMsg = css`
  margin-top: 8px;
  color: ${color.red};
`;

const Adornment = css`
  &&.MuiInputAdornment-root {
    margin-left: -40px;
  }
`;

export const Login = () => {
  const history = useHistory();
  const { login } = useAuth();
  const { t } = useTranslation();
  const [form, setForm] = React.useState({ email: '', password: '' });
  const [isBlankEmail, setIsBlankEmail] = React.useState<boolean>(false);
  const [isBlankPassword, setIsBlankPassword] = React.useState<boolean>(false);
  const [isPersistenceChecked, setPersistenceChecked] = React.useState(false);
  const [emailHelperText, setEmailHelperText] = React.useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = React.useState<string>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>('');
  const dispatch = useDispatch();

  const handleCheckPersistence = () => {
    setPersistenceChecked(!isPersistenceChecked);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (emailValidation(event.target.value)) {
      setIsBlankEmail(false);
      setEmailHelperText('');
    } else {
      setIsBlankEmail(true);
      setEmailHelperText(t('pleaseEnterUsingCorrectEmailAddressFormat', 'メールアドレスの形式で入力してください'));
    }
    setForm((prev) => {
      return { ...prev, email: event.target.value };
    });
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setIsBlankPassword(false);
      setPasswordHelperText('');
    } else {
      setIsBlankPassword(true);
      setPasswordHelperText(t('pleaseEnterYourPassword', 'パスワードを入力してください'));
    }
    setForm((prev) => {
      return { ...prev, password: event.target.value };
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await auth.setPersistence(isPersistenceChecked ? 'local' : 'session');
    await login(form.email, form.password)
      .then(async (res: any) => {
        if (res.user) {
          history.push(`/`);
        }
      })
      .catch((err: AuthError) => {
        if ('auth/user-disabled'.includes(err.code))
          setErrorText(t('userNotEnabled', 'ユーザーが有効化されていません'));
        else if (
          ['auth/user-not-found', 'auth/invalid-email', 'auth/wrong-password', 'auth/too-many-requests'].includes(
            err.code
          )
        )
          setErrorText(t('emailAddressOrPasswordIsDifferent', 'メールアドレスまたはパスワードが違います'));
        else setErrorText(t('emailAddressOrPasswordIsDifferent', 'メールアドレスまたはパスワードが違います'));
      });
  };

  return (
    <>
      <div css={container}>
        <TextField
          error={isBlankEmail}
          css={StyledTextField}
          id='email'
          label={t('emailAddress', 'メールアドレス')}
          variant='outlined'
          onChange={handleChangeEmail}
          type='email'
          autoComplete='email'
          helperText={emailHelperText}
        />
      </div>
      <div>
        <TextField
          error={isBlankPassword}
          css={StyledTextField}
          id='password'
          label={t('password', 'パスワード')}
          variant='outlined'
          onChange={handleChangePassword}
          type={showPassword ? 'text' : 'password'}
          autoComplete='current-password'
          helperText={passwordHelperText}
          InputProps={{
            endAdornment: (
              <InputAdornment css={Adornment} position='end'>
                <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div css={CheckboxContainer} onClick={handleCheckPersistence}>
        <Checkbox checked={isPersistenceChecked} css={StyledCheckbox} />
        <span>{t('automaticallyLoginFromNextTime', '次回から自動ログイン')}</span>
      </div>
      <div>
        <Button
          css={StyledButton}
          onClick={handleSubmit}
          variant='contained'
          disabled={isBlankEmail || isBlankPassword}
        >
          {t('login', 'ログイン')}
        </Button>
      </div>
      <a href='/reset-password' css={StyledLink}>
        {t('didYouForgetYourPassword', 'パスワードをお忘れですか')}?
      </a>
      {errorText && <div css={warnMsg}>{errorText}</div>}
    </>
  );
};
