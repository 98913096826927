import React from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import HeatmapModule from 'highcharts/modules/heatmap';
import {
  ACTIVITY_STATUS_POS_CODES_EN,
  ACTIVITY_STATUS_POS_CODES_JA,
  ACTIVITY_STATUS_POS_NUM,
} from '../../../../constants/activityStatus';

HeatmapModule(Highcharts);
dayjs.extend(isSameOrBefore);

interface Props {
  heatmapData: { data: any; xCategories: string[]; yCategories: string[] };
  isPrinting?: boolean;
}

export const ActivityStatusHeatmapGraph = React.memo(({ heatmapData, isPrinting = false }: Props) => {
  const { i18n } = useTranslation();
  const xAxisMax = heatmapData.xCategories ? heatmapData.xCategories.length - 1 : 0;
  const yAxisMax = heatmapData.yCategories ? heatmapData.yCategories.length - 1 : 0;

  const option: Highcharts.Options = {
    title: {
      text: '',
    },
    chart: {
      zoomType: 'x',
      marginLeft: 120,
      marginRight: isPrinting ? 180 : 48,
      marginTop: 0,
      marginBottom: 42,
      shadow: false,
      animation: false,
      backgroundColor: '#FFFFFF',
      height: heatmapData.yCategories ? heatmapData.yCategories.length * 30 + 60 : 100,
    },
    xAxis: {
      categories: heatmapData.xCategories,
      min: 0,
      max: xAxisMax,
      tickInterval: 12,
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    yAxis: {
      reversed: false,
      categories: heatmapData.yCategories,
      min: 0,
      max: yAxisMax,
      gridLineWidth: 0,
      title: { text: '' },
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    colorAxis: {
      min: 0,
      max: 7,
      // stopsの値は色コードは姿勢コード÷姿勢の総数で求めた値を入れる
      stops: [
        [0, '#FFF4CC'],
        [0.125, '#FFF4CC'],
        [0.25, '#000000'],
        [0.375, '#141F80'],
        [0.5, '#D7F5FF'],
        [0.625, '#5855DB'],
        [0.75, '#4EC1D0'],
        [0.875, '#B092E2'],
        [1, '#FF7152'],
      ],
    },
    series: [
      {
        type: 'heatmap',
        boostThreshold: 100,
        data: heatmapData.data,
        colsize: 1,
        rowsize: 1,
        index: 0,
        turboThreshold: Number.MAX_VALUE,
        dataLabels: { enabled: false },
        tooltip: {
          headerFormat: '',
          pointFormatter: function () {
            // 挙動が重い場合、xCategoriesの要素が日跨ぎかどうかの判定を親コンポーネントで持ってもよい
            const value = this.value;
            const POS_CODE = i18n.language === 'ja' ? ACTIVITY_STATUS_POS_CODES_JA : ACTIVITY_STATUS_POS_CODES_EN;
            const xCategory = this.x !== undefined ? heatmapData.xCategories[this.x] : '';
            const yCategory = this.y !== undefined ? heatmapData.yCategories[this.y] : '';
            const pos =
              typeof value === 'number' && value >= 0 && value <= 8 ? POS_CODE[value as ACTIVITY_STATUS_POS_NUM] : '';

            if (xCategory === '' || yCategory === '') return `<strong>${pos}</strong>`;

            const firstDate = dayjs(heatmapData.xCategories[0], 'HH:mm');
            const lastDate = dayjs(heatmapData.xCategories[heatmapData.xCategories.length - 1], 'HH:mm');
            const currentDate = dayjs(heatmapData.xCategories[this.x], 'HH:mm');

            let offsetYcategory;
            if (firstDate.isSameOrBefore(lastDate) || currentDate.isSameOrAfter(firstDate)) {
              offsetYcategory = yCategory;
            } else {
              offsetYcategory = dayjs(yCategory).add(1, 'day').format('YYYY/MM/DD');
            }
            return `${offsetYcategory} ${xCategory}<br><strong>${pos}</strong>`;
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={option} />
    </div>
  );
});
