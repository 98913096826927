import React from 'react';
import { Cell, CellBase, HeaderCell, Row, Table, TextWrap } from '../../../styles/table';
import { equipmentDiapers, equipmentPads } from '../../../types/api/traversal/getNursingHomeEquipments';
import { numInsertComma } from '../../../utils/dataFormatUtil';
import { formatSize } from '../../../utils/dataLabelUtil';

interface Props {
  data: equipmentDiapers[] | equipmentPads[];
  type: 'pants' | 'pads';
}

// テーブルのセルの横幅を左から順に定義
const CellWidth = [
  { flex: '2 1 160px' },
  { flex: '3 1 400px' },
  { flex: '1 1 80px' },
  { flex: '1 1 80px' },
  { flex: '1 1 90px' },
];

export const EquipmentsTable = React.memo(({ data, type }: Props) => {
  const tableHeader = ['シリーズ', '製品名', 'サイズ', '合計数', '合計金額'];

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0]]}>{data.series}</div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.name ?? '-'}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>
                {type === 'pants' ? formatSize(data.size ?? '') : (data.size ?? '') + 'ml'}
              </div>
              <div css={[CellBase, Cell, CellWidth[3]]}>{data.amount ?? 0}枚</div>
              <div css={[CellBase, Cell, CellWidth[4]]}>{numInsertComma(data.totalCost ?? 0)}円</div>
            </div>
          );
        })}
      </div>
    </>
  );
});
