import dayjs from 'dayjs';
import { AnalysisTableData } from '../pages/analysis/order';
import { IGetCareRecordOrderAnalysis } from '../types/api/traversal/getCareRecordOrderAnalysis';
import { IGetOrderAnalysis } from '../types/api/traversal/getOrderAnalysis';

export function mergeAnalysis(orders: IGetOrderAnalysis[], records: IGetCareRecordOrderAnalysis[]) {
  // const mergedAnalysis:IGetOrderAnalysis[]  = records.map(record => {
  //   const order = orders.find(order => order.yyyyMM === record.yyyyMM);
  //   const yyyyMM = dayjs(record.yyyyMM).format('YYYY日MM月');
  // })
}

export function calcAnalysisAverage(data: AnalysisTableData[]) {
  const total = data.reduce((prev, current) => {
    return {
      ...prev,
      activeUser: prev.activeUser + current.activeUser,
      capacity: prev.capacity + current.capacity,
      deliveryPrice: prev.deliveryPrice + current.deliveryPrice,
      occupancyRate: prev.occupancyRate ? prev.occupancyRate + current.occupancyRate : current.occupancyRate,
      invoice: prev.invoice + current.invoice,
      numberOfDiaper: prev.numberOfDiaper + current.numberOfDiaper,
      numberOfPad: prev.numberOfPad + current.numberOfPad,
      absorption: prev.absorption + current.absorption,
    };
  });

  const average = {
    ...total,
    yyyyMM: '平均値',
    activeUser: total.activeUser > 0 ? Math.round(total.activeUser / data.length) : 0,
    capacity: total.capacity > 0 ? Math.round(total.capacity / data.length) : 0,
    deliveryPrice: total.deliveryPrice > 0 ? Math.round(total.deliveryPrice / data.length) : 0,
    occupancyRate: total.occupancyRate > 0 ? Math.round(total.occupancyRate / data.length) : 0,
    invoice: total.invoice > 0 ? Math.round(total.invoice / data.length) : 0,
    numberOfDiaper: total.numberOfDiaper > 0 ? Math.round(total.numberOfDiaper / data.length) : 0,
    numberOfPad: total.numberOfPad > 0 ? Math.round(total.numberOfPad / data.length) : 0,
    absorption: total.absorption > 0 ? Math.round(total.absorption / data.length) : 0,
  };

  return average;
}
