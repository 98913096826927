import React, { CSSProperties } from 'react';
import { TextField } from '@mui/material';

// id: (必須)入力エリアに指定するID
// value: (必須)入力エリアに入力されている値
// label: 入力エリアに表示するラベル名
// type: HTMLInputElementの指定（https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types）
// autoComplete: フォーム記憶の設定
// helperText: フォーム下に表示される説明文
// isError: フォームをエラー表示のスタイルに切り替えるか
// multiline: フォームを複数行にまたがることを許可するか
// disabled: フォームを非活性化するかどうか
// fullWidth: フォーム幅を最大幅とするか
// style: CSSのスタイル設定
// className: CSSスタイル付きのClassNameを定義する時に使用 (emotionCSSを利用する時などに)
// onChange: (必須)フォームの内容が変更された場合に発火し、イベント内容を返す

interface Props {
  id: string;
  value: any;
  label?: string;
  type?: 'text' | 'number' | 'email' | 'tel' | 'url' | 'search';
  autoComplete?: 'on' | 'off' | 'username' | 'email' | 'current-password' | 'url';
  errorText?: string;
  isError?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: CSSProperties;
  className?: string;

  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const StyledTextField = React.memo(
  ({
    id,
    value,
    label = '',
    type = 'text',
    autoComplete = 'off',
    errorText = '',
    isError = false,
    multiline = false,
    disabled = false,
    fullWidth = false,
    style,
    className,
    onChange,
  }: Props) => {
    const [inputValue, setInputValue] = React.useState<any>('');
    const [helperText, setHelperText] = React.useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      onChange(event);
    };

    React.useEffect(() => {
      if (isError) setHelperText(errorText);
      else setHelperText('');
    }, [isError]);

    React.useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <>
        <TextField
          label={label}
          id={id}
          variant='outlined'
          multiline={multiline}
          type={type}
          autoComplete={autoComplete}
          helperText={helperText}
          error={isError}
          value={inputValue}
          disabled={disabled}
          style={style}
          className={className}
          onChange={handleChange}
          fullWidth={fullWidth}
        />
      </>
    );
  }
);
