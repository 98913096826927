import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoginLayout } from '../layouts/loginLayout';
import { Login } from '../pages/login';
import { PasswordReset } from '../pages/password-reset';
import { PrivateRoute } from './PrivateRoute';
import { useAuth } from '../contexts/AuthContext';
import { selfDataSelector } from '../redux/config/selfData';
import { RedirectLayout } from '../layouts/redirectLayout';
import { NotFound } from '../pages/404';
import { NursingHomeRouter } from './NursingHomeRouter';

export const Router: React.FC = () => {
  const { currentUser } = useAuth();
  const { selfUser } = useSelector(selfDataSelector);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path='/'>
            {currentUser.user ? (
              <RedirectLayout>
                <Redirect exact to={`/nursinghome/${selfUser.nursingHomeId}/residents`} />
              </RedirectLayout>
            ) : (
              <Redirect exact to='/login' />
            )}
          </PrivateRoute>
          <Route exact path='/login'>
            <LoginLayout>
              <Login />
            </LoginLayout>
          </Route>
          <Route exact path='/reset-password'>
            <LoginLayout>
              <PasswordReset />
            </LoginLayout>
          </Route>
          <PrivateRoute path='/nursinghome/:nursingHomeId'>
            <NursingHomeRouter />
          </PrivateRoute>
          <Route path='/404'>
            <NotFound />
          </Route>
          <Redirect path='*' to='/404' />
        </Switch>
      </BrowserRouter>
    </>
  );
};
