import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import api from '../../../api';
import { PanelContainer } from '../../../components/panel';
import { color } from '../../../styles';
import { IGetDrinkRes } from '../../../types/api/care-settings/getDrink';
import { IUpdateDrinkParams } from '../../../types/api/care-settings/updateDrink';

interface Props {
  url: string;
}

const TextFieldWrapper = css`
  margin-top: 24px;
`;

const TextFieldContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TextFieldTitle = css`
  font-size: 16px;
  width: 60px;
  margin-bottom: 24px;

  span {
    margin-right: 4px;
    color: ${color.red};
    font-size: 16px;
    font-weight: bold;
  }
`;

const StyledInput = css`
  max-width: 560px;
  width: 80%;
  height: 80px;
`;

const ButtonContainer = css`
  display: flex;
  justify-content: center;
`;

const StyledButton = css`
  margin: 16px 0 16px 32px;
  min-width: 80px;
`;

export const UpdateDrink = ({ url }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { drinkId } = useParams<{ drinkId: string }>();
  const [nameHelperText, LaxativeHelperText] = React.useState<string>('');
  const [isPassedValidation, setIsPassedValidation] = React.useState<boolean>(false);
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [sendData, setSendData] = React.useState<IUpdateDrinkParams>({ name: '', memo: '' });
  const history = useHistory();
  const [initDrinkName, setInitLaxativeName] = React.useState<string | null>(null);
  const [pageTitle, setPageTitle] = React.useState<string>('');

  // Addの場合のフォームの初期値設定
  const initAddMode = () => {
    setPageTitle('ドリンクの追加');
    setInitLaxativeName('');
    setSendData({ name: '', memo: '' });
  };

  // Editの場合のフォームの初期値設定
  const initEditMode = (drink: IGetDrinkRes) => {
    setPageTitle('ドリンクの編集');
    setInitLaxativeName(drink.name);
    setSendData({ name: drink.name, memo: drink.memo });
    setIsPassedValidation(true);
  };

  const fetchDrink = async () => {
    const drink = await api.get('/drinks/:nursingHomeDrinkId', {
      params: { nursingHomeDrinkId: drinkId },
    });
    return drink;
  };

  const createDrink = async () => {
    return await api.post('/nursing-homes/:nursingHomeId/drinks', {
      nursingHomeId,
      name: sendData.name,
      memo: sendData.memo,
    });
  };

  const updateDrink = async () => {
    return await api.put('/drinks/:nursingHomeDrinkId', {
      nursingHomeDrinkId: drinkId,
      name: sendData.name,
      memo: sendData.memo,
    });
  };

  const handleDrinkNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      LaxativeHelperText('');
      setIsPassedValidation(true);
      setSendData((prev) => {
        return { ...prev, name: event.target.value };
      });
    } else {
      LaxativeHelperText('飲料名は必須入力です');
      setIsPassedValidation(false);
    }
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(url);
  };

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    setOnSend(true);
    // paramsにidがある場合はput、そうでない場合はpostとして処理
    if (drinkId) {
      updateDrink();
    } else {
      createDrink();
    }
    setTimeout(() => {
      setOnSend(false);
      history.push(url);
    }, 500);
  };

  React.useEffect(() => {
    // paramsにidがある場合はEdit、そうでない場合はAddとして処理
    if (drinkId) {
      const init = async () => {
        const drink = await fetchDrink();
        initEditMode(drink);
      };
      init();
    } else {
      initAddMode();
    }
  }, []);

  return (
    <PanelContainer title={pageTitle}>
      <div css={TextFieldWrapper}>
        <div css={TextFieldContainer}>
          {!(initDrinkName === null) && (
            <>
              <div css={TextFieldTitle}>
                名前<span>*</span>:
              </div>
              <TextField
                css={StyledInput}
                id='drinkName'
                label='飲料名'
                variant='outlined'
                onChange={handleDrinkNameChange}
                type='text'
                autoComplete='no'
                defaultValue={initDrinkName}
                helperText={nameHelperText}
                error={!isPassedValidation && !!nameHelperText}
              />
            </>
          )}
        </div>
      </div>
      <div css={ButtonContainer}>
        <Button css={StyledButton} color={'secondary'} onClick={handleCancel} variant='contained'>
          キャンセル
        </Button>
        <Button css={StyledButton} onClick={handleSubmit} variant='contained' disabled={!isPassedValidation || onSend}>
          OK
        </Button>
      </div>
    </PanelContainer>
  );
};
