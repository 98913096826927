import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { selfDataSelector } from '../../../redux/config/selfData';
import { color } from '../../../styles';
import { MenuItemProps, Select } from '../../../components/mui/select';

interface Props {
  onChangeUnitFilter: (selected: MenuItemProps) => void;
  onChangeDateFilter: (selected: MenuItemProps) => void;
}

interface ItemProps {
  key: number;
  value: string;
}

const FilterSelectorContainer = css`
  width: 100%;
  padding: 16px;
  border: 1px solid ${color.border_gray};
  border-radius: 4px;
  background-color: ${color.bg_gray};
`;

const SectionTitle = css`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const SelectorContainer = css`
  display: flex;
  gap: 24px;
`;

const SelectorWrapper = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledInput = css`
  min-width: 200px;
  &&.MuiFormControl-root {
    background-color: ${color.white};
  }
`;

const SelectorLabel = css`
  min-width: 80px;
  font-size: 16px;
`;

export const HistoryFilterSelector = React.memo(({ onChangeDateFilter, onChangeUnitFilter }: Props) => {
  const { selfUnits } = useSelector(selfDataSelector);
  const { selfUser } = useSelector(selfDataSelector);
  const defaultUnit = { key: 999999, value: 'すべて' };
  const dateFilterList = [
    { key: 1, value: '直近1ヶ月' },
    { key: 3, value: '直近3ヶ月' },
    { key: 6, value: '直近6ヶ月' },
    { key: 12, value: '直近12ヶ月' },
    { key: 60, value: 'すべての期間' },
  ];
  const [dateFilterValue, setDateFilterValue] = React.useState<{ key: number; value: string }>(dateFilterList[0]);
  const [unitValue, setUnitValue] = React.useState<{ key: number; value: string }>(defaultUnit);
  const [selfUnit, setSelfUnit] = React.useState<{ key: number; value: string }>();

  // ユニットの絞り込みに対して key:0は施設全体 key:999999は全てを表す
  const units = React.useMemo(() => {
    let unitList = [
      { key: 999999, value: 'すべて' },
      { key: 0, value: '施設全体' },
    ];
    if (!selfUnits) return unitList;
    selfUnits.forEach((unit) => {
      unitList.push({ key: unit.id, value: unit.name });
    });
    return unitList;
  }, [selfUnits]);

  // ログインユーザーがUnitPermを持っていた場合、Unit情報に自身のUnitを入れる
  React.useEffect(() => {
    if (!selfUser || !units) return onChangeUnitFilter(defaultUnit);
    if (selfUser.hasUnitPerm) {
      const unit =
        units.find((v) => {
          return v.key === selfUser.nursingHomeUnitId;
        }) ?? defaultUnit;
      onChangeUnitFilter(unit);
      setSelfUnit(unit);
    } else {
      onChangeUnitFilter(defaultUnit);
    }
  }, [selfUser, units]);

  React.useEffect(() => {
    onChangeDateFilter(dateFilterList[0]);
  }, []);

  return (
    <div css={FilterSelectorContainer}>
      <div css={SectionTitle}>絞り込み</div>
      <div css={SelectorContainer}>
        <div css={SelectorWrapper}>
          <div css={SelectorLabel}>注文日:</div>
          {units && (
            <Select
              id='filter-date-selector'
              listData={dateFilterList}
              value={dateFilterValue}
              label=''
              onChange={onChangeDateFilter}
              className={StyledInput}
            />
          )}
        </div>
        <div css={SelectorWrapper}>
          {!selfUser.hasUnitPerm && (
            <>
              <div css={SelectorLabel}>ユニット:</div>
              {units && (
                <Select
                  id='unit-selector'
                  listData={units}
                  value={unitValue}
                  label=''
                  onChange={onChangeUnitFilter}
                  className={StyledInput}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});
