import React from 'react';
import { css } from '@emotion/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useFetchResidentLifeCarePlannings } from '../../../../hooks/api/life/useFetchResidentLifeCarePlannings';
import { LifeCarePlanningTable } from './carePlanningTable';
import { OutlineButton } from '../../../../components/outlineButton';

interface Props {
  nursingHomeId: string;
  residentId: string;
}

const Container = css`
  padding: 24px;
`;

const UpperContianer = css`
  display: flex;
  justify-content: end;
  margin-bottom: 16px;
`;

const Caution = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

export const ResidentDetailLifeCarePlannings: React.FC<Props> = React.memo(({ nursingHomeId, residentId }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { resdientLifeCarePlannings, fetchResidentLifeCarePlannings } = useFetchResidentLifeCarePlannings(
    true,
    +nursingHomeId,
    +residentId
  );

  const handleClickAddButton = () => {
    history.push(`${url}/new`);
  };

  const handleClickEditCell = (id: number) => {
    history.push(`${url}/${id}/edit`);
  };

  return (
    <div css={Container}>
      <div css={UpperContianer}>
        <OutlineButton icon={'╋'} onClick={() => handleClickAddButton()}>
          新規排泄支援情報の作成
        </OutlineButton>
      </div>
      {resdientLifeCarePlannings?.length ? (
        <LifeCarePlanningTable data={resdientLifeCarePlannings} onClickEditCell={handleClickEditCell} />
      ) : (
        <div css={Caution}>データがありません</div>
      )}
    </div>
  );
});
