import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetOrderSummaryRes } from '../../../types/api/traversal/getOrderSummary';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  // sort: string; ソートが対応したら追加
  // limit: number; リミットが対応したら追加
  from: string;
  to: string;
}

export const useFetchOrdersSummary = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeUnitId: number | null = null,
  from?: string,
  to?: string
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [ordersSummary, setOrdersSummary] = React.useState<IGetOrderSummaryRes[]>();

  const fetchOrdersSummary = async (nursingHomeUnitId: number | null = null, from: string, to: string) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      from,
      to,
    };

    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/traversal/nursing-homes/:nursingHomeId/orders/summary', {
      params,
    });

    // unitIdが0の場合は施設全体を取り出す
    if (nursingHomeUnitId === 0) {
      result = result.filter((v) => v.nursingHomeUnitId === null);
    }

    setOrdersSummary(result);
  };

  React.useEffect(() => {
    if (!shouldInitFetch || !from || !to) return;
    fetchOrdersSummary(nursingHomeUnitId, from, to);
  }, []);

  return { ordersSummary, fetchOrdersSummary };
};
