import React from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { color } from '../../styles';
import api from '../../api';
import { IGetOrder } from '../../pages/expendables/saved-order';
import { Dialog } from '../mui/dialog';
import { Cell, CellBase, HeaderCell, Row, Table, TableLinkText, TableLinkCell } from '../../styles/table';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { navSlice } from '../../redux/config/leftnav';

interface Props {
  data: IGetOrder[];
  url: string;
  dataFetch: () => void;
}

const ModalText = css`
  margin-bottom: 16px;
  font-size: 14px;
`;

const ModalSubText = css`
  color: ${color.red};
  font-size: 14px;
  font-weight: bold;
`;

// テーブルのセルの横幅を左から順に定義
const CellWidth = [{ flex: '1 1 150px' }, { flex: '0 1 180px' }, { flex: '2 1 300px' }, { flex: '0 0 164px' }];

export const SavedOrderTable = React.memo(({ data, url, dataFetch: dataFetch }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const tableHeader = ['ユニット', '保存日時', '発注メモ', '操作'];
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [selectedData, setSelectedData] = React.useState<IGetOrder>();

  const handleClickEdit = (id: number) => (e: React.MouseEvent) => {
    history.push(`${url}/order?id=${id}`);
  };

  const handleClickDelete = (data: IGetOrder) => async (e: React.MouseEvent) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleClickModalClose = () => {
    setIsModalOpen(false);
    dataFetch();
    // TODO: 一時保存バッヂを更新する
  };

  const handleClickModalSubmit = async () => {
    if (selectedData) {
      await api.delete('/orders/:orderId', {
        params: { orderId: selectedData.id },
      });
      dispatch(navSlice.actions.setTemporarilySavedBadge(-1));
      setTimeout(() => {
        handleClickModalClose();
        showSnackbar('削除しました');
      }, 500);
    }
  };

  const OperationCell = (data: IGetOrder) => {
    return (
      <div css={TableLinkCell}>
        <div css={TableLinkText} onClick={handleClickEdit(data.id)}>
          確認・編集
        </div>
        <div css={TableLinkText} onClick={handleClickDelete(data)}>
          削除
        </div>
      </div>
    );
  };

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0]]}>{data.displayUnitName}</div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{data.displayTimestamp}</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{data.memo}</div>
              <div css={[CellBase, Cell, CellWidth[3]]}>{OperationCell(data)}</div>
            </div>
          );
        })}
      </div>
      {isModalOpen && (
        <Dialog
          isOpen={isModalOpen}
          title={'消耗品の一時保存を削除'}
          onClose={handleClickModalClose}
          onOkClick={handleClickModalSubmit}
        >
          <div css={ModalText}>下記の消耗品発注データを削除しますか?</div>
          <div css={ModalSubText}>{selectedData?.displayUnitName}</div>
          <div css={ModalSubText}>{selectedData?.displayTimestamp}</div>
          <div css={ModalSubText}>{selectedData?.memo}</div>
        </Dialog>
      )}
    </>
  );
});
