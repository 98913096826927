export const LAXATIVE_UNIT = {
  tablet: '錠',
  drop: '滴',
  ml: 'ml',
  pack: '包',
  unknown: 'その他・不明',
};

// プルダウンリスト用
export const LAXATIVE_UNIT_LIST = [
  {
    label: '錠',
    value: 'tablet',
  },
  {
    label: '滴',
    value: 'drop',
  },
  {
    label: 'ml',
    value: 'ml',
  },
  {
    label: '包',
    value: 'pack',
  },
  {
    label: 'その他・不明',
    value: 'unknown',
  },
];
