interface ISize {
  [key: string]: string;
}

export const SIZE: ISize = {
  ss: 'SS',
  s: 'S',
  m: 'M',
  l: 'L',
  ll: 'LL',
  xl: 'XL',
  unknown: 'その他・不明',
};
