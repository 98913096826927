import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetNotificationRes } from '../../../types/api/notification/getNotification';
import { selfDataSelector } from '../../../redux/config/selfData';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  from?: string | null;
  to?: string | null;
  sort?: string;
  limit?: number;
}

export const useFetchNotifications = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeUnitId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  sort = 'desc',
  limit = 10000
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const [notifications, setNotifications] = React.useState<IGetNotificationRes[]>();

  const fetchNotifications = async (
    nursingHomeUnitId: number | null = null,
    from: string | null = null,
    to: string | null = null,
    sort = 'desc',
    limit = 10000
  ) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      from,
      to,
      sort,
      limit,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;
    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/nursing-homes/:nursingHomeId/notifications', {
      params,
    });

    setNotifications(result);
    return result; // TODO: 値を直接returnし、useStateを使っていないため修正する
  };

  React.useEffect(() => {
    // 初回fetchはUnitIdの指定がない場合は、自身のUnitIdを優先して取得
    // それ以外はUnitIdなし（全ユニット取得）
    if (!shouldInitFetch || !selfUser.nursingHomeId) return;
    if (nursingHomeUnitId) fetchNotifications(nursingHomeUnitId, from, to, sort, limit);
    else if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId)
      fetchNotifications(selfUser.nursingHomeUnitId, from, to, sort, limit);
    else fetchNotifications(null, from, to, sort, limit);
  }, [selfUser]);

  return { notifications, fetchNotifications };
};
