import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import { selfDataSelector } from '../../../redux/config/selfData';
import { currentNursingHomeSelector } from '../../../redux/config/currentNursingHome';
import { OutlineButton } from '../../../components/outlineButton';
import { PanelContainer } from '../../../components/panel';
import { IGetResidentRes } from '../../../types/api/resident/getResident';
import { IDailyCareRecord, IGetDailyCareRecordRes } from '../../../types/api/traversal/getDailyCareRecord';
import { useFetchResidents } from '../../../hooks/api/resident/useFetchResidents';
import { useFetchDailyCareRecord } from '../../../hooks/api/traversal/useFetchDailyCareRecord';
import { useFetchLaxatives } from '../../../hooks/api/careSettings/useFetchLaxatives';
import { useFetchDrinks } from '../../../hooks/api/careSettings/useFetchDrinks';
import { ResidentsTable } from '../residentsTable';
import { exportCSV } from '../../../utils/exportCSV';
import {
  formatDefecationJa,
  formatDrinkingType,
  formatHardnessJa,
  formatIncontinenceJa,
  formatPadJa,
  formatPadEn,
  formatToiletJa,
  formatToiletEn,
  formatUrineJa,
} from '../../../utils/dataLabelUtil';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { CARE_HISTORY_CSV_COL_JA, CARE_HISTORY_CSV_COL_EN } from '../../../constants';

interface IDisplayResident extends IGetResidentRes {
  isChecked: boolean; // テーブルのチェック状態の管理フラグ
}

const TopContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;

const DateRangePicker = css`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const CareRecordCSV = React.memo(() => {
  const rangeMinDate = dayjs().startOf('day').subtract(3, 'year');
  const rangeMaxDate = dayjs().endOf('day');
  const { residents } = useFetchResidents(true);
  const { laxatives } = useFetchLaxatives(true);
  const { drinks } = useFetchDrinks(true);
  const { dailyCareRecord, fetchDailyCareRecord } = useFetchDailyCareRecord(false);
  const { selfUser } = useSelector(selfDataSelector);
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const [tableData, setTableData] = React.useState<IDisplayResident[]>();
  const [checkArray, setCheckArray] = React.useState<boolean[]>();
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [fetchTimestamp, setFetchTimestamp] = React.useState<{ start: Dayjs; end: Dayjs }>({
    start: dayjs().startOf('day').subtract(1, 'day'),
    end: dayjs().endOf('day'),
  });
  const { showSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const selfUnit: number | null = React.useMemo(() => {
    if (!selfUser.nursingHomeId) return null;
    return selfUser.hasUnitPerm ? selfUser.nursingHomeUnitId : null;
  }, [selfUser]);

  const selectedResidents: IGetResidentRes[] = React.useMemo(() => {
    if (!residents || !checkArray) return [];
    return residents?.filter((v, i) => {
      return checkArray[i] === true;
    });
  }, [checkArray, residents]);

  const handleChangeStartTime = (start: Dayjs | null) => {
    if (!start) return;
    setFetchTimestamp((prev) => {
      return { ...prev, start: start.startOf('day') };
    });
  };

  const handleChangeEndTime = (end: Dayjs | null) => {
    if (!end) return;
    setFetchTimestamp((prev) => {
      return { ...prev, end: end.endOf('day') };
    });
  };

  const handleClickCSVDownload = async () => {
    if (!selectedResidents.length) {
      showSnackbar(t('noResidentSelected', '入居者が選択されていません'), 'error');
      return;
    }
    if (!fetchTimestamp?.start || !fetchTimestamp?.end) {
      return;
    }
    setOnSend(true);
    // resident情報とcareRecord情報をマージ
    // データの加工がhandleClickCSVDownloadとcreateCSVDataの２箇所で行われているので注意
    const records = await Promise.all(
      selectedResidents.map(async (v) => {
        return await fetchDailyCareRecord(
          v.id,
          fetchTimestamp.start.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          fetchTimestamp.end.local().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]')
        );
      })
    );
    await createCSVData(records);
    setOnSend(false);
  };

  const insertPlace = (careRecord: IDailyCareRecord) => {
    if (careRecord.padType !== null && careRecord.toiletType !== null) {
      return i18n.language === 'ja'
        ? formatPadJa(careRecord.padType) + '/' + formatToiletJa(careRecord.toiletType)
        : formatPadEn(careRecord.padType) + '/' + formatToiletEn(careRecord.toiletType);
    } else if (careRecord.padType !== null && careRecord.toiletType === null) {
      return i18n.language === 'ja' ? formatPadJa(careRecord.padType) : formatPadEn(careRecord.padType);
    } else if (careRecord.padType === null && careRecord.toiletType !== null) {
      return i18n.language === 'ja' ? formatToiletJa(careRecord.toiletType) : formatToiletEn(careRecord.toiletType);
    } else {
      return '';
    }
  };

  const insertDrinkingName = (careRecord: IDailyCareRecord) => {
    if (careRecord.drinkingOther) {
      return careRecord.drinkingOther;
    } else if (careRecord.drinkingType) {
      return formatDrinkingType(careRecord.drinkingType) ?? '';
    } else if (careRecord.drinkingId) {
      const result = drinks?.find((v) => v.id === careRecord.drinkingId)?.name;
      return result ?? '';
    } else {
      return '';
    }
  };

  const insertLaxativeName = (laxativeId: number) => {
    if (!laxativeId) return '';
    const result = laxatives?.find((v) => v.id === laxativeId)?.name;
    return result ?? '';
  };

  const createCSVData = async (data: IGetDailyCareRecordRes[]) => {
    let exportData: any[] = [];
    if (!data.filter((v) => v.careRecords.length).length) {
      showSnackbar(t('dataForTheSpecifiedPeriodWasNotFound', '指定した期間のデータが見つかりませんでした'));
      return;
    }

    data.forEach((resident) => {
      resident.careRecords.forEach((v) => {
        // CSVデータの作成
        let csvData = {};
        i18n.language === 'ja'
          ? (csvData = {
              residentId: resident.id,
              name: resident.name ?? '',
              facilityName: currentNursingHome.name ?? '',
              unitName: resident.nursingHomeUnitName ?? '',
              roomName: resident.roomName ?? '',
              timestamp: dayjs(v.timestamp).utc().local().format('YYYY/MM/DD HH:mm'),
              place: insertPlace(v),
              urine: formatUrineJa(v.padUrineVolumeType ?? ''),
              incontinence: formatIncontinenceJa(v.incontinence ?? ''),
              defecation: formatDefecationJa(v.defecationVolumeType) ?? '',
              hardnessType: formatHardnessJa(v.hardnessType),
              drinkName: insertDrinkingName(v),
              drinkingVolume: v.drinkingVolume ? v.drinkingVolume + 'ml' : '',
              laxative: insertLaxativeName(v.laxativeId),
              laxativeAmount: v.laxativeAmount ?? '',
              memo: v.memo ? v.memo.replace(/\r?\n/g, '') : '',
              staffName: v.staffName ?? '',
              workingTime: v.workingTime ? String(v.workingTime) : '',
            })
          : // 日本語以外のデータの場合は特にフォーマットせずに返す
            // DBに文字列があるものはinsertXXXXで取得して返す
            (csvData = {
              residentId: resident.id,
              name: resident.name ?? '',
              facilityName: currentNursingHome.name ?? '',
              unitName: resident.nursingHomeUnitName ?? '',
              roomName: resident.roomName ?? '',
              timestamp: dayjs(v.timestamp).utc().local().format('YYYY/MM/DD HH:mm'),
              place: insertPlace(v), // 英語化対応済み
              urine: v.padUrineVolumeType ?? '',
              incontinence: v.incontinence ?? '',
              defecation: v.defecationVolumeType ?? '',
              hardnessType: v.hardnessType ?? '',
              drinkName: insertDrinkingName(v),
              drinkingVolume: v.drinkingVolume ? v.drinkingVolume + 'ml' : '',
              laxative: insertLaxativeName(v.laxativeId),
              laxativeAmount: v.laxativeAmount ?? '',
              memo: v.memo ? v.memo.replace(/\r?\n/g, '') : '',
              staffName: v.staffName ?? '',
              workingTime: v.workingTime ? String(v.workingTime) : '',
            });

        exportData.push(csvData);
      });
    });

    const filename =
      currentNursingHome.name +
      '_' +
      t('careHistory', 'ケア履歴') +
      '_' +
      dayjs(fetchTimestamp!.start).format('YYYY-MM-DD') +
      '_' +
      dayjs(fetchTimestamp!.end).format('YYYY-MM-DD');

    const column = i18n.language === 'ja' ? CARE_HISTORY_CSV_COL_JA : CARE_HISTORY_CSV_COL_EN;
    // dataとcolumnの先頭に通し番号を追加
    exportData = exportData.map((v, i) => {
      return { 'S.No': i + 1, ...v };
    });
    const columnWithSNo = [{ label: 'S.No', value: 'S.No' }, ...column];
    await exportCSV(exportData, columnWithSNo, filename);
  };

  const handleChangeCheckBox = (checkArray: boolean[]) => {
    // TODO: selectedResidentsの更新検知のためにスプレッド演算子で更新を行なっているが
    // チェックボックスの状態を変更すると描画がTable全体に入るため要見直し
    setCheckArray([...checkArray]);
  };

  const createTableData = (data: IGetResidentRes[] | undefined) => {
    return data?.map((v) => {
      return {
        ...v,
        isChecked: false,
      };
    });
  };

  React.useEffect(() => {
    if (!residents) return;
    const tableData = createTableData(residents);
    setTableData(tableData);
  }, [residents]);

  return (
    <PanelContainer title={t('csvdownload', 'CSVダウンロード') + ' - ' + t('careHistory', 'ケア履歴')}>
      {tableData && tableData.length ? (
        <>
          <div css={TopContainer}>
            <div css={DateRangePicker}>
              <DatePicker
                views={['year', 'month', 'day']}
                label={t('startDate', '開始日')}
                value={fetchTimestamp?.start}
                minDate={rangeMinDate}
                maxDate={fetchTimestamp?.end ?? undefined}
                onChange={handleChangeStartTime}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
              〜
              <DatePicker
                views={['year', 'month', 'day']}
                label={t('endDate', '終了日')}
                value={fetchTimestamp?.end}
                minDate={fetchTimestamp?.start ?? undefined}
                maxDate={rangeMaxDate}
                onChange={handleChangeEndTime}
                renderInput={(params) => <TextField variant='standard' {...params} helperText={null} />}
              />
            </div>
            <Tooltip
              placement='top-end'
              title={t(
                'downloadingMayTakeSomeTimeDependingOnTheAmountOfData',
                'データ量に応じてダウンロードに時間がかかる場合がございます。'
              )}
            >
              <div>
                <OutlineButton
                  icon={<img src='/images/analysis/icon_dl_csv.svg' alt='CSV' />}
                  onClick={handleClickCSVDownload}
                  disable={onSend}
                >
                  {t('download', 'ダウンロード')}
                </OutlineButton>
              </div>
            </Tooltip>
          </div>

          <ResidentsTable data={tableData} onChange={handleChangeCheckBox} />
        </>
      ) : (
        <>{t('thereAreNoResidents', '入居者がいません')}</>
      )}
    </PanelContainer>
  );
});
