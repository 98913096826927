/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selfDataSelector } from '../../../redux/config/selfData';
import api from '../../../api';
import { IGetResidentRes } from '../../../types/api/resident/getResident';

interface IFetchParams {
  nursingHomeId: number;
  nursing_home_unit_id?: number | null;
  limit?: number;
}

export const useFetchResidents = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeUnitId: number | null = null,
  limit = 10000
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const [residents, setResidents] = React.useState<IGetResidentRes[]>();

  const fetchResidents = async (nursingHomeUnitId: number | null = null, limit = 10000) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursing_home_unit_id: nursingHomeUnitId,
      limit,
    };

    // UnitIdの指定がない場合は、自身のUnitIdを優先して取得
    if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId && !nursingHomeUnitId)
      params.nursing_home_unit_id = selfUser.nursingHomeUnitId;

    if ([0, null].includes(nursingHomeUnitId)) delete params.nursing_home_unit_id;

    const result = await api.get('/nursing-homes/:nursingHomeId/residents', {
      params,
    });

    setResidents(result);
  };

  React.useEffect(() => {
    // 初回fetchはUnitIdの指定がない場合は、自身のUnitIdを優先して取得
    // それ以外はUnitIdなし（全ユニット取得）
    if (!shouldInitFetch || !selfUser.nursingHomeId) return;
    if (nursingHomeUnitId) fetchResidents(nursingHomeUnitId, limit);
    else if (selfUser.hasUnitPerm && selfUser.nursingHomeUnitId) fetchResidents(selfUser.nursingHomeUnitId, limit);
    else fetchResidents(null, limit);
  }, [selfUser]);

  return { residents, fetchResidents };
};
