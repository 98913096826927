import React from 'react';
import { TableLinkCell } from '../../../../styles/table';
import { css } from '@emotion/react';
import { numberValidation } from '../../../../utils/validation';
import TextField from '@mui/material/TextField';

interface Props {
  value: number | null; // 入力フォームに表示する値
  id?: string;
  label?: string;
  onChange: (value: number) => void;
}

const CssStyle = css`
  input {
    height: 32px;
    font-size: 14px;
    padding: 4px 8px;
  }
`;

export const OrderAmountCell = React.memo(({ value, id = 'table-cell', label = '', onChange }: Props) => {
  const limit = { min: 0, max: 20 };
  const [helperText, setHeplerText] = React.useState<string>('');
  const [isError, setIsError] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState<number | null>(0);
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 入力フォームはmin以下とmax以上と小数点を許容しない
    if (!numberValidation(+e.target.value)) return;
    setHeplerText(+e.target.value === 0 ? '発注数を0にするには 項目を削除してください' : '');
    setIsError(+e.target.value === 0 ? true : false);
    if (+e.target.value >= limit.min && +e.target.value <= limit.max) {
      setInputValue(+e.target.value);
      onChange(+e.target.value);
    } else if (+e.target.value < limit.min) {
      setInputValue(limit.min);
      onChange(limit.min);
    } else if (+e.target.value > limit.max) {
      setInputValue(limit.max);
      onChange(limit.max);
    }
  };

  React.useEffect(() => {
    setInputValue((prev) => {
      if (prev === value) return prev;
      return value;
    });
  }, [value]);

  return (
    <div css={TableLinkCell}>
      <TextField
        label={label}
        id={id}
        variant='outlined'
        type='number'
        autoComplete='off'
        error={isError}
        helperText={helperText}
        value={inputValue}
        css={CssStyle}
        onChange={onValueChange}
      />
    </div>
  );
});
