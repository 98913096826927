import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CircularProgress, iconButtonClasses } from '@mui/material';
import { color } from '../../styles';

interface Props {
  text?: string;
}

interface bgProps {
  size: number;
}

const BackGround = styled.div<bgProps>({
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  backgroundColor: `rgba(88,88,88,0.2)`,
});

const Text = css`
  margin-top: 8px;
  background: ${color.white};
  padding: 4px 8px;
  border-radius: 8px;
`;

const ProgressIcon = css``;

export const Loading = React.memo(({ text }: Props) => {
  const loadingIconSize = 80;
  return (
    <BackGround size={loadingIconSize}>
      <CircularProgress css={ProgressIcon} size={loadingIconSize} />
      {text && <div css={Text}>{text}</div>}
    </BackGround>
  );
});
