import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { IGetDiaperRes, IGetDiapersParams } from '../../../types/api/care-settings/getDiaper';

export const useFetchNursingHomeDiapers = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  sort: string = 'desc',
  limit: number = 1000,
  offset: number | null = null
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [nursingHomeDiapers, setNursingHomeDiapers] = React.useState<IGetDiaperRes[]>();
  const [nursingHomePants, setNursingHomePants] = React.useState<IGetDiaperRes[]>();
  const [nursingHomePads, setNursingHomePads] = React.useState<IGetDiaperRes[]>();

  const fetchNursingHomeDiapers = async (sort = 'desc', limit = 1000, offset: number | null = null) => {
    const params: IGetDiapersParams = {
      nursingHomeId: +nursingHomeId,
      sort,
      limit,
      offset,
    };

    if (!sort) delete params.sort;
    if (!limit) delete params.limit;
    if (!offset) delete params.offset;

    const result = await api.get('/nursing-homes/:nursingHomeId/diapers', {
      params,
    });

    // データをパンツとパッドに分ける
    const pants = result.filter((v) => v.diaperShape === 'outer');
    const pads = result.filter((v) => v.diaperShape === 'inner');

    setNursingHomeDiapers(result); // パンツ・パッドに分割していないもの
    setNursingHomePants(pants); // パンツレコードのみ
    setNursingHomePads(pads); // パッドレコードのみ
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchNursingHomeDiapers(sort, limit, offset);
  }, []);

  return { nursingHomeDiapers, nursingHomePants, nursingHomePads, fetchNursingHomeDiapers };
};
