import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../redux/core/store';

interface AuthState {
  idToken: string;
  refreshToken: string;
  isLoggin: boolean;
}

// initialize
const initialState: AuthState = {
  idToken: '',
  refreshToken: '',
  isLoggin: false,
};

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggin = action.payload.isLoggin;
    },
  },
});

// action
export const { setAuth } = authSlice.actions;

// selector
export const authSelector = (state: RootState) => state.auth;
