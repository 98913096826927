import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api';
import { IGetDiaperRes } from '../../types/api/care-settings/getDiaper';
import { IGetMasterDiaperRes } from '../../types/api/master/getDiaper';

// nursingHomeDiapersから発注可能なパンツとパッドを抽出
// masterDiapersから発注可能な消耗品を抽出
// それらを出力する

export const useFetchOrderableDiapers = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  sort: string = 'desc',
  limit: number = 10000
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [orderablePants, setOrderablePants] = React.useState<IGetDiaperRes[]>([]);
  const [orderablePads, setOrderablePads] = React.useState<IGetDiaperRes[]>([]);
  const [orderableExpendable, setOrderableExpendable] = React.useState<IGetMasterDiaperRes[]>([]);

  const fetchOrderableDiapers = async (sort = 'desc', limit = 10000) => {
    const result = await api.get('/nursing-homes/:nursingHomeId/diapers', {
      params: { nursingHomeId, sort, limit },
    });

    // データをパンツとパッドに分ける
    const pants = result.filter((v) => v.diaperShape === 'outer' && v.orderEnable);
    const pads = result.filter((v) => v.diaperShape === 'inner' && v.orderEnable);

    setOrderablePants(pants); // 発注可能なパンツレコードのみ
    setOrderablePads(pads); // 発注可能なパッドレコードのみ
  };

  const fetchOrderableExpendable = async (sort = 'desc', limit = 10000) => {
    const result: IGetMasterDiaperRes[] = await api.get('/master/diapers', {
      params: { sort, limit },
    });

    const expendable = result.filter((v) => v.shape === 'expendable' && v.enableOrder);
    setOrderableExpendable(expendable); // 発注可能な消耗品レコードのみ
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchOrderableDiapers(sort, limit);
    fetchOrderableExpendable(sort, limit);
  }, []);

  if (orderablePants && orderablePads && orderableExpendable) {
    return { pants: orderablePants, pads: orderablePads, expendable: orderableExpendable };
  }
};
