import { css } from '@emotion/react';

// ボタンをクリックした場合に波紋のアニメーションを表示する
export const BtnClickAnimation = css`
  position: relative;
  overflow: hidden;
  user-select: none;
  text-decoration: none;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(circle, #fff 10%, transparent 10%) no-repeat 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.3s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    transition: 0s;
    opacity: 0.3;
  }
`;
