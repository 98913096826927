import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { FacilityRadioGroup } from '../../../components/mui/radio';
import { selfDataSelector } from '../../../redux/config/selfData';
import { MenuItemProps, Select } from '../../../components/mui/select';

// onChangeUnits    変更された場合に、コールバックでunits情報を{unitId, unit名}で返す。施設全体は{ 0 , '施設全体'}として返す
// onChangeFacility ラジオボタンが変更された場合に、コールバックで施設全体は'nursingHome'をユニットは'unit'を返す

interface Props {
  onChangeUnit: (list: MenuItemProps) => void;
  onChangeFacility?: (type: string) => void;
}

const Container = css`
  width: 100%;
`;

const StyledUnitList = css({
  width: '200px',
});

export const UnitSelector = React.memo(
  ({ onChangeUnit: onChangeUnits = () => ({ key: 0, value: '施設全体' }), onChangeFacility }: Props) => {
    const { selfUnits } = useSelector(selfDataSelector);
    const { selfUser } = useSelector(selfDataSelector);
    const [selectedFacilityType, setSelectedFacilityType] = React.useState('nursingHome');
    const [unitId, setUnitId] = React.useState<MenuItemProps>();

    const units = React.useMemo(() => {
      if (!selfUnits) return [];
      return selfUnits.map((unit) => {
        return { key: unit.id, value: unit.name };
      });
    }, [selfUnits]);

    // ラジオボタンが切り替わった場合の処理
    // ユニットに切り替わった場合にUnitsの0番目を入れる
    const handleChangeFacility = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFacilityType(event.target.value);
        onChangeFacility ? onChangeFacility(event.target.value) : 0;
        if (event.target.value === 'unit') {
          onChangeUnits(units[0]);
          setUnitId(units[0]);
        } else if (event.target.value === 'nursingHome') {
          onChangeUnits({ key: 0, value: '施設全体' });
          setUnitId(undefined);
        }
      },
      [units]
    );

    const handleChangeUnit = (event: MenuItemProps) => {
      onChangeUnits(event);
    };

    // ログインユーザーがUnitPermを持っていた場合、Unit情報に自身のUnitを入れる
    React.useEffect(() => {
      if (!selfUser || !selfUser.id || !units.length) return;
      if (selfUser.hasUnitPerm) {
        const unit = units.find((v) => {
          return v.key === selfUser.nursingHomeUnitId;
        }) ?? { key: 0, value: '施設全体' };
        onChangeUnits(unit);
        setUnitId(unit);
      } else {
        onChangeUnits({ key: 0, value: '施設全体' });
      }
    }, [selfUser, units]);

    // ログインユーザーがUnitPermを持っていた場合はコンポーネントを非表示に
    return (
      <div css={Container}>
        {(() => {
          if (selfUser?.hasUnitPerm) {
            return;
          } else {
            return (
              <>
                <FacilityRadioGroup facilityType={selectedFacilityType} onChange={handleChangeFacility} />
                {selectedFacilityType === 'unit' && (
                  <Select
                    id={'unit-selector'}
                    listData={units}
                    value={unitId}
                    label={'ユニット'}
                    className={StyledUnitList}
                    onChange={handleChangeUnit}
                  />
                )}
              </>
            );
          }
        })()}
        {selfUser.hasUnitPerm}
      </div>
    );
  }
);
