import React from 'react';
import api from '../../../api';
import { IGetAnnouncementRes, IGetAnnouncementsParams } from '../../../types/api/announcement/announcement';

export const useFetchAllAnnouncements = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  lang: string | null,
) => {
  const [announcements, setAnnouncements] = React.useState<IGetAnnouncementRes[]>();

  const fetchAllAnnouncements = async () => {
    const params: IGetAnnouncementsParams = { lang };
    const result = await api.get('/announcements', { params });
    setAnnouncements(result);
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchAllAnnouncements();
  }, []);

  return { announcements, fetchAllAnnouncements };
};
