import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HeatmapModule from 'highcharts/modules/heatmap';
import { css } from '@emotion/react';
import { color } from '../../../../styles/color';

HeatmapModule(Highcharts);

interface Props {
  graphData: any;
  xCategories: string[];
  isPrinting?: boolean;
}

const GraphContainer = css`
  width: 100%;
`;

export const CareRecordPieGraph = React.memo(({ graphData, xCategories, isPrinting = false }: Props) => {
  const option: Highcharts.Options = {
    title: {
      text: '',
    },
    chart: {
      type: 'pie',
      margin: isPrinting ? [0, 40, 0, 0] : [0, 0, 0, 0],
      shadow: false,
      animation: false,
      backgroundColor: color.light_gray,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b><br>{point.y} %',
          distance: -60,
          style: {
            fontSize: '16px',
          },
          filter: {
            property: 'percentage',
            operator: '>=',
            value: 10,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        data: graphData,
        innerSize: '40%',
      },
    ],
    colors: ['#72fbfd', '#75f94c', '#CCCCCC', '#6D6D6D'],
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.name}<br><b>{point.y}%</b>',
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
    },
  };

  React.useEffect(() => {
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.reflow();
      }
    });
  }, [isPrinting]);

  return (
    <div css={GraphContainer}>
      <HighchartsReact highcharts={Highcharts} options={option} />
    </div>
  );
});
