import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../../api';
import { PanelContainer } from '../../../components/panel';
import { color } from '../../../styles';
import { LAXATIVE_UNIT_LIST } from '../../../constants';
import { IGetLaxativeRes } from '../../../types/api/care-settings/getLaxative';
import { IUpdateLaxativeParams } from '../../../types/api/care-settings/updateLaxative';

interface Props {
  url: string;
}

export interface IGetLaxative extends IGetLaxativeRes {
  displayUnit: string; // 日本語化された下剤の単位
}

interface ILaxativeUnit {
  label: string;
  value: string;
}

const TextFieldWrapper = css`
  margin-top: 24px;
`;

const TextFieldContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TextFieldTitle = css`
  font-size: 16px;
  width: 60px;
  margin-bottom: 24px;

  span {
    margin-right: 4px;
    color: ${color.red};
    font-size: 16px;
    font-weight: bold;
  }
`;

const StyledInput = css`
  max-width: 560px;
  width: 80%;
  height: 80px;
`;

const ButtonContainer = css`
  display: flex;
  justify-content: center;
`;

const StyledButton = css`
  margin: 16px 0 16px 32px;
  min-width: 80px;
`;

export const UpdateLaxative = ({ url }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { laxativeId } = useParams<{ laxativeId: string }>();
  const [laxativeNameHelperText, LaxativeHelperText] = React.useState<string>('');
  const [isPassedValidation, setIsPassedValidation] = React.useState<boolean>(false);
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [sendData, setSendData] = React.useState<IUpdateLaxativeParams>({ name: '', unit: '' });
  const history = useHistory();
  const [initLaxativeUnit, setInitLaxativeUnit] = React.useState<ILaxativeUnit | null>(null);
  const [initLaxativeName, setInitLaxativeName] = React.useState<string | null>(null);
  const [pageTitle, setPageTitle] = React.useState<string>('');

  // Addの場合のフォームの初期値設定
  const initAddMode = () => {
    setPageTitle('下剤の追加');
    setInitLaxativeName('');
    setSendData({ name: '', unit: LAXATIVE_UNIT_LIST[0].value });
    setInitLaxativeUnit(LAXATIVE_UNIT_LIST[0]);
  };

  // Editの場合のフォームの初期値設定
  const initEditMode = (laxative: IGetLaxativeRes) => {
    setPageTitle('下剤の編集');
    setInitLaxativeName(laxative.name);

    const found = LAXATIVE_UNIT_LIST.find((v) => {
      return v.value === laxative.unit;
    });
    setInitLaxativeUnit(found ?? LAXATIVE_UNIT_LIST[0]);
    setSendData({ name: laxative.name, unit: laxative.unit });
    setIsPassedValidation(true);
  };

  const fetchLaxative = async () => {
    const laxative = await api.get('/laxatives/:nursingHomeLaxativeId', {
      params: { nursingHomeLaxativeId: laxativeId },
    });
    return laxative;
  };

  const createLaxative = async () => {
    return await api.post('/nursing-homes/:nursingHomeId/laxatives', {
      nursingHomeId,
      name: sendData.name,
      unit: sendData.unit,
    });
  };

  const updateLaxative = async () => {
    return await api.put('/laxatives/:nursingHomeLaxativeId', {
      nursingHomeLaxativeId: laxativeId,
      name: sendData.name,
      unit: sendData.unit,
    });
  };

  const handleLaxativeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      LaxativeHelperText('');
      setIsPassedValidation(true);
      setSendData((prev) => {
        return { ...prev, name: event.target.value };
      });
    } else {
      LaxativeHelperText('下剤名は必須入力です');
      setIsPassedValidation(false);
    }
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(url);
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    setOnSend(true);

    // paramsにidがある場合はput、そうでない場合はpostとして処理
    if (laxativeId) {
      await updateLaxative();
    } else {
      await createLaxative();
    }
    setTimeout(() => {
      setOnSend(false);
      history.push(url);
    }, 500);
  };

  const handleUnitPulldown = (e: React.SyntheticEvent, v: ILaxativeUnit) => {
    setSendData((prev) => {
      return { ...prev, unit: v.value };
    });
  };

  React.useEffect(() => {
    // paramsにidがある場合はEdit、そうでない場合はAddとして処理
    if (laxativeId) {
      const init = async () => {
        const laxative = await fetchLaxative();
        initEditMode(laxative);
      };
      init();
    } else {
      initAddMode();
    }
  }, []);

  return (
    <PanelContainer title={pageTitle}>
      <div css={TextFieldWrapper}>
        <div css={TextFieldContainer}>
          {!(initLaxativeName === null) && (
            <>
              <div css={TextFieldTitle}>
                名前<span>*</span>:
              </div>
              <TextField
                css={StyledInput}
                id='laxativeName'
                label='下剤名'
                variant='outlined'
                onChange={handleLaxativeNameChange}
                type='text'
                autoComplete='off'
                defaultValue={initLaxativeName}
                helperText={laxativeNameHelperText}
                error={!isPassedValidation && !!laxativeNameHelperText}
              />
            </>
          )}
        </div>
        <div css={TextFieldContainer}>
          {initLaxativeUnit && (
            <>
              <div css={TextFieldTitle}>
                単位<span>*</span>:
              </div>
              <Autocomplete
                css={StyledInput}
                onChange={handleUnitPulldown}
                disablePortal
                disableClearable={true}
                id='pulldown'
                options={LAXATIVE_UNIT_LIST}
                defaultValue={initLaxativeUnit}
                renderInput={(params) => <TextField {...params} label='単位' />}
              />
            </>
          )}
        </div>
      </div>
      <div css={ButtonContainer}>
        <Button css={StyledButton} color={'secondary'} onClick={handleCancel} variant='contained'>
          キャンセル
        </Button>
        <Button css={StyledButton} onClick={handleSubmit} variant='contained' disabled={!isPassedValidation || onSend}>
          OK
        </Button>
      </div>
    </PanelContainer>
  );
};
