import React from 'react';
import { useReactToPrint } from 'react-to-print';
import dayjs, { Dayjs } from 'dayjs';
import { css } from '@emotion/react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import * as Highcharts from 'highcharts';
import HeatmapModule from 'highcharts/modules/heatmap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import api from '../../../../api';
import { color } from '../../../../styles';
import { DateSelector } from '../../../../components/mui/dateSelector';
import { OutlineButton } from '../../../../components/outlineButton';
import { useSnackbar } from '../../../../contexts/SnackbarContext';
import { useLoading } from '../../../../contexts/LoadingContext';
import { IGetNotificationRes } from '../../../../types/api/notification/getNotification';
import { IGetCareRecordRes } from '../../../../types/api/care-record/getCareRecord';
import { IGetResamples, IResample } from '../../../../types/api/ultra-sonic-data/getResample';
import { IGetSensorReaction, IGetSensorReactionRes } from '../../../../types/api/ultra-sonic-data/getSensorReaction';
import { useFetchResident } from '../../../../hooks/api/resident/useFetchResident';
import { currentNursingHomeSelector } from '../../../../redux/config/currentNursingHome';
import { generateTimeLabelArray } from '../../../../utils/arrayUtil';
import { getDayStartToEndRange } from '../../../../utils/dateutil';
import { UrineGraph } from '../urineTrendAnalysis/urineGraph';
import { IGetActivityStatusAnalysisRes } from '../../../../types/api/ultra-sonic-data/getActivityStatusAnalysis';
import { ActivityStatusHeatmapGraph } from './activityStatusHeatmap';
import { ActivityStatusGraphCaption, NotificationCaption, PlaceCaption } from '../urineTrendAnalysis/caption';

HeatmapModule(Highcharts);

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface Props {
  nursingHomeId: string;
  residentId: string;
}

interface HeatmapGraph {
  data: number[][];
  xCategories: string[];
  yCategories: string[];
}

interface SensorGraph {
  [date: string]: {
    start: Dayjs;
    end: Dayjs;
    sensorData: [number, number | null][];
    resampleData: [number, number | null][];
  };
}

interface scatterItem {
  x: number;
  y: number;
  color: string;
  name: string;
}

const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrintHidden = css`
  @media print {
    display: none;
  }
`;

const PrintContainer = css`
  @media print {
    width: 1440px;
  }
`;

const DateSelectContainer = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DataSelector = css`
  margin: 6px 0;
  display: grid;
  grid-template-columns: auto 1fr; /* 最大内容幅に基づいて列幅を設定 */
  align-items: center;
  gap: 16px;
`;

const UpperContainer = css`
  padding: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.17);
`;

const MiddleContainer = css`
  padding: 24px 0;
`;

const HeatmapYLengthCaption = css`
  margin: 0 22px;
`;

const RawGraphChexkbox = css`
  display: flex;
  justify-content: end;
  padding: 24px 24px 12px 24px;
`;

const LabelText = css`
  text-align: end;
  min-width: 72px;
`;

const contentStyle = css`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-left: 16px;
`;

const CareRecordCaptionContainer = css`
  display: flex;
  flex-direction: column;
  margin: 8px 24px 32px 24px;
  gap: 8px 0;
`;

const Caution = css`
  ${FlexCenter};
  width: 100%;
  height: 200px;
`;

const Warning = css`
  ${FlexCenter};
  color: ${color.red};
  width: 100%;
  height: 64px;
`;

const CaptionText = css`
  margin: 6px 0;
  font-size: 12px;
  color: ${color.dark_gray};
`;

export const ResidentDetailActivityStatusAnalysis: React.FC<Props> = React.memo(({ residentId }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoading();
  const { resident } = useFetchResident(true, +residentId);
  const currentNursingHome = useSelector(currentNursingHomeSelector);
  const componentRef = React.useRef<HTMLDivElement>(null);

  // ヒートマップおよびヒートマッププロットの時間間隔(分)
  const intervalMin = 5;
  // データ取得閾値 期間内にこれ以上のデータセット件数がある場合はfetchを中止する
  const dataCountThreshold = 100000;
  // 自由設定フォームの時間帯プルダウンデータ
  const times = Array.from({ length: 24 }, (_, i) => `${i}:00`);
  // 日付指定フォームの日時
  const [startDate, setStartDate] = React.useState<Dayjs>(dayjs().subtract(1, 'week').startOf('day').utc());
  const [endDate, setEndDate] = React.useState<Dayjs>(dayjs().startOf('day').utc());
  // 時間帯ラジオボタンの値（timeRangeFormArray参照）
  const [timeRange, setTimeRange] = React.useState<string>('all-time');
  // 時間帯の自由設定フォームの時間
  const [selectedStartTime, setSelectedStartTime] = React.useState<string>('7:00');
  const [selectedEndTime, setSelectedEndTime] = React.useState<string>('19:00');
  // グラフの表示フラグ
  const [showSensorResample, setShowSensorResample] = React.useState<boolean>(false);
  // ヒートマップデータ
  const [heatmapData, setHeatmapData] = React.useState<HeatmapGraph>({
    data: [],
    xCategories: [],
    yCategories: [],
  });
  const [hasHeatmapData, setHasHeatmapData] = React.useState<boolean>(false);

  // 尿量グラフの日別に分割したデータの配列
  // (例 2023/03/01: {start: 2023/03/01 7:00のDayjs, 2023/03/01 19:00のDayjs, sensorData: 尿量グラフデータ, resampleData: 補正前グラフデータ})
  const [sensorReactionPerDay, setSensorReactionPerDay] = React.useState<SensorGraph>({});
  const [notificationScatterData, setNotificationScatterData] = React.useState<scatterItem[]>([]);
  const [careRecordScatterData, setCareRecordScatterData] = React.useState<scatterItem[]>([]);

  const [isPrinting, setIsPrinting] = React.useState(false);

  const timeRangeFormArray = [
    { key: 'all-time', value: t('allday', '終日') },
    { key: 'day-time', value: t('daytime7-19', '日中(7時~19時)') },
    { key: 'night-time', value: t('nighttime19-7', '夜間(19時~7時)') },
    { key: 'selected-time', value: t('specify', '自由設定') },
  ];

  const handleChangeStartDate = React.useCallback(
    (start: dayjs.Dayjs) => {
      setStartDate(start);
    },
    [startDate]
  );

  const handleChangeEndDate = React.useCallback(
    (end: dayjs.Dayjs) => {
      setEndDate(end);
    },
    [endDate]
  );

  const handleUpdateButtonClick = React.useCallback(async () => {
    const [startTime] = getTimeRange(dayjs(startDate));
    const [, endTime] = getTimeRange(dayjs(endDate));

    const from = startTime.subtract(1, 'minute').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const to = endTime.add(1, 'minute').format('YYYY-MM-DD[T]HH:mm:ss[Z]');

    const dataset = await api.get('/residents/:residentId/ultra-sonic-data-sets', {
      params: { residentId, from, to, limit: 1000 },
    });

    // 指定期間内のdatasetのcountの総数が閾値を超えている場合はfetchしない
    const total = dataset.reduce((sum, item) => sum + item.count, 0);
    if (total > dataCountThreshold) {
      showSnackbar(
        t(
          'SpecifiedPeriodIsTooLong-PleaseNarrowDownThePeriodAndTryAgain',
          '選択期間が長すぎます。期間を絞ってやり直してください。'
        ),
        'error'
      );
      hideLoading();
      return;
    }

    fetchGraphData(from, to);
  }, [currentNursingHome, timeRange, startDate, endDate, selectedStartTime, selectedEndTime]);

  const fetchGraphData = (start: string, end: string) => {
    const params = { residentId, from: start, to: end, sort: 'asc', limit: 100000 };
    showLoading();
    Promise.all([
      api.get('/residents/:residentId/activity-status-analysis', { params }),
      api.get('/residents/:residentId/sensor-reactions', { params }),
      api.get('/traversal/residents/:residentId/sensor-reaction-resamples', { params }),
      api.get('/residents/:residentId/notifications', { params }),
      api.get('/residents/:residentId/care-records', { params }),
    ]).then(([activityStatuses, sensorReactions, sensorResamples, notifications, careRecords]) => {
      if (!activityStatuses.length) {
        showSnackbar(t('dataForTheSpecifiedPeriodWasNotFound', '指定した期間のデータが見つかりませんでした'));
        hideLoading();
        return;
      }

      // 日別尿量折線グラフ生成
      const sensorReactionArray = createSensorReactionGraphData(sensorReactions, sensorResamples);
      setSensorReactionPerDay(sensorReactionArray);

      // 日別尿量グラフ用ケア履歴データ生成
      const careRecordData = createCareData(careRecords);
      setCareRecordScatterData(careRecordData);

      // 日別尿量グラフ用通知データ生成
      const notificationData = createNotificationData(notifications);
      setNotificationScatterData(notificationData);

      // 活動傾向分析heatmap生成
      const heatmapData = createHeatmapData(activityStatuses);

      setHeatmapData(heatmapData);
      setHasHeatmapData(!!heatmapData.data.length);

      hideLoading();
    });
  };

  // 日別Heatmap生成
  const createHeatmapData = (data: IGetActivityStatusAnalysisRes) => {
    const dateSet = new Set<string>();
    const heatmapData: { day: string; time: string; level: number }[] = [];
    const xCategories = setXAxisCategories();

    data.forEach((r) => {
      let posCode;
      let currentTime = dayjs(r.timestamp);
      let [startOfDay, endOfDay] = getTimeRange(dayjs(currentTime));

      // データがstartOfDay以上〜endOfDay未満の間でない場合、スキップ
      if (!currentTime.isBetween(startOfDay, endOfDay, null, '[]')) {
        // 一致しない場合、1日前の日付を基にgetTimeを再度呼び出す
        [startOfDay, endOfDay] = getTimeRange(dayjs(currentTime.subtract(1, 'day')));
        if (!currentTime.isBetween(startOfDay, endOfDay, null, '[]')) return; // この場合も範囲外ならスキップ
      }
      const minute = Math.floor(currentTime.minute() / intervalMin) * intervalMin;
      const formattedTime = `${currentTime.hour()}:${String(minute).padStart(2, '0')}`;
      const day = endOfDay.subtract(1, 'minute').format('YYYY/MM/DD');
      dateSet.add(day);
      // activityStatusが2（活動状態の時）は他の姿勢より優先して活動状態であることを示す
      if (r.activityStatus === 2) {
        // 活動状態は姿勢で定義されていない番号を使う
        // 7: 活動状態かつ寝ている状態 8: 活動状態かつ立っている状態
        posCode = [0, 1, 2].includes(r.posture) ? 8 : 7;
      } else {
        posCode = r.posture;
      }
      heatmapData.push({
        day,
        time: formattedTime,
        level: posCode,
      });
    });
    const yCategories = Array.from(dateSet).sort();

    const formattedData = heatmapData.map((r) => {
      const x = xCategories.indexOf(r.time);
      const y = yCategories.indexOf(r.day);
      return [x, y, r.level];
    });

    return {
      data: formattedData,
      xCategories,
      yCategories,
    };
  };

  // 通知プロット生成
  const createNotificationData = (data: IGetNotificationRes[]) => {
    const scatterData: scatterItem[] = [];

    data.forEach((r) => {
      const x = dayjs(r.timestamp).valueOf();
      const y = 0; //通知プロットはグラフの一番下に描画する
      const colors =
        r.notificationType === 'urinated'
          ? color.notification_pink
          : r.notificationType === 'bigBladder'
          ? color.notification_yellow
          : r.notificationType === 'gotUp'
          ? color.notification_red
          : null;
      const name =
        r.notificationType === 'urinated'
          ? t('checkGraphLabel', 'でたかも')
          : r.notificationType === 'bigBladder'
          ? t('almostGraphLabel', 'そろそろ')
          : r.notificationType === 'gotUp'
          ? t('gotUpGraphLabel', '起き上がり')
          : null;

      if (colors && name) {
        scatterData.push({ x, y, color: colors, name });
      }
    });
    return scatterData;
  };

  // ケア履歴プロット生成
  const createCareData = (data: IGetCareRecordRes[]) => {
    const scatterData: scatterItem[] = [];

    const filteredData = data.filter((r) => r.urinatedToilet !== null);
    filteredData.forEach((r) => {
      const x = dayjs(r.timestamp).valueOf();
      const y = 10; //通知プロットはグラフの一番下に描画する
      const urinatedInToilet = !!r.urinatedToilet;
      const urinatedInPad = ['large', 'medium', 'small'].includes(r.padUrineVolumeType || '');

      const color = urinatedInToilet ? (urinatedInPad ? '#75f94c' : '#72fbfd') : urinatedInPad ? '#CCCCCC' : '#6D6D6D';
      const name = `${t('toilet', 'トイレ')}: ${urinatedInToilet ? t('yes', '有') : t('no', '無')} ${t(
        'diaper',
        'おむつ/パッド'
      )}: ${urinatedInPad ? t('yes', '有') : t('no', '無')}`;

      if (color && name) {
        scatterData.push({ x, y, color, name });
      }
    });
    return scatterData;
  };

  // 日別尿量グラフと補正前グラフ生成
  const createSensorReactionGraphData = (sensorReactions: IGetSensorReactionRes, sensorResamples: IGetResamples) => {
    let prevTime: string;

    // データを日付でグループ化
    const sensorData: SensorGraph = {};

    const processData = (array: IGetSensorReactionRes | IGetResamples, dataType: 'sensorData' | 'resampleData') => {
      array.forEach((r: IGetSensorReaction | IResample | any) => {
        const currentTime = dayjs(r.timestamp);
        let [startOfDay, endOfDay] = getTimeRange(dayjs(currentTime));

        if (prevTime) {
          const diffInMin = currentTime.diff(dayjs(prevTime), 'minute');
          // 前のタイムスタンプから25分以上離れていたらnullを挿入
          if (diffInMin >= 25) {
            const newTimestamp = dayjs(prevTime).add(1, 'second');
            const newDate = newTimestamp.format('YYYY/MM/DD');
            if (sensorData[newDate] && sensorData[newDate][dataType].length) {
              sensorData[newDate][dataType].push([newTimestamp.valueOf(), null]);
            }
          }
        }

        prevTime = r.timestamp;

        // 1日ごとにレコードを区切る処理
        // データがstartOfDay以上〜endOfDay未満の間でない場合、スキップ
        if (!currentTime.isBetween(startOfDay, endOfDay, null, '[)')) {
          // 一致しない場合、1日前の日付を基にgetTimeを再度呼び出す
          [startOfDay, endOfDay] = getTimeRange(dayjs(currentTime.subtract(1, 'day')));
          if (!currentTime.isBetween(startOfDay, endOfDay, null, '[)')) return; // この場合も範囲外ならスキップ
        }
        const day = endOfDay.subtract(1, 'minute').format('YYYY/MM/DD');

        if (!sensorData[day]) {
          sensorData[day] = { start: startOfDay, end: endOfDay, sensorData: [], resampleData: [] };
        }

        const value = dataType === 'sensorData' ? r.u1xActiveRatio * 10 : r.semiSensorSummary * 10;
        sensorData[day][dataType].push([dayjs(r.timestamp).valueOf(), value]);
      });
    };
    processData(sensorReactions, 'sensorData');
    processData(sensorResamples, 'resampleData');

    // sensorData配列とresampleData配列の両方がない日のレコードは除去する
    const filteredSensorData = Object.fromEntries(
      Object.entries(sensorData).filter(([, entries]) => entries.sensorData.length || entries.resampleData.length)
    );

    // 日付の降順になるようにソート処理を追加
    const sortedSensorData: SensorGraph = Object.fromEntries(
      Object.entries(filteredSensorData).sort((a, b) => dayjs(b[0]).unix() - dayjs(a[0]).unix())
    );
    return sortedSensorData;
  };

  // 指定された日時の指定の開始時間から終了時間の範囲を返す
  const getTimeRange = (time: Dayjs) => {
    switch (timeRange) {
      case 'all-time': {
        // 今日の0時から23時59分59秒までの範囲を返す
        return [dayjs(time).local().startOf('day').utc(), dayjs(time).local().endOf('day').utc()];
      }
      case 'day-time': {
        // 今日の7時から19時までの範囲を返す
        const startHour = 7;
        const endHour = 19;
        return getDayStartToEndRange(time, startHour, endHour);
      }

      case 'night-time': {
        // 今日の19時から明日の7時までの範囲を返す
        const startHour = 19;
        const endHour = 7;
        return getDayStartToEndRange(time, startHour, endHour);
      }

      case 'selected-time': {
        // 自由設定時間を返す
        const startHour = +selectedStartTime.split(':')[0];
        const endHour = +selectedEndTime.split(':')[0];
        return getDayStartToEndRange(time, startHour, endHour);
      }
      default:
        throw new Error('Invalid selected timeRange');
    }
  };

  // heatmap x軸のCategoriesを生成
  const setXAxisCategories = () => {
    let start = '7:00';
    let arrayLength = 12 * (60 / intervalMin) + 1;

    switch (timeRange) {
      case 'all-time': {
        start = '0:00';
        arrayLength = 24 * (60 / intervalMin);
        break;
      }
      case 'day-time': {
        start = '7:00';
        arrayLength = 12 * (60 / intervalMin) + 1;
        break;
      }
      case 'night-time': {
        start = '19:00';
        arrayLength = 12 * (60 / intervalMin) + 1;
        break;
      }
      case 'selected-time': {
        start = selectedStartTime;
        const startHour = +selectedStartTime.split(':')[0];
        const endHour = +selectedEndTime.split(':')[0];
        arrayLength =
          startHour <= endHour
            ? (endHour - startHour) * (60 / intervalMin) + 1
            : (24 - (startHour - endHour)) * (60 / intervalMin) + 1;
        break;
      }
      default:
        throw new Error('Invalid selected timeRange');
    }
    const xCategories = generateTimeLabelArray(start, intervalMin, arrayLength);
    return xCategories;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true);
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const PrintButton = ({ disable }: { disable: boolean }) => {
    return (
      <OutlineButton disable={disable} onClick={handlePrint}>
        <span>{'印刷'}</span>
      </OutlineButton>
    );
  };

  return (
    <>
      <div css={UpperContainer}>
        <div css={DataSelector}>
          <div css={LabelText}>{t('period', '期間')}:</div>
          <div css={contentStyle}>
            <div css={DateSelectContainer}>
              <DateSelector newValue={startDate} maxDate={endDate} onChange={handleChangeStartDate} />
              〜
              <DateSelector
                newValue={endDate}
                minDate={startDate}
                maxDate={dayjs(new Date())}
                onChange={handleChangeEndDate}
              />
            </div>
            <div css={[FlexCenter, DateSelectContainer]}>
              <PrintButton disable={!hasHeatmapData} />
              <Button variant='contained' disableElevation onClick={handleUpdateButtonClick}>
                {t('dataUpdate', 'データ更新')}
              </Button>
            </div>
          </div>
        </div>
        <div css={DataSelector}>
          <div css={LabelText}>{t('timePeriod', '時間帯')}:</div>
          <div>
            <div css={DateSelectContainer}>
              <FormControl component='fieldset'>
                <RadioGroup
                  row={true}
                  aria-label='group1'
                  value={timeRange}
                  onChange={(e) => setTimeRange(e.target.value)}
                >
                  {timeRangeFormArray.map((v, index) => (
                    <FormControlLabel key={index} value={v.key} control={<Radio />} label={v.value} />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl disabled={timeRange !== 'selected-time'} style={{ padding: '0', minWidth: 80 }}>
                <InputLabel>{t('startTime', '開始時間')}</InputLabel>
                <Select
                  style={{ marginTop: '6px' }}
                  variant='standard'
                  value={selectedStartTime}
                  onChange={(e) => setSelectedStartTime(e.target.value)}
                >
                  {times.map((time, i) => (
                    <MenuItem key={time} value={time} disabled={i === times.indexOf(selectedEndTime)}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              〜
              <FormControl disabled={timeRange !== 'selected-time'} style={{ padding: '0', minWidth: 80 }}>
                <InputLabel>{t('endTime', '終了時間')}</InputLabel>
                <Select
                  style={{ marginTop: '6px' }}
                  variant='standard'
                  value={selectedEndTime}
                  onChange={(e) => setSelectedEndTime(e.target.value)}
                >
                  {times.map((time, i) => (
                    <MenuItem key={time} value={time} disabled={i === times.indexOf(selectedStartTime)}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div css={PrintContainer} ref={componentRef}>
        <div css={MiddleContainer}>
          <div css={CareRecordCaptionContainer}>
            <NotificationCaption />
            <PlaceCaption />
            <ActivityStatusGraphCaption />
          </div>
          {hasHeatmapData ? (
            <>
              <ActivityStatusHeatmapGraph heatmapData={heatmapData} isPrinting={isPrinting} />
              <div css={[CaptionText, HeatmapYLengthCaption]}>{heatmapData.yCategories.length}日分</div>
              <div css={RawGraphChexkbox}>
                <FormControlLabel
                  checked={showSensorResample}
                  onChange={(e: any) => setShowSensorResample(e.target.checked)}
                  control={<Checkbox />}
                  label={t('rawGraphWithOutCorrection', '補正前グラフ')}
                />
              </div>
              {Object.entries(sensorReactionPerDay)
                .slice(0, 30)
                .map(([date, entries]) => (
                  <UrineGraph
                    key={date}
                    start={entries.start}
                    end={entries.end}
                    title={date}
                    sensorData={entries.sensorData}
                    resampleData={entries.resampleData}
                    notificationData={notificationScatterData}
                    careRecordData={careRecordScatterData}
                    bladderThreshold={resident?.bladderThresholdPercentage}
                    showResampleData={showSensorResample}
                    showNotificationData={true}
                    showCareRecordData={true}
                    isPrinting={isPrinting}
                  />
                ))}
              {Object.entries(sensorReactionPerDay).length > 30 && (
                <div css={Warning}>{t('displayingTheLast30Graphs', '直近30件のグラフを表示しています')}</div>
              )}
            </>
          ) : (
            <div css={Caution}>{t('noData', 'データがありません')}</div>
          )}
        </div>
      </div>
    </>
  );
});
