import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getAuth } from 'firebase/auth';
import { auth } from '../App';
import { Endpoints } from '../types/api/endpoint';

// 共通のレスポンスの型 ここは仮情報

interface AnyResponse {
  error: string;
  maintenance: 1 | 0;
  version: string;
  data: any;
}

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  withCredentials: true,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

axios.interceptors.response.use(
  (response) => {
    const { data, status } = response as AxiosResponse<AnyResponse>; // レスポンスによってdataの部分を変える
    // TODO: エラー時にTokenの再発行を行う レスポンス時に割り込ませる共通処理
    return data;
  },(error) => {
    if (error.response.status === 401) {
      location.href = '/login';
    }
    if (error.response.status === 404) {
      location.href = '/404';
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(async (request) => {
  let params = request.params;
  if (['post', 'put'].includes(request.method ?? '')) {
    params = request.data;
  }
  if (request.url && request.baseURL) {
    request.url = replaceUrl(request.url, request.baseURL, params);
  }

  if (auth.currentUser && request.headers) {
    const token = await auth.currentUser.getIdToken();
    request.headers.Authorization = `bearer ${token}`;
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  return request;
});

/** :idのような文字列をparamsの値に置換する処理 */
function replaceUrl(url: string, baseURL: string, params: { [key: string]: any } = {}): string {
  (url.match(/:([a-zA-Z0-9]+)/g) || []).forEach((value) => {
    const index = value.replace(/:/i, '');
    if (params[index]) {
      url = url.replace(new RegExp(value, 'i'), params[index]);
      delete params[index];
    }
    value = value.replace(/:/i, '');
  });

  return !url.match(/http/g) ? baseURL + url : url;
}

// endpoint = APIのURI config = params
export const api: Endpoints = {
  get<T = any>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.get(endpoint, config);
  },
  post<T = any>(endpoint: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    return axios.post(endpoint, data, config);
  },
  put<T = any>(endpoint: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    return axios.put(endpoint, data, config);
  },
  delete<T = any>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.delete(endpoint, config);
  },
  // patchはここに加える感じ
};

export default api;
