import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/react';
import { DateSelector } from '../../../../components/mui/dateSelector';
import api from '../../../../api';
import { OutlineButton } from '../../../../components/outlineButton';
import { IDailyCareRecord, IGetDailyCareRecordRes } from '../../../../types/api/traversal/getDailyCareRecord';
import { Loading } from '../../../../components/mui/Loading';
import { color } from '../../../../styles';
import { CareHistoryTable } from './careHistoryTable';
import { Dialog } from '../../../../components/mui/dialog';
import { useSnackbar } from '../../../../contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';

interface Props {
  residentId: string;
}

const Container = css`
  padding: 24px;
`;

const Header = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const SummaryContainer = css`
  border-radius: 8px;
  overflow: hidden;
  color: ${color.dark_gray};
  border: 1px solid ${color.table_green};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const Summary = css`
  flex-grow: 1;
  flex-shrink: 0;
  width: 14vw;
`;

const SummaryTitle = css`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.table_green};
  border-left: 1px solid ${color.white};
`;

const SummaryContent = css`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.light_green};
  border-left: 1px solid ${color.table_green};
  font-size: 20px;
  line-height: 30px;
  font-family: 'Oswald', sans-serif;
`;

const Unit = css`
  padding-left: 6px;
  font-size: 14px;
  line-height: 14px;
`;

const ModalText = css`
  margin-bottom: 16px;
  font-size: 14px;
`;

const ModalSubText = css`
  color: ${color.red};
  font-size: 14px;
  font-weight: bold;
`;

export const ResidentDetailCareHistory: React.FC<Props> = ({ residentId }) => {
  const [date, setDate] = React.useState<dayjs.Dayjs>(dayjs(new Date()));
  const { url } = useRouteMatch();
  const { showSnackbar } = useSnackbar();
  const [records, setRecords] = React.useState<IGetDailyCareRecordRes>();
  const [dates, setDates] = React.useState<dayjs.Dayjs[]>([]);
  const [deleteRecord, setDeleteRecord] = React.useState<IDailyCareRecord>();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const fetchRecords = React.useCallback(async () => {
    const records = await api.get('/traversal/residents/:residentId/care-records', {
      params: {
        residentId,
        from: date.set('hour', 0).set('minute', 0).set('second', 0).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        to: date.set('hour', 23).set('minute', 59).set('second', 59).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      },
    });
    const residentRecords = await api.get('/residents/:residentId/care-records', {
      params: {
        residentId,
        from: date
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .subtract(3, 'year')
          .utc()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        to: dayjs(new Date())
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .utc()
          .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
        limit: 10000,
      },
    });
    const dates: dayjs.Dayjs[] = [];
    // ケア履歴がある日のみ選択可能にする
    residentRecords.forEach((record) => {
      dates.push(dayjs(record.timestamp));
    });
    // ケア履歴がなくても本日は選択可能にする
    dates.push(dayjs(new Date()));

    setDates(dates);
    setRecords(records);
  }, [date]);

  const handleOpenDialog = React.useCallback((record: IDailyCareRecord) => {
    setDeleteRecord(record);
  }, []);

  const handleCloseDialog = React.useCallback(() => {
    setDeleteRecord(undefined);
  }, []);

  const handleDeleteRecord = React.useCallback(async () => {
    if (!deleteRecord) return;
    try {
      await api.delete('/care-records/:careRecordId', { params: { careRecordId: deleteRecord.id } });
      handleCloseDialog();
      showSnackbar('ケア履歴を削除しました');
      fetchRecords();
    } catch (e) {
      handleCloseDialog();
      showSnackbar('エラーが発生しました');
    }
  }, [deleteRecord]);

  const handleChangeDate = React.useCallback((date: dayjs.Dayjs) => {
    setDate(date.local());
  }, []);

  const handleClickRecordAddButton = () => {
    history.push(`${url}/new`);
  };

  const padData = React.useMemo(() => {
    if (!records) return;
    // パッド消費量
    const amountVolume = records.careRecords.reduce((prev, current) => {
      return prev + current.padFirst + current.padSecond;
    }, 0);
    // パッド消費枚数
    const amount = records.careRecords.reduce((prev, current) => {
      return prev + (current.padFirst ? 1 : 0) + (current.padSecond ? 1 : 0);
    }, 0);
    const urineVolume = records.careRecords.reduce((prev, current) => {
      return prev + (current.padUrineVolume ?? 0);
    }, 0);
    // パッド使用率
    const useRate = Math.round(urineVolume === 0 || amountVolume === 0 ? 0 : (urineVolume / amountVolume) * 1000) / 10;
    return { amountVolume, amount, useRate };
  }, [records]);

  React.useEffect(() => {
    fetchRecords();
  }, [date]);

  return (
    <div css={Container}>
      {records ? (
        <>
          <div css={Header}>
            <DateSelector maxDate={dayjs(new Date())} enableDates={dates} onChange={handleChangeDate} />
            <OutlineButton
              onClick={() => handleClickRecordAddButton()}
              icon={<img src='/images/common/add.svg' alt={t('addRecord', '記録を追加')} />}
            >
              {t('addRecord', '記録を追加')}
            </OutlineButton>
          </div>
          <div css={SummaryContainer}>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('urinationCount', '排尿回数')}</div>
              <div css={SummaryContent}>
                {records.summary.urine}
                <span css={Unit}>{t('times', '回')}</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('urineAmount', '排尿量')}</div>
              <div css={SummaryContent}>
                {records.summary.urineVolume}
                <span css={Unit}>ml</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('incontinenceUrination', '失禁')}</div>
              <div css={SummaryContent}>
                {records.summary.incontinence}
                <span css={Unit}>{t('times', '回')}</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('toiletGuidanceNoUrge', '空振り')}</div>
              <div css={SummaryContent}>
                {records.summary.miss}
                <span css={Unit}>{t('times', '回')}</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('defecationCount', '排便回数')}</div>
              <div css={SummaryContent}>
                {records.summary.defecation}
                <span css={Unit}>{t('times', '回')}</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('numberOfDiapers', 'テープ/パンツ消費量')}</div>
              <div css={SummaryContent}>
                {records.summary.diaperAmount}
                <span css={Unit}>{t('count', '枚')}</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('numberOfPads', 'パッド消費量')}</div>
              <div css={SummaryContent}>
                {padData && padData.amountVolume}
                <span css={Unit}>ml</span>
              </div>
            </div>
            {i18n.language === 'ja' && (
              <>
                <div css={Summary}>
                  <div css={SummaryTitle}>パッド消費枚数</div>
                  <div css={SummaryContent}>
                    {padData && padData.amount}
                    <span css={Unit}>枚</span>
                  </div>
                </div>
                <div css={Summary}>
                  <div css={SummaryTitle}>パッド使用率</div>
                  <div css={SummaryContent}>
                    {padData && padData.useRate}
                    <span css={Unit}>%</span>
                  </div>
                </div>
              </>
            )}
            <div css={Summary}>
              <div css={SummaryTitle}>{t('totalWaterIntake', '飲水量合計')}</div>
              <div css={SummaryContent}>
                {records.summary.drinking}
                <span css={Unit}>ml</span>
              </div>
            </div>
            <div css={Summary}>
              <div css={SummaryTitle}>{t('totalWorkingTime', '作業時間合計')}</div>
              <div css={SummaryContent}>
                {records.summary.totalWorkingTime}
                <span css={Unit}>分</span>
              </div>
            </div>
          </div>
          {records.careRecords.length > 0 ? (
            <CareHistoryTable records={records.careRecords} url={url} onClickTrash={handleOpenDialog} />
          ) : (
            <>{t('noCareHistory', 'この日のケア履歴はまだありません')}</>
          )}
          {deleteRecord && (
            <Dialog
              isOpen={!!deleteRecord}
              title={t('removeCareHistoryConfirmTitle', 'ケア履歴の削除')}
              onClose={handleCloseDialog}
              onOkClick={handleDeleteRecord}
            >
              <div css={ModalText}>{t('removeCareHistoryConfirmText', '下記の日時の記録を消去しますか？')}</div>
              <div css={ModalSubText}>{dayjs(deleteRecord.timestamp).format('YYYY/MM/DD HH:mm')}</div>
            </Dialog>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
