import React from 'react';
import { css } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, FormControlLabel, Pagination, styled } from '@mui/material';
import { IGetUltraSonicData } from '../../../../../types/api/ultra-sonic-data/getUltraSonicDataSets';
import { DataSetListItem } from './datasetListItem';
import { useTranslation } from 'react-i18next';

interface Props {
  datasets: IGetUltraSonicData[];
  addGraph: (start: string, end: string) => void;
  updateGraph: (start: string, end: string) => void;
  toggleFavorite: (start: string, end: string, favoriteId?: number) => void;
  onClose: () => void;
}

const Container = css`
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = css`
  padding: 16px;
  flex-shrink: 0;
`;

const Title = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  flex-shrink: 0;
`;

const DatasetList = css`
  padding: 0 16px 16px;
  overflow-y: auto;
`;

const Close = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: center;
  }
`;

export const Dataset = React.memo(({ datasets, addGraph, updateGraph, toggleFavorite, onClose }: Props) => {
  const [page, setPage] = React.useState(1);
  const [isFilter, setFilter] = React.useState(false);
  const { t } = useTranslation();

  const handleChangePage = React.useCallback((e, value: number) => {
    setPage(value);
  }, []);

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.checked);
  };

  const memoDatasets = React.useMemo(() => {
    return isFilter ? datasets.filter((dataset) => dataset.isFavorite) : datasets;
  }, [isFilter]);

  const totalPages = React.useMemo(() => {
    return Math.ceil(memoDatasets.length / 10);
  }, [memoDatasets]);

  const paginationDataset = React.useMemo(() => {
    if (memoDatasets.length < 10) {
      return memoDatasets;
    }
    if (page === 1) {
      return memoDatasets.slice(0, 10);
    }
    return memoDatasets.slice((page - 1) * 10, 10 * page);
  }, [memoDatasets, page]);

  return (
    <div css={Container}>
      <div css={Header}>
        <div css={Title}>
          {t('dataset', 'データセット')} <Close onClick={onClose} />
        </div>
        <FormControlLabel
          control={<Checkbox onChange={handleChangeFilter} />}
          label={t('filterFavolite', 'お気に入りを絞り込む')}
        />
      </div>
      <div css={DatasetList}>
        {paginationDataset.map((dataset) => (
          <DataSetListItem
            key={dataset.begin}
            dataset={dataset}
            addGraph={addGraph}
            updateGraph={updateGraph}
            toggleFavorite={toggleFavorite}
          />
        ))}
      </div>
      <div>{/* {page}/{datasets.length} */}</div>
      <StyledPagination
        sx={{ marginBottom: '8px', justifyContent: 'center' }}
        count={totalPages}
        page={page}
        onChange={handleChangePage}
      />
    </div>
  );
});
