import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import { IGetNursingHomeResidentsRes, IResident } from '../../../types/api/traversal/getNursingHomeResidents';
import { ResidentListHeader } from '../../../components/residentListHeader';
import { DfreeGraphListItem } from './dfreeGraphListItem';
import { getUtcRange } from '../../../utils/dateutil';
import { residentListSettingsSelector } from '../../../redux/config/residentListSettings';
import { useLoading } from '../../../contexts/LoadingContext';
import { RecordDialog } from '../../../components/careRecord/recordDialog';
import { RecordModal } from '../../../components/careRecord/recordModal';
import { navSelector, navSlice } from '../../../redux/config/leftnav';
import { selfDataSelector } from '../../../redux/config/selfData';
import { useSortResidents } from '../../../hooks/useSortResidents';
import { SortType } from '@/types/components';

const Container = css`
  padding: 0 16px;
`;

const EmptyResidentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

export const DfreeGraphList: React.FC = () => {
  const dispatch = useDispatch();
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { badge } = useSelector(navSelector);
  const residentListSettings = useSelector(residentListSettingsSelector);
  const self = useSelector(selfDataSelector);
  const [resident, setResident] = React.useState<IResident>();
  const { sortedResidents, sortResidents } = useSortResidents<IResident>();

  const [dateRange, setDateRange] = React.useState<{ start: string; end: string }>({
    start: getUtcRange(new Date(), 5)[0],
    end: getUtcRange(new Date(), 5)[1],
  });
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  const createFilterType = (condition: 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit') => {
    if (['deviceAssigned', 'deviceAttached'].includes(condition)) {
      return condition as 'deviceAssigned' | 'deviceAttached';
    } else return null;
  };

  const fetchResidents = React.useCallback(
    async (unitId?: number | null, condition?: 'deviceAssigned' | 'deviceAttached' | null, sortType?: SortType) => {
      const [start, end] = getUtcRange(new Date(), 5);
      setDateRange({ start, end });
      showLoading();
      Promise.all([
        api.get('/traversal/nursing-homes/:nursingHomeId', {
          params: unitId ? { nursingHomeId, nursingHomeUnitId: unitId } : { nursingHomeId },
        }),
        api.get('/traversal/nursing-homes/:nursingHomeId/sensor-reactions', {
          params: { nursingHomeId, from: start, to: end, sort: 'asc' },
        }),
        api.get('/traversal/nursing-homes/:nursingHomeId/care-records', {
          params: { nursingHomeId, from: start, to: end, limit: 10000 },
        }),
      ]).then(([residentList, reactions, records]) => {
        const filteredResidents = (() => {
          if (condition === 'deviceAssigned') {
            return residentList.residents.filter((resident) => resident.dfreeDeviceId);
          } else if (condition === 'deviceAttached') {
            return residentList.residents.filter((resident) => resident.urineSize !== null);
          } else {
            return residentList.residents;
          }
        })();

        let residents = filteredResidents.map((resident) => {
          const sensor = reactions.residents.find((v) => v.residentId === resident.id);
          if (!sensor) return resident;
          const destSensorReactions: [number, number | null][] = [];
          let prevTimestamp: number;

          sensor.sensorReactions.forEach((item) => {
            // 前後から25分以上離れていたら切断
            const timestamp = Date.parse(item.timestamp);
            if (timestamp - prevTimestamp > 60 * 1000 * 25) {
              destSensorReactions.push([timestamp - 60 * 1000, null]);
            }
            prevTimestamp = timestamp;

            destSensorReactions.push([timestamp, item.u1xActiveRatio * 10]);
          });

          // care recored plots. want refactor.
          const careResidents = records?.residents;
          const careRecords = careResidents.find((v) => v.residentId === resident.id);
          if (!careRecords || Object.keys(careRecords.careRecords).length == 0) {
            return {
              ...resident,
              bladderThresholdPercentage: sensor.bladderThresholdPercentage,
              sensorReactions: destSensorReactions,
              sensorNotifications: sensor.notifications,
              careRecordPlots: [],
            };
          }
          const careRecordPlots = careRecords.careRecords.map((row) => {
            const v = row.data;
            return { timestamp: v.timestamp, toilet: v.urinatedToilet, pad: v.padUrineVolumeType !== 'none' };
          });

          return {
            ...resident,
            bladderThresholdPercentage: sensor.bladderThresholdPercentage,
            sensorReactions: destSensorReactions,
            sensorNotifications: sensor.notifications,
            careRecordPlots: careRecordPlots,
          };
        });

        // 入居者一覧を並び替え
        sortResidents(residents, sortType ?? residentListSettings.sortType);
        hideLoading();
      });
    },
    [sortedResidents, residentListSettings, nursingHomeId]
  );

  const handleOpenRecordModal = React.useCallback((resident: IResident) => {
    setResident(resident);
  }, []);

  const handleCloseRecordModal = React.useCallback(() => {
    setResident(undefined);
  }, []);

  const handleUpdate = React.useCallback(() => {
    fetchResidents(
      residentListSettings.unitId,
      createFilterType(residentListSettings.conditions),
      residentListSettings.sortType
    );
  }, [residentListSettings, nursingHomeId]);

  React.useEffect(() => {
    if (!self.selfUser.id) return;
    let unitId = null;

    let conditions =
      (localStorage.getItem('condition') as 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit') ?? 'all';
    // 前回と異なるIDの場合はデフォルト値
    if (self.selfUser.id !== Number(localStorage.getItem('self')) && !self.selfUser.nursingHomeUnitId) {
      unitId = null;
      conditions = 'all';
    } else if (self.selfUser.nursingHomeUnitId) {
      unitId = self.selfUser.nursingHomeUnitId;
    } else if (!localStorage.getItem('unit') || localStorage.getItem('unit') === 'null') {
      unitId = null;
    } else {
      unitId = localStorage.getItem('unit');
    }
    fetchResidents(unitId ? +unitId : null, createFilterType(conditions));
  }, [self.selfUser, nursingHomeId]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      handleUpdate();
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [residentListSettings, nursingHomeId]);

  // 通知アラートの消去とバッヂカウントの更新処理
  const handleResetNotification = React.useCallback(
    async (residentId: number) => {
      const resident = await api.get('/residents/:residentId', { params: { residentId } });
      const params = {
        ...resident,
        uncaredBigBladder: false,
        uncaredGotUp: false,
        uncaredUrination: false,
        uncaredSensorError: false,
        uncaredUrineReset: false,
        uncaredZeroReset: false,
        uncaredUpsideDown: false,
      };
      await api.put('/residents/:residentId', { residentId, ...params });
      if (badge.resident > 0) {
        dispatch(navSlice.actions.setResidentBadge(-1));
      }
      fetchResidents(residentListSettings.unitId, createFilterType(residentListSettings.conditions));
    },
    [residentListSettings, badge, nursingHomeId]
  );

  return (
    <div css={Container}>
      <ResidentListHeader
        title={t('dfreeGraph', 'DFreeグラフ')}
        conditions={residentListSettings.conditions}
        sortType={residentListSettings.sortType}
        unitId={residentListSettings.unitId}
        onUpdate={handleUpdate}
        onChangeListSettings={fetchResidents}
      />
      {sortedResidents ? (
        <>
          {sortedResidents.length > 0 ? (
            <>
              {sortedResidents.map((resident, i) => (
                <DfreeGraphListItem
                  key={resident.id}
                  resident={resident}
                  fetch={handleUpdate}
                  dateRange={dateRange}
                  openDialog={handleOpenRecordModal}
                  resetNotification={handleResetNotification}
                />
              ))}
            </>
          ) : (
            <div css={EmptyResidentContainer}>{t('thereAreNoResidents', '入居者がいません')}</div>
          )}
        </>
      ) : (
        <></>
      )}
      {resident && (
        <RecordDialog isOpen={!!resident} dialogWidth={'640px'} onClose={handleCloseRecordModal}>
          <RecordModal
            resident={resident}
            resetNotification={handleResetNotification}
            onClose={handleCloseRecordModal}
            onUpdate={handleUpdate}
          />
        </RecordDialog>
      )}
    </div>
  );
};
