// 同じ内容の別配列を生成する
export const cloneArray = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

// data配列内にあるkey名を指定し、key名を元に降順ソートを行う
// ※ 計算量がそれなりに多い為、巨大なソートには非推奨
export const sortArray = (data: any[], key: string, order: 'asc' | 'desc' = 'asc') => {
  return [...data].sort((x: any, y: any) => {
    if (order === 'asc') return x[key] > y[key] ? 1 : x[key] < y[key] ? -1 : 0;
    else return x[key] < y[key] ? 1 : x[key] > y[key] ? -1 : 0;
  });
};

/**
 * 指定された開始時間文字列、間隔、および長さに基づいて、時間の文字列の配列を生成する関数。
 *
 * @param {string} startTime - 開始時間の文字列（例: "1:00"）
 * @param {number} interval - 追加する時間の間隔（分）
 * @param {number} length - 生成する時間の数
 * @returns {string[]} 指定された条件に基づく時間の文字列の配列
 */
export const generateTimeLabelArray = (startTime: string, interval: number, length: number): string[] => {
  const [startHour, startMinute] = startTime.split(':').map(Number);

  let result = [];
  let currentHour = startHour;
  let currentMinute = startMinute;

  for (let i = 0; i < length; i++) {
    // 現在の時間と分を文字列として配列に追加
    result.push(`${currentHour}:${String(currentMinute).padStart(2, '0')}`);

    currentMinute += interval;

    if (currentMinute >= 60) {
      currentHour += 1;
      currentMinute = 0;
    }

    // 時間が24を超えた場合、0にリセット
    if (currentHour === 24) {
      currentHour = 0;
    }
  }

  return result;
};
