import React from 'react';
import { Loading } from '../components/mui/Loading';

interface Context {
  showLoading: (text?: string) => void;
  hideLoading: () => void;
}

const loadingContext = React.createContext<Context>({
  showLoading: () => {},
  hideLoading: () => {},
});

export function useLoading() {
  return React.useContext(loadingContext);
}

export function LoadingProvider({ children }: any) {
  const [isShowLoading, setIsShowLoading] = React.useState(false);
  const [text, setText] = React.useState<string>();

  function showLoading(text?: string) {
    setIsShowLoading(true);
    if (text) setText(text);
  }

  function hideLoading() {
    setIsShowLoading(false);
  }

  const value = {
    showLoading,
    hideLoading,
  };

  return (
    <>
      <loadingContext.Provider value={value}>{children}</loadingContext.Provider>
      {isShowLoading && <Loading text={text} />}
    </>
  );
}
