import React from 'react';
import { css } from '@emotion/react';
import api from '../../../api';
import { color } from '../../../styles';
import { ITraversalOrder } from '../../../types/api/traversal/order';
import { IGetDiaperRes } from '../../../types/api/care-settings/getDiaper';
import { IGetMasterDiaperRes } from '../../../types/api/master/getDiaper';
import { IUpdateOrderParams, IUpdateOrders } from '../../../types/api/order/updateOrder';
import { IDisplayOrderSummary } from '.';
import { numInsertComma } from '../../../utils/dataFormatUtil';
import { BtnClickAnimation } from '../../../styles/animation';
import { Dialog } from '../../../components/mui/dialog';
import { OrderedDiaperTable } from './orderedDiaperTable';
import { OrderedExpendableTable } from './orderedExpendableTable';
import { OrderChangeModal } from './order-change/orderChangeModal';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import dayjs from 'dayjs';

interface Props {
  orderData: IDisplayOrderSummary;
  orderableList:
    | {
        pants: IGetDiaperRes[];
        pads: IGetDiaperRes[];
        expendable: IGetMasterDiaperRes[];
      }
    | undefined;
  onReload: () => Promise<void>;
}

const OrderInfoContainer = css`
  margin: 24px 0 16px 0;
  padding: 16px;
  background-color: ${color.bg_light_green};
  font-size: 16px;
  border-radius: 8px;
`;

const BoldText = css`
  font-size: 16px;
  font-weight: bold;
`;

const Separate = css`
  margin: 16px 0 12px 0;
`;

const Border = css`
  padding-bottom: 32px;
  margin: 0 4px;
  border-bottom: 1px solid ${color.border_gray};
`;

const InfoContent = css`
  display: flex;
  align-items: center;
  line-height: 28px;
  letter-spacing: 0.05em;
  font-size: 16px;
`;

const InfoLabel = css`
  padding: 0 8px;
  min-width: 140px;
  font-size: 16px;
`;

const InfoText = css`
  padding: 0 8px;
  min-width: 112px;
  font-size: 16px;
`;

const LinkText = css`
  padding: 0 16px;
  font-size: 16px;
  color: ${color.green};
  &:hover {
    background-color: ${color.bg_gray};
  }
  ${BtnClickAnimation}
`;

export const OrderDetails = React.memo(({ orderData, orderableList, onReload }: Props) => {
  const { showSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isOrderDeleteModalOpen, setIsOrderDeleteModalOpen] = React.useState<boolean>(false);
  const [orderPants, setOrderPants] = React.useState<ITraversalOrder[]>([]);
  const [orderPads, setOrderPads] = React.useState<ITraversalOrder[]>([]);
  const [orderExpendable, setOrderExpendable] = React.useState<ITraversalOrder[]>([]);
  const [changedOrderData, setChangedOrderData] = React.useState<IUpdateOrderParams>();
  const [onSend, setOnSend] = React.useState<boolean>(false);

  const updateOrder = async (params: IUpdateOrderParams) => {
    await api.put('/orders/:orderId', params);
  };

  const cancelOrder = async (params: any) => {
    await api.put('/orders/:orderId/cancel', params);
  };

  const handleChangeOrderData = (data: any) => {
    setChangedOrderData(data);
  };

  const handleOpenModalButton = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClickModalSubmit = async () => {
    if (
      onSend ||
      !changedOrderData ||
      !changedOrderData.orders.length ||
      changedOrderData.orders.some((v) => v.amount <= 0) ||
      changedOrderData.orders.some((v) => v.diaperId === 0)
    ) {
      return;
    }
    setOnSend(true);

    const params: IUpdateOrderParams = {
      orderId: orderData.id,
      memo: changedOrderData.memo,
      orders: changedOrderData.orders,
    };

    updateOrder(params)
      .then(async () => {
        setOnSend(false);
        handleModalClose();
        await onReload();
        showSnackbar('発注内容を変更しました', 'success');
      })
      .catch((err) => {
        setOnSend(false);
        console.log(err);
        showSnackbar('発注内容を変更できませんでした', 'error');
      });
  };

  const handleClickDeleteOrderButton = () => {
    setIsOrderDeleteModalOpen(true);
  };

  const handleOrderDeleteModalClose = () => {
    setIsOrderDeleteModalOpen(false);
  };

  const handleClickOrderDeleteModalSubmit = () => {
    if (!orderData.id) return;

    const params: any = {
      orderId: orderData.id,
    };

    cancelOrder(params).then(() => {
      handleOrderDeleteModalClose();
      handleModalClose();
      showSnackbar('発注内容を取消しました', 'success');
      onReload();
    });
  };

  React.useEffect(() => {
    const pants = orderData.orders.filter((v) => v.diaperShape === 'outer');
    const pads = orderData.orders.filter((v) => v.diaperShape === 'inner');
    const expendable = orderData.orders.filter((v) => v.diaperShape === 'expendable');
    setOrderPants(pants);
    setOrderPads(pads);
    setOrderExpendable(expendable);
  }, [orderData]);

  return (
    <>
      <>
        <div css={OrderInfoContainer}>
          <div css={InfoContent}>
            <div css={[InfoLabel, BoldText]}>発注ID</div>
            <div css={InfoText}>{orderData.orderCode}</div>
          </div>
          <div css={InfoContent}>
            <div css={[InfoLabel, BoldText]}>発注日時</div>
            <div css={InfoText}>{orderData.displayOrderDate}</div>
            <div css={InfoText}>
              {orderData.quickOrder === 'normal'
                ? '(定期発注)'
                : orderData.quickOrder === 'freeAsap' && '（お急ぎ発注）'}
            </div>
          </div>
          <div css={InfoContent}>
            <div css={[InfoLabel, BoldText]}>納品日</div>
            <div css={InfoText}>{orderData.displayOrderDeliveryDate}</div>
          </div>
          <div css={InfoContent}>
            <div css={[InfoLabel, BoldText]}>発注ユニット</div>
            <div css={InfoText}>{orderData.displayUnitName}</div>
          </div>
          <div css={InfoContent}>
            <div css={[InfoLabel, BoldText]}>発注状態</div>
            <div css={InfoText}>{orderData.displayOrderStatus}</div>
            {orderData.orderStatus === 'recept' && (
              <div css={[LinkText]} onClick={handleOpenModalButton}>
                内容変更
              </div>
            )}
          </div>
          <div css={InfoContent}>
            <div css={[InfoLabel, BoldText]}>納品額</div>
            <div css={InfoText}>{numInsertComma(orderData.totalDeliveryPrice ?? 0)}円</div>
            <div css={InfoText}>※実際の請求額とは異なります</div>
          </div>
        </div>
        {!!orderPants?.length && (
          <>
            <div css={[BoldText, Separate]}>テープ/パンツ</div>
            <OrderedDiaperTable data={orderPants} />
          </>
        )}
        {!!orderPads?.length && (
          <>
            <div css={[BoldText, Separate]}>パッド</div>
            <OrderedDiaperTable data={orderPads} />
          </>
        )}
        {!!orderExpendable?.length && (
          <>
            <div css={[BoldText, Separate]}>消耗品</div>
            <OrderedExpendableTable data={orderExpendable} />
          </>
        )}
        {orderData.memo && (
          <>
            <div css={[BoldText, Separate]}>発注メモ</div>
            <div css={InfoText}>{orderData.memo}</div>
          </>
        )}
        <div css={Border}></div>
      </>
      <>
        {isModalOpen && (
          <Dialog
            isOpen={isModalOpen}
            title={''}
            onClose={handleModalClose}
            onOkClick={handleClickModalSubmit}
            dialogWidth={'1080px'}
          >
            <OrderChangeModal
              orderPants={orderPants}
              orderPads={orderPads}
              orderExpendables={orderExpendable}
              orderMemo={orderData.memo}
              orderableList={orderableList}
              onChange={handleChangeOrderData}
              onClickDeleteButton={handleClickDeleteOrderButton}
            />
          </Dialog>
        )}
        {isOrderDeleteModalOpen && (
          <Dialog
            isOpen={isOrderDeleteModalOpen}
            title={'注文の取り消し'}
            onClose={handleOrderDeleteModalClose}
            onOkClick={handleClickOrderDeleteModalSubmit}
            dialogWidth={'600px'}
          >
            <div>この注文を取り消しますか？</div>
          </Dialog>
        )}
      </>
    </>
  );
});
