import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { IDailyCareRecord } from '../../../../types/api/traversal/getDailyCareRecord';
import dayjs from 'dayjs';
import { color } from '../../../../styles';
import Tooltip from '@mui/material/Tooltip';
import {
  TOILET_TYPE_JA,
  TOILET_TYPE_EN,
  CARE_RECORDS,
  INCONTINENCE_TYPE_JA,
  INCONTINENCE_TYPE_EN,
  URINE_TYPE_JA,
  URINE_TYPE_EN,
  DEFECATION_TYPE_JA,
  DEFECATION_TYPE_EN,
  HARDNESS_TYPE_JA,
  HARDNESS_TYPE_EN,
} from '../../../../constants';
import { formatDrinkingType } from '../../../../utils/dataLabelUtil';
import { useFetchDrinks } from '../../../../hooks/api/careSettings/useFetchDrinks';
import { useTranslation } from 'react-i18next';

interface Props {
  records: IDailyCareRecord[];
  url: string;
  onClickTrash: (record: IDailyCareRecord) => void;
}

const Table = css`
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid ${color.border_gray};
`;

const TableHeader = css`
  background-color: ${color.table_gray};
`;

const ThCell = css`
  padding: 16px 8px;
  border: 1px solid ${color.border_gray};
`;

const TBody = css`
  height: 60px;
`;

const TdCell = css`
  border-right: 1px dashed ${color.border_gray};
  border-bottom: 1px solid ${color.border_gray};
  &:last-child {
    border-right: 1px solid ${color.border_gray};
  }
`;

const IconButton = css`
  margin-left: 8px;
  &:first-of-type {
    margin-left: 0;
  }
`;

const IconButtonCell = css`
  text-align: right;
  padding: 0 16px;
  width: 182px;
  & > * {
    cursor: pointer;
  }
`;

export const CareHistoryTable = React.memo(({ records, url, onClickTrash }: Props) => {
  const { drinks } = useFetchDrinks(true);
  const { t, i18n } = useTranslation();

  const formatDrink = (record: IDailyCareRecord) => {
    if (record.drinkingOther) {
      return record.drinkingOther;
    }
    if (record.drinkingType) {
      return formatDrinkingType(record.drinkingType);
    }
    if (record.drinkingId) {
      const result = drinks?.find((v) => v.id === record.drinkingId)?.name;
      return result;
    }
    return;
  };

  return (
    <>
      <table css={Table}>
        <thead css={TableHeader}>
          <tr>
            <th css={ThCell} rowSpan={2}>
              {t('time', '時間')}
            </th>
            <th css={ThCell} colSpan={3}>
              {t('urine', '排尿')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('defecation', '排便量')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('defecationHardnessType', '排便形態')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('diaperUse', 'テープ/パンツ交換')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('padUse', 'パッド交換')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('hydration', '飲水')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('laxativeAmount', '下剤量')}
            </th>
            <th css={ThCell} rowSpan={2}>
              {t('staffName', '担当職員')}<br />{t('workingTime', 'ケア時間')}
            </th>
            <th css={ThCell} rowSpan={2}></th>
          </tr>
          <tr>
            <th css={ThCell}>{t('toilet', 'トイレ')}</th>
            <th css={ThCell}>{t('pad', 'パッド')}</th>
            <th css={ThCell}>{t('incontinence', '失禁・空振り')}</th>
          </tr>
        </thead>
        {records.map((record) => (
          <tbody css={TBody} key={record.id}>
            <tr>
              <td css={TdCell}>{dayjs(record.timestamp).format('HH:mm')}</td>
              <td css={TdCell}>
                <div>
                  {record.toiletType && i18n.language === 'ja'
                    ? TOILET_TYPE_JA[record.toiletType]
                    : TOILET_TYPE_EN[record.toiletType]}
                </div>
                <div>{record.toiletUrineVolume && record.toiletUrineVolume + 'ml'}</div>
              </td>
              <td css={TdCell}>
                <div>
                  {record.padUrineVolumeType && i18n.language === 'ja'
                    ? URINE_TYPE_JA[record.padUrineVolumeType]
                    : URINE_TYPE_EN[record.padUrineVolumeType]}
                </div>
                <div>{record.padUrineVolume && record.padUrineVolume + 'ml'}</div>
              </td>
              <td css={TdCell}>
                {record.incontinence && i18n.language === 'ja'
                  ? INCONTINENCE_TYPE_JA[record.incontinence]
                  : INCONTINENCE_TYPE_EN[record.incontinence]}
              </td>
              <td css={TdCell}>
                {record.defecationVolumeType && i18n.language === 'ja'
                  ? DEFECATION_TYPE_JA[record.defecationVolumeType]
                  : DEFECATION_TYPE_EN[record.defecationVolumeType]}
              </td>
              <td css={TdCell}>
                {record.hardnessType && i18n.language === 'ja'
                  ? HARDNESS_TYPE_JA[record.hardnessType]
                  : HARDNESS_TYPE_EN[record.hardnessType]}
              </td>
              <td css={TdCell}>
                {record.diaper && CARE_RECORDS.DIAPER_SIZE.find((v) => v.value === record.diaper)?.label}
              </td>
              <td css={TdCell}>
                {record.padFirst && <div>{record.padFirst + 'ml'}</div>}
                {record.padSecond && <div>{record.padSecond + 'ml'}</div>}
              </td>
              <td css={TdCell}>
                <div>{formatDrink(record)}</div>
                <div>{record.drinkingVolume && record.drinkingVolume + 'ml'}</div>
              </td>
              <td css={TdCell}>{record.laxativeAmount}</td>
              <td css={TdCell}>
                <div>{record.staffName ? record.staffName : ''}</div>
                <div>{record.workingTime ? record.workingTime + t('minutes', '分') : ''}</div>
              </td>
              <td css={[TdCell, IconButtonCell]}>
                {record.memo && (
                  <Tooltip title={record.memo} placement='top'>
                    <span css={IconButton}>
                      <img src='/images/careRecord/care-record-memo.svg' alt='メモを表示' />
                    </span>
                  </Tooltip>
                )}
                <span css={IconButton}>
                  <Link to={`${url}/${record.id}/edit`}>
                    <img src='/images/careRecord/care-record-edit.svg' alt='編集' />
                  </Link>
                </span>
                <span css={IconButton} onClick={() => onClickTrash(record)}>
                  <img src='/images/careRecord/care-record-trash.svg' alt='削除' />
                </span>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </>
  );
});
