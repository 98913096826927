interface Key {
  [key: string]: string;
}

export const TOILET_TYPE_JA: Key = {
  toilet: 'トイレ',
  ptoilet: 'ポータブルトイレ',
  bed: 'ベッド',
  urineBottle: '尿瓶',
  urethralCat: '導尿',
  other: 'その他',
};

export const TOILET_TYPE_EN: Key = {
  toilet: 'Toilet',
  ptoilet: 'Portable toilet',
  bed: 'Bed',
  urineBottle: 'Urine bottle',
  urethralCat: 'Urethral catheterization',
  other: 'other',
};

export const PAD_TYPE_JA: Key = {
  pad: 'パッド',
  pants: 'リハビリテーションパンツ',
  catheter: 'カテーテル',
  clothPants: '布パンツ',
  other: 'その他',
};

export const PAD_TYPE_EN: Key = {
  pad: 'Pad',
  pants: 'Pants',
  catheter: 'Catheter',
  clothPants: 'Cloth pants',
  other: 'Other',
};

export const URINE_TYPE_JA: Key = {
  large: '多',
  medium: '中',
  small: '少',
  none: '無',
};

export const URINE_TYPE_EN: Key = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
  none: 'None',
};

export const DEFECATION_TYPE_JA: Key = {
  large: '多',
  medium: '中',
  small: '少',
  bit: '付',
};

export const DEFECATION_TYPE_EN: Key = {
  large: 'Large',
  medium: 'Medium',
  small: 'Small',
  bit: 'Bit',
};

export const HARDNESS_TYPE_JA: Key = {
  korokoro: 'コロコロ便',
  hard: '硬い便',
  littleHard: 'やや硬い便',
  middle: '普通便',
  soft: '軟便',
  littleSoft: '泥状便',
  water: '水様便',
};

export const HARDNESS_TYPE_EN: Key = {
  korokoro: 'Hard lumps',
  hard: 'Hard',
  littleHard: 'Semi-hard',
  middle: 'Normal',
  soft: 'Soft',
  littleSoft: 'Mushy',
  water: 'Watery',
};

export const INCONTINENCE_TYPE_JA: Key = {
  incontinence: '失禁',
  miss: '空振り',
  success: '成功',
  both: '失禁/空振り',
};

export const INCONTINENCE_TYPE_EN: Key = {
  incontinence: 'Incontinence',
  miss: 'Toilet guidance (No urge)',
  success: 'Toilet guidance and urination',
  both: 'Incontinence / Toilet guidance (No urge)',
};

export const ASSISTANT_TYPE: Key = {
  unnecessary: '自立',
  part: '一部介助',
  whole: '全部介助',
};
export const DRINKING_TYPE: Key = {
  water: '水',
  tea: 'お茶',
  other: 'その他',
};

export const CARE_RECORDS = {
  CARE_RECORD_KEYS: {
    toiletType: 'TOILET_VALUES',
    padType: 'PAD_VALUES',
    padUrineVolumeType: 'URINE_VALUES',
    defecationVolumeType: 'DEFECATION_VALUES',
    hardnessType: 'HARDNESS_TYPE_VALUES',
    assistanceType: 'ASSISTANT_TYPE_VALUES',
    drinkingType: 'DRINKING_TYPE_VALUES',
    incontinence: 'INCONTINENCE_TYPE_VALUES',
  },

  TOILET_VALUES: [
    {
      value: 'toilet',
      label: 'トイレ',
    },
    {
      value: 'ptoilet',
      label: 'ポータブルトイレ',
    },
    {
      value: 'bed',
      label: 'ベッド',
    },
    {
      value: 'urineBottle',
      label: '尿瓶',
    },
    {
      value: 'urethralCat',
      label: '導尿',
    },
    {
      value: 'other',
      label: 'その他',
    },
  ],
  PAD_VALUES: [
    {
      value: 'pad',
      label: 'パッド',
    },
    {
      value: 'pants',
      label: 'リハビリテーションパンツ',
    },
    {
      value: 'catheter',
      label: 'カテーテル',
    },
    {
      value: 'clothPants',
      label: '布パンツ',
    },
    {
      value: 'other',
      label: 'その他',
    },
  ],
  PLACE_VALUES: [
    {
      value: 'toilet',
      label: 'トイレ',
    },
    {
      value: 'ptoilet',
      label: 'ポータブルトイレ',
    },
    {
      value: 'pad',
      label: 'パッド',
    },
    {
      value: 'pants',
      label: 'リハビリテーションパンツ',
    },
    {
      value: 'catheter',
      label: 'カテーテル',
    },
    {
      value: 'other',
      label: 'その他',
    },
    {
      value: 'padtoilet',
      label: 'トイレ/パッド',
    },
    {
      value: 'padptoilet',
      label: 'ポータブルトイレ/パッド',
    },
    {
      value: 'pantstoilet',
      label: 'トイレ/リハビリテーションパンツ',
    },
    {
      value: 'pantsptoilet',
      label: 'ポータブルトイレ/リハビリテーションパンツ',
    },
    {
      value: 'cathetertoilet',
      label: 'トイレ/カテーテル',
    },
    {
      value: 'catheterptoilet',
      label: 'ポータブルトイレ/カテーテル',
    },
    {
      value: 'othertoilet',
      label: 'トイレ/その他',
    },
    {
      value: 'otherptoilet',
      label: 'ポータブルトイレ/その他',
    },
    {
      value: 'bed',
      label: 'ベッド',
    },
    {
      value: 'padbed',
      label: 'ベッド/パッド',
    },
    {
      value: 'pantsbed',
      label: 'ベッド/リハビリテーションパンツ',
    },
    {
      value: 'catheterbed',
      label: 'ベッド/カテーテル',
    },
    {
      value: 'clothPantsbed',
      label: 'ベッド/布パンツ',
    },
    {
      value: 'clothPantstoilet',
      label: 'トイレ/布パンツ',
    },
    {
      value: 'clothPantsptoilet',
      label: 'ポータブルトイレ/布パンツ',
    },
    {
      value: 'urethralCat',
      label: '導尿',
    },
    {
      value: 'padurethralCat',
      label: '導尿/パッド',
    },
    {
      value: 'pantsurethralCat',
      label: '導尿/リハビリテーションパンツ',
    },
    {
      value: 'catheterurethralCat',
      label: '導尿/カテーテル',
    },
    {
      value: 'clothPantsurethralCat',
      label: '導尿/布パンツ',
    },
    {
      value: 'urineBottle',
      label: '尿瓶',
    },
    {
      value: 'padurineBottle',
      label: '尿瓶/パッド',
    },
    {
      value: 'pantsurineBottle',
      label: '尿瓶/リハビリテーションパンツ',
    },
    {
      value: 'catheterurineBottle',
      label: '尿瓶/カテーテル',
    },
    {
      value: 'clothPantsurineBottle',
      label: '尿瓶/布パンツ',
    },
    {
      value: 'clothPantsother',
      label: 'その他/布パンツ',
    },
    {
      value: 'catheterurineother',
      label: 'その他/カテーテル',
    },
    {
      value: 'padother',
      label: 'その他/パッド',
    },
    {
      value: 'pantsother',
      label: 'その他/リハビリテーションパンツ',
    },
    {
      value: 'otherbed',
      label: 'ベッド/その他',
    },
    {
      value: 'otherurineBottle',
      label: '尿瓶/その他',
    },
    {
      value: 'otherurethralCat',
      label: '導尿/その他',
    },
    {
      value: 'otherother',
      label: 'その他/その他',
    },
  ],
  URINE_VALUES: [
    {
      value: 'large',
      label: '多',
    },
    {
      value: 'medium',
      label: '中',
    },
    {
      value: 'small',
      label: '少',
    },
    {
      value: 'none',
      label: '無',
    },
  ],
  DEFECATION_VALUES: [
    {
      value: 'large',
      label: '多',
    },
    {
      value: 'medium',
      label: '中',
    },
    {
      value: 'small',
      label: '少',
    },
    {
      value: 'bit',
      label: '付',
    },
  ],
  HARDNESS_TYPE_VALUES: [
    {
      value: 'korokoro',
      label: 'コロコロ便',
    },
    {
      value: 'hard',
      label: '硬い便',
    },
    {
      value: 'littleHard',
      label: 'やや硬い便',
    },
    {
      value: 'middle',
      label: '普通便',
    },
    {
      value: 'soft',
      label: 'やや柔らかい',
    },
    {
      value: 'littleSoft',
      label: '泥状便',
    },
    {
      value: 'water',
      label: '水様便',
    },
  ],
  INCONTINENCE_TYPE_VALUES: [
    {
      value: 'incontinence',
      label: '失禁',
    },
    {
      value: 'miss',
      label: '空振り',
    },
    {
      value: 'success',
      label: '成功',
    },
    {
      value: 'both',
      label: '失禁/空振り',
    },
  ],
  ASSISTANT_TYPE_VALUES: [
    {
      value: 'unnecessary',
      label: '自立',
    },
    {
      value: 'part',
      label: '一部介助',
    },
    {
      value: 'whole',
      label: '全部介助',
    },
  ],
  DRINKING_TYPE_VALUES: [
    {
      value: 'water',
      label: '水',
    },
    {
      value: 'tea',
      label: 'お茶',
    },
    {
      value: 'other',
      label: 'その他',
    },
  ],
  DRINKING_VOL: [
    {
      value: 150,
      label: '150ml',
    },
    {
      value: 200,
      label: '200ml',
    },
    {
      value: 100,
      label: 'その他',
    },
  ],
  LAXATIVE_VALUES: [
    {
      value: true,
      label: 'あり',
    },
    {
      value: false,
      label: '',
    },
  ],
  URINATED_TOILET: [
    {
      label: '有',
      value: true,
    },
    {
      label: '無',
      value: false,
    },
  ],
  ABBR_HARDNESS_TYPE_VALUES: [
    {
      value: 'hard',
      label: '硬',
    },
    {
      value: 'middle',
      label: '普',
    },
    {
      value: 'soft',
      label: '軟',
    },
    {
      value: 'littleSoft',
      label: '泥',
    },
    {
      value: 'water',
      label: '水',
    },
  ],
  URINATED_PAD: [
    {
      value: 'large',
      label: '多',
    },
    {
      value: 'medium',
      label: '中',
    },
    {
      value: 'small',
      label: '少',
    },
    {
      value: 'none',
      label: '無',
    },
  ],
  SUMMARY_PAD_TYPE: [
    {
      value: 'padAmountSS',
      label: '〜200ml',
    },
    {
      value: 'padAmountS',
      label: '〜300ml',
    },
    {
      value: 'padAmountM',
      label: '〜500ml',
    },
    {
      value: 'padAmountL',
      label: '〜700ml',
    },
    {
      value: 'padAmountLL',
      label: '〜999ml',
    },
    {
      value: 'padAmountXL',
      label: '1000ml〜',
    },
  ],
  DIAPER_VALUES: [
    {
      value: 'diaper',
      label: 'テープ/パンツ',
    },
    {
      value: 'pad',
      label: 'パッド',
    },
  ],
  DIAPER_SIZE: [
    {
      value: 'ss',
      label: 'SS',
    },
    {
      value: 's',
      label: 'S',
    },
    {
      value: 'm',
      label: 'M',
    },
    {
      value: 'l',
      label: 'L',
    },
    {
      value: 'll',
      label: 'LL',
    },
    {
      value: 'xl',
      label: 'XL',
    },
    {
      value: 'unknown',
      label: 'その他・不明',
    },
  ],
};

export const TERM = [
  { key: 1, value: '1' },
  { key: 7, value: '7' },
  { key: 10, value: '10' },
  { key: 14, value: '14' },
  { key: 31, value: '31' },
];