import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { initializeGA, useTracking } from '../hooks/analytics/useTracking';

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { currentUser } = useAuth();
  const gaId = process.env.REACT_APP_GA_ANALYTICS_ID ?? '';

  React.useEffect(() => {
    // GoogleAnalytics gtag.js読み込み
    return initializeGA(gaId);
  }, [gaId]);

  useTracking(gaId);

  return currentUser.isPending ? (
    <></>
  ) : currentUser.user ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to='/login' />
  );
};
