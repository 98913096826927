import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../core/store';
import { SortType } from '@/types/components';

interface ResidentListSettings {
  sortType: SortType;
  conditions: 'all' | 'deviceAssigned' | 'deviceAttached' | 'unit';
  unitId: number | null;
}

const initialState: ResidentListSettings = {
  sortType: getInitialState<SortType>('sort', 'unit'),
  conditions: getInitialState<'all' | 'deviceAssigned' | 'deviceAttached'>('condition', 'all'),
  unitId: getInitialState<number | null>('unit', null),
};

export const residentListSettingsSlice = createSlice({
  name: 'residentListSettings',
  initialState,
  reducers: {
    setSortType: (state: ResidentListSettings, action: PayloadAction<ResidentListSettings['sortType']>) => {
      state.sortType = action.payload;
    },
    setFilterCondition: (
      state: ResidentListSettings,
      action: PayloadAction<Omit<ResidentListSettings, 'sortType'>>
    ) => {
      state.conditions = action.payload.conditions;
      state.unitId = action.payload.unitId;
    },
    set: (state: ResidentListSettings, action: PayloadAction<ResidentListSettings>) => {
      state.sortType = action.payload.sortType;
      state.conditions = action.payload.conditions;
      state.unitId = action.payload.unitId;
    },
    resetSortType: (state: ResidentListSettings) => {
      state.sortType = 'unit';
    },
    resetFilterCondition: (state: ResidentListSettings) => {
      state.conditions = 'all';
      state.unitId = null;
    },
    reset: (state: ResidentListSettings) => {
      state.sortType = 'unit';
      state.conditions = 'all';
      state.unitId = null;
    },
  },
});

export const { setSortType, setFilterCondition, set, resetSortType, resetFilterCondition, reset } =
  residentListSettingsSlice.actions;

export const residentListSettingsSelector = (state: RootState) => state.residentListSettings;
export const filterConditionSelector = (state: RootState) => ({
  conditions: state.residentListSettings.conditions,
  unitId: state.residentListSettings.unitId,
});
export const sortTypeSelector = (state: RootState) => ({
  sortType: state.residentListSettings.sortType,
});

function getInitialState<T>(key: string, defaultValue: T): T {
  const storedValue = localStorage.getItem(key);
  if (storedValue === null) return defaultValue;
  return typeof defaultValue === 'string' ? (storedValue as unknown as T) : JSON.parse(storedValue);
}
