import React, { useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';

// 選択された日付の表示を現地時間で行い
// 呼び出し元には日付をdayjs形式のUTC時間で返す

interface Props {
  newValue?: Dayjs;
  label?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  variant?: 'standard' | 'outlined' | 'filled';
  enableDates?: Dayjs[];
  onChange: (value: Dayjs) => void;
}

// TODO: adapterLocaleがjaにしか対応させていない為、言語によって可変させるようにする（最低でもENに対応）

export const DateSelector = React.memo(
  ({ newValue, label = '', minDate, maxDate, variant = 'standard', enableDates, onChange }: Props) => {
    const [value, setValue] = React.useState<Dayjs | null>(dayjs());

    const handleChangeValue = (value: dayjs.Dayjs | null, keyboardInputValue?: string | undefined) => {
      if (keyboardInputValue) return; // キーボード入力を無効化
      setValue(value!.local()); // 表示はローカル時間で表示
      onChange(value!.utc()); // 親にはUTC時間で渡す
    };

    useEffect(() => {
      if (!newValue) return;
      setValue(newValue.local());
    }, [newValue]);

    return (
      <DatePicker
        views={['year', 'month', 'day']}
        label={label}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChangeValue}
        shouldDisableDate={(day) => {
          if (!enableDates) return false;
          const recordDate = enableDates.find((date) => date.format('YYYY-MM-DD') === day.format('YYYY-MM-DD'));
          return !recordDate;
        }}
        renderInput={(params) => <TextField variant={variant} {...params} helperText={null} />}
      />
    );
  }
);
