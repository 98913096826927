import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import {
  equipmentDiapers,
  equipmentLaxatives,
  equipmentPads,
  IGetNursingHomeEquipmentsParams,
  IGetNursingHomeEquipmentsRes,
} from '../../../types/api/traversal/getNursingHomeEquipments';

export const useFetchEquipments = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  from: string | null = null,
  to: string | null = null
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [equipments, setEquipments] = React.useState<IGetNursingHomeEquipmentsRes>();
  const [equipmentDiapers, setEquipmentDiapers] = React.useState<equipmentDiapers[]>();
  const [equipmentPads, setEquipmentPads] = React.useState<equipmentPads[]>();
  const [equipmentLaxatives, setEquipmentLaxatives] = React.useState<equipmentLaxatives[]>();

  const fetchEquipments = async (from: string | null = null, to: string | null = null) => {
    const params: IGetNursingHomeEquipmentsParams = {
      nursingHomeId: +nursingHomeId,
      from,
      to,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;

    let result = await api.get('/traversal/nursing-homes/:nursingHomeId/equipments', {
      params,
    });

    setEquipments(result);
    setEquipmentDiapers(result.diapers);
    setEquipmentPads(result.pads);
    setEquipmentLaxatives(result.laxatives);
    return result;
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchEquipments(from, to);
  }, []);

  return { equipments, equipmentDiapers, equipmentPads, equipmentLaxatives, fetchEquipments };
};
