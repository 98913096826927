import dayjs from 'dayjs';

/**
 * 入力された数値を3桁のカンマ区切りに変換
 * @param {number} data (ex. 123456789.1234)
 * @return {string} カンマ区切りされた値 (ex. 123,456,789.1234)
 */
export const numInsertComma = (data: number) => {
  return Intl.NumberFormat().format(data) ?? 0;
};

// 入力された値が上限値と下限値のを超えていた場合に上限値もしくは下限値に変換
export const numBetweenMinMax = (data: number, min: number, max: number) => {
  return data > max ? max : data < min ? min : data;
};

export const setApiDateFormat = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  return [
    start.set('hour', 0).set('minute', 0).set('second', 0).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    end.set('hour', 23).set('minute', 59).set('second', 59).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
  ];
};

export const setTableDateFormat = (date: string) => {
  return dayjs(date).format('YYYY/MM/DD');
};

/**
 * 任意の桁で四捨五入する関数
 * @param {number} data 四捨五入する数値
 * @param {number} decimal どの桁で四捨五入するか（0で整数, 1で小数点第一 ...）
 * @return {number} 四捨五入した値
 */
export const mathRound = (data: number, decimal = 0) => {
  const base = 10 ** decimal;
  return Math.round(data * base) / base;
}

/**
* 任意の桁で切り上げする関数
* @param {number} data 切り上げする数値
* @param {number} decimal どの桁で切り上げするか（0で整数, 1で小数点第一 ...）
* @return {number} 切り上げした値
*/
export const mathCeil = (data: number, decimal = 0) => {
  const base = 10 ** decimal;
  return Math.ceil(data * base) / base;
}

/**
* 任意の桁で切り捨てする関数
* @param {number} data 切り捨てする数値
* @param {number} decimal どの桁で切り捨てするか（0で整数, 1で小数点第一 ...）
* @return {number} 切り捨てした値
*/
export const mathFloor = (data: number, decimal = 0) => {
  const base = 10 ** decimal;
  return Math.floor(data * base) / base;
}
