import React from 'react';
import Button from '@mui/material/Button';
import { Dialog as MUIDialog, styled } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  okText?: string;
  dialogWidth?: string;
  onClose: () => void;
  onOkClick: () => void;
}

interface DialogProps {
  width: string;
}

const BootstrapDialog = styled(MUIDialog)<DialogProps>(({ width }) => ({
  textAlign: 'center',
  '& .MuiDialog-paper': {
    maxWidth: '80vw',
    pointerEvents: 'auto',
    width: `${width}`,
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'center',
    paddingBottom: '16px',
  },
}));

export const Dialog: React.FC<Props> = React.memo(
  ({ isOpen, title, children, type = 'button', okText = 'OK', dialogWidth = '540px', onClose, onOkClick }) => {
    const { t } = useTranslation();
    return (
      <BootstrapDialog open={isOpen} width={dialogWidth} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant='contained' disableElevation color='secondary' onClick={onClose}>
            {t('cancel', 'キャンセル')}
          </Button>
          <Button type={type} variant='contained' disableElevation onClick={onOkClick}>
            {okText}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  }
);
