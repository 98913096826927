import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { IResidentCareRecord } from '../../types/api/traversal/getNursingHomeCareRecord';
import { useTranslation } from 'react-i18next';

interface Props {
  resident: IResidentCareRecord;
}

const Container = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 48px;
  gap: 8px;
  font-size: 12px;
`;

const ItemContainer = css`
  display: flex;
  align-items: end;
  justify-content: center;
  flex-shrink: 0;
  min-width: 70px;
  height: 32px;
  line-height: 24px;
`;

const ToiletCountNumber = css`
  text-align: center;
  font-size: 24px;
  width: 28px;
  padding: 0;
  letter-spacing: -0.05em;
  font-family: 'Oswald', sans-serif;
`;

const SmallText = css`
  font-size: 12px;
`;

const Image = css`
  margin-right: 4px;
`;

const CountContainer = css`
  display: flex;
  align-items: baseline;
  flex-shrink: 0;
  line-height: 24px;
`;

export const ResidentHeaderCareStatus = React.memo(({ resident }: Props) => {
  const hourPerDay = 24;
  const [urineCount, setUrineCount] = React.useState<number>(0);
  const { t, i18n } = useTranslation();

  const calcLatestTime = (min: number) => {
    if (!min && min !== 0) return i18n.language === 'ja' ? '記録無し' : 'No record';

    let day = Math.floor(min / (60 * 24));
    let hour = Math.floor((min % (60 * 24)) / 60);
    let rem = Math.floor(min % 60);

    if (day) {
      return day + (i18n.language === 'ja' ? '日' : 'd') + hour + (i18n.language === 'ja' ? '時間' : 'h');
    } else if (hour)
      return hour + (i18n.language === 'ja' ? '時間' : 'h') + rem + (i18n.language === 'ja' ? '分' : 'm');
    else if (rem !== 0) return rem + (i18n.language === 'ja' ? '分' : 'm');
    else return i18n.language === 'ja' ? '1分未満' : 'Just now';
  };

  // 排尿回数のカウント
  React.useEffect(() => {
    if (!resident) return;
    let urineCount = 0;

    for (let i = 0; i < hourPerDay; i++) {
      resident.careRecords.forEach((v) => {
        if (dayjs(v.timestamp).local().hour() === i) {
          if (v.data.urinatedToilet) urineCount++;
        }
      });
    }
    setUrineCount(urineCount);
  }, [resident]);

  return (
    <div css={Container}>
      <div css={ItemContainer}>{t('elapsedTimeFromTheLastRecord', '最終記録からの経過時間')}</div>
      <div css={ItemContainer}>
        <img css={Image} src='/images/residents/residentStats/urine.svg' alt='排尿経過' />
        {calcLatestTime(resident.latestUrinationRecordingTime)}
      </div>
      <div css={ItemContainer}>
        <img css={Image} src='/images/residents/residentStats/stool.svg' alt='排便経過' />
        {calcLatestTime(resident.latestDefecationRecordingTime)}
      </div>
      <div css={ItemContainer}>
        <img css={Image} src='/images/residents/residentStats/laxative.svg' alt='下剤経過' />
        {calcLatestTime(resident.latestLaxativeRecordingTime)}
      </div>
      <div css={ItemContainer}>
        <div css={[CountContainer]}>
          {t('toiletUrination', 'トイレ排尿回数')}
          <span css={ToiletCountNumber}>{urineCount}</span>
          {t('times', '回')}
        </div>
      </div>
    </div>
  );
});
