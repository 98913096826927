import React from 'react';
import api from '../../../api';
import { IGetAnalyticsMonthlyParams, IGetAnalyticsMonthlyRes } from '../../../types/api/traversal/getAnalyticsMonthly';

export const useFetchAnalyticsMonthly = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  residentId: number | null = null,
  from: string | null = null,
  to: string | null = null
) => {
  const [analyticsMonthly, setAnalyticsMonthly] = React.useState<IGetAnalyticsMonthlyRes>();

  const fetchAnalyticsMonthly = async (residentId: number, from: string | null = null, to: string | null = null) => {
    const params: IGetAnalyticsMonthlyParams = {
      residentId,
      from,
      to,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;

    let result = await api.get('/traversal/residents/:residentId/analytics/monthly', {
      params,
    });

    setAnalyticsMonthly(result);
    return result; // TODO: 値を直接returnし、useStateを使っていないため修正する
  };

  React.useEffect(() => {
    if (!shouldInitFetch || !residentId) return;
    fetchAnalyticsMonthly(residentId, from, to);
  }, []);

  return { analyticsMonthly, fetchAnalyticsMonthly };
};
