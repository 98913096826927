import React from "react";
import i18n from './i18n/configs';
import { useSelector } from 'react-redux';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/core/store';
import { Router } from './router/Router';
import { color } from './styles/color';
import { ThemeProvider } from '@emotion/react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from './contexts/AuthContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { LoadingProvider } from './contexts/LoadingContext';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import 'dayjs/locale/en';

const theme = createTheme({
  palette: {
    primary: {
      main: color.green,
      contrastText: color.white,
    },
    secondary: {
      main: color.button_gray,
      contrastText: color.dark_gray,
    },
    error: {
      main: color.red,
    },
  },
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

function App() {
  const { t, i18n } = useTranslation();
  return (
    <I18nextProvider i18n={i18n}>
      <ReduxProvider store={store}>
        <AuthProvider>
          <MUIThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <LoadingProvider>
                <SnackbarProvider>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18n.language === 'ja' ? ja : undefined}
                  >
                    <Router />
                  </LocalizationProvider>
                </SnackbarProvider>
              </LoadingProvider>
            </ThemeProvider>
          </MUIThemeProvider>
        </AuthProvider>
      </ReduxProvider>
    </I18nextProvider>
  );
}

export default App;
