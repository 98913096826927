import React from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import { GetDiaperStockResponse } from '../../../types/api/diapers/getDiaperStock';

interface IFetchParams {
  nursingHomeId: number;
  nursingHomeUnitId?: number | null;
  sort: string;
  limit: number;
}

// 絞り込み
// nursingHomeUnitId: null = 全体検索, 0 = 施設全体のみ , unitId = 指定unitのみ

export const useFetchDiaperStocks = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeUnitId: number | null = null,
  sort = 'desc',
  limit = 10000
) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const [diaperStocks, setDiaperStocks] = React.useState<GetDiaperStockResponse>();
  const [pantsStocks, setPantsStocks] = React.useState<GetDiaperStockResponse>();
  const [padsStocks, setPadsStocks] = React.useState<GetDiaperStockResponse>();

  const fetchDiaperStocks = async (nursingHomeUnitId: number | null, sort = 'desc', limit = 10000) => {
    const params: IFetchParams = {
      nursingHomeId: +nursingHomeId,
      nursingHomeUnitId,
      sort,
      limit,
    };

    if ([0, null].includes(nursingHomeUnitId)) delete params.nursingHomeUnitId;

    let result = await api.get('/nursing-homes/:nursingHomeId/diaper-stocks', {
      params,
    });

    // unitIdが0の場合は施設全体を取り出す
    if (nursingHomeUnitId === 0) {
      result = result.filter((v) => v.nursingHomeUnitId === null);
    }
    // データをパンツとパッドに分ける
    const pants = result.filter((v) => v.diaperShape === 'outer');
    const pads = result.filter((v) => v.diaperShape === 'inner');

    setDiaperStocks(result);
    setPantsStocks(pants);
    setPadsStocks(pads);
  };

  React.useEffect(() => {
    if (!shouldInitFetch) return;
    fetchDiaperStocks(nursingHomeUnitId, sort, limit);
  }, []);

  return { diaperStocks, pantsStocks, padsStocks, fetchDiaperStocks };
};
