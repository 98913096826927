import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import api from '../../../api';
import { Dialog } from '../../../components/mui/dialog';
import { StyledTextField } from '../../../components/mui/textfield';
import { Cell, CellBase, HeaderCell, Row, Table, TableLinkText, TableLinkCell } from '../../../styles/table';
import { IGetUnitRes } from '../../../types/api/unit/getUnit';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';

interface Props {
  data: IGetUnitRes[];
  dataFetch: () => Promise<void>;
}

const ModalBodyContainer = css`
  margin: 8px;
  text-align: center;
`;

// テーブルのセルの横幅を左から順に定義
const CellWidth = [{ flex: '1 1 150px' }, { flex: '0 0 74px' }];

export const UnitTable = React.memo(({ data, dataFetch }: Props) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { t } = useTranslation();
  const tableHeader = [t('unitName', 'ユニット名'), t('operation', '操作')];
  const [isEditModalOpen, setIsEditModalOpen] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>();
  const [selectedData, setSelectedData] = React.useState<IGetUnitRes>();
  const [unitName, setUnitName] = React.useState<string>();
  const { showSnackbar } = useSnackbar();

  const deleteUnits = async (nursingHomeUnitId: number) => {
    await api.delete('/nursing-home-units/:nursingHomeUnitId', {
      params: { nursingHomeUnitId },
    });
  };

  const updateUnits = async (nursingHomeUnitId: number, name: string) => {
    await api.put('/nursing-home-units/:nursingHomeUnitId', {
      nursingHomeUnitId,
      name,
    });
  };

  const handleClickEdit = (data: IGetUnitRes) => (e: React.MouseEvent) => {
    setIsEditModalOpen(true);
    setSelectedData(data);
  };

  const handleClickEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleClickEditModalSubmit = async () => {
    if (selectedData && unitName) {
      await updateUnits(selectedData.id, unitName);
      setTimeout(() => {
        dataFetch();
        showSnackbar(t('unitHasBeenEdited', 'ユニットを更新しました'), 'success');
        handleClickEditModalClose();
      }, 500);
    }
  };

  const handleChangeUnitName = (event: React.ChangeEvent<HTMLInputElement>) => {
    !event.target.value ? setIsError(true) : setIsError(false);
    setUnitName(event.target.value);
  };

  const OperationCell = (data: IGetUnitRes) => {
    return (
      <div css={TableLinkCell}>
        <div css={TableLinkText} onClick={handleClickEdit(data)}>
          {t('edit', '編集')}
        </div>
      </div>
    );
  };

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0]]}>{data.name}</div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{OperationCell(data)}</div>
            </div>
          );
        })}
      </div>
      {isEditModalOpen && selectedData && (
        <Dialog
          isOpen={isEditModalOpen}
          title={t('editUnit', 'ユニットの編集')}
          onClose={handleClickEditModalClose}
          onOkClick={handleClickEditModalSubmit}
        >
          <div css={ModalBodyContainer}>
            <StyledTextField
              id={'add-unit'}
              value={selectedData.name}
              label={t('unitName', 'ユニット名')}
              isError={isError}
              errorText={t('unitNameIsRequired', 'ユニット名を入力してください')}
              onChange={handleChangeUnitName}
              fullWidth
            ></StyledTextField>
          </div>
        </Dialog>
      )}
    </>
  );
});
