import React from 'react';
import api from '../../../api';
import { IGetCareRecordParams, IGetCareRecordRes } from '../../../types/api/care-record/getCareRecord';

export const useFetchCareRecord = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  careRecordId: number | null = null
) => {
  const [careRecord, setCareRecord] = React.useState<IGetCareRecordRes>();

  const fetchCareRecord = async (careRecordId: number) => {
    const params: IGetCareRecordParams = {
      careRecordId,
    };

    let result = await api.get('/care-records/:careRecordId', {
      params,
    });

    setCareRecord(result);
    return result;
  };

  React.useEffect(() => {
    if (!shouldInitFetch || !careRecordId) return;
    fetchCareRecord(careRecordId);
  }, []);

  return { careRecord, fetchCareRecord };
};
