interface MediaType {
  minLargeDesktop: string;
  minDesktop: string;
  minTablet: string;
  minMobile: string;
  maxMobile: string;
}

export const viewports = {
  largeDesktop: 1280,
  desktop: 960,
  tablet: 520,
  mobile: 320,
} as const;

function parseUpperCase(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const media = Object.keys(viewports).reduce(
  (pre: Partial<MediaType>, cur): any => ({
    ...pre,
    [`min${parseUpperCase(cur)}`]: `@media screen and (min-width: ${viewports[cur as keyof typeof viewports]}px)`,
    maxMobile: `@media screen and (max-width: 499px)`,
  }),
  {}
) as MediaType;

export { media };
