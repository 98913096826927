export const FACILITY_REFERENCE_VALUE = [
  {
    type: 1,
    activeUser: null,
    capacity: null,
    invoice: null,
    nursingHomeId: null,
    nursingHomeName: '',
    occupancyRate: null,
    plan: null,
    yyyyMM: '基準値',
    deliveryPrice: null,
    deliveryPerPerson: 4980,
    numberOfDiaper: 0.91,
    numberOfPad: 4.08,
    absorption: 2917,
  },
  {
    type: 2,
    activeUser: null,
    capacity: null,
    invoice: null,
    nursingHomeId: null,
    nursingHomeName: '',
    occupancyRate: null,
    plan: null,
    yyyyMM: '基準値',
    deliveryPrice: null,
    deliveryPerPerson: 3980,
    numberOfDiaper: 0.9,
    numberOfPad: 3.56,
    absorption: 2385,
  },
  {
    type: 3,
    activeUser: null,
    capacity: null,
    invoice: null,
    nursingHomeId: null,
    nursingHomeName: '',
    occupancyRate: null,
    plan: null,
    yyyyMM: '基準値',
    deliveryPrice: null,
    deliveryPerPerson: 5380,
    numberOfDiaper: 0.95,
    numberOfPad: 3.83,
    absorption: 2883,
  },
];
