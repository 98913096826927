import React from 'react';
import { css } from '@emotion/react';
import { ITraversalOrder } from '../../../../types/api/traversal/order';
import { IGetDiaperRes } from '../../../../types/api/care-settings/getDiaper';
import { IGetMasterDiaperRes } from '../../../../types/api/master/getDiaper';
import { BtnClickAnimation } from '../../../../styles/animation';
import { color } from '../../../../styles';
import { OutlineButton } from '../../../../components/outlineButton';
import { MenuItemProps } from '../../../../components/mui/select';
import { StyledTextField } from '../../../../components/mui/textfield';
import { DiaperOrderChangeTable } from './diaperOrderChangeTable';
import { ExpendableOrderChangeTable } from './expendableOrderChangeTable';

interface Props {
  orderPants: ITraversalOrder[];
  orderPads: ITraversalOrder[];
  orderExpendables: ITraversalOrder[];
  orderMemo: string;
  orderableList:
    | {
        pants: IGetDiaperRes[];
        pads: IGetDiaperRes[];
        expendable: IGetMasterDiaperRes[];
      }
    | undefined;
  onChange: (orderData: any) => void;
  onClickDeleteButton: () => void;
}

const BodyContainer = css`
  text-align: left;
`;

const ContentHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Separate = css`
  margin: 16px 0 12px 0;
`;

const SeparateBottom = css`
  margin-bottom: 24px;
`;

const BoldText = css`
  font-size: 16px;
  font-weight: bold;
`;

const TitleText = css`
  font-size: 20px;
`;

const CancelLinkText = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 16px;
  font-size: 16px;
  color: ${color.red};
  &:hover {
    background-color: ${color.bg_gray};
  }
  ${BtnClickAnimation}
`;

const EmptyTable = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border: 1px solid ${color.border_gray};
`;

const WarnTextArea = css`
  margin-top: 24px;
  color: ${color.red};
`;

export const OrderChangeModal = React.memo(
  ({ orderPants, orderPads, orderExpendables, orderMemo, orderableList, onChange, onClickDeleteButton }: Props) => {
    const blankData = {
      id: 0,
      nursingHomeDiaperId: 0, //更新に必須
      diaperProductCode: '',
      diaperId: 0, //更新に必須
      diaperSheets: 0,
      diaperBags: 0,
      diaperSeries: '',
      diaperShorten: '',
      diaperDisplaySize: '',
      diaperSpecSize: '',
      diaperShape: '',
      diaperSize: '',
      cost: 0,
      caseCost: 0,
      amount: 1, //更新に必須
      diaperExpendablePrice: 0,
      includeTax: false,
      deliveryPrice: 0,
    };

    const [changedPants, setChangedPants] = React.useState<ITraversalOrder[]>([]);
    const [changedPads, setChangedPads] = React.useState<ITraversalOrder[]>([]);
    const [changedExpendable, setChangedExpendable] = React.useState<ITraversalOrder[]>([]);
    const [changedOrderMemo, setChangedOrderMemo] = React.useState<string>('');

    const handleClickAddPantsButton = () => {
      setChangedPants((prev) => [...prev, blankData]);
    };

    const handleClickAddPadButton = () => {
      setChangedPads((prev) => [...prev, blankData]);
    };

    const handleClickAddExpendableButton = () => {
      setChangedExpendable((prev) => [...prev, blankData]);
    };

    const handleDeletePantsOrder = (id: number) => {
      setChangedPants((prev) => prev.filter((v, i) => i !== id));
    };

    const handleDeletePadsOrder = (id: number) => {
      setChangedPads((prev) => prev.filter((v, i) => i !== id));
    };

    const handleDeleteExpendableOrder = (id: number) => {
      setChangedExpendable((prev) => prev.filter((v, i) => i !== id));
    };

    const handleChangePantAmount = (id: number, value: number) => {
      setChangedPants((prev) => {
        return prev.map((v, i) => {
          return id === i ? { ...v, amount: value } : v;
        });
      });
    };

    const handleChangePadAmount = (id: number, value: number) => {
      setChangedPads((prev) => {
        return prev.map((v, i) => {
          return id === i ? { ...v, amount: value } : v;
        });
      });
    };

    const handleChangeExpendableAmount = (id: number, value: number) => {
      setChangedExpendable((prev) => {
        return prev.map((v, i) => {
          return id === i ? { ...v, amount: value } : v;
        });
      });
    };

    const handleChangePantName = (id: number, value: MenuItemProps) => {
      setChangedPants((prev) => {
        const result = prev.map((v, i) => {
          return id === i
            ? { ...v, nursingHomeDiaperId: +value.key, diaperId: value.option.diaperId, diaperShorten: value.value }
            : v;
        });
        return result;
      });
    };

    const handleChangePadName = (id: number, value: MenuItemProps) => {
      setChangedPads((prev) => {
        return prev.map((v, i) => {
          return id === i
            ? { ...v, nursingHomeDiaperId: +value.key, diaperId: value.option.diaperId, diaperShorten: value.value }
            : v;
        });
      });
    };

    const handleChangeExpendableName = (id: number, value: MenuItemProps) => {
      setChangedExpendable((prev) => {
        // expendableはid(nursingHomeDiaperId)は持たないので0にしておく
        return prev.map((v, i) => {
          return id === i ? { ...v, id: 0, diaperId: +value.key, diaperShorten: value.value } : v;
        });
      });
    };

    const handleChangeOrderMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChangedOrderMemo(event.target.value);
    };

    React.useEffect(() => {
      setChangedPants(orderPants);
    }, [orderPants]);

    React.useEffect(() => {
      setChangedPads(orderPads);
    }, [orderPads]);

    React.useEffect(() => {
      setChangedExpendable(orderExpendables);
    }, [orderExpendables]);

    React.useEffect(() => {
      setChangedOrderMemo(orderMemo);
    }, [orderMemo]);

    React.useEffect(() => {
      const orders: any = [];
      changedPants.forEach((v) => {
        orders.push({
          amount: v.amount,
          diaperId: v.diaperId,
          nursingHomeDiaperId: v.nursingHomeDiaperId,
        });
      });
      changedPads.forEach((v) => {
        orders.push({
          amount: v.amount,
          diaperId: v.diaperId,
          nursingHomeDiaperId: v.nursingHomeDiaperId,
        });
      });
      changedExpendable.forEach((v) => {
        orders.push({
          amount: v.amount,
          diaperId: v.diaperId,
          nursingHomeDiaperId: null,
        });
      });
      const data = {
        memo: changedOrderMemo,
        orders: orders,
      };
      onChange(data);
    }, [changedPants, changedPads, changedExpendable, changedOrderMemo]);

    return (
      <>
        <div css={BodyContainer}>
          <div css={[ContentHeader, SeparateBottom]}>
            <div css={[BoldText, TitleText]}>注文の編集</div>
            <div css={CancelLinkText} onClick={onClickDeleteButton}>
              注文取消
            </div>
          </div>
          <div css={ContentHeader}>
            <div css={[BoldText, Separate]}>テープ/パンツ</div>
            <OutlineButton icon={'＋'} onClick={handleClickAddPantsButton}>
              追加
            </OutlineButton>
          </div>
          {changedPants?.length ? (
            <DiaperOrderChangeTable
              data={changedPants}
              orderableList={orderableList?.pants}
              onAmountChange={handleChangePantAmount}
              onDiaperNameChange={handleChangePantName}
              onDelete={handleDeletePantsOrder}
            />
          ) : (
            <div css={EmptyTable}>テープ/パンツの発注情報はありません</div>
          )}
          <div css={ContentHeader}>
            <div css={[BoldText, Separate]}>パッド</div>
            <OutlineButton icon={'＋'} onClick={handleClickAddPadButton}>
              追加
            </OutlineButton>
          </div>
          {changedPads?.length ? (
            <DiaperOrderChangeTable
              data={changedPads}
              orderableList={orderableList?.pads}
              onAmountChange={handleChangePadAmount}
              onDiaperNameChange={handleChangePadName}
              onDelete={handleDeletePadsOrder}
            />
          ) : (
            <div css={EmptyTable}>パッドの発注情報はありません</div>
          )}
          <div css={ContentHeader}>
            <div css={[BoldText, Separate]}>消耗品</div>
            <OutlineButton icon={'＋'} onClick={handleClickAddExpendableButton}>
              追加
            </OutlineButton>
          </div>
          {changedExpendable?.length ? (
            <ExpendableOrderChangeTable
              data={changedExpendable}
              orderableList={orderableList?.expendable}
              onAmountChange={handleChangeExpendableAmount}
              onDiaperNameChange={handleChangeExpendableName}
              onDelete={handleDeleteExpendableOrder}
            />
          ) : (
            <div css={EmptyTable}>消耗品の発注情報はありません</div>
          )}
          <div css={[BoldText, Separate]}>発注メモ</div>
          <StyledTextField
            id='order-memo'
            value={changedOrderMemo}
            multiline
            style={{ width: '100%' }}
            isError={changedOrderMemo?.length >= 300}
            errorText={changedOrderMemo?.length >= 300 ? 'メモの文字数を300文字以下にしてください' : ''}
            onChange={handleChangeOrderMemo}
          />
        </div>
        {!changedPants?.length && !changedPads?.length && !changedExpendable?.length && (
          <div css={WarnTextArea}>
            発注内容がありません
            <br />
            発注を取り消す場合は右上の注文取消を押してください
          </div>
        )}
      </>
    );
  }
);
