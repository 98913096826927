import React from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import api from '../../../../api';
import { IGetCareRecordRes } from '../../../../types/api/care-record/getCareRecord';
import { Button } from '../../../../components/mui/button';
import { FormControlLabel, Checkbox, Drawer, styled } from '@mui/material';
import { color, TableLinkText } from '../../../../styles';
import { OutlineButton } from '../../../../components/outlineButton';
import { useLoading } from '../../../../contexts/LoadingContext';
import { useFetchResidentCareRecord } from '../../../../hooks/api/careRecord/useFetchResidentCareRecord';

/* *****************************
 * U3プロジェクト向けに試作
 * 2023/12/5 現在このページは開発環境のみ
 ***************************** */

interface Props {
  nursingHomeId: string;
  residentId: string;
}

interface ITotalTable {
  nightTimeUrineVolume: number; // 夜間尿量
  dayTimeUrineVolume: number; // 昼間尿量
  allDayUrineVolume: number; // 合計尿量
  nightTimeUrineCount: number; // 夜間排尿回数
  dayTimeUrineCount: number; // 昼間排尿回数
  allDayUrineCount: number; // 合計排尿回数
  leakCount: number; // 尿漏れ回数
  urgencyCount: number; // 切迫感回数
}

interface ICareRecordTable {
  careRecordId: number;
  timestamp: string;
  urineVolume: number; // padUrineVolumeとtoiletVolumeを足した値
  isLeak: boolean; // padUrineVolueTypeがnone以外記録されている場合
  isUrgency: boolean;
  memo: string;
}

const Body = css`
  min-width: 600px;
  padding: 24px;
  min-height: 40vh;
`;

const DatePickerContainer = css`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TableContainer = css`
  margin-bottom: 24px;
`;

const UpperTh = css`
  padding: 4px 8px;
  font-weight: normal;
`;

const Th = (bgColor: string = '#FFFFFF') => css`
  border: 1px solid ${color.border_gray};
  padding: 8px;
  text-align: left;
  font-weight: normal;
  background-color: ${bgColor};
`;

const Td = (bgColor: string = '#FFFFFF') => css`
  border: 1px solid ${color.border_gray};
  padding: 8px;
  text-align: left;
  font-weight: normal;
  background-color: ${bgColor};
`;

const TableSeparate = css`
  margin: 0 12px;
  border-collapse: separate;
  border-spacing: 10px;
`;

const DiaryTable = css`
  width: 100%;
`;

const Icon = css`
  width: 14px;
  height: 14px;
  margin: 0 10px 2px 10px;
`;

const TextCenter = css`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding: 0;
`;

// テーブルのセルの横幅を左から順に定義
const CellWidth = [
  { width: '100px' },
  { width: '100px' },
  { width: '80px' },
  { width: '80px' },
  { width: '300px' },
  { width: '48px' },
];

export const ResidentDetailDiary: React.FC<Props> = ({ nursingHomeId, residentId }) => {
  const history = useHistory();
  const initialEndDate = dayjs().set('hour', 21).set('minute', 0).set('second', 0).utc();
  const initialStartDate = initialEndDate.subtract(1, 'day');

  const { residentCareRecord, fetchResidentCareRecord } = useFetchResidentCareRecord(
    true,
    +residentId,
    initialStartDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    initialEndDate.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    'asc'
  );
  const [range, setRange] = React.useState<{ start: Dayjs; end: Dayjs }>({
    start: initialStartDate,
    end: initialEndDate,
  });
  const [recordTotal, setRecordTotal] = React.useState<ITotalTable>();
  const [dayTimeRecords, setDayTimeRecords] = React.useState<ICareRecordTable[]>([]);
  const [nightTimeRecords, setNightTimeRecords] = React.useState<ICareRecordTable[]>([]);

  const handleChangeDate = React.useCallback((date: Dayjs | null, keyboardInputValue?: string | undefined) => {
    setRange((prev) => {
      return {
        ...prev,
        start: dayjs(date).local().set('hour', 21).set('minute', 0).set('second', 0).utc(),
        end: dayjs(date).add(1, 'day').local().set('hour', 21).set('minute', 0).set('second', 0).utc(),
      };
    });
    fetchResidentCareRecord(
      +residentId,
      dayjs(date).local().set('hour', 21).set('minute', 0).set('second', 0).utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      dayjs(date)
        .add(1, 'day')
        .local()
        .set('hour', 21)
        .set('minute', 0)
        .set('second', 0)
        .utc()
        .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      'asc'
    );
  }, []);

  const handleClickCreate = () => {
    history.push(`/nursinghome/${nursingHomeId}/residents/${residentId}/care-record/new`);
  };

  const handleClickEdit = (id: number) => () => {
    history.push(`/nursinghome/${nursingHomeId}/residents/${residentId}/care-record/${id}/edit`);
  };

  const createTableRecord = (records: IGetCareRecordRes[]) => {
    return records.map((v) => {
      return {
        careRecordId: v.id,
        timestamp: dayjs(v.timestamp).local().format('HH:mm'),
        urineVolume: v.padUrineVolume + v.toiletVolume,
        isLeak: v.padUrineVolumeType && v.padUrineVolumeType !== 'none' ? true : false,
        isUrgency: v.isUrgency,
        memo: v.memo,
      };
    });
  };

  const createRecordTotal = (dayTimeRecords: IGetCareRecordRes[], nightTimeRecords: IGetCareRecordRes[]) => {
    let dayTimeUrineVolume = 0;
    let dayTimeUrineCount = 0;
    let nightTimeUrineVolume = 0;
    let nightTimeUrineCount = 0;
    let leakCount = 0;
    let urgencyCount = 0;

    dayTimeRecords.forEach((v) => {
      dayTimeUrineVolume += v.padUrineVolume + v.toiletVolume;
      (v.urinatedToilet || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none')) && dayTimeUrineCount++;
      (v.padUrineVolume || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none')) && leakCount++;
      v.isUrgency && urgencyCount++;
    });

    nightTimeRecords.forEach((v) => {
      nightTimeUrineVolume += v.padUrineVolume + v.toiletVolume;
      (v.urinatedToilet || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none')) && nightTimeUrineCount++;
      (v.padUrineVolume || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none')) && leakCount++;
      v.isUrgency && urgencyCount++;
    });

    return {
      dayTimeUrineVolume,
      dayTimeUrineCount,
      nightTimeUrineVolume,
      nightTimeUrineCount,
      allDayUrineVolume: dayTimeUrineVolume + nightTimeUrineVolume,
      allDayUrineCount: dayTimeUrineCount + nightTimeUrineCount,
      leakCount,
      urgencyCount,
    };
  };

  React.useEffect(() => {
    if (!residentCareRecord) return;
    // トイレ排尿無し、おむつ/パッド排尿無しは一覧から除く
    const nightTimeCareRecords = residentCareRecord.filter((v) => {
      return (
        dayjs(v.timestamp).isBetween(
          dayjs(range.start).local().set('hour', 21).set('minute', 0).set('second', 0).utc(),
          dayjs(range.start).local().set('hour', 21).set('minute', 0).set('second', 0).add(10, 'hour').utc()
        ) &&
        (v.urinatedToilet || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none'))
      );
    });
    const dayTimeCareRecords = residentCareRecord.filter((v) => {
      return (
        dayjs(v.timestamp).isBetween(
          dayjs(range.start).local().set('hour', 21).set('minute', 0).set('second', 0).add(10, 'hour').utc(),
          dayjs(range.end).local().set('hour', 21).set('minute', 0).set('second', 0).utc()
        ) &&
        (v.urinatedToilet || (v.padUrineVolumeType && v.padUrineVolumeType !== 'none'))
      );
    });
    setDayTimeRecords(createTableRecord(dayTimeCareRecords));
    setNightTimeRecords(createTableRecord(nightTimeCareRecords));
    setRecordTotal(createRecordTotal(dayTimeCareRecords, nightTimeCareRecords));
  }, [residentCareRecord]);

  return (
    <div css={Body}>
      <div css={DatePickerContainer}>
        <DatePicker
          views={['year', 'month', 'day']}
          value={range.start.local()}
          maxDate={initialEndDate}
          onChange={handleChangeDate}
          renderInput={(params) => <TextField variant={'standard'} {...params} helperText={null} />}
        />
        <OutlineButton bgColor={color.white} icon={'╋'} onClick={handleClickCreate}>
          記録を追加
        </OutlineButton>
      </div>
      <div>
        <table css={TableContainer}>
          <tbody>
            <tr>
              <th css={UpperTh}>開始時刻：</th>
              <td>{range.start.local().format('YYYY/MM/DD HH:mm')}</td>
              <th css={UpperTh}>終了時刻：</th>
              <td>{range.end.local().format('YYYY/MM/DD HH:mm')}</td>
            </tr>
            <tr>
              <th css={UpperTh}>起床時刻：</th>
              <td>7:00</td>
              <th css={UpperTh}>就寝時刻：</th>
              <td>21:00</td>
            </tr>
          </tbody>
        </table>
        <table css={TableContainer}>
          <tbody>
            <tr>
              <th css={Th('#E3F5FF')}>夜間尿量</th>
              <td css={Td()}>{recordTotal?.nightTimeUrineVolume}ml</td>
              <th css={Th('#FFF7E3')}>昼間尿量</th>
              <td css={Td()}>{recordTotal?.dayTimeUrineVolume}ml</td>
              <th css={Th(color.bg_light_gray)}>合計尿量</th>
              <td css={Td()}>{recordTotal?.allDayUrineVolume}ml</td>
              <th css={[Th(color.bg_light_gray), TableSeparate]}>尿もれ回数</th>
              <td css={Td()}>{recordTotal?.leakCount}回</td>
              <th css={[Th(color.bg_light_gray), TableSeparate]}>切迫感回数</th>
              <td css={Td()}>{recordTotal?.urgencyCount}回</td>
            </tr>
            <tr>
              <th css={Th('#E3F5FF')}>夜間排尿回数</th>
              <td css={Td()}>{recordTotal?.nightTimeUrineCount}回</td>
              <th css={Th('#FFF7E3')}>昼間排尿回数</th>
              <td css={Td()}>{recordTotal?.dayTimeUrineCount}回</td>
              <th css={Th(color.bg_light_gray)}>合計排尿回数</th>
              <td css={Td()}>{recordTotal?.allDayUrineCount}回</td>
            </tr>
          </tbody>
        </table>
        <table css={DiaryTable}>
          <thead>
            <tr>
              <th css={[Th(color.bg_light_gray), CellWidth[0]]}>排尿時刻</th>
              <th css={[Th(color.bg_light_gray), CellWidth[1]]}>{'排尿量(ml)'}</th>
              <th css={[Th(color.bg_light_gray), CellWidth[2]]}>尿もれ</th>
              <th css={[Th(color.bg_light_gray), CellWidth[3]]}>切迫感</th>
              <th css={[Th(color.bg_light_gray), CellWidth[4]]}>メモ</th>
              <th css={[Th(color.bg_light_gray), CellWidth[5]]}>{''}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={6} css={Td('#DFF4FF')}>
                就寝
                <img src='/images/residents/diary/moon.svg' alt='' css={Icon} />
                21:00
              </td>
            </tr>
            <>
              {nightTimeRecords.map((v, i) => {
                return (
                  <tr key={i}>
                    <td css={Td()}>{v.timestamp}</td>
                    <td css={Td()}>{v.urineVolume}</td>
                    <td css={Td()}>{v.isLeak ? '○' : ''}</td>
                    <td css={Td()}>{v.isUrgency ? '○' : ''}</td>
                    <td css={Td()}>{v.memo}</td>
                    <td css={Td()}>
                      <div css={[TableLinkText, TextCenter]} onClick={handleClickEdit(v.careRecordId)}>
                        編集
                      </div>
                    </td>
                  </tr>
                );
              })}
              {!nightTimeRecords.length && (
                <tr>
                  <td colSpan={6} css={Td()}>
                    排尿記録はありません
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={6} css={Td('#FFEED5')}>
                  起床
                  <img src='/images/residents/diary/sun.svg' alt='' css={Icon} />
                  7:00
                </td>
              </tr>
              {dayTimeRecords.map((v, i) => {
                return (
                  <tr key={i}>
                    <td css={Td()}>{v.timestamp}</td>
                    <td css={Td()}>{v.urineVolume}</td>
                    <td css={Td()}>{v.isLeak ? '○' : ''}</td>
                    <td css={Td()}>{v.isUrgency ? '○' : ''}</td>
                    <td css={Td()}>{v.memo}</td>
                    <td css={Td()}>
                      <div css={[TableLinkText, TextCenter]} onClick={handleClickEdit(v.careRecordId)}>
                        編集
                      </div>
                    </td>
                  </tr>
                );
              })}
              {!dayTimeRecords.length && (
                <tr>
                  <td colSpan={6} css={Td()}>
                    排尿記録はありません
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
      </div>
    </div>
  );
};
