import { IGetMasterDiaperRes } from '../types/api/master/getDiaper';

export function filterDuplicateMakers(masters: IGetMasterDiaperRes[]) {
  const filteredMakers = Array.from(
    new Set(
      masters.map((master) => {
        return master.maker;
      })
    )
  );
  return filteredMakers.map((maker, index) => {
    return { key: index, value: maker };
  });
}

export function filterDuplicateSeries(masters: IGetMasterDiaperRes[], maker?: string) {
  /* eslint-disable-next-line no-extra-boolean-cast */
  const filteredMasters = !!maker ? masters.filter((master) => master.maker === maker) : masters;
  const filteredSeries = Array.from(
    new Set(
      filteredMasters.map((master) => {
        return master.series;
      })
    )
  );
  return filteredSeries.map((series, index) => {
    return { key: index, value: series };
  });
}

export function filterDuplicateProducts(masters: IGetMasterDiaperRes[], maker?: string, series?: string) {
  const filteredMasters =
    !!maker && !!series
      ? masters.filter((master) => master.maker === maker && master.series === series)
      : /* eslint-disable-next-line no-extra-boolean-cast */
      !!maker
      ? masters.filter((master) => master.maker === maker)
      : /* eslint-disable-next-line no-extra-boolean-cast */
      !!series
      ? masters.filter((master) => master.series === series)
      : masters;
  const filteredProducts = Array.from(
    new Set(
      filteredMasters.map((master) => {
        return master.shorten;
      })
    )
  );
  return filteredProducts.map((product, index) => {
    return { key: index, value: product };
  });
}
