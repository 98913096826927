import dayjs from 'dayjs';
import { IGetDiaperRes } from '../types/api/care-settings/getDiaper';
import { DiaperRequired } from '../types/api/diaper-required/getDiaperRequired';
import { Stock } from '../types/api/diapers/getDiaperStock';
import { OrderAverage } from '../types/api/order/getOrderAverage';

// あるべき在庫・発注ライン追加メソッド
export function margeDiaperTableData(
  diapers: IGetDiaperRes[],
  requireds: DiaperRequired[],
  stocks: Stock[],
  type: string,
  unitId?: number,
  average?: OrderAverage[]
): IGetDiaperRes[] {
  return diapers.map((diaper) => {
    const diaperRequired = requireds.find(
      (required) =>
        diaper.diaperId === required.diaperId && required.nursingHomeUnitId === (type === 'unit' ? unitId : null)
    );
    const diaperStock = stocks.find((stock) => {
      return diaper.id === stock.nursingHomeDiaperId && stock.nursingHomeUnitId === (type === 'unit' ? unitId : null);
    });
    const diaperAverage = average
      ? average.find(
          (average) =>
            diaper.diaperId === average.diaperId && average.nursingHomeUnitId === (type === 'unit' ? unitId : null)
        )
      : null;
    return {
      ...diaper,
      bagStock: diaperStock ? diaperStock.bagStock : undefined,
      stock: diaperStock ? diaperStock.stock : undefined,
      stockUpdatedAt: diaperStock ? dayjs(diaperStock.stockUpdatedAt).format('MM/DD HH:mm') : undefined,
      borderLine: diaperRequired ? diaperRequired.borderLine : undefined,
      requiredStock: diaperRequired ? diaperRequired.requiredStock : undefined,
      diaperAverage: diaperAverage ? Math.round(diaperAverage.average) : undefined, // diaperAverageは現在未使用
      isWithinThreeDays: diaperStock ? dayjs(new Date()).diff(dayjs(diaperStock?.stockUpdatedAt), 'days') <= 3 : false,
    };
  });
}
