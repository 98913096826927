import React from 'react';
import api from '../../../api';
import { IGetDailyCareRecordParams, IGetDailyCareRecordRes } from '../../../types/api/traversal/getDailyCareRecord';

export const useFetchDailyCareRecord = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  residentId: number | null = null,
  from: string | null = null,
  to: string | null = null
) => {
  const [dailyCareRecord, setDailyCareRecord] = React.useState<IGetDailyCareRecordRes>();

  const fetchDailyCareRecord = async (residentId: number, from: string | null = null, to: string | null = null) => {
    const params: IGetDailyCareRecordParams = {
      residentId,
      from,
      to,
    };

    if (!from) delete params.from;
    if (!to) delete params.to;

    let result = await api.get('/traversal/residents/:residentId/care-records', {
      params,
      timeout: 5 * 60 * 1000, // 5分に設定
    });

    setDailyCareRecord(result);
    return result; // TODO: 値を直接returnし、useStateを使っていないため修正する
  };

  React.useEffect(() => {
    if (!shouldInitFetch || !residentId) return;
    fetchDailyCareRecord(residentId, from, to);
  }, []);

  return { dailyCareRecord, fetchDailyCareRecord };
};
