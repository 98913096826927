import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { color } from '../../../src/styles';

interface Props {
  name: string;
  serialNumber?: string;
  unitName: string;
  roomName: string | null;
  memo?: string;
  middleElement?: React.ReactNode;
  rightElement: React.ReactNode;
}

const ResidentHeaderWrapper = css`
  width: 100%;
  min-height: 48px;
  padding: 8px 16px;
`;

const Container = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 0;
`;

const Name = css`
  display: flex;
  gap: 8px;
  font-weight: bold;
  font-size: 18px;
`;

const SerialText = css`
  font-weight: normal;
  background: ${color.bg_light_gray};
  padding: 3px 8px;
  font-size: 14px;
`;

const LeftContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 48px;
  margin-right: 24px;
`;

const RightContainer = css`
  display: flex;
  justify-content: end;
  flex-grow: 1;
`;

const MemoRow = css`
  font-size: 14px;
`;

const ResidentInfo = css`
  font-size: 14px;
`;

export const ResidentItemHeader = React.memo(
  ({ name, serialNumber, unitName, memo, roomName, middleElement, rightElement }: Props) => {
    const { t } = useTranslation();

    return (
      <div css={ResidentHeaderWrapper}>
        <div css={Container}>
          <div css={LeftContainer}>
            <div css={Name}>
              <span>{name}</span>
              {serialNumber && <span css={SerialText}>{serialNumber}</span>}
            </div>
            <div css={ResidentInfo}>
              {unitName}
              {roomName && unitName && ' - '}
              {roomName}
            </div>
          </div>
          {middleElement && <div>{middleElement}</div>}
          <div css={RightContainer}>{rightElement}</div>
        </div>
        {memo && <div css={MemoRow}>{memo}</div>}
      </div>
    );
  }
);
