import React from 'react';
import api from '../../../api';
import { IGetLifeCarePlanningsRes, IGetLifeCarePlanningsParams } from '../../../types/api/life/getLifeCarePlanning';

export const useFetchLifeCarePlannings = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeId: number | null = null,
  residentIds: number[] | null = null,
  from: string | null = null,
  to: string | null = null,
  sort: string | null = null,
  limit: number | null = null,
  offset: number | null = null
) => {
  const [lifeCarePlannings, setLifeCarePlannings] = React.useState<IGetLifeCarePlanningsRes>();

  const fetchLifeCarePlannings = React.useCallback(
    async (
      nursingHomeId: number,
      residentIds: number[] | null = null,
      from: string | null = null,
      to: string | null = null,
      sort: string | null = null,
      limit: number | null = null,
      offset: number | null = null
    ) => {
      const params: IGetLifeCarePlanningsParams = {
        nursingHomeId,
        // eslint-disable-next-line camelcase
        resident_ids: residentIds,
        from,
        to,
        sort,
        limit,
        offset,
      };

      if (!from) delete params.from;
      if (!to) delete params.to;
      if (!sort) delete params.sort;
      if (!limit) delete params.limit;
      if (!offset) delete params.offset;

      let result = await api.get('/external/life/nursing-homes/:nursingHomeId/care-plannings', {
        params,
      });
      setLifeCarePlannings(result);
      return result;
    },
    [nursingHomeId, residentIds, from, to]
  );

  React.useEffect(() => {
    if (!shouldInitFetch || !nursingHomeId) return;
    fetchLifeCarePlannings(nursingHomeId, residentIds, from, to, sort, limit, offset);
  }, []);

  return { lifeCarePlannings, fetchLifeCarePlannings };
};
