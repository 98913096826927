import React from 'react';
import { TableLinkCell } from '../../styles/table';
import { css } from '@emotion/react';
import { numberValidation } from '../../utils/validation';
import TextField from '@mui/material/TextField';

interface Props {
  value: number | null; // 入力フォームに表示する値
  id?: string;
  label?: string;
  min?: number; // 入力できる最小の値 (デフォルト:0)
  max?: number; // 入力できる最大の値 (デフォルト:Number.MAX_SAFE_INTEGER)
  isError?: boolean;
  helperText?: string;
  onChange: (value: number, error?: 'lower-limit' | 'upper-limit' | undefined) => void;
}

const CssStyle = css`
  input {
    height: 32px;
    max-width: 60px;
    font-size: 14px;
    padding: 4px 8px;
  }
`;

export const InputFormCell = React.memo(
  ({
    value,
    id = 'table-cell',
    label = '',
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    isError = false,
    helperText = '',
    onChange,
  }: Props) => {
    const [inputValue, setInputValue] = React.useState<number | null>(0);
    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // 入力フォームはmin以下とmax以上と小数点を許容しない
      if (!numberValidation(+e.target.value)) return;
      if (+e.target.value >= min && +e.target.value <= max) {
        setInputValue(+e.target.value);
        onChange(+e.target.value);
      } else if (+e.target.value < min) {
        setInputValue(min);
        onChange(min, 'lower-limit');
      } else if (+e.target.value > max) {
        setInputValue(max);
        onChange(max, 'upper-limit');
      }
    };

    React.useEffect(() => {
      setInputValue((prev) => {
        if (prev === value) return prev;
        return value;
      });
    }, [value]);

    return (
      <div css={TableLinkCell}>
        <TextField
          label={label}
          id={id}
          variant='outlined'
          type='number'
          autoComplete='off'
          error={isError}
          helperText={helperText}
          value={inputValue}
          css={CssStyle}
          onChange={onValueChange}
        />
      </div>
    );
  }
);
