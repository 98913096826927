import { authSlice } from '../config/auth';
import { configureStore } from '@reduxjs/toolkit';
import { selfDataSlice } from '../config/selfData';
import { currentNursingHomeSlice } from '../config/currentNursingHome';
import { navSlice } from '../config/leftnav';
import { residentListSettingsSlice } from '../config/residentListSettings';
import { currentResidentSlice } from '../config/currentResident';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    selfData: selfDataSlice.reducer,
    currentNursingHome: currentNursingHomeSlice.reducer,
    currentResident: currentResidentSlice.reducer,
    navState: navSlice.reducer,
    residentListSettings: residentListSettingsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
