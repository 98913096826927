import React from 'react';
import api from '../../../api';
import {
  IGetResidentLifeCarePlanningsParams,
  IGetLifeCarePlanningsRes,
} from '../../../types/api/life/getLifeCarePlanning';

export const useFetchResidentLifeCarePlannings = (
  shouldInitFetch: boolean = false, //初回fetchをするかどうか
  nursingHomeId: number | null = null,
  residentId: number | null = null,
  from: string | null = null,
  to: string | null = null,
  sort: string | null = null,
  limit: number | null = null,
  offset: number | null = null
) => {
  const [resdientLifeCarePlannings, setResidentLifeCarePlannings] = React.useState<IGetLifeCarePlanningsRes>();

  const fetchResidentLifeCarePlannings = React.useCallback(
    async (
      nursingHomeId: number,
      residentId: number,
      from: string | null = null,
      to: string | null = null,
      sort: string | null = null,
      limit: number | null = null,
      offset: number | null = null
    ) => {
      const params: IGetResidentLifeCarePlanningsParams = {
        nursingHomeId,
        residentId,
        from,
        to,
        sort,
        limit,
        offset,
      };

      if (!from) delete params.from;
      if (!to) delete params.to;
      if (!sort) delete params.sort;
      if (!limit) delete params.limit;
      if (!offset) delete params.offset;

      let result = await api.get('/external/life/nursing-homes/:nursingHomeId/residents/:residentId/care-plannings', {
        params,
      });
      setResidentLifeCarePlannings(result);
      return result;
    },
    [nursingHomeId, residentId, from, to]
  );

  React.useEffect(() => {
    if (!shouldInitFetch || !nursingHomeId || !residentId) return;
    fetchResidentLifeCarePlannings(nursingHomeId, residentId, from, to, sort, limit, offset);
  }, []);

  return { resdientLifeCarePlannings, fetchResidentLifeCarePlannings };
};
