import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import objectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

export function getCurrentUtcTime(): string {
  return dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZ');
}

export function formatTimeJstToUtc(value: string): string {
  const time = dayjs(value, 'YYYY-MM-DD HH:mm:ss');
  return time.utc().format('YYYY-MM-DD HH:mm:ss');
}

export function formatTimeUtcToJst(value: string): number {
  return dayjs(value, 'YYYY-MM-DD HH:mm:ss').tz('Asia/Tokyo').local().valueOf();
}

export function formatGraphTime(value: string): string {
  return dayjs(value).format('YYYY-MM-DD HH:mm');
}

export function formatTimeStringToValue(timestamp: string) {
  return dayjs(timestamp).valueOf();
}

export function formatTimeDayjsToValue(timestamp: dayjs.Dayjs) {
  return dayjs(timestamp).valueOf();
}

// 引数をUTC時間に変換してFetch用のTimestamp形式に変換する
// 2021-09-11 01:15:00 -> 2021-09-10T16:15:00Z
export function formatUtcFetchTimestamp(timestamp: dayjs.Dayjs) {
  return dayjs(timestamp).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
}

// timezoneが引数としてない場合はローカルのタイムゾーンを利用
// 2021-09-10 16:15:00 +0000 -> 2021-09-11 01:15:00
// 2021-09-10T16:15:00.000Z -> 2021-09-11 01:15:00
export function convertUtcToTzString(timestamp: dayjs.Dayjs | string, timezone?: string) {
  if (!timezone) return dayjs(timestamp).local().format('YYYY-MM-DD HH:mm');
  return dayjs(timestamp).tz(timezone, false).format('YYYY-MM-DD HH:mm');
}

export function convertUtcToTz(timestamp: dayjs.Dayjs, timezone?: string) {
  return dayjs(timestamp).tz(timezone, false);
}

export function convertTzToUtc(timestamp: dayjs.Dayjs, timezone: string) {
  return dayjs(timestamp).tz(timezone, true).utc();
}

export function convertTzToUtcString(timestamp: dayjs.Dayjs, timezone: string) {
  return dayjs(timestamp).tz(timezone, true).utc().format('YYYY-MM-DD HH:mm');
}

export function convertTzToUtcValue(timestamp: dayjs.Dayjs, timezone: string) {
  return dayjs(timestamp).tz(timezone, true).utc().valueOf();
}

// 引数をタイムゾーン時間
// timezoneが引数としてない場合はローカルのタイムゾーンを利用
// Tz変換後も末尾にZが入っているが、先行実装に合わせるために付与している
// 16:15:00Z -> 01:15:00Z
export function convertUtcToTzHourString(timestamp: string, timezone?: string) {
  if(!timezone) return dayjs().utc()
    .hour(+timestamp.slice(0, 2)).minute(+timestamp.slice(3, 5)).second(+timestamp.slice(6, 8))
    .local().format('HH:mm:ss[Z]');
  return dayjs().utc()
    .hour(+timestamp.slice(0, 2)).minute(+timestamp.slice(3, 5)).second(+timestamp.slice(6, 8))
    .tz(timezone).format('HH:mm:ss[Z]');
}

// 引数をタイムゾーン時間
// timezoneが引数としてない場合はローカルのタイムゾーンを利用
// 01:15:00Z -> 16:15:00Z
export function convertTzToUtcHourString(timestamp: string, timezone?: string) {
  if(!timezone) return dayjs().local()
    .hour(+timestamp.slice(0, 2)).minute(+timestamp.slice(3, 5)).second(+timestamp.slice(6, 8))
    .utc().format('HH:mm:ss[Z]');
  return dayjs().tz(timezone)
    .hour(+timestamp.slice(0, 2)).minute(+timestamp.slice(3, 5)).second(+timestamp.slice(6, 8))
    .utc().format('HH:mm:ss[Z]');
}

/** 現在時刻から指定された時間の間のUTCを取得 */
export function getUtcRange(date: Date, hours = 5): string[] {
  return [dayjs(date).local().subtract(hours, 'hours').utc().format(), dayjs(date).local().utc().format()];
}
/** 指定された日の0時〜24時までUTCで取得する */
export function getUtcRangeByDate(date: Date): string[] {
  return [
    dayjs(new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0)).local().utc().format(),
    dayjs(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0))
      .local()
      .utc()
      .format(),
  ];
}

  // 指定された日時のその日の開始時間から終了時間の範囲を返す
  // 開始時間が終了時間より後ろの場合は開始時間から翌日の終了時間までの範囲を返す
export function getDayStartToEndRange(timestamp: Dayjs, rangeStart: number, rangeEnd: number) {
  if (rangeStart <= rangeEnd) {
    return [
      dayjs(timestamp).local().set('hour', rangeStart).set('minute', 0).set('second', 0).utc(),
      dayjs(timestamp).local().set('hour', rangeEnd).set('minute', 0).set('second', 0).utc(),
    ];
  } else {
    return [
      dayjs(timestamp).local().set('hour', rangeStart).set('minute', 0).set('second', 0).utc(),
      dayjs(timestamp).local().add(1, 'day').set('hour', rangeEnd).set('minute', 0).set('second', 0).utc(),
    ];
  }
}
