import React from 'react';
import { Cell, CellBase, HeaderCell, Row, Table, TextWrap } from '../../../styles/table';
import { ITraversalOrder } from '../../../types/api/traversal/order';
import { numInsertComma } from '../../../utils/dataFormatUtil';

interface Props {
  data: ITraversalOrder[];
}

// テーブルのセルの横幅を左から順に定義
const CellWidth = [{ flex: '5 0.6 430px' }, { flex: '1 1 90px' }, { flex: '2 1 180px' }, { flex: '1 1 80px' }];

export const OrderedExpendableTable = React.memo(({ data }: Props) => {
  const tableHeader = ['シリーズ/製品名', '単価', '発注数', '納品額'];

  return (
    <>
      <div css={Table}>
        <div css={Row}>
          {tableHeader.map((hData, i) => {
            return (
              <div css={[CellBase, HeaderCell, CellWidth[i]]} key={i}>
                {hData}
              </div>
            );
          })}
        </div>
        {data.map((data, i) => {
          return (
            <div css={Row} key={i}>
              <div css={[CellBase, Cell, CellWidth[0], TextWrap]}>
                {data.diaperSeries}
                <br />
                {data.diaperShorten}
              </div>
              <div css={[CellBase, Cell, CellWidth[1]]}>{numInsertComma(data.diaperExpendablePrice ?? 0)}円</div>
              <div css={[CellBase, Cell, CellWidth[2]]}>{data.amount ?? 0}個</div>
              <div css={[CellBase, Cell, CellWidth[3]]}>
                {numInsertComma(data.diaperExpendablePrice * data.amount ?? 0)}円
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
});
