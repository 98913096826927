import React from "react";
import dayjs from "dayjs";
import { css } from "@emotion/react";
import { color } from "../../styles";
import { IGetAnnouncementRes } from "../../types/api/announcement/announcement";

interface Props {
  announcement: IGetAnnouncementRes;
}

const Container = css`
  margin: 24px;
  padding: 24px 36px;
  border-radius: 8px;
  background: ${color.white};
`;

const TitleArea = css`
  font-size: 18px;
  font-weight:bold;
`;

const DateArea = css`
  padding-top: 16px;
  font-size: 12px;
  font-weight:bold;
  color: ${color.gray};
`;

const MessageArea = css`
  padding-top: 16px;
  font-size: 16px;
  white-space: pre-line;
`;

const LinkArea = css`
  padding-top: 16px;
  font-size: 16px;
`;

export const AnnouncementCard = React.memo(({announcement}: Props) => {
  return (
    <div css={Container}>
      <div css={TitleArea}>
        {announcement.title}
      </div>
      <div css={DateArea}>
        {dayjs(announcement.start).format('YYYY/MM/DD')}
      </div>
      <div css={MessageArea}>
        {announcement.message}
      </div>
      {announcement.link && (
        <div css={LinkArea}>
          <a href={announcement.link}  target="_blank" rel="noreferrer">{announcement.link}</a>
        </div>
      )}
    </div>
  );
});
