import React from 'react';
import { css } from '@emotion/react';
import { color as baseColor, BtnClickAnimation } from '../styles';

interface Props {
  children: React.ReactNode;
  icon?: React.ReactNode;
  color?: string; // 文字と枠線の色を一括で変更
  bgColor?: string; // 背景色を変更
  minWidth?: string; // ボタンの横幅を指定する場合に ex.'100px'
  disable?: boolean; // ボタンの非活性化
  onClick?: () => void;
}

const ButtonStyle = (color: string, bgColor: string, minWidth: string, disable: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: ${minWidth};
  height: 36px;
  border: 1px solid ${color};
  border-radius: 18px;
  color: ${color};
  font-weight: bold;
  background-color: ${bgColor};
  padding: 0 16px;
  pointer-events: ${disable ? 'none' : 'auto'};
  opacity: ${disable ? 0.4 : 1.0};

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  ${BtnClickAnimation};
`;

const IconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const TextStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 100%;
  font-size: 14px;
`;

export const OutlineButton = React.memo(
  ({
    children,
    icon,
    color = baseColor.dark_gray,
    bgColor = 'transparent',
    minWidth = 'max-content',
    disable = false,
    onClick,
  }: Props) => {
    return (
      <div css={ButtonStyle(color, bgColor, minWidth, disable)} onClick={onClick}>
        {icon && <div css={IconStyle}>{icon}</div>}
        <span css={TextStyle}>{children}</span>
      </div>
    );
  }
);
