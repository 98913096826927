import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import MenuItem from '@mui/material/MenuItem';
import { Select, styled, TextField, InputLabel, FormControl, FormHelperText, Tooltip, Step } from '@mui/material';
import api from '../../../api';
import { color } from '../../../styles';
import { IGetDiaperRes } from '../../../types/api/care-settings/getDiaper';
import { IGetMasterDiaperRes } from '../../../types/api/master/getDiaper';
import { CreateDiaperRequiredRequest } from '../../../types/api/diaper-required/createDiaperRequired';
import { selfDataSelector } from '../../..//redux/config/selfData';
import { useFetchMasterDiapers } from '../../../hooks/api/master/useFetchMasterDiapers';
import { useFetchDiapersRequired } from '../../../hooks/api/diaperRequired/useFetchDiaperRequired';
import { filterDuplicateMakers, filterDuplicateProducts, filterDuplicateSeries } from '../../../utils/filterDuplicate';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { Button } from '../../../components/mui/button';
import { MenuItemProps } from '../../../components/mui/select';
import { PanelContainer } from '../../../components/panel';
import { UnitSelector } from '../stocks/unitSelector';
import { InputFormCell } from '../../../components/parts/inputFormCell';
import { mathRound } from '../../../utils/dataFormatUtil';

interface Props {
  type: 'outer' | 'inner';
  url: string;
  isEdit?: boolean;
}

interface FormType {
  maker: string;
  series: string;
  productName: string;
  cost: number;
  diaperId?: number;
  sheets?: number;
  money?: number;
  caseRequiredStock: number | null;
  bagRequiredStock: number | null;
  caseBorderLine: number | null;
  bagBorderLine: number | null;
}

const defaultValues: FormType = {
  maker: '',
  series: '',
  productName: '',
  cost: 0,
  caseRequiredStock: 0,
  bagRequiredStock: 0,
  caseBorderLine: 0,
  bagBorderLine: 0,
};

const ContentPadding = css`
  padding: 16px;
`;

const ContentTitle = css`
  font-size: 16px;
  padding: 8px 0;
`;

const FilterContainer = css`
  background: ${color.light_gray};
`;

const StyledSelect = styled(Select)`
  width: 400px;
`;

const SelectContainer = css`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Form = css`
  margin-bottom: 16px;
`;

const ButtonContainer = css`
  display: flex;
  justify-content: center;
`;

const ConfirmButton = styled(Button)({
  color: '#fff',
  backgroundColor: color.green,
});

const CancelButton = styled(Button)({
  backgroundColor: color.button_gray,
  color: color.black,
  '&:hover': {
    backgroundColor: color.button_gray,
  },
  marginRight: '16px',
});

const Cost = css`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

const Required = css`
  color: ${color.red};
`;

const Table = css`
  margin: 16px 0;
  border-collapse: collapse;
  border: solid 1px ${color.border_gray};
`;

const TableRow = css`
  height: 70px;
`;

const ThContainer = css`
  min-width: 140px;
  font-size: 14px;
  font-weight: normal;
  border-collapse: collapse;
  border: solid 1px ${color.border_gray};
  background-color: ${color.bg_light_gray};
`;

const ThCell = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;

  span {
    height: 16px;
  }
`;

const TdContainer = css`
  border-collapse: collapse;
  border: solid 1px ${color.border_gray};
`;

const TdCell = css`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 16px;
`;

const Img = css`
  width: 16px;
  height: 16px;
  margin: 4px;
`;

// 追加・編集を行うページ
export const DiaperSetting: React.FC<Props> = ({ type, url, isEdit = false }) => {
  const { nursingHomeId, diaperId } = useParams<{ nursingHomeId: string; diaperId?: string }>();
  const { selfUser } = useSelector(selfDataSelector);
  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { masterPants, masterPads, masterDiapers } = useFetchMasterDiapers();
  const { diapersRequired, fetchDiapersRequired } = useFetchDiapersRequired();
  const { handleSubmit, watch, getValues, reset, setValue, control, formState } = useForm({
    defaultValues,
  });
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const [selectedUnitId, setSelectUnitId] = React.useState<number>(0);
  const [nursingHomeDiaper, setNursingHomeDiaper] = React.useState<IGetDiaperRes>();
  const [masterDiaper, setMasterDiaper] = React.useState<IGetMasterDiaperRes>();
  const [displayRequiredStock, setDisplayRequiredStock] = React.useState<number>(0);
  const [displayBorderLine, setDisplayBorderLine] = React.useState<number>(0);
  const { maker, series, productName } = useWatch({ control });
  // 単価計算フォーム
  const [isOpenCalcForm, setOpenCalcForm] = React.useState(false);
  const { errors } = formState;

  const fetchNursingHomeDiaper = React.useCallback(async () => {
    const res = await api.get('/diapers/:nursingHomeDiaperId', {
      params: { nursingHomeDiaperId: diaperId },
    });
    setNursingHomeDiaper(res);
  }, [masterDiapers]);

  const createRequiredStock = async (params: CreateDiaperRequiredRequest) => {
    await api.post('/nursing-homes/:nursingHomeId/diaper-required', {
      nursingHomeId,
      ...params,
    });
  };

  const updateRequiredStock = async (diaperRequiredId: number, params: CreateDiaperRequiredRequest) => {
    await api.put('/diaper-required/:diaperRequiredId', {
      diaperRequiredId,
      ...params,
    });
  };

  const handleChangeUnit = React.useCallback((e: MenuItemProps) => {
    // TODO: 初回読み込み時に複数回fetchするので直す
    fetchDiapersRequired(+e.key, 'desc', 10000);
    setSelectUnitId(+e.key);
  }, []);

  const diaperRequired = React.useMemo(() => {
    if (!isEdit || !masterDiaper) return;
    const unitId = selectedUnitId === 0 ? null : selectedUnitId;
    return diapersRequired?.find((diaperRequired) => {
      if (diaperRequired.nursingHomeUnitId === unitId && diaperRequired.diaperId === masterDiaper.id) {
        return true;
      }
    });
  }, [masterDiaper, diapersRequired, selectedUnitId]);

  const handleToggleCalcForm = React.useCallback(() => {
    setOpenCalcForm(!isOpenCalcForm);
  }, [isOpenCalcForm]);

  const handleClickCancel = React.useCallback(() => {
    history.push(url);
  }, []);

  const handleCalc = React.useCallback(() => {
    const { sheets, money } = getValues();
    if (!sheets || !money || sheets < 0 || money < 0) return;
    setValue('cost', Math.round((money / sheets) * 10) / 10);
  }, []);

  const memoMakers = React.useMemo(() => {
    if (type === 'outer') {
      if (!masterPants) return;
      return filterDuplicateMakers(masterPants);
    } else {
      if (!masterPads) return;
      return filterDuplicateMakers(masterPads);
    }
  }, [series, masterPants, masterPads]);

  const memoSeries = React.useMemo(() => {
    if (type === 'outer') {
      if (!masterPants) return;
      return filterDuplicateSeries(masterPants, getValues('maker'));
    } else {
      if (!masterPads) return;
      return filterDuplicateSeries(masterPads, getValues('maker'));
    }
  }, [maker, masterPants, masterPads]);

  const memoProductNames = React.useMemo(() => {
    if (type === 'outer') {
      if (!masterPants) return;
      return filterDuplicateProducts(masterPants, getValues('maker'), getValues('series'));
    } else {
      if (!masterPads) return;
      return filterDuplicateProducts(masterPads, getValues('maker'), getValues('series'));
    }
  }, [maker, series, masterPants, masterPads]);

  const onSubmit = async (data: FormType) => {
    if (!masterDiaper) return;

    const requiredStock = masterDiaper.bags
      ? (data.caseRequiredStock ?? 0) * masterDiaper.bags + (data.bagRequiredStock ?? 0)
      : data.bagRequiredStock ?? 0;
    const borderLine = masterDiaper.bags
      ? (data.caseBorderLine ?? 0) * masterDiaper.bags + (data.bagBorderLine ?? 0)
      : data.bagBorderLine ?? 0;
    if (requiredStock < borderLine) {
      showSnackbar('発注ラインをあるべき在庫数以下にしてください');
      return;
    }
    setOnSend(true);
    const diaperParams = {
      diaperId: masterDiaper.id,
      cost: +data.cost ?? 0,
      caseCost: +data.cost ? mathRound(+data.cost * (masterDiaper.bags * masterDiaper.sheets)) : 0,
    };

    try {
      if (!isEdit) {
        await api.post('/nursing-homes/:nursingHomeId/diapers', { nursingHomeId, ...diaperParams });
        setOnSend(false);
        showSnackbar('登録が完了しました');
      } else {
        // 編集時にコストと品名に変更がない場合はリクエストを送らない
        if (nursingHomeDiaper?.diaperId !== masterDiaper.id || +data.cost !== nursingHomeDiaper?.cost) {
          await api.put('/diapers/:nursingHomeDiaperId', { nursingHomeDiaperId: data.diaperId, ...diaperParams });
        }
        if (!diaperRequired) {
          const diaperRequiredParams = {
            nursingHomeDiaperId: masterDiaper.id,
            nursingHomeUnitId: selectedUnitId ? selectedUnitId : null,
            requiredStock: (data.caseRequiredStock ?? 0) * masterDiaper.bags + (data.bagRequiredStock ?? 0),
            borderLine: (data.caseBorderLine ?? 0) * masterDiaper.bags + (data.bagBorderLine ?? 0),
          };
          if (data.bagBorderLine || data.bagRequiredStock || data.caseBorderLine || data.caseRequiredStock) {
            await createRequiredStock(diaperRequiredParams);
          }
        } else if (isEdit && diaperRequired?.id) {
          const diaperRequiredParams = {
            nursingHomeDiaperId: masterDiaper.id,
            nursingHomeUnitId: selectedUnitId ? selectedUnitId : null,
            requiredStock: (data.caseRequiredStock ?? 0) * masterDiaper.bags + (data.bagRequiredStock ?? 0),
            borderLine: (data.caseBorderLine ?? 0) * masterDiaper.bags + (data.bagBorderLine ?? 0),
          };
          await updateRequiredStock(diaperRequired.id, diaperRequiredParams);
        }
        setOnSend(false);
        showSnackbar('保存しました');
      }
      history.push(url);
    } catch (e) {
      setOnSend(false);
      showSnackbar('エラーが発生しました');
    }
  };

  React.useEffect(() => {
    if (isEdit && diaperId) {
      fetchNursingHomeDiaper();
    }
  }, []);

  React.useEffect(() => {
    if (nursingHomeDiaper) {
      reset({
        series: nursingHomeDiaper.diaperSeries,
        productName: nursingHomeDiaper.diaperName,
        cost: nursingHomeDiaper.cost,
        diaperId: nursingHomeDiaper.id,
      });
    }
  }, [nursingHomeDiaper]);

  // 編集時のマスターのおむつデータの初期値設定
  React.useEffect(() => {
    if (!isEdit || !nursingHomeDiaper || !masterDiapers) return;
    const masterDiaperData = masterDiapers?.find((v) => v.id === nursingHomeDiaper.diaperId);
    setMasterDiaper(masterDiaperData);
  }, [nursingHomeDiaper, masterDiapers]);

  // unit権限のアカウントの場合のunit初期値設定
  React.useEffect(() => {
    if (!selfUser) return;
    if (selfUser.hasUnitPerm) setSelectUnitId(selfUser.nursingHomeUnitId);
  }, [selfUser]);

  // diaperRequiredからあるべき在庫と発注ラインの初期値を設定
  React.useEffect(() => {
    if (masterDiaper && diaperRequired?.requiredStock) {
      const caseRequiredStock = masterDiaper.bags ? Math.floor(diaperRequired.requiredStock / masterDiaper.bags) : 0;
      const bagRequiredStock = masterDiaper.bags
        ? diaperRequired.requiredStock % masterDiaper.bags
        : diaperRequired.requiredStock;
      const caseBorderLine = masterDiaper.bags ? Math.floor(diaperRequired.borderLine / masterDiaper.bags) : 0;
      const bagBorderLine = masterDiaper.bags
        ? diaperRequired.borderLine % masterDiaper.bags
        : diaperRequired.borderLine;
      setValue('caseRequiredStock', caseRequiredStock);
      setValue('bagRequiredStock', bagRequiredStock);
      setValue('caseBorderLine', caseBorderLine);
      setValue('bagBorderLine', bagBorderLine);
    } else {
      setValue('caseRequiredStock', 0);
      setValue('bagRequiredStock', 0);
      setValue('caseBorderLine', 0);
      setValue('bagBorderLine', 0);
    }
  }, [diaperRequired, masterDiaper, selectedUnitId]);

  // あるべき在庫と発注ラインのフォーム内容が変わった場合に表示する合計袋数を更新
  React.useEffect(() => {
    if (!masterDiaper) return;
    const subscription = watch((value, { name, type: watchType }) => {
      const requiredStock = (value.caseRequiredStock ?? 0) * (masterDiaper.bags ?? 0) + (value.bagRequiredStock ?? 0);
      const borderLine = (value.caseBorderLine ?? 0) * (masterDiaper.bags ?? 0) + (value.bagBorderLine ?? 0);
      setDisplayRequiredStock(requiredStock);
      setDisplayBorderLine(borderLine);
    });
    return () => subscription.unsubscribe();
  }, [watch, masterDiaper]);

  // 品目が変わった場合に該当する製品をmasterから取得し更新
  React.useEffect(() => {
    if (!masterDiapers) return;
    const subscription = watch((value, { name, type: watchType }) => {
      const masterDiaperData = masterDiapers?.find((v) => v.shorten === value.productName);
      setMasterDiaper(masterDiaperData);
    });
    return () => subscription.unsubscribe();
  }, [watch, masterDiapers]);

  return (
    <PanelContainer
      title={
        type === 'outer'
          ? isEdit
            ? 'テープ/パンツの編集'
            : 'テープ/パンツの追加'
          : isEdit
          ? 'パッドの編集'
          : 'パッドの追加'
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div css={[FilterContainer, ContentPadding]}>
          <div css={ContentTitle}>絞り込み</div>
          <div css={SelectContainer}>
            {memoMakers && (
              <Controller
                name='maker'
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id='maker'>メーカー</InputLabel>

                    <StyledSelect
                      {...field}
                      labelId='maker'
                      label='メーカー'
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    >
                      {memoMakers.map((maker, index) => (
                        <MenuItem key={index} value={maker.value}>
                          {maker.value}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            )}
            {memoSeries && (
              <Controller
                name='series'
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id='series'>シリーズ</InputLabel>
                    <StyledSelect
                      {...field}
                      labelId='series'
                      label='シリーズ'
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    >
                      {memoSeries.map((maker, index) => (
                        <MenuItem key={index} value={maker.value}>
                          {maker.value}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                )}
              />
            )}
          </div>
        </div>
        <div css={ContentPadding}>
          <div css={Form}>
            <div css={ContentTitle}>
              製品名<span css={Required}>*</span>
            </div>
            {memoProductNames && (
              <Controller
                name='productName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormControl error={!!errors.productName}>
                    <StyledSelect
                      {...field}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    >
                      {memoProductNames.map((maker, index) => (
                        <MenuItem key={index} value={maker.value}>
                          {maker.value}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    {!!errors.productName && <FormHelperText>製品名は必須入力です</FormHelperText>}
                  </FormControl>
                )}
              />
            )}
          </div>
          <div css={Form}>
            <div css={ContentTitle}>
              1枚あたりの単価<span css={Required}>*</span>
            </div>
            <Controller
              name='cost'
              control={control}
              rules={{ min: 0, required: true }}
              render={({ field }) => (
                <FormControl>
                  <div css={Cost}>
                    <TextField
                      type='number'
                      variant='outlined'
                      autoComplete='off'
                      value={field.value}
                      inputProps={{
                        min: 0,
                        max: 100000,
                        step: '0.01',
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={!!errors.cost}
                      helperText={!!errors.cost && '1枚あたりの単価は必須です'}
                    />
                    <>円/枚</>
                    <Button variant='outlined' onClick={handleToggleCalcForm}>
                      1パック枚数と金額から単価計算
                    </Button>
                  </div>
                </FormControl>
              )}
            />
          </div>
          {isOpenCalcForm && (
            <div css={Form}>
              <Controller
                name='sheets'
                control={control}
                rules={{ min: 0 }}
                render={({ field }) => (
                  <FormControl>
                    <div css={Cost}>
                      <TextField
                        type='number'
                        variant='outlined'
                        value={field.value}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <>枚入り</>
                    </div>
                  </FormControl>
                )}
              />
              <Controller
                name='money'
                control={control}
                rules={{ min: 0 }}
                render={({ field }) => (
                  <FormControl>
                    <div css={Cost}>
                      <TextField
                        type='number'
                        variant='outlined'
                        value={field.value}
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <>円</>
                      <Button variant='contained' onClick={handleCalc}>
                        単価計算
                      </Button>
                    </div>
                  </FormControl>
                )}
              />
            </div>
          )}
        </div>
        {isEdit && (
          <div css={ContentPadding}>
            <div css={ContentTitle}>設定</div>
            <div>
              <UnitSelector onChangeUnit={handleChangeUnit} />
            </div>
            <table css={Table}>
              <tbody>
                <tr css={TableRow}>
                  <th css={ThContainer}>
                    <div css={ThCell}>
                      <span>あるべき在庫</span>
                      <Tooltip title={'在庫として持っておくべき数量'} arrow={true}>
                        <img css={Img} src='/images/expendable/ic_question.svg' alt='あるべき在庫説明' />
                      </Tooltip>
                    </div>
                  </th>
                  <td css={TdContainer}>
                    <div css={TdCell}>
                      <Controller
                        name='caseRequiredStock'
                        control={control}
                        render={({ field }) => (
                          <InputFormCell
                            value={field.value}
                            id='required-case'
                            label='ケース'
                            min={0}
                            max={9999}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      ></Controller>
                      <span>+</span>
                      <Controller
                        name='bagRequiredStock'
                        control={control}
                        render={({ field }) => (
                          <InputFormCell
                            value={field.value}
                            id='required-bag'
                            label='袋'
                            min={0}
                            max={9999}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      ></Controller>
                      <span>=</span>
                      <span>合計{displayRequiredStock}袋</span>
                    </div>
                  </td>
                </tr>
                <tr css={TableRow}>
                  <th css={ThContainer}>
                    <div css={ThCell}>
                      <span>発注ライン</span>
                      <Tooltip title={'在庫がなくなってきていることを示す数量'} arrow={true}>
                        <img css={Img} src='/images/expendable/ic_question.svg' alt='発注ライン説明' />
                      </Tooltip>
                    </div>
                  </th>
                  <td css={TdContainer}>
                    <div css={TdCell}>
                      <Controller
                        name='caseBorderLine'
                        control={control}
                        render={({ field }) => (
                          <InputFormCell
                            value={field.value}
                            id='borderline-case'
                            label='ケース'
                            min={0}
                            max={9999}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      ></Controller>
                      <span>+</span>
                      <Controller
                        name='bagBorderLine'
                        control={control}
                        render={({ field }) => (
                          <InputFormCell
                            value={field.value}
                            id='borderline-bag'
                            label='袋'
                            min={0}
                            max={9999}
                            onChange={(e) => field.onChange(e)}
                          />
                        )}
                      ></Controller>
                      <span>=</span>
                      <span>合計{displayBorderLine}袋</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div css={ButtonContainer}>
          <CancelButton variant='contained' onClick={handleClickCancel}>
            キャンセル
          </CancelButton>
          <ConfirmButton variant='contained' disabled={onSend} type='submit'>
            {isEdit ? '保存' : '追加'}
          </ConfirmButton>
        </div>
      </form>
    </PanelContainer>
  );
};;;;;;
