import React from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import api from '../../../api';
import { useFetchDiapersRequired } from '../../../hooks/api/diaperRequired/useFetchDiaperRequired';
import { useFetchDiaperStocks } from '../../../hooks/api/diaperStock/useFetchDiaperStocks';
import { useFetchNursingHomeDiapers } from '../../../hooks/api/careSettings/useFetchNursingHomeDiapers';
import { IUpdateDiaperStockParams } from '../../../types/api/diaper-stock/updateDiaperStock';
import { ICreateDiaperStockParams } from '../../../types/api/diaper-stock/createDiaperStock';
import { IGetDiaperRes } from '../../../types/api/care-settings/getDiaper';
import { StockTable } from '../../../components/parts/stockTable/stockTable';
import { BottomStickyButton } from '../../../components/bottomStickyButton';
import { PanelContainer } from '../../../components/panel';
import { UnitSelector } from './unitSelector';
import { MenuItemProps } from '../../../components/mui/select';
import { color } from '../../../styles';
import { convertUtcToTzString, getCurrentUtcTime } from '../../../utils/dateutil';
import { cloneArray, sortArray } from '../../../utils/arrayUtil';
import { useSnackbar } from '../../../contexts/SnackbarContext';

export interface IStockTableData extends IGetDiaperRes {
  requiredStock: string; // あるべき在庫 (diaperRequiredから取得)
  displaySize: string; // 表示用のサイズ情報
  stock: number; // ケースの在庫数
  bagStock: number; // 袋の在庫数
  stockUpdatedAt: string; // 在庫更新日時 (diaperStockから取得)
  stockId: number; // StockIdがある場合は格納（post, putの判断に使用）
}

const TableTitle = css`
  display: flex;
  align-items: center;
  display: flex;
  height: 36px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  color: ${color.dark_gray};
`;

const TableWrapper = css`
  margin-bottom: 24px;
`;

export const Stocks = React.memo(() => {
  const { showSnackbar } = useSnackbar();
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { nursingHomeDiapers } = useFetchNursingHomeDiapers(true);
  const { diapersRequired, fetchDiapersRequired } = useFetchDiapersRequired(0, 'desc', 10000);
  const { diaperStocks, fetchDiaperStocks } = useFetchDiaperStocks(false, 0, 'desc', 10000);
  const [beforeDiapersData, setBeforeDiapersData] = React.useState<IStockTableData[]>();
  const [pantsData, setPantsData] = React.useState<IStockTableData[]>();
  const [changedDiapersData, setChangedDiapersData] = React.useState<IStockTableData[]>();
  const [changedPantsData, setChangedPantsData] = React.useState<IStockTableData[]>();
  const [changedPadsData, setChangedPadsData] = React.useState<IStockTableData[]>();
  const [padsData, setPadsData] = React.useState<IStockTableData[]>();
  const [selectedUnitId, setSelectUnitId] = React.useState<number>(0);
  const [onSend, setOnSend] = React.useState<boolean>(false);

  const pickDiffStock = () => {
    return changedDiapersData?.filter((changedData) => {
      return beforeDiapersData?.some((beforeData) => {
        return (
          beforeData.id === changedData.id &&
          (beforeData.stock !== changedData.stock || beforeData.bagStock !== changedData.bagStock)
        );
      });
    });
  };

  const isEnableSendButton = React.useMemo(() => {
    return !!pickDiffStock()?.length;
  }, [changedDiapersData]);

  const handleChangeUnit = React.useCallback(
    (e: MenuItemProps) => {
      setSelectUnitId(+e.key);
    },
    [selectedUnitId]
  );

  const handleClickSendButton = () => {
    const filteredData = pickDiffStock();
    if (!filteredData?.length) return;
    setOnSend(true);
    const currentTime = getCurrentUtcTime();
    // 登録・更新のAPIが1件ずつにしか対応していないため、在庫登録・更新するPromiseを配列で管理
    const promises: Promise<any>[] = [];

    // stockIdがある場合はput、ない場合はpostを行う
    filteredData.forEach((v) => {
      if (v.stockId === 0) {
        const params: ICreateDiaperStockParams = {
          stock: v.stock,
          bagStock: v.bagStock,
          stockUpdatedAt: currentTime,
          nursingHomeDiaperId: v.id,
          nursingHomeUnitId: selectedUnitId,
        };
        if (!selectedUnitId) delete params.nursingHomeUnitId;
        promises.push(createDiaperStock(params, +nursingHomeId));
      } else {
        const params: IUpdateDiaperStockParams = {
          stock: v.stock,
          bagStock: v.bagStock,
          stockUpdatedAt: currentTime,
        };
        promises.push(updateDiaperStock(params, v.stockId));
      }
    });
    Promise.all(promises)
      .then(() => {
        showSnackbar('在庫を更新しました', 'success');
        // 更新後データの取得
        Promise.all([fetchDiaperStocks(selectedUnitId), fetchDiapersRequired(selectedUnitId)])
          .then(() => {
            setOnSend(false);
          })
          .catch((err) => {
            console.log(err);
            setOnSend(false);
          });
      })
      .catch((err) => {
        setOnSend(false);
        console.log(err);
      });
  };

  const handleChangePantsStock = React.useCallback(
    (id: number, stock: number, bagStock: number) => {
      setChangedPantsData((prev) => {
        if (!prev) return;
        prev[id].stock = stock;
        prev[id].bagStock = bagStock;
        return [...prev];
      });
    },
    [changedPantsData]
  );

  const handleChangePadsStock = React.useCallback(
    (id: number, stock: number, bagStock: number) => {
      setChangedPadsData((prev) => {
        if (!prev) return;
        prev[id].stock = stock;
        prev[id].bagStock = bagStock;
        return [...prev];
      });
    },
    [changedPadsData]
  );

  const insertRequiredStock = React.useCallback(
    (diaper: IGetDiaperRes) => {
      const result =
        diapersRequired?.find((v) => {
          return v.diaperId === diaper.diaperId;
        })?.requiredStock ?? 0;
      return result ? result + '袋' : '';
    },
    [diapersRequired]
  );

  const insertDisplaySize = (diaper: IGetDiaperRes) => {
    return !diaper.diaperSize || diaper.diaperSize === 'unknown' ? '-' : diaper.diaperSize.toUpperCase();
  };

  const insertStockUpdatedAt = React.useCallback(
    (diaper: IGetDiaperRes) => {
      const result =
        diaperStocks?.find((v) => {
          return v.nursingHomeDiaperId === diaper.id;
        })?.stockUpdatedAt ?? 0;
      return result ? convertUtcToTzString(result) : '';
    },
    [diaperStocks]
  );

  const insertStock = React.useCallback(
    (diaper: IGetDiaperRes) => {
      const result =
        diaperStocks?.find((v) => {
          return v.nursingHomeDiaperId === diaper.id;
        })?.stock ?? 0;
      return result ? result : 0;
    },
    [diaperStocks]
  );

  const insertBagStock = React.useCallback(
    (diaper: IGetDiaperRes) => {
      const result =
        diaperStocks?.find((v) => {
          return v.nursingHomeDiaperId === diaper.id;
        })?.bagStock ?? 0;
      return result ? result : 0;
    },
    [diaperStocks]
  );

  const insertStockId = React.useCallback(
    (diaper: IGetDiaperRes) => {
      const result =
        diaperStocks?.find((v) => {
          return v.nursingHomeDiaperId === diaper.id;
        })?.id ?? 0;
      return result ? result : 0;
    },
    [diaperStocks]
  );

  // StockデータにnursingHomeDiaperからシリーズ名、diaperRequiredからあるべき在庫を追加、サイズ情報を大文字に
  const createTableData = (diapers: IGetDiaperRes[]) => {
    return diapers?.map((diaper) => {
      return {
        ...diaper,
        requiredStock: insertRequiredStock(diaper),
        displaySize: insertDisplaySize(diaper),
        stock: insertStock(diaper),
        bagStock: insertBagStock(diaper),
        stockUpdatedAt: insertStockUpdatedAt(diaper),
        stockId: insertStockId(diaper),
      };
    });
  };

  const createDiaperStock = async (params: ICreateDiaperStockParams, id: number) => {
    return await api.post('/nursing-homes/:nursingHomeId/diaper-stocks', {
      nursingHomeId: id,
      stock: params.stock,
      bagStock: params.bagStock,
      stockUpdatedAt: params.stockUpdatedAt,
      nursingHomeDiaperId: params.nursingHomeDiaperId,
      nursingHomeUnitId: params.nursingHomeUnitId,
    });
  };

  const updateDiaperStock = async (params: IUpdateDiaperStockParams, id: number) => {
    return await api.put('/diaper-stocks/:diaperStockId', {
      diaperStockId: id,
      stock: params.stock,
      bagStock: params.bagStock,
      stockUpdatedAt: params.stockUpdatedAt,
    });
  };

  // PantsとPadsのデータを結合（順番は担保されていない）
  React.useEffect(() => {
    if (!changedPantsData || !changedPadsData) return;
    const cloneData = changedPantsData?.concat(changedPadsData ?? []);
    setChangedDiapersData(cloneData);
  }, [changedPantsData, changedPadsData]);

  // TODO: 初回に無駄にFetchが走っているため、改善する
  React.useEffect(() => {
    if (!selectedUnitId === undefined) return;
    fetchDiaperStocks(selectedUnitId);
    fetchDiapersRequired(selectedUnitId);
  }, [selectedUnitId]);

  // テーブルデータ作成
  React.useEffect(() => {
    if (!nursingHomeDiapers || !diapersRequired || !diaperStocks) return;
    let diapersData = createTableData(nursingHomeDiapers);
    diapersData = sortArray(diapersData, 'diaperName', 'desc');
    setBeforeDiapersData(cloneArray(diapersData));

    const pants = diapersData?.filter((v) => v.diaperShape === 'outer');
    const pads = diapersData?.filter((v) => v.diaperShape === 'inner');

    setChangedPantsData(pants);
    setChangedPadsData(pads);

    setPantsData(pants);
    setPadsData(pads);
  }, [nursingHomeDiapers, diapersRequired, diaperStocks]);

  return (
    <>
      <PanelContainer title={'在庫確認・設定'}>
        <UnitSelector onChangeUnit={handleChangeUnit} />
        <div css={TableTitle}>テープ/パンツ</div>
        <div css={TableWrapper}>
          {pantsData && pantsData.length ? (
            <StockTable data={pantsData} onChange={handleChangePantsStock} />
          ) : (
            'テープ/パンツのデータはありません'
          )}
        </div>
        <div css={TableTitle}>パッド</div>
        <div>
          {padsData && padsData.length ? (
            <StockTable data={padsData} onChange={handleChangePadsStock} />
          ) : (
            'パッドデータはありません'
          )}
        </div>
      </PanelContainer>
      <BottomStickyButton disabled={!isEnableSendButton || onSend} onClick={handleClickSendButton}>
        在庫登録
      </BottomStickyButton>
    </>
  );
});
